import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_SummaryActionsHistory = _resolveComponent("SummaryActionsHistory")!
  const _component_q_tab_panel = _resolveComponent("q-tab-panel")!
  const _component_SummaryMessage = _resolveComponent("SummaryMessage")!
  const _component_q_tab_panels = _resolveComponent("q-tab-panels")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, { class: "full-width" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card_section, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_tabs, {
            modelValue: _ctx.activeTab,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event)),
            dense: "",
            class: "nav-tabs",
            "active-color": "primary",
            "indicator-color": "primary",
            align: "left",
            "narrow-indicator": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_tab, {
                name: "actions",
                label: _ctx.$t('task.buttons.actions'),
                class: "nav nav-item flex-grow-0"
              }, null, 8, ["label"]),
              _createVNode(_component_q_tab, {
                name: "message",
                label: _ctx.$t('demand.tabs.message'),
                class: "nav nav-item flex-grow-0"
              }, null, 8, ["label"])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_q_card_section, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_tab_panels, {
            modelValue: _ctx.activeTab,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.activeTab) = $event)),
            animated: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_tab_panel, {
                name: "actions",
                class: "p-0 pt-1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_SummaryActionsHistory, {
                    "entity-state": _ctx.entityState,
                    "histories-step": _ctx.historiesStep
                  }, null, 8, ["entity-state", "histories-step"])
                ]),
                _: 1
              }),
              _createVNode(_component_q_tab_panel, {
                name: "message",
                class: "p-0 pt-1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_SummaryMessage, { "entity-state": _ctx.entityState }, null, 8, ["entity-state"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}