
import { Vue } from "vue-class-component";
import store from "@/store";
import {
  formatWithMask,
  formatAmoutswitchCurrency,
} from "@/utils/configuration/formatters-config";

export default class SimulationFinancialSituation extends Vue {
  formatWithMask = formatWithMask;

  get financialSituation() {
    return (
      store.state.financingModule.scalesModule.scaleSelected?.calculationResult ||
      store.state.financingModule.scalesModule.scaleSelected?.financialSituation
    );
  }

  get sumOfInterests() {
    const amount = this.financialSituation.sumOfInterests?.amount || 0
    return formatAmoutswitchCurrency(
      "EUR",
        amount
    );
  }
  get sumOfTotalPayments() {
    const amount = this.financialSituation.sumOfTotalPayments?.amount || 0
    return formatAmoutswitchCurrency(
      "EUR",
        amount
    );
  }
  get aprc() {
    return this.financialSituation.aprc.toFixed(2);
  }
  get air() {
    return this.financialSituation.air.toFixed(2);
  }
}
