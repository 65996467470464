import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '../store'
import i18n from '../i18n'
import Home from "@/components/Home.vue";
import { tokenIsValid, checkUserByRole } from "@/utils";
import { HomePageRoles, MenuRoles } from "@/types";


/**
 * Define route configuration with role-based access control
 * 
 * Meta role "all" means the route is accessible to all users
 * Other roles are specific to user permissions
 */
const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        meta: { role: "all" },
        component: () => import('@/components/auth/Login.vue')
    },
    {
        path: '/home',
        name: 'home',
        meta: { role: MenuRoles.ROLE_MENU_HOME },
        component: Home
    },
    {
        path: '/login',
        name: 'login',
        meta: { role: "all" },
        component: () => import('@/components/auth/Login.vue')
    },
    {
        path: '/signup',
        name: 'signup',
        meta: { role: "all" },
        component: () => import('@/components/auth/Registration.vue')
    },
    {
        path: '/forgotPassword',
        name: 'forgotPassword',
        meta: { role: "all" },
        component: () => import('@/components/auth/ForgotPassword.vue')
    },
    {
        path: '/demand/list',
        name: 'demand-list',
        meta: {
            role: MenuRoles.ROLE_MENU_OFFER  
        },
        component: () => import('@/views/demand/DemandViewList.vue')
    },
    {
        path: '/demand/edit/:id?',
        name: 'demand-edit',
        meta: {
            role: MenuRoles.ROLE_MENU_OFFER  
        },
        component: () => import('@/views/demand/DemandViewEdit.vue')
    },
    {
        path: '/mo/demand/detail/:id?',
        name: 'mo-demand-detail',
        meta: {
            role: MenuRoles.ROLE_MENU_OFFER  
        },
        component: () => import('@/views/middle-office/MiddleOfficeView.vue')
    },
    {
        path: '/third/list',
        name: 'third-list',
        meta: {
            role: MenuRoles.ROLE_MENU_THIRD  
        },
        component: () => import('@/views/party/ThirdViewList.vue')
    },
    {
        path: '/third/edit',
        name: 'third-edit',
        meta: {
            role: MenuRoles.ROLE_MENU_THIRD  
        },
        component: () => import('@/views/party/ThirdViewEdit.vue')
    },
    {
        path: '/task/list',
        name: 'task-list',
        meta: {
            role: MenuRoles.ROLE_MENU_TASKS 
        },
        component: () => import('@/views/tasks/TaskViewList.vue')
    },
    {
        path: '/task/detail',
        name: 'TaskDetail',
        meta: {
            role: MenuRoles.ROLE_MENU_TASKS 
        },
        component: () => import('@/views/tasks/TaskView.vue')
    },
    {
        path: '/scale/list',
        name: 'scale-list',
        meta: {
            role: MenuRoles.ROLE_MENU_SCALE 
        },
        component: () => import('@/views/configuration/scales/ScaleViewList.vue')
    },
    {
        path: '/scale/edit',
        name: 'scale-edit',
        meta: {
            role: MenuRoles.ROLE_MENU_SCALE 
        },
        component: () => import('@/views/configuration/scales/ScaleViewDetails.vue')
    },
    {
        path: '/profile',
        name: 'Profile',
        meta: {
            role: "all"
        },
        component: () => import('@/views/user-preference/ProfileView.vue')
    }, {
        path: '/garanty/list',
        name: 'garanty-list',
        meta: {
            role: MenuRoles.ROLE_MENU_GARANTY 
        },
        component: () => import('@/views/configuration/garanty/GuaranteeView.vue')
    },
    {
        path: '/good/list',
        name: 'asset-list',
        meta: {
            role: MenuRoles.ROLE_MENU_GOOD 
        },
        component: () => import('@/views/configuration/goods/GoodViewList.vue')
    },
    {
        path: '/userAdmin/list',
        name: 'user-list',
        meta: {
            role: MenuRoles.ROLE_MENU_USERS 
        },
        component: () => import('@/views/configuration/users/UserViewList.vue')
    },
    {
        path: '/userAdmin/edit',
        name: 'user-edit',
        meta: {
            role: MenuRoles.ROLE_MENU_USERS 
        },
        component: () => import('@/views/configuration/users/UserViewDetails.vue'),
    },
    {
        path: '/good/edit',
        name: 'good-edit',
        meta: {
            role: MenuRoles.ROLE_MENU_GOOD 
        },
        component: () => import('@/views/configuration/goods/GoodViewDetails.vue')
    }, 
    {
        path: '/availability/list',
        name: 'availability-list',
        meta: {
            role: MenuRoles.ROLE_MENU_AVAILABILITY 
        },
        component: () => import('@/views/configuration/availabilities/AvailabilityViewList.vue')
    },
    {
        path: '/changePassword',
        name: 'changePassword',
        meta: {
            role: "all"
        },
        component: () => import('@/components/auth/ChangePassword.vue')
    },
    {
        path: '/role-menu',
        name: 'ScreenAuthority',
        meta: {
            role: MenuRoles.ROLE_MENU_SCREEN_AUTHORITY 
        },
        component: () => import('@/views/configuration/role-menu/RoleMenuViewList.vue')
    },
    {
        path: '/superset/list',
        name: 'superset-list',
        meta: {
            role: MenuRoles.ROLE_MENU_SUPERSET 
        },
        component: () => import('@/views/configuration/superset/SupersetViewList.vue')
    },
       {
        path: '/global-settings/edit',
           name: 'global-settings',
           meta: {
               role: MenuRoles.ROLE_MENU_GLOBAL_SETTINGS 
           },
        component: () => import('@/views/configuration/globalSettings/globalSettings.vue')
    },
    {
        path: '/dashboard',
        name: 'ScreenDashboard',
        meta: {
            role: MenuRoles.ROLE_MENU_DASHBOARD  
        },
        
        component: () => import('@/views/configuration/dashboard/DashboardViewList.vue')
    },
    {
        path: '/404',
        name: 'Page404',
        meta: {
            role: "all"
        },
        component: () => import('@/views/404.vue')
    },
    // Catch-all route to handle undefined routes
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        meta: { role: "all" },
        component: () => import('@/views/404.vue')
    }
]

// Create router instance with history mode
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    // Scroll behavior to reset scroll position on route change
    scrollBehavior(to, from, savedPosition) {
        return savedPosition || { top: 0 }
    }
})

/**
 * Global navigation guard for authentication and role-based access
 * 
 * Key responsibilities:
 * 1. Check user authentication
 * 2. Validate route access based on user roles
 * 3. Handle redirects for authenticated/unauthenticated users
 * 4. Manage module-specific state
 * 5. Handle application lock state
 */
router.beforeEach((to, from, next) => {
    // Determine if the route is accessible based on role
    const isRouteAccessible = to.meta.role === "all" || checkUserByRole([to.meta.role]);

    if (isRouteAccessible) {
        store.dispatch('resetStates');
        // Reset task filter for non-task routes
        if (!to.path.includes('task')) {
            store.dispatch('taskModule/setFilterByOfferReference', '')
        }

        // Dispatch module based on current route
        const moduleDispatches: { [key: string]: string } = {
            "demand": "demand",
            "third": "third",
            "task": "task",
            "good": "good",
            "order": "dossier",
            "deliverySchedule": "deliverySchedule"
        };

        const moduleKey = Object.keys(moduleDispatches).find(key => to.path.includes(key));
        store.dispatch("module", moduleKey ? moduleDispatches[moduleKey] : "home");

        // Handle authentication and routing logic
        const publicRoutes = ['/', '/login', '/signup', '/forgotPassword'];
        const isPublicRoute = publicRoutes.includes(to.path);

        if (isPublicRoute && tokenIsValid()) {
            // Redirect authenticated users from login/signup to appropriate home page
            const homePageRolesKeys = Object.keys(HomePageRoles);
            for (let key of homePageRolesKeys) {
                if (checkUserByRole([key])) {
                    next('/' + (HomePageRoles as any)[key]);
                    return;
                }
            }
        } else if (!isPublicRoute && !tokenIsValid()) {
            // Redirect unauthenticated users to login
            next('/login');
            return;
        } else if (to.path === '/changePassword' && tokenIsValid()) {
            // Special handling for change password route
            store.commit('authModule/setChangePassWordIn', false);
            next();
            return;
        }

        // Handle application lock state
        const lock = store.state.lockState;
        if (lock) {
            store.dispatch("showConfirmationDialog", {
                message: i18n.global.t('main.confirmationDialog.message'),
                target: to.fullPath
            });
            next(false);
        } else {
            next();
        }
    } else {
        // Redirect to 404 for unauthorized routes
        next('/404');
    }
});


export default router

/**
 * Router Configuration Notes:
 * 
 * 1. Uses history mode for clean URLs
 * 2. Implements role-based access control
 * 3. Handles authentication redirects
 * 4. Provides a catch-all route for undefined paths
 * 5. Manages module-specific state dispatching
 * 6. Supports application lock state
 */