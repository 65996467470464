
import i18n from "@/i18n";
import store from "@/store";
import {Vue} from "vue-class-component";
import {ComboBoxItem, ProposalGuarantee, UniformOdmName} from "@/types";
import {UON} from "@/utils";

export default class GuaranteeDetail extends Vue {
    asset = [] as ComboBoxItem[];
    fullFilledAssociatedParty = false;
    fullFilledUnderlyingType = false;

    get offerAssociatedParties() {
        return store.state.demandModule?.offer?.associatedParties;
    }

    /**
     * Retrieves the underlying asset products from the store's demand module and extracts their resource UIDs.
     *
     * @function
     * @name underlying
     * @returns {Array} An array of asset product resource UIDs.
     */
    get underlying(): Array<any> {
        store.state.demandModule?.assetState.proposalAssets.forEach(
            (asset: any) => {
                if (asset.assetProduct) {
                    this.asset.push({
                        value: asset.assetProduct.resourceUid,
                        label: asset.assetProduct.resourceUid.replace(
                            "CarProduct.",
                            ""
                        ),
                        config: {
                            objectType: asset.assetProduct.objectType
                        }
                    });
                }
            }
        );
        return this.asset;
    }

    /**
     * Returns the localized display value of the guarantee type for the selected product guarantee.
     *
     * @function
     * @name guarantyProposalType
     * @returns {string} The localized display value of the guarantee type.
     */
    get guarantyProposalType(): string {
        if (this.proposalGuaranteeSelected.productGuarantee?.guaranteeType) {
            return i18n.global.t("garanty.guaranteeType." + (
                    this.proposalGuaranteeSelected.productGuarantee.guaranteeType as string
                ).replace("product-", "")
            );
        }
        return "";
    }


    /**
     * Initiates the process of closing the scale detail by dispatching a confirmation dialog.
     *
     * @function
     * @name closeScaleDetail
     * @returns {void}
     */
    closeScaleDetail(): void {
        store.dispatch("financingModule/proposalGuaranteeModule/showProposalGuaranteeDetail");
    }

    /**
     * Retrieves the selected proposal guarantee from the store's financing module and sets the associated party name.
     *
     * @function
     * @name proposalGuaranteeSelected
     * @returns {ProposalGuarantee} The selected proposal guarantee.
     */
    get proposalGuaranteeSelected(): ProposalGuarantee {
        const proposalGuaranteeSelected =store.getters[ "financingModule/proposalGuaranteeModule/getProposalGuaranteeSelected" ];
        this.fullFilledUnderlyingType = !!proposalGuaranteeSelected.productGuarantee?.underlyingType;
        return proposalGuaranteeSelected;
    }

    get productGuaranteeSelected() {
        return store.getters["financingModule/proposalGuaranteeModule/getProductGuaranteeSelected"];
    }

    get guaranteeParties() {
      if ( this.productGuaranteeSelected ) {
        if ( !this.productGuaranteeSelected.partyReference.resourceUid ) {
          const parties = this.offerAssociatedParties?.filter(oAssociatedParty =>
              oAssociatedParty?.role_code === this.productGuaranteeSelected?.role?.resourceUid
          ).map(oAssociatedParty => {
            let item = {value: '', label: ''} as ComboBoxItem;
            if ( oAssociatedParty.associatedParty?.third ) {
              const {type, person, organization} = oAssociatedParty.associatedParty?.third;
              const setItem = (objectType: string, label?: string, uid?: string) => {
                return {
                  config: {
                    objectType: objectType,
                    fromOffer: true
                  },
                  label: label,
                  value: uid
                }
              }
              if( type?.id?.toLowerCase().includes("person")) {
                item = setItem(
                    UON.ObjectType.frenchPerson,
                    `${person?.firstName} ${person?.familyName}`,
                    person?.resourceUid
                );
              }
              else {
                item = setItem(
                    UON.ObjectType.frenchOrganization,
                    organization?.name,
                    organization?.resourceUid
                );
              }
            }
            return item;
          });
          this.fullFilledAssociatedParty = !this.fullFilledAssociatedParty;
          return parties;
        }
        else {
          this.fullFilledAssociatedParty = true;
          return {
            value: this.productGuaranteeSelected.partyReference.resourceUid,
            label: this.productGuaranteeSelected.partyReference.label
          } as ComboBoxItem
        }
      }
      else {
        return [];
      }
    }

    add() {
        this.closeScaleDetail();
        this.$emit("addProposalGuarantee");
    }

    /**
     * Retrieves and transforms the underlying types from the picklist module, excluding "OTHER", and returns an array of items with localized labels.
     *
     * @function
     * @name underlyingType
     * @returns {Array} An array of underlying types with localized labels.
     */
    get underlyingType(): ComboBoxItem[] | undefined {
        return store.getters["picklistModule/underlyingTypeItems"]
            .filter((type: ComboBoxItem) => type.value !== "OTHER")
            .map((item: ComboBoxItem) => (
                {
                    ...item,
                    label: item.value?.toString() !== "" ?
                        i18n.global.t("garantie.garantiedetails." + item.label) : "",
                }
            ));
    }

    /**
     * Sets the underlying type for the selected proposal guarantee.
     *
     * @function
     * @name setUnderlyingType
     * @param {ComboBoxItem} underlyingType - The underlying type to be set.
     */
    setUnderlyingType(underlyingType: ComboBoxItem) {
        if (this.proposalGuaranteeSelected.productGuarantee) {
            this.fullFilledUnderlyingType = false
            this.proposalGuaranteeSelected.productGuarantee.underlyingType = underlyingType;
        }
    }

    /**
     * Calculates the percentage amount based on the provided basis and rate.
     * Retrieves the product guarantee selected and dispatches an action to calculate the proposal guarantee percentage.
     *
     * @function
     * @name calculatePercentageAmount
     * @param {any} basis - The basis for the calculation.
     * @param {any} rate - The rate for the calculation.
     */
    calculatePercentageAmount(basis: UniformOdmName, rate: number) {
        const guaranteeProduct = this.proposalGuaranteeSelected.productGuarantee;
        store.dispatch( "financingModule/proposalGuaranteeModule/calculateProposalGuaranteePercentage",
            {guaranteeProduct, basis, rate }
        );
    }

    /**
     * Retrieves and returns an array containing the currency names.
     *
     * @function
     * @name currency
     * @returns {Array} An array of currency names.
     */
    get currency(): Array<any> {
        return [{ id: "1", name: "EUR" }]?.map((item) => item.name);
    }

}
