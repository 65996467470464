import {formatMask, formatWithMask} from "@/utils/configuration/formatters-config";
import i18n from "@/i18n";
import {globalConfig} from "@/utils";

const columnSelected = [
    'annualRate',
    'basis',
    'basisValue',
    'calculationMethodOption',
    'paymentDatesOption',
    'tax'
]


const columnMapper = [
    {
        key: 'annualRate',
        mapper: (item: any) => {
            if (item.rate) {
                return `${item.rate * 100} %`
            } else {
                return '----'
            }
        }
    },
    {
        key: 'basis',
        mapper: (item: any) => {
            if (item.basis) {
                return i18n.global.t(`demand.product.services.enum.basis.${item.basis}`)
            } else {
                return '----'
            }
        }
    },
    {
        key: 'calculationMethodOption',
        mapper: (item: any) => {
            if (item.calculationMethodOption) {
                return i18n.global.t(`demand.product.services.enum.calculationMethodOptions.${item.calculationMethodOption.toLowerCase()}`)
            } else {
                return '----'
            }
        }
    },
    {
        key: 'paymentDatesOption',
        mapper: (item: any) => {
            if (item.paymentDatesOption) {
                return i18n.global.t(`demand.product.services.enum.paymentDatesOptions.${item.paymentDatesOption.toLowerCase()}`)
            } else {
                return '----'
            }
        }
    },
    {
        key: 'tax',
        mapper: (item: any) => {
            if (item.taxValue) {
                return `${item.taxValue} %`
            } else {
                return '----'
            }
        }
    },
    {
        key: 'basisValue',
        mapper: (item: any) => {
            if (item.basisValue) {
                return formatWithMask(item.basisValue, 2)
            } else {
                return '----'
            }
        }
    }
]

export const getServiceCol = () => {
    return columnSelected.map(key => {
            return {
                name: key,
                label: i18n.global.t(`demand.product.services.columns.${key}`),
                align: 'center',
                style: 'width: 15%',
                field: (row: { item: any; }) => {
                    const mapper = columnMapper.filter(m => m.key === key)[0]
                    if (mapper) {
                        return mapper.mapper(row)
                    } else {
                        return '----'
                    }
                },
                sortable: false
            }
        })
}
