import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0de17e12"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-header bg-transparent border-0" }
const _hoisted_2 = { class: "default-border-radius bg-warning p-3" }
const _hoisted_3 = {
  key: 0,
  style: {"min-height":"3rem"}
}
const _hoisted_4 = {
  key: 1,
  class: "d-flex text-white",
  style: {"gap":"15px","min-height":"3rem"}
}
const _hoisted_5 = { class: "text-right" }
const _hoisted_6 = { class: "mb-0" }
const _hoisted_7 = { class: "w-100" }
const _hoisted_8 = { class: "text-right" }
const _hoisted_9 = { class: "font-medium h5 m-0 tw-formattedNumberFont" }
const _hoisted_10 = { class: "text-left ml-1" }
const _hoisted_11 = { class: "font-medium h5 m-0 tw-formattedNumberFont ml-1" }
const _hoisted_12 = { class: "text-right" }
const _hoisted_13 = { class: "font-medium h5 m-0 tw-formattedNumberFont" }
const _hoisted_14 = { class: "text-left ml-1" }
const _hoisted_15 = { class: "font-medium h5 m-0 tw-formattedNumberFont ml-1" }
const _hoisted_16 = { class: "text-right" }
const _hoisted_17 = { class: "mb-0" }
const _hoisted_18 = { class: "w-100" }
const _hoisted_19 = { class: "text-right" }
const _hoisted_20 = { class: "font-medium h5 m-0 tw-formattedNumberFont" }
const _hoisted_21 = { class: "text-left" }
const _hoisted_22 = { class: "font-medium h5 m-0 tw-formattedNumberFont ml-1" }
const _hoisted_23 = { class: "text-right" }
const _hoisted_24 = { class: "font-medium h5 m-0 tw-formattedNumberFont" }
const _hoisted_25 = { class: "text-left" }
const _hoisted_26 = { class: "font-medium h5 m-0 tw-formattedNumberFont ml-1" }
const _hoisted_27 = { class: "d-block mt-3 cursor-pointer" }
const _hoisted_28 = { class: "card-body border-bottom" }
const _hoisted_29 = { class: "col-lg-4" }
const _hoisted_30 = { class: "col-lg-4 font-weight-bold text-right tw-formattedNumberFont" }
const _hoisted_31 = { class: "col-lg-4 font-weight-bold text-right tw-formattedNumberFont" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_spinner_facebook = _resolveComponent("q-spinner-facebook")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_PartyInsuranceComponent = _resolveComponent("PartyInsuranceComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.calculationLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_q_spinner_facebook, {
                size: "2em",
                color: "white"
              })
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("demand.product.services.panel.totalAmount", { duration: _ctx.duration })), 1),
                _createElementVNode("table", _hoisted_7, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", _hoisted_8, [
                      _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.totalAmountHT), 1)
                    ]),
                    _createElementVNode("td", _hoisted_10, [
                      _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t('middleOfficeFinancingService.label.withOutTax')), 1)
                    ])
                  ]),
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", _hoisted_12, [
                      _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.totalAmountTTC), 1)
                    ]),
                    _createElementVNode("td", _hoisted_14, [
                      _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.$t('middleOfficeFinancingService.label.withTax')), 1)
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t("demand.product.services.panel.payment", { duration: _ctx.duration })), 1),
                _createElementVNode("table", _hoisted_18, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", _hoisted_19, [
                      _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.paymentHT), 1)
                    ]),
                    _createElementVNode("td", _hoisted_21, [
                      _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.$t('middleOfficeFinancingService.label.withOutTax')), 1)
                    ])
                  ]),
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", _hoisted_23, [
                      _createElementVNode("p", _hoisted_24, _toDisplayString(_ctx.paymentTTC), 1)
                    ]),
                    _createElementVNode("td", _hoisted_25, [
                      _createElementVNode("p", _hoisted_26, _toDisplayString(_ctx.$t('middleOfficeFinancingService.label.withTax')), 1)
                    ])
                  ])
                ])
              ])
            ])),
        _createElementVNode("div", _hoisted_27, [
          _createElementVNode("span", {
            class: "text-white",
            style: {"text-decoration":"underline"},
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openServicesDetail()))
          }, "SERVICES")
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_28, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.scaleSelectedServices, (service, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "bg-white default-border-radius p-3 mb-2 row"
        }, [
          _createElementVNode("div", _hoisted_29, [
            _createVNode(_component_q_checkbox, {
              dense: "",
              "checked-icon": "radio_button_checked",
              "unchecked-icon": "radio_button_unchecked",
              modelValue: service.config.selected,
              "onUpdate:modelValue": [($event: any) => ((service.config.selected) = $event), ($event: any) => (_ctx.onSelectService(service))],
              label: _ctx.findServiceType(service.objectType),
              disable: service.config.required || _ctx.calculationLoading
            }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "disable"])
          ]),
          _createElementVNode("div", _hoisted_30, _toDisplayString(`${_ctx.getAmountHT(service)} ${service.paymentDatesOption === "INSTALLMENT" ? "/Mois" : "HT"
        }`), 1),
          _createElementVNode("div", _hoisted_31, _toDisplayString(`${_ctx.getAmountTTC(service)} ${service.paymentDatesOption === "INSTALLMENT" ? "/Mois" : "TTC"
          }`), 1)
        ]))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partyInsuranceService, (item, index) => {
        return (_openBlock(), _createBlock(_component_PartyInsuranceComponent, {
          key: index,
          data: item
        }, null, 8, ["data"]))
      }), 128))
    ])
  ], 64))
}