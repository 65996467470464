import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DecisionCard = _resolveComponent("DecisionCard")!
  const _component_DecisionReserve = _resolveComponent("DecisionReserve")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DecisionCard, { decisions: _ctx.decisions }, null, 8, ["decisions"]),
    (_ctx.decisions.decisionReserves.length > 0)
      ? (_openBlock(), _createBlock(_component_DecisionReserve, {
          key: 0,
          decisions: _ctx.decisions
        }, null, 8, ["decisions"]))
      : _createCommentVNode("", true)
  ], 64))
}