import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-942778ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 form-header" }
const _hoisted_3 = { class: "row items-center" }
const _hoisted_4 = { class: "col-lg-12 col-md-12 col-sm-12" }
const _hoisted_5 = { class: "col-lg-12 col-md-12 col-sm-12" }
const _hoisted_6 = { class: "col-lg-12 col-md-12 col-sm-12" }
const _hoisted_7 = { class: "col-lg-12 col-md-12 col-sm-12" }
const _hoisted_8 = { class: "col-lg-12 col-md-12 col-sm-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, {
    flat: "",
    bordered: "",
    class: "keep-box-size"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.isSelected? 'q-tab__label q-mt-md card-title':'q-tab__label q-mt-md card-title text-primary'])
      }, "Asset", 2),
      _createVNode(_component_q_card_section, { class: "q-pt-none q-mt-lg" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    outlined: "",
                    type: "number",
                    label: _ctx.$t('demand.asset.vehicle.quantity'),
                    class: "bg-white",
                    style: {"margin-bottom":"20px","margin-right":"10px"},
                    min: "1",
                    disable: ""
                  }, null, 8, ["label"])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    outlined: "",
                    class: "bg-white",
                    label: _ctx.$t('demand.asset.vehicle.fournisseur'),
                    style: {"margin-bottom":"20px","margin-right":"10px"},
                    disable: ""
                  }, {
                    append: _withCtx(() => [
                      _createVNode(_component_q_btn, {
                        rounded: "",
                        dense: "",
                        flat: "",
                        icon: "search"
                      })
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_q_select, {
                    dense: "",
                    outlined: "",
                    class: "bg-white",
                    label: _ctx.$t('demand.asset.vehicle.brand'),
                    style: {"margin-bottom":"20px","margin-right":"10px"},
                    disable: ""
                  }, null, 8, ["label"])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_q_select, {
                    dense: "",
                    outlined: "",
                    class: "bg-white",
                    label: _ctx.$t('demand.asset.vehicle.model'),
                    style: {"margin-bottom":"20px","margin-right":"10px"},
                    disable: ""
                  }, null, 8, ["label"])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    outlined: "",
                    class: "bg-white",
                    label: _ctx.$t('demand.asset.vehicle.description'),
                    style: {"margin-bottom":"20px","margin-right":"10px"},
                    disable: ""
                  }, null, 8, ["label"])
                ])
              ])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}