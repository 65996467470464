import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "card no-border",
  style: {"border":"none !important"}
}
const _hoisted_2 = { class: "card-body p-0" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-lg-3 col-md-3 col-sm-3 form-control no-border" }
const _hoisted_5 = { class: "d-block mb-0 small text-muted" }
const _hoisted_6 = { class: "mr-2 font-weight-normal" }
const _hoisted_7 = { class: "theme-1" }
const _hoisted_8 = { class: "d-flex align-items-center" }
const _hoisted_9 = { class: "badge badge-primary" }
const _hoisted_10 = { class: "col-lg-9 col-md-9 col-sm-9 form-control no-border" }
const _hoisted_11 = { class: "d-block mb-0 small text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_input = _resolveComponent("q-input")!
  const _directive_ripple = _resolveDirective("ripple")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("Analysis.Avis")), 1),
          _createVNode(_component_q_select, {
            outlined: "",
            dense: "",
            modelValue: _ctx.itemText,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.itemText) = $event)),
            rules: [(val) => val.resourceUid !== null || _ctx.requiredField],
            options: _ctx.OpinionsList,
            "emit-value": "",
            "map-options": ""
          }, {
            "selected-item": _withCtx((scope) => [
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.getSelectedLabel(scope).resourceUid !== null
                ? _ctx.$t(`Analysis.opinion.${_ctx.getSelectedLabel(scope).resourceUid}`)
                : _ctx.getSelectedLabel(scope).resourceUid), 1)
            ]),
            option: _withCtx((scope) => [
              _createElementVNode("div", _hoisted_7, [
                _withDirectives((_openBlock(), _createElementBlock("div", _mergeProps({
                  class: "border p-2",
                  key: _ctx.getSelectedLabel(scope),
                  clickable: ""
                }, scope.itemProps), [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t(`Analysis.opinion.${_ctx.getSelectedLabel(scope).resourceUid}`)), 1)
                  ])
                ], 16)), [
                  [_directive_ripple],
                  [_directive_close_popup]
                ])
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "rules", "options"])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t("Analysis.Comment")), 1),
          _createVNode(_component_q_input, {
            modelValue: _ctx.opinions[0].comment,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.opinions[0].comment) = $event)),
            outlined: "",
            type: "textarea",
            onChange: _ctx.setGlobalOpinion
          }, null, 8, ["modelValue", "onChange"])
        ])
      ])
    ])
  ]))
}