import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_GlobalPaymentSchedulePanel = _resolveComponent("GlobalPaymentSchedulePanel")!
  const _component_GlobalPaymentScheduleTable = _resolveComponent("GlobalPaymentScheduleTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("span", {
      class: "bold q-mb-lg cursor-pointer text-primary",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.backPreviousState()))
    }, [
      _createVNode(_component_q_icon, {
        size: "sm",
        name: "arrow_left",
        class: "text-gray-1"
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('demand.simulation.globalPaymentSchedule.previous')), 1)
    ]),
    _createVNode(_component_GlobalPaymentSchedulePanel, { class: "mb-2" }),
    _createVNode(_component_GlobalPaymentScheduleTable)
  ], 64))
}