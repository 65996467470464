import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c7525ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "card bg-light"
}
const _hoisted_2 = { class: "card-body p-3" }
const _hoisted_3 = { class: "d-flex flex-column" }
const _hoisted_4 = {
  key: 0,
  class: "img-thumbnail mt-2 tw-asset-boxe-wrapper"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  class: "img-thumbnail mt-2 tw-asset-boxe-wrapper"
}
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!(this.offer?.businessData && this.offer?.businessData.API === _ctx.businessDataUtils.offerApiSource))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.productValues)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (_ctx.showImage)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        class: "d-block m-auto",
                        src: _ctx.imageData,
                        alt: "Vehicule"
                      }, null, 8, _hoisted_5))
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("img", {
                    class: "d-block m-auto",
                    src: _ctx.logoImage,
                    alt: "Vehicule"
                  }, null, 8, _hoisted_7)
                ]))
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}