import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79f27fc6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_Study = _resolveComponent("Study")!
  const _component_q_tab_panel = _resolveComponent("q-tab-panel")!
  const _component_q_tab_panels = _resolveComponent("q-tab-panels")!
  const _component_q_form = _resolveComponent("q-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_q_form, {
        autofocus: "",
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent","stop"])),
        onValidationError: _cache[3] || (_cache[3] = () => {}),
        ref: "form"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_tabs, {
            modelValue: _ctx.tab,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
            "inline-label": "",
            "active-color": "primary",
            class: "tw-custom-tabs-type-1 mb-0 pl-3 pr-3 pt-3 pb-0 d-none"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_tab, {
                class: "btn btn-sm w-100 btn-outline-primary",
                name: "analysis",
                label: "OFFER ANALYSIS"
              }),
              _createVNode(_component_q_tab, {
                class: "btn btn-sm w-100 btn-outline-primary",
                name: "history",
                label: "HISTORY"
              })
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_q_tab_panels, {
            modelValue: _ctx.tab,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tab) = $event)),
            animated: "",
            "transition-prev": "scale",
            "transition-next": "scale"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_tab_panel, {
                name: "analysis",
                class: "p-3"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Study, {
                    ref: "study",
                    reCalculation: false
                  }, null, 512)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }, 512)
    ])
  ]))
}