
import { Options, Vue } from "vue-class-component";
import { formatAmoutswitchCurrency} from "@/utils/configuration/formatters-config";
import { globalConfig, serviceName, amountHT, amountTTC, paymentDatesOption} from "@/utils";
import store from "@/store";
import { businessDataUtils, paymentDatesOptions } from "@/types"
@Options({
  props: ["data"],
})
export default class FinancingPartyInsurance extends Vue {
  formatAmoutswitchCurrency = formatAmoutswitchCurrency;
  serviceName = serviceName;
  amountHT = amountHT;
  amountTTC = amountTTC;
  paymentDatesOption = paymentDatesOption;
  businessDataUtils = businessDataUtils;
  paymentDatesOptions = paymentDatesOptions
  data?: any;
  expanded = true;
  get calculatestatus() {
    return config.VUE_APP_CALCULATE 
  }
  get offer() {
    return store.state.middleOfficeModule?.offer
  }

  get beneficiaries() {
    if (config.VUE_APP_CALCULATE === 'false' && (this.offer?.businessData && this.offer?.businessData.API === businessDataUtils.offerApiSource)) {
      return this.data.associatedInsuranceBeneficiaries
}
    else{
      return this.data.beneficiaries
    }
  }

  get columns() {
    return globalConfig.table_config.partyInsuranceCol;
  }

}
