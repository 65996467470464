
import store from "@/store";
import { Options, Vue } from "vue-class-component";
import LabelAndValue from "@/components/general/LabelAndValue.vue";
import { globalConfig } from "@/utils";
import i18n from "@/i18n";

@Options({
  components: { LabelAndValue },
  watch: {
    "$store.state.middleOfficeModule.variablesVisible"(newValue) {
      this.visible = newValue;
    },
    async "$store.state.middleOfficeModule.statusHistoryVariableSelected"(newValue) {
      if (newValue) {
        try {
          await new Promise(resolve => {
            store.dispatch('demandModule/getProposalStatus', {
              statusId: newValue.offerStatus,
              callback: (result: any) => {
                newValue.offerPhase = result.internalCode;
                resolve(newValue.offerPhase);
              }
            });
          });
        } catch (error) {
          console.error('Erreur lors de la mise à jour de offerPhase :', error);
        }
        this.rows = [
          { "label": i18n.global.t('task.historiesStep.headers.variables.offerReference'), "value": newValue.offerReference },
          { "label": i18n.global.t('task.historiesStep.headers.variables.offerStatus'), "value": i18n.global.t(`enum.offer.status.${newValue.offerStatus}`) },
          { "label": i18n.global.t('task.historiesStep.headers.variables.offerPhase'), "value": i18n.global.t(`enum.offer.status.${newValue.offerPhase}`) },
          { "label": i18n.global.t('task.historiesStep.headers.variables.offerCreationDate'), "value": newValue.offerCreationDate },
          { "label": i18n.global.t('task.historiesStep.headers.variables.customerReference'), "value": newValue.customerReference },
          { "label": i18n.global.t('task.historiesStep.headers.variables.customerName'), "value": newValue.customerName },
          { "label": i18n.global.t('task.historiesStep.headers.variables.customerType'), "value": i18n.global.t(`enum.party.type.${newValue.customerType}`) },
          { "label": i18n.global.t('task.historiesStep.headers.variables.comment'), "value": newValue.comment }]
      } else {
        this.rows = []
      }
    },
  },
})
export default class StatusHistory extends Vue {
  visible: boolean = false;
  rows: any = []

  pagination = {
    rowsPerPage: 20
  };

  closeStatusHistory() {
    store.dispatch("middleOfficeModule/setVariablesVisible", false);
  }

  get columns() {
    return globalConfig.table_config.statusHistoryCols;
  }

  get statusHistoryVariableSelected() {
    return store.state.middleOfficeModule.statusHistoryVariableSelected;
  }

}
