import { ClientTaskDefinition, DemandProgressStatus } from "@/types";

export const demandProgressStatusItems = [
  new DemandProgressStatus(
    "assignment",
    "primary",
    ClientTaskDefinition.ENTER_CUSTOMER_DECISION,
    [
      ClientTaskDefinition.ENTER_CUSTOMER_DECISION,
      ClientTaskDefinition.CUSTACCEPT,
      ClientTaskDefinition.CANCELLED,
    ],
    "check"
  ),
  new DemandProgressStatus(
    "assignment",
    "primary",
    ClientTaskDefinition.COMPLETE_OFFER_INFO,
    [ClientTaskDefinition.COMPLETE_OFFER_INFO],
    "check"
  ),
  new DemandProgressStatus(
    "assignment",
    "primary",
    ClientTaskDefinition.UPLOAD_DOCUMENTS,
    [ClientTaskDefinition.UPLOAD_DOCUMENTS],
    "check"
  ),
  new DemandProgressStatus(
    "assignment",
    "primary",
    ClientTaskDefinition.STEP_START_FINANCING_REQUEST,
    [ClientTaskDefinition.STEP_START_FINANCING_REQUEST],
    "check"
  ),
  new DemandProgressStatus(
    "assignment",
    "primary",

    ClientTaskDefinition.ANALYSIS,
    [
      ClientTaskDefinition.ANALYSIS,
      ClientTaskDefinition.ANALYSIS_ON_GOING,
      ClientTaskDefinition.ANALYSIS_ON_HOLD,
      ClientTaskDefinition.ANALYSIS_FINISHED,
      ClientTaskDefinition.VALIDATE_DOCUMENTS,
      ClientTaskDefinition.SEND_SALES
    ],
    "check"
  ),
  new DemandProgressStatus(
    "assignment",
    "primary",
    ClientTaskDefinition.DELEGATION_LEVEL,
    [ClientTaskDefinition.DELEGATION_LEVEL],
    "check"
  ),
  new DemandProgressStatus(
    "assignment",
    "primary",
    ClientTaskDefinition.ENTER_OPINION,
    [ClientTaskDefinition.ENTER_OPINION],
    "check"
  ),
  new DemandProgressStatus(
    "assignment",
    "primary",
    ClientTaskDefinition.ENTER_DECISION,
    [
      ClientTaskDefinition.ENTER_DECISION,
      ClientTaskDefinition.DECISION,
      ClientTaskDefinition.ACCEPTED,
      ClientTaskDefinition.REJECTED,
      ClientTaskDefinition.SEND_DECISION
    ],
    "check"
  ),
  new DemandProgressStatus(
    "assignment",
    "primary",
    ClientTaskDefinition.PRINT_DOCUMENT,
    [
      ClientTaskDefinition.PRINT_DOCUMENT,
      ClientTaskDefinition.MANSIGNATURE_INPROGRESS,
      ClientTaskDefinition.ELECSIGNATURE_INPROGRESS,
      ClientTaskDefinition.MANSIGNATURE_COMPLETED,
      ClientTaskDefinition.ELECSIGNATURE_COMPLETED,
    ],

    "check"
  ),
];
