
import {Options, Vue} from "vue-class-component";
import store from "@/store";
import PrintDocumentDialog from "@/components/document/PrintDocumentDialog.vue";
import SideBar from "@/components/general/SideBar.vue";
import PrintDocument from "@/components/task/tabs/PrintDocument.vue";
@Options({
  components: { PrintDocumentDialog, SideBar }
})
export default class PrintDocumentButton extends Vue {
  printDocument = PrintDocument
  showSideBar: boolean = false;
  componentPropsSB = {
    modal: true,
    notDisplaySave: true 
  };

  showSideBarToggle() {
    this.showSideBar = true
  }

  disappearSideBarToggle() {
    this.showSideBar = false
  }

}
