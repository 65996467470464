import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26ff194c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-title flex-grow-1 justify-content-between align-items-center mb-0" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { class: "font-semi-bold font-size-12" }
const _hoisted_4 = { class: "mr-2" }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = { class: "p-2 bg-white default-border-radius mb-3" }
const _hoisted_7 = { class: "d-flex" }
const _hoisted_8 = { class: "flex-grow-1" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "d-block mb-0 small text-muted" }
const _hoisted_11 = {
  key: 0,
  class: "input-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_SideBar = _resolveComponent("SideBar")!
  const _component_q_form = _resolveComponent("q-form")!

  return (_openBlock(), _createBlock(_component_q_form, null, {
    default: _withCtx(() => [
      _createVNode(_component_q_list, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_expansion_item, {
            "switch-toggle-side": "",
            "header-class": "card-header bg-transparent no-border p-4",
            class: "card tw-items-dashed-group tw-items-group-with-bg tw-level-two-group",
            onShow: _cache[1] || (_cache[1] = () => {}),
            onHide: _cache[2] || (_cache[2] = () => {})
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("task.study.cotations")), 1),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_q_btn, {
                      disable: !_ctx.historyRating || _ctx.historyRating.length === 0,
                      class: "btn btn-primary btn-square btn-sm",
                      icon: "search",
                      onClickCapture: _withModifiers(_ctx.showRatingHistory, ["stop"])
                    }, null, 8, ["disable", "onClickCapture"])
                  ])
                ])
              ])
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ratings, (rating, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "col-lg-4 col-md-4 col-sm-12 form-control no-border",
                            key: index
                          }, [
                            _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("task.study." + rating.type)), 1),
                            (rating.decision === '' || rating.decision === 'null')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                  _createVNode(_component_q_input, {
                                    outlined: "",
                                    dense: "",
                                    modelValue: rating.calculatedValue,
                                    "onUpdate:modelValue": ($event: any) => ((rating.calculatedValue) = $event),
                                    disabled: "",
                                    readonly: true
                                  }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                                  _createVNode(_component_q_input, {
                                    outlined: "",
                                    dense: "",
                                    modelValue: rating.updatedValue,
                                    "onUpdate:modelValue": [
                                      ($event: any) => ((rating.updatedValue) = $event),
                                      _cache[0] || (_cache[0] = ($event: any) => (_ctx.handelCalculation()))
                                    ],
                                    type: "number",
                                    class: "tw-rating-updatedValue"
                                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                ]))
                              : (_openBlock(), _createBlock(_component_q_input, {
                                  key: 1,
                                  class: "tw-input-with-bg",
                                  "input-class": _ctx.color(index),
                                  type: "text",
                                  name: "rating_client",
                                  outlined: "",
                                  dense: "",
                                  modelValue: rating.decision,
                                  "onUpdate:modelValue": ($event: any) => ((rating.decision) = $event),
                                  readonly: _ctx.reCalculation === false
                                }, null, 8, ["input-class", "modelValue", "onUpdate:modelValue", "readonly"]))
                          ]))
                        }), 128))
                      ])
                    ])
                  ])
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.historyRating)
        ? (_openBlock(), _createBlock(_component_SideBar, {
            key: 0,
            show: _ctx.modalOpen,
            component: _ctx.componentToRenderSB,
            label: _ctx.ratingHistoryTitle,
            cancelButtonLabel: _ctx.cancelButtonLabel,
            ctaLabel: '',
            onOnClose: _ctx.closeModal
          }, null, 8, ["show", "component", "label", "cancelButtonLabel", "onOnClose"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}