


import { Options, Vue } from "vue-class-component";

import GoodOrderList from "@/components/order/asset/dialogueOrderAsset/GoodOrderList.vue";
import GoodOrderFilter from "@/components/order/asset/dialogueOrderAsset/GoodOrderFilter.vue";

@Options({
    components: {
        GoodOrderFilter,
        GoodOrderList,
    },
    methods: {
        /**
        * This function updates the result rows of the good list.
        * @param rows The rows to update the good list with.
        */
        callGoodListRows(rows: any) {
            this.$refs.assetListRows.updateResultRows(rows);
        },
    },
})
export default class GoodOrderSearch extends Vue {
    mainView?: boolean;
}
