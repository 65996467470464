
import {Options, Vue} from "vue-class-component";
import DemandEdit from "@/components/demand/DemandEdit.vue";
import store from "@/store";
import {ActionType } from "@/types";
import router from "@/router";
import { evaluateUiControls, handleUpdateEvent } from "@/utils";
@Options({
  props: ["disable"],
  components: {
    DemandEdit
  },
  watch: {
    "$store.state.taskModule.executedAction"(action: ActionType) {
      if (action === ActionType.ANALYSIS ) {
        if (!store.state.taskModule.selectedTask.proceed) {
              store.commit("setLockState", false);
              store.commit("setConfirmationDialogState", {
                show: false,
              });
             router.push("/task/list");
        }
      }
    },
  },
  beforeCreate() {
    let attempts = 0;  // Initialize the loop counter
    const maxAttempts = 10;  // Set the maximum number of attempts

    const checkConditions = () => {
      attempts++;
      if (this.isTaskAnalysis && this.AnalysisOfferTask) {
        this.handleAnalysisData();
      } else if (attempts < maxAttempts) {
        // Retry after a short delay (e.g., 500ms)
        setTimeout(checkConditions, 1000);
      } else {
        // If maximum attempts are reached, stop and return a message
        console.log("Conditions not met after 5 attempts.");
      }
    };

    // Start the first check
    checkConditions();
  },
 
  unmounted() {
    store.dispatch("taskModule/analysisModule/initAnalysisState")
  },

  created() {
    evaluateUiControls("analysis-task", 'insert', this.$router, true);
  },
  updated() {
    this.$emit('update');
  },


})
export default class Analyse extends Vue {
  validateForm = false;
  disable: any;
  handleUpdateEvent = handleUpdateEvent;
  handleAnalysisData() {
    const TaskVariablesState: any = store.state.taskModule.selectedTask?.variables;
    store.dispatch("taskModule/analysisModule/analysis", { data: TaskVariablesState._decisionAnalysisProcessId, isEditing: false, reCalculate: false });
  }

  get AnalysisOfferTask() {
    return store.state.taskModule?.offer?.status?.resourceUid?.includes("ANALYSIS")
  }
  get isTaskAnalysis() {
    return store.getters["taskModule/isTaskAnalysis"];
  }
}
