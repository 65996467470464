
import {Options, Vue} from "vue-class-component";
import {FileContent, Metadata, PicklistItem} from "@/types";
import {UON} from "@/utils";
import store from "@/store";
import Notify from 'quasar/src/plugins/Notify.js';;
import moment from "moment";
import i18n from "@/i18n";
import uploadDocumentModule from "@/store/modules/task/actions/uploadDocumentModule";

@Options({
  props: ["gatheringProcessListItem"],
})
export default class UploadConditionsDocuments extends Vue {
  files: any[] = [];
  gatheringProcessListItem: any;
  docTypeManualList: PicklistItem[] = [];
  justifyTypeManualList: PicklistItem[] = [];
  taskDefinition: string | undefined
  statusOffer: string | undefined
  showComponent: boolean = false
  adding = false

  get getFile() {
    return uploadDocumentModule.state.files
  }

  get targetEntityList() {
    return this.gatheringProcessListItem.entityTargets.map((item: any) => {
      return {
        ...item,
        label: item.information?.toString() !== "" ? `${item.information} (${this.setRoles(item.resourceUid)})` : ''
      }
    }).sort((a: any, b: any) =>
        a.label.localeCompare(b.label)
    );
  }

  get supportingDocumentManualList() {
    return store.getters["taskModule/modifyConditionsModule/getSupportingDocumentManualList"];
  }
  get isAddedDocument() {
    return this.supportingDocumentManualList.some((doc:any) => !doc.fileContent || !doc.typeJustify || !doc.documentType || !doc.targetEntity);
  }

  getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const resp = reader.result as string;
        resolve(resp.split("base64,")[1]);
      };
      reader.onerror = (error) => reject(error);
    });
  }

  dragover(event: any) {
    event.preventDefault();
    if (!event.currentTarget.classList.contains("bg-green-300")) {
      event.currentTarget.classList.remove("bg-gray-100");
      event.currentTarget.classList.add("bg-green-300");
    }
  }

  dragleave(event: any) {
    event.currentTarget.classList.add("bg-gray-100");
    event.currentTarget.classList.remove("bg-green-300");
  }

  onfileSelected(event: any, documentType: string, processResourceUid: string, index: any, typeJustify: string, targetEntity: any) {
    const files = event.target.files;
    this.onChange(files, documentType, processResourceUid, index, typeJustify, targetEntity);
  }

  onChange(files: any, documentType: string, processResourceUid: string, index: number, typeJustify: string, targetEntity: any) {
    this.uploadDocManually(files, documentType, processResourceUid, index, typeJustify, targetEntity);
  }

  drop(event: any, documentType: string, processResourceUid: string, index: any, typeJustify: string, targetEntity: any) {
    event.preventDefault();
    let newTransfer = new DataTransfer();
    let files: any;
    const originalFileName = event.dataTransfer.getData("originalFileName");
    if (!originalFileName) {
      files = event.dataTransfer.files;
    } else {
      files = [this.getFile[event.dataTransfer.getData("fileIndex")]];

    }

    this.onChange(files, documentType, processResourceUid, index, typeJustify, targetEntity);
    event.currentTarget.classList.add("bg-gray-100");
    event.currentTarget.classList.remove("bg-green-300");
    newTransfer.items.clear();
  }

  remove(index: any) {
    this.supportingDocumentManualList.splice(index, 1);
    store.dispatch("taskModule/modifyConditionsModule/updateSupportingDocumentManualList", [store.getters["taskModule/modifyConditionsModule/getSupportingDocumentManualItemInit"]]);
  }

  async addSupportingDocumentManual() {
    this.adding = true
    await store.dispatch("taskModule/modifyConditionsModule/updateSupportingDocumentManualList",this.supportingDocumentManualList)
    await store.dispatch("taskModule/modifyConditionsModule/saveSupportingDocumentsManual")
    this.adding = false
  }

  async fillDocumentTypeManualList(requiredDocType: any) {
    this.docTypeManualList = [];
    const currentSupportingDocumentManualList = this.supportingDocumentManualList
    currentSupportingDocumentManualList[0].documentType = ''
    await store.dispatch("taskModule/modifyConditionsModule/updateSupportingDocumentManualList", currentSupportingDocumentManualList)
    await store.dispatch("taskModule/modifyConditionsModule/getDocumentTypesFromJustifyType", {
      request: {
        resourceUid: requiredDocType.id
      },
      callback: (documentType: any) => {
        if (documentType) {
          documentType.map((requiredDocType: any) => {
            let docType: PicklistItem = {
              label: "",
              id: ""
            };
            docType.id = requiredDocType.supporting_type_id;
            docType.label = requiredDocType.supporting_type_id;
            docType.label = i18n.global.t(`task.supportingDocumentLabel.${requiredDocType.supporting_type_id}`);
            if (!this.docTypeManualList.some((item) => item.id === docType.id)) {
              this.docTypeManualList.push(docType);
            }
          })
        }
      },
    })
  }

  fillJustifyTypeManualList(supportingType: any) {
    let justifyType: PicklistItem = {
      label: "",
      id: ""
    };
    justifyType.id = supportingType.documentFamily.resourceUid;
    justifyType.label = i18n.global.t(`task.supportingDocumentLabel.${supportingType.documentFamily.resourceUid}`);
    if (!this.justifyTypeManualList.some((item) => item.id === justifyType.id)) {
      this.justifyTypeManualList.push(justifyType);
    }
  }

  uploadDocManually(files: any, documentType: any, processResourceUid: string, index: number, typeJustify: any, targetEntity: any) {
    let document = files[0];
    if (document) {
      const offerReference = store.state.taskModule.offer?.reference as string;
      const connectedUser = store.getters["authModule/getUserConnected"]
      const metadataMap = new Map<string, string>();
      metadataMap.set("associatedOffer", offerReference);
      metadataMap.set("addedBy", `${offerReference};${connectedUser.username}`);
      metadataMap.set("documentType", typeof documentType === 'object' ? documentType.id : documentType);
      metadataMap.set("targetEntityObjectType", targetEntity.objectType);
      metadataMap.set("targetEntityUID", targetEntity.resourceUid);

      this.getBase64(document).then((base64) => {
        const fileContent: FileContent = {
          originalFileName: document.name,
          format: document.type,
          content: base64,
          relations: {
            items: [
              {
                qualifier: {
                  rawValue: documentType,
                },
                objectUON: {
                  objectType: "odm.filemngt.filecontent",
                  systemUid: UON.SystemUid.odmFileManagement,
                },
              },
            ],
          },
          metadata: Array.from(metadataMap.entries()).map(([key, value]) => ({
            key, value
          }) as Metadata),
        };

        this.supportingDocumentManualList[index].fileContent = fileContent;
        this.supportingDocumentManualList[index].documentType = typeof documentType === 'object' ? documentType.id : documentType;
        this.supportingDocumentManualList[index].typeJustify = typeof typeJustify === 'object' ? typeJustify.id : typeJustify;
        this.supportingDocumentManualList[index].processResourceUid = processResourceUid;
        this.supportingDocumentManualList[index].status = "QUALIFIED";
        this.supportingDocumentManualList[index].creationDate = moment().format('DD/MM/YYYY HH:mm');
        this.supportingDocumentManualList[index].comment = fileContent.originalFileName;
        this.supportingDocumentManualList[index].targetEntity = targetEntity;

        store.dispatch("taskModule/modifyConditionsModule/updateSupportingDocumentManualList", this.supportingDocumentManualList);

      });
    } else {
      Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
        color: "negative",
        message: "Erreur!",
      });
    }

  }

  setRoles(uid:any) {
    const associatedParties = store.state.taskModule.offer?.associatedParties
    let party = associatedParties?.find(party =>party.associatedParty?.third?.resourceUid === uid);
    let roleCode = party ? party.associatedParty?.role_code : null;
    return roleCode? i18n.global.t(`demand.party.roles.${roleCode}`):'-';
  }

  created() {
    this.taskDefinition = this.$store.state.taskModule.selectedTask.taskDefinition;
    this.statusOffer = this.$store.state.taskModule.offer?.status.resourceUid;
    if (this.taskDefinition && this.statusOffer
        && (this.taskDefinition === 'STEP_MODIFY_CONDITIONS' || this.taskDefinition === 'STEP_VALIDATE_DOCUMENTS' || this.taskDefinition === 'STEP_START_ANALYSIS')
        && this.gatheringProcessListItem.assessmentContext
        && this.gatheringProcessListItem.assessmentContext.resourceUid) {
      const list = store.getters["taskModule/modifyConditionsModule/getSupportingDocumentManualItemInit"]
      if (!list || !list.length) {
        store.dispatch("taskModule/modifyConditionsModule/updateSupportingDocumentManualList", [store.getters["taskModule/modifyConditionsModule/getSupportingDocumentManualItemInit"]]);
      }
      store.dispatch("taskModule/modifyConditionsModule/getAssessList", {
        request: {
          assessmentContextResourceUid: this.gatheringProcessListItem.assessmentContext.resourceUid,
        },
        callback: (res: any) => {
          if (res) {
            this.showComponent = true;
            res.supportingDocuments.map((requiredDoc: any) => {
              this.fillJustifyTypeManualList(requiredDoc);
            })
          }
        },
      });
    }
  }

}
