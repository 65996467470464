import store from "@/store";

interface FileOptions {
    mimeType: string,
    base64: string,
    name: string
}

/**
 * Sets the isLoading property of the row object to true and dispatches an action to download a file.
 * @param {any} row - The row object representing a document.
 */
export const viewDocument = (row: any) => {
    row.isLoading = true
    store.dispatch('taskModule/uploadDocumentModule/downloadFile', {
        request: row.document,
        row: row,
        callback: viewDocumentResponse
    })
}
export const viewDocumentOrder = (row: any) => {
    row.isLoading = true
    store.dispatch('taskModule/uploadDocumentModule/downloadFile', {
        request: row.documentUpload,
        row: row,
        callback: viewDocumentResponse
    })
}

/**
 * Handles the response after downloading the file and opens it in a new tab if successful.
 * @param {any} response - The response object containing the file information.
 * @param {any} row - The row object representing a document.
 */
const viewDocumentResponse = async (response: any, row: any) => {
    if (response) {
        const { format, content } = response
        openFileInNewTab({ name: response.originalFileName, mimeType: format, base64: content });
        if (row)
            row.isLoading = false;
    }
}

/**
 * Opens the file in a new browser tab.
 * @param {Object} fileOptions - The file options containing the MIME type, base64 content, and name of the file.
 */
export const openFileInNewTab = (fileOptions: FileOptions) => {
    const {
        mimeType,
        base64,
        name
    } = fileOptions

    const linkSource = `data:${mimeType};base64,${base64}`;
    const newWindow = window.open("");
    if (newWindow) {
        newWindow.document.write("<object type='" + mimeType + "' width='100%' height='100%' data='" + linkSource + "'></object>");
        newWindow.document.title = name;

    }
}
