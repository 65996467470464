import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "default-border-radius table-responsive p-3 pt-0 pb-0 bg-white"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_table = _resolveComponent("q-table")!

  return ((_ctx.decisionReserves.length > 0))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_q_table, {
          rows: _ctx.decisionReserves,
          "row-key": "resourceUid",
          class: "shadow-0 table table-sm bg-white mb-0",
          "hide-pagination": ""
        }, {
          header: _withCtx((props) => [
            _createVNode(_component_q_tr, { props: props }, {
              default: _withCtx(() => [
                _createVNode(_component_q_th, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('opinion.decisionTab.decisionReserve')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_th, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('opinion.decisionTab.decisionComment')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          body: _withCtx((props) => [
            _createVNode(_component_q_tr, { props: props }, {
              default: _withCtx(() => [
                _createVNode(_component_q_td, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_select, {
                      type: "text",
                      autogrow: "",
                      dense: "",
                      outlined: "",
                      "model-value": _ctx.reserveTraduction(props.row.reserve.resourceUid),
                      readonly: "",
                      options: _ctx.reserveOpinion,
                      style: {"width":"100%"}
                    }, null, 8, ["model-value", "options"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_q_td, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_input, {
                      type: "text",
                      autogrow: "",
                      dense: "",
                      outlined: "",
                      modelValue: props.row.comment,
                      "onUpdate:modelValue": ($event: any) => ((props.row.comment) = $event),
                      style: {"width":"100%"},
                      readonly: ""
                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          _: 1
        }, 8, ["rows"])
      ]))
    : _createCommentVNode("", true)
}