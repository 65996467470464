
import { Options, Vue } from "vue-class-component";
import { CalculationType, ScaleSliderConfig, SimulationAttributeEnum, SliderConfig, SlideType } from "@/types";
import store from "@/store";
import Notify from 'quasar/src/plugins/Notify.js';;
import i18n from "@/i18n";
import {
  deleteFormatMaskAndConvertToNumber,
  formatAmoutswitchCurrency,
  formatString,
  formatWithMask,
} from "@/utils/configuration/formatters-config";
import {TAXDEFAULT} from "@/utils";

const findSliderValue = (sliderConfig: ScaleSliderConfig[], config?: SliderConfig) => {
  const attributeList = sliderConfig.map((slider: ScaleSliderConfig) => slider.attribute);
  if (config && attributeList.indexOf(config.attribute) !== -1) {
    const newSliderConfig = sliderConfig.filter(
      (scaleSliderConfig: ScaleSliderConfig) =>
        config.attribute === scaleSliderConfig.attribute
    )[0];
    let typeSelected;
    if (newSliderConfig.typeSelected) {
      typeSelected = newSliderConfig.typeSelected;
    }
    const inputValue = newSliderConfig.value;
    return { typeSelected, inputValue };
  }
  return { typeSelected: undefined, inputValue: undefined };
};

@Options({
  props: ["config", "index"],
  watch: {
    "$i18n.locale"() {
      const normalModeState = store.state.simulationModule.normalModeState;
      const attribute = normalModeState.attributes.filter(
      (item:any) => item.attribute === this.config?.attribute
      )[0];
      if (attribute.type==='PERCENT'|| attribute.type==='RATE'){  
        this.inputValue=this.percentMask(this.inputValue)
    }
    },
    "$store.state.simulationModule.sliderConfig"(newValue) {
      const sliderConfig: ScaleSliderConfig[] = newValue;
      const { typeSelected, inputValue } = findSliderValue(sliderConfig, this.config);
      this.typeSelected = typeSelected;
      this.inputValue = inputValue;
    },
    inputValue(newValue) {
      if (this.config) {
        this.config.model = newValue;
      }
      if (isNaN(parseFloat(String(newValue).replace(",", ".")))) {  
        this.inputValue = this.previousInputValue;
      } else {
        this.previousInputValue = newValue;
      } 
      if (this.isSliderConfigRate) {
        const rate = deleteFormatMaskAndConvertToNumber(this.rateFinancingTax);
        const newValueNumber =
          typeof newValue === "string"
            ? deleteFormatMaskAndConvertToNumber(newValue)
            : newValue;
        this.rateMarge = this.percentMask(rate + newValueNumber);
        this.inputValue = this.percentMask(newValueNumber);
      }
    },
    // "$store.state.simulationModule.isFinancingTypeTTC"(isTTC) {
    //   const normalModeState = store.state.simulationModule.normalModeState;
    //   normalModeState.attributes
    //     .filter((item) => item.attribute === this.config?.attribute)
    //     .forEach((attribute) => {
    //       const financingProductSelected =
    //         store.state.financingModule.scalesModule.scaleSelected;
    //       if (financingProductSelected) {
    //         const tax = Number(financingProductSelected.productTax?.fixedValue || 0);
    //         if (!tax) {
    //           return;
    //         }
    //         let value = deleteFormatMaskAndConvertToNumber(this.inputValue.toString());
    //         const isMonthlyPayment = attribute.attribute === "payment";
    //         const isFirstPaymentOrResidualValue =
    //           attribute.attribute === SimulationAttributeEnum.FIRST_PAYMENT ||
    //           attribute.attribute === SimulationAttributeEnum.RESIDUAL_VALUE;
    //         const calculeWithTax = value * (1 + tax);
    //         const calculeWithoutTax = value / (1 + tax);
    //         if (isMonthlyPayment && isTTC) {
    //           if (
    //             !this.reachesMinLimit(calculeWithTax) &&
    //             !this.reachesMaxLimit(calculeWithTax)
    //           ) {
    //             this.inputValue = formatWithMask(calculeWithTax, 2);
    //             this.inputValueSlider = calculeWithTax;
    //           }
    //         } else if (isMonthlyPayment && !isTTC) {
    //           if (
    //             !this.reachesMinLimit(calculeWithoutTax) &&
    //             !this.reachesMaxLimit(calculeWithoutTax)
    //           ) {
    //             this.inputValue = formatWithMask(calculeWithoutTax, 2);
    //             this.inputValueSlider = calculeWithoutTax;
    //           }
    //         } else if (isFirstPaymentOrResidualValue && isTTC) {
    //           if (
    //             !this.reachesMinLimit(calculeWithTax) &&
    //             !this.reachesMaxLimit(calculeWithTax)
    //           ) {
    //             // TODO 3009 test
    //             // this.inputValue = formatWithMask(calculeWithTax, 2);
    //             // this.inputValueSlider = calculeWithTax;
    //           }
    //         } else if (isFirstPaymentOrResidualValue && !isTTC) {
    //           if (
    //             !this.reachesMinLimit(calculeWithoutTax) &&
    //             !this.reachesMaxLimit(calculeWithoutTax)
    //           ) {
    //             this.inputValue = formatWithMask(calculeWithoutTax, 2);
    //             this.inputValueSlider = calculeWithoutTax;
    //           }
    //         }
    //       }
    //     });
    // },
    "$store.state.financingModule.simulationChanged"(simulationChanged) {
      this.simulationChanged = simulationChanged;
    },
    "$store.state.simulationModule.normalModeState.attributes"(newValue) {
      // if (newValue && newValue.length && this.config.attribute === SimulationAttributeEnum.RESIDUAL_VALUE) {
      //   const slider = newValue.filter((s: any) => s.attribute === SimulationAttributeEnum.RESIDUAL_VALUE)[0];
      //   this.inputValueSlider = slider
      //     ? slider.percentValue || slider.value
      //     : this.inputValueSlider;
      //   this.inputValue = this.percentMask(this.inputValueSlider.toFixed(10));
      // }
      if (newValue && newValue.length && this.config.attribute === SimulationAttributeEnum.MILEAGE) {
        const slider = newValue.filter((s: any) => s.attribute === SimulationAttributeEnum.MILEAGE)[0];
        this.inputValueSlider = slider ? slider.value : this.inputValueSlider;
        this.inputValue = `${this.percentMask((this.inputValueSlider || 0) % 1 === 0 
                                ?(this.inputValueSlider || 0).toFixed(0)
                                :(this.inputValueSlider || 0).toFixed(2))}`;
      }
    },
  },
})
export default class OdmSliderTTC extends Vue {
  valueStyle = {
    "font-weight": "bold",
    "margin-left": "20px",
    color: "black",
  };

  labelText = 'TTC'
  index: any;
  simulationChanged = false;
  isEnabled: boolean | undefined = true;
  rateToggle = false;
  rateMarge = 10;
  inputValue: any = "0";
  inputValueSlider = 0;
  previousInputValue: any = "0";
  config: SliderConfig | undefined;
  typeSelected: string | undefined = "PERCENT";

  percentMask(value: any): string {
      if (i18n.global.locale.value === 'fr-FR') {
        return value.toString().substring(0, 8).replace(".", ",");
      } else if (i18n.global.locale.value === 'en-US') {
        return value.toString().substring(0, 8).replace(",", ".");
      }
      return value.toString();
    }

  reachesMinLimit(value: number) {
    if (value < (this.min || 0) || (this.config?.attribute !== SimulationAttributeEnum.FIRST_PAYMENT && value == 0)) {
      Notify.create({
        timeout: 10000,
        actions: [{ icon: 'close', color: 'white' }],
        message: i18n.global.t("demand.simulation.invalidSlideValue"),
        color: "negative",
      });
      return true;
    }
    return false;
  }
  reachesMaxLimit(value: number) {
    if (value > (this.max || 100)) {
      Notify.create({
        timeout: 10000,
        actions: [{ icon: 'close', color: 'white' }],
        message: i18n.global.t("demand.simulation.invalidSlideValue"),
        color: "negative",
      });
      return true;
    }
    return false;
  }

  reachesMinLimitRate(value: number) {
    if (value < (this.minSpread || 0)) {
      Notify.create({
        timeout: 10000,
        actions: [{ icon: 'close', color: 'white' }],
        message: i18n.global.t("demand.simulation.invalidSlideValue"),
        color: "negative",
      });
      return true;
    }
    return false;
  }
  reachesMaxLimitRate(value: number) {
    if (value > (this.maxSpread || 100)) {
      Notify.create({
        timeout: 10000,
        actions: [{ icon: 'close', color: 'white' }],
        message: i18n.global.t("demand.simulation.invalidSlideValue"),
        color: "negative",
      });
      return true;
    }
    return false;
  }

  calculateTTC(value: number) {
    const tax = Number(this.scaleSelected?.productTax?.fixedValue || TAXDEFAULT.val)
    if (this.scaleSelected?.taxOnInterest && this.scaleSelected?.taxOnInterest === 'NO_VAT'){
      return value
    }
    return (value * (1 + tax/100))
  }

  calculateHT(value: number) {
    store.dispatch("simulationModule/setAmountTTC", value)
    const tax = Number(this.scaleSelected?.productTax?.fixedValue || TAXDEFAULT.val)
    if(this.scaleSelected?.taxOnInterest && this.scaleSelected?.taxOnInterest === 'NO_VAT' ){ 
      return value
    }
    return (value / (1 + (tax/100)))
  }

  created() {
    const simulationChanged = store.state.financingModule.simulationChanged;
    if (simulationChanged) {
      this.simulationChanged = simulationChanged;
    }
    const normalModeState = store.state.simulationModule.normalModeState;
    const attribute = normalModeState.attributes.filter(
      (item) => item.attribute === this.config?.attribute
    )[0];
    const price = deleteFormatMaskAndConvertToNumber(this.price.toString());
    if (price === 0) {
      this.inputValue = this.min?.toString() || this.config?.min.toString() || "0";
      this.typeSelected = attribute
        ? attribute.type
        : this.config && this.config.slideType
        ? this.config.slideType
        : SlideType.DEFAULT;
    } else {
      if (attribute) {
        this.typeSelected = attribute.type;
        switch (attribute.type) {
          case "RATE":
            const value =
              (attribute.percentValue || 0) +
              (this.scaleSelected?.scalePresentation?.rateFinancingTax || 0);
            this.inputValue = this.percentMask(Number((attribute.percentValue || 0).toFixed(2)));            
            this.inputValueSlider = value;
            break;
          case "PERCENT":
            if (this.hasTtcOrHt) {
              const valueTTC = this.calculateTTC(attribute.value || 0)
              this.inputValue = formatWithMask(valueTTC, 2);
              this.inputValueSlider = valueTTC;
            } else {
              this.inputValue = this.percentMask((attribute.percentValue || 0))
              this.inputValueSlider = attribute.percentValue || 0;
            }
            break;
          default:
            if (this.hasTtcOrHt) {
              const valueTTC = this.calculateTTC(attribute.value || 0)
              this.inputValue = formatWithMask(valueTTC, 2);
              this.inputValueSlider = valueTTC;
            } else {
              this.inputValue = attribute.value?.toString() || "0";
              this.inputValueSlider = attribute.value || 0;
            }
            break;
        }

      } else {
        this.inputValue = this.config?.value.toString() || "0";
        this.typeSelected =
          this.config && this.config.slideType
            ? this.config.slideType
            : SlideType.DEFAULT;
      }
    }
  }

  get amountHT() {
    const value = deleteFormatMaskAndConvertToNumber(this.inputValue)
 
    return this.simulationChanged ? '- € HT' : formatAmoutswitchCurrency("EUR", this.calculateHT(value)) + ' ' + i18n.global.t('middleOfficeFinancingService.label.withOutTax')
  }

  get calculationDisabled() {
    return !this.calculationType || this.price < 1;
  }

  /**
   * Getter method that returns the CSS class name for the calculation button based on calculationDisabled conditions and display from SliderConfig.
   * @returns {string} The CSS class name for the calculation button.
   */
  get calculationButtonClass() {
    let classList;
    if (this.calculationDisabled) {
      classList = "tw-disabled-action";
    }
    if (!this.calculationDisabled && !this.config?.hideCalculationButton) {
      classList = this.simulationChanged
        ? "badge-danger tw-blink-btn"
        : "badge-success text-success border-success border";
    }
    return classList;
  }

  get rateFinancingTax() {
    return formatWithMask(
      this.scaleSelected?.scalePresentation?.rateFinancingTax || 0,
      1
    );
  }

  get scaleSelected() {
    return store.getters["financingModule/scalesModule/scaleSelected"];
  }

  get price() {
    return store.getters["financingModule/simulationPrice"];
  }
  

  get markerLabel() {
    if (this.suffix === '€' && this.min && this.maxPresentation)
    { return [
      {
        value: this.min,
        label: this.isCustomSuffix ? this.minSuffix : formatAmoutswitchCurrency("EUR", this.min),
      },
      {
        value: this.max,
        label: this.isCustomSuffix ? this.maxSuffix :  formatAmoutswitchCurrency("EUR", this.maxPresentation),
      },
    ];}

      return [
      {
        value: this.min,
        label: this.isCustomSuffix ? this.minSuffix : `${this.min}${this.suffix}`,
      },
      {
        value: this.max,
        label: this.isCustomSuffix ? this.maxSuffix : `${this.maxPresentation}${this.suffix}`,
      },
    ]; 
  }

  get sliderConfigType(): SlideType {
    if (this.config) return this.config.slideType || SlideType.DEFAULT;
    return SlideType.DEFAULT;
  }

  get isSliderConfigPercent() {
    return this.sliderConfigType === SlideType.PERCENT;
  }

  get isSliderConfigRate() {
    return this.sliderConfigType === SlideType.RATE;
  }

  get isSliderPercent() {
    return this.typeSelected === SlideType.PERCENT;
  }

  get hasTtcOrHt() {
    const isPercent = this.typeSelected === "PERCENT";
    return this.config?.attribute === SimulationAttributeEnum.PAYMENT ||
        (!isPercent && (this.config?.attribute === SimulationAttributeEnum.FIRST_PAYMENT ||
                this.config?.attribute === SimulationAttributeEnum.RESIDUAL_VALUE ||
                this.config?.attribute === SimulationAttributeEnum.DOWN_PAYMENT))
  }

  get suffixTTC() {
    if (this.hasTtcOrHt) {
      return this.suffix + '  ' + i18n.global.t('middleOfficeFinancingService.label.withTax')
    }
    return this.suffix
  }

  get suffix() {
    return this.isSliderPercent || this.isSliderConfigRate ? "%" : this.configSuffix;
  }

  get suffixReadOnly() {
    return this.isSliderPercent ? "€ HT" : "%";
  }

  get valueReadOnly() {
    let values = this.calculateValues();
    return !this.isSliderPercent
      ? this.percentMask(Number(values.percentValue).toFixed(2))
      : formatWithMask(values.amountValue, 2);
  }

  calculateValues() {
    const selectedValue = this.inputValueSlider;
    const price = this.price;
    let percentValue;
    let amountValue;
    if (this.isSliderPercent) {
      amountValue = Number(((selectedValue * price) / 100).toFixed(2));
      percentValue = (Number(((selectedValue * price) / 100)) / price) * 100;
    } else {
      amountValue = Number(selectedValue.toFixed(2));
      percentValue = (Number(selectedValue)/ price) * 100;
    }
    return {
      percentValue,
      amountValue,
    };
  }

  get configSuffix() {
    return this.config?.suffix;
  }

  get minSuffix() {
    return this.config?.minSuffix;
  }

  get maxSuffix() {
    return this.config?.maxSuffix;
  }

  get isCustomSuffix() {
    return this.config?.isCustomSuffix;
  }

  get minSpread() {
    return this.config?.minSpread;
  }

  get maxSpread() {
    return this.config?.maxSpread;
  }

  get min() {
    return this.isSliderPercent || this.isSliderConfigRate
      ? this.config?.percentMin
      : this.config?.min;
  }

  get max() {
    return this.isSliderPercent || this.isSliderConfigRate
      ? this.config?.percentMax
      : this.config?.max;
  }

  get maxPresentation() {
    let result = this.config?.max;
    if (this.isSliderPercent || this.isSliderConfigRate) {
      result = this.config?.percentMax;
    }
    return result;
  }

  // getters

  get financingProductSelected() {
    return store.state.simulationModule.financingProductSelected;
  }

  get calculationType() {
    return this.config?.calculationType;
  }

  // events
  onInputValueEvent(value: number, changeSimulation?: boolean) {
    if (SlideType.DEFAULT !== this.typeSelected) {
      if (value < 0 || value > 100) {
        Notify.create({
        timeout: 10000,
        actions: [{ icon: 'close', color: 'white' }],
          message: "Error",
          color: "negative",
        });
        return;
      }
    }
    if (this.hasTtcOrHt) {
      value = this.calculateHT(value)
    }
    store
      .dispatch("simulationModule/changeSliderValue", {
        attribute: this.config?.attribute,
        value: value,
        typeSelected: this.typeSelected,
      })
      .then(() => {
        store.dispatch("financingModule/setSimulationChanged", changeSimulation);
        if (this.config?.attribute === SimulationAttributeEnum.MILEAGE) {
          store.dispatch("financingModule/refreshResidualValue");
        }
        if (this.config?.attribute === "duration") {
          store
            .dispatch("financingModule/refreshMillage")
            .then(() => {
              store.dispatch("financingModule/refreshResidualValue")
              store.dispatch('financingModule/serviceModule/refreshPartyInsurance', {duration: value})
            });
        }
      });
  }

  onInputValueSliderChange() {
    const isPercent = this.typeSelected === "PERCENT";
    let value = 0;
    if (isPercent) {
      value = deleteFormatMaskAndConvertToNumber(this.inputValueSlider.toFixed(2));
    } else if (this.typeSelected === "RATE") {
      value =
        this.inputValueSlider -
        (this.scaleSelected?.scalePresentation?.rateFinancingTax || 0);
    } else {
      value = Number(this.inputValueSlider);
    }
    if (this.config?.attribute !== "duration" && this.config?.attribute !== "gracePeriodTerms" ) {
      this.inputValue = this.percentMask(value);
    } else {
      this.inputValue = this.percentMask(value);
    }
    this.onInputValueEvent(value, true);
  }
  // events


  onInputValueChangeRadio(changeSimulation = true) {
    const isPercent = this.typeSelected === "PERCENT";
    if (
        this.config?.attribute === SimulationAttributeEnum.MONTHLY_PAYMENT ||
        (!isPercent &&
            (this.config?.attribute === SimulationAttributeEnum.FIRST_PAYMENT ||
                this.config?.attribute === SimulationAttributeEnum.RESIDUAL_VALUE ||
                this.config?.attribute === SimulationAttributeEnum.DOWN_PAYMENT))
    ) {
      let value = this.hasTtcOrHt
          ?  this.calculateTTC(deleteFormatMaskAndConvertToNumber(this.inputValue.toString()) || 0)
          : deleteFormatMaskAndConvertToNumber(this.inputValue.toString())
      if (!this.reachesMinLimit(value) && !this.reachesMaxLimit(value)) {
        this.onInputValueEvent(value, changeSimulation);
        this.inputValue = formatWithMask(value, 2);
        this.inputValueSlider = value;
      } else {
        this.inputValue = formatWithMask(this.inputValueSlider, 2);
      }
    } else if (
        this.config?.attribute === SimulationAttributeEnum.MONTHLY_PAYMENT ||
        (isPercent &&
            (this.config?.attribute === SimulationAttributeEnum.FIRST_PAYMENT ||
                this.config?.attribute === SimulationAttributeEnum.RESIDUAL_VALUE ||
                this.config?.attribute === SimulationAttributeEnum.DOWN_PAYMENT))
    ) {
      let value = deleteFormatMaskAndConvertToNumber(this.inputValue.toString());
      if (!this.reachesMinLimit(value) && !this.reachesMaxLimit(value)) {
        this.inputValueSlider = value;
        const calculatedValue = this.calculateValues();
        this.inputValueSlider = calculatedValue.percentValue;
        value = calculatedValue.percentValue;
        this.inputValue = this.percentMask(calculatedValue.percentValue.toFixed(2));
        this.onInputValueEvent(value, changeSimulation);
      } else {
        this.inputValue = this.inputValueSlider.toFixed(2).toString();
      }
    } else {
      if (this.typeSelected === "RATE") {
        let value = deleteFormatMaskAndConvertToNumber(this.inputValue.toString());
        let totalValue =
            value + (this.scaleSelected?.scalePresentation?.rateFinancingTax || 0);
        if (!this.reachesMinLimitRate(value) && !this.reachesMaxLimitRate(value)) {
          this.onInputValueEvent(value, changeSimulation);
          this.inputValueSlider = totalValue;
        } else {
          value =
              this.inputValueSlider -
              (this.scaleSelected?.scalePresentation?.rateFinancingTax || 0);
          this.inputValue = value.toString();
          this.onInputValueEvent(value, changeSimulation);
        }
      } else {
        let value = deleteFormatMaskAndConvertToNumber(this.inputValue.toString());
        if (!this.reachesMinLimit(value) && !this.reachesMaxLimit(value)) {
          this.onInputValueEvent(value, changeSimulation);
          this.inputValueSlider = value;
        } else {
          value = this.inputValueSlider;
          this.inputValue = this.inputValueSlider.toString();
          this.onInputValueEvent(value, changeSimulation);
        }
      }
    }
  }

  onInputValueChange(changeSimulation: any) {
    const value = parseFloat(this.inputValue.replace(',', '.'));
    if (isNaN(value)) {
      this.inputValue = this.previousInputValue;
      return;
    }
    const isPercent = this.typeSelected === "PERCENT";
    if (this.reachesMinLimit(changeSimulation) || this.reachesMaxLimit(changeSimulation)) {
      const calculatedValue = this.calculateValues();
      this.inputValue = isPercent ? calculatedValue.percentValue : calculatedValue.amountValue;
    } else { 
      if (
        this.config?.attribute === SimulationAttributeEnum.MONTHLY_PAYMENT ||
        (!isPercent &&
          (this.config?.attribute === SimulationAttributeEnum.FIRST_PAYMENT ||
            this.config?.attribute === SimulationAttributeEnum.RESIDUAL_VALUE ||
            this.config?.attribute === SimulationAttributeEnum.DOWN_PAYMENT))
      ) {
        // TODO 3009 - finish
        let value = deleteFormatMaskAndConvertToNumber(this.inputValue.toString())
        if (!this.reachesMinLimit(value) && !this.reachesMaxLimit(value)) {
          this.onInputValueEvent(value, changeSimulation);
          this.inputValue = formatWithMask(value, 2);
          this.inputValueSlider = value;
        } else {
          this.inputValue = formatWithMask(this.inputValueSlider, 2);
        }
      } else if (
        this.config?.attribute === SimulationAttributeEnum.MONTHLY_PAYMENT ||
        (isPercent &&
          (this.config?.attribute === SimulationAttributeEnum.FIRST_PAYMENT ||
            this.config?.attribute === SimulationAttributeEnum.RESIDUAL_VALUE ||
            this.config?.attribute === SimulationAttributeEnum.DOWN_PAYMENT))
      ) {
        let value = deleteFormatMaskAndConvertToNumber(this.inputValue.toString());
        if (!this.reachesMinLimit(value) && !this.reachesMaxLimit(value)) {
          this.inputValueSlider = value;
          const calculatedValue = this.calculateValues();
          this.inputValueSlider = calculatedValue.percentValue;
          value = calculatedValue.percentValue;
          this.inputValue = this.percentMask(calculatedValue.percentValue.toFixed(10));
          this.onInputValueEvent(value, changeSimulation);
        } else {
          this.inputValue = this.inputValueSlider.toString();
        }
      } else {
        if (this.typeSelected === "RATE") {
          let value = deleteFormatMaskAndConvertToNumber(this.inputValue.toString());
          let totalValue =
            value + (this.scaleSelected?.scalePresentation?.rateFinancingTax || 0);
          if (!this.reachesMinLimitRate(value) && !this.reachesMaxLimitRate(value)) {
            this.onInputValueEvent(value, changeSimulation);
            this.inputValueSlider = totalValue;
          } else {
            value =
              this.inputValueSlider -
              (this.scaleSelected?.scalePresentation?.rateFinancingTax || 0);
            this.inputValue = value.toString();
            this.onInputValueEvent(value, changeSimulation);
          }
        } else {
          let value = deleteFormatMaskAndConvertToNumber(this.inputValue.toString());
          if (!this.reachesMinLimit(value) && !this.reachesMaxLimit(value)) {
            this.onInputValueEvent(value, changeSimulation);
            this.inputValueSlider = value;
          } else {
            value = this.inputValueSlider;
            this.inputValue = this.inputValueSlider.toString();
            this.onInputValueEvent(value, changeSimulation);
          }
        }
      }
    }
  }

  calculateClick() {
    store.dispatch("financingModule/calculateFromState", {
      searchType: this.calculationType,
      financingType: 'TTC',
      scale: store.state.financingModule.scalesModule.scaleSelected,
      callback: this.handleCalculationResponse,
    });
  }

  handleAfterCalculation() {
    store.dispatch("financingModule/setSimulationChanged", false);
  }

  handleCalculationResponse(res: any) {
    const isPercent = this.typeSelected === "PERCENT";
    let result;
    let financialInstallment = 0;
    switch (this.calculationType) {
      case CalculationType.PAYMENT:

        let paymentSchedule = 2
        if(store.state.financingModule.scalesModule.scaleSelected) {
          const gracePeriod = store.state.financingModule.scalesModule.scaleSelected.scalePresentation.sliders.filter((s: any) => s.attribute === 'gracePeriodTerms')[0]
          if (gracePeriod) {
            paymentSchedule = +gracePeriod.model + 2 // Number 2 because as describe by DA this payment schedule dont change if you skip one step more or not
          }
        }

        financialInstallment = res.calculationResult.paymentSchedules[paymentSchedule].financialInstallmentWTax
        this.inputValue = this.validateCalculationValue(financialInstallment,
          () => {
            this.typeSelected = SlideType.DEFAULT;
            store.dispatch("financingModule/refreshScales");
            this.inputValueSlider = financialInstallment;
          }, 
        );
        break;
      case CalculationType.FIRST_PAYMENT:
        financialInstallment = this.calculateTTC(res.calculationResult.result)
        result = this.validateCalculationValue(financialInstallment, () => {
          store.dispatch("financingModule/refreshScales");
        });
        if (result) {
          if (!isPercent) {
            this.inputValue = result;
            this.inputValueSlider = res.calculationResult.result;
          } else {
            result = (Number(res.calculationResult.result.toFixed(2)) / this.price) * 100;
            this.inputValue = this.percentMask(result.toFixed(10));
            this.inputValueSlider = result;
          }
        }
        break;
      case CalculationType.OUTSTANDING_BALANCE_BEFORE:
        result = this.validateCalculationValue(res.calculationResult.downPayment, () => {
          store.dispatch("financingModule/refreshScales");
        });
        if (result) {
          if (!isPercent) {
            this.inputValue = result;
            this.inputValueSlider = res.calculationResult.downPayment;
          } else {
            result =
              (Number(res.calculationResult.downPayment.toFixed(2)) / this.price) * 100;
            this.inputValue = this.percentMask(result);
            this.inputValueSlider = result;
          }
        }
        break;
      case CalculationType.OUTSTANDING_BALANCE_AFTER:
        financialInstallment = this.calculateTTC(res.calculationResult.residualValueWTax)
        result = this.validateCalculationValue(
            financialInstallment,
          () => {
            store.dispatch("financingModule/refreshScales");
          }
        );
        if (result) {
          if (!isPercent) {
            this.inputValue = result;
            this.inputValueSlider = financialInstallment
          } else {
            result =
              (Number(financialInstallment.toFixed(2)) / this.price) * 100;
            this.inputValue = this.percentMask(result);
            this.inputValueSlider = result;
          }
        }
        break;
      case CalculationType.ANNUAL_RATE:
        const item = res.calculationResult.paymentSchedules.filter(
          (item: any) => !!item.stepNo
        )[0];
        if (item) {
          const nominalRate = item.nominalRate;
          const value =
            nominalRate - (this.scaleSelected?.scalePresentation?.rateFinancingTax || 0);
          if (nominalRate > 0) {
            result = this.validateCalculationValue(nominalRate, () => {
              store.dispatch("financingModule/refreshScales");
            });
            this.inputValue = this.percentMask(value.toFixed(2));
            this.inputValueSlider = nominalRate;
          }
        }
        break;
    }
    this.handleAfterCalculation();
  }

  validateCalculationValue(value?: number, callback?: any, fractionDigits?: number) {
    if (value && value > 0) {
      Notify.create({
        timeout: 10000,
        actions: [{ icon: 'close', color: 'white' }],
        message: `${i18n.global.t("main.dialog.validation.success")}`,
        color: "positive",
      });
      if (callback) {
        callback(value);
      }
      return formatWithMask(Number(value.toFixed(fractionDigits || 2)), fractionDigits || 2);
    } else {
      Notify.create({
        timeout: 10000,
        actions: [{ icon: 'close', color: 'white' }],
        message: `Calculation returned an invalid result ${value}`,
        color: "warning",
      });
      return this.inputValue;
    }
  }

  onTypeChange() {
    // TODO 3009 - verify
    let newValue;
    const price = deleteFormatMaskAndConvertToNumber(this.price.toString());
    const calculatedValue = this.calculateValues();
    const isPercent = this.typeSelected === "PERCENT";
    if (isPercent) {
      const value = deleteFormatMaskAndConvertToNumber(calculatedValue.percentValue.toString());
      newValue = Number(((this.calculateHT(value) / price) * 100).toFixed(2));
    } else {
      const value = deleteFormatMaskAndConvertToNumber(calculatedValue.amountValue.toString());
      newValue = Number(((price * value) / 100).toFixed(2));
    }
    if (newValue < (this.min || 0)) {
      Notify.create({
        timeout: 10000,
        actions: [{ icon: 'close', color: 'white' }],
        message: i18n.global.t("demand.simulation.invalidSlideValue"),
        color: "negative",
      });
      const min = this.min || 0;
      this.inputValue = formatWithMask(min, 2);
    } else if (newValue > (this.max || 100)) {
      Notify.create({
        timeout: 10000,
        actions: [{ icon: 'close', color: 'white' }],
        message: i18n.global.t("demand.simulation.invalidSlideValue"),
        color: "negative",
      });
      const max = this.max || 100;
      this.inputValue = formatWithMask(max, 2);
    } else {
      if (isPercent) {
        this.inputValue = this.percentMask(newValue.toFixed(2));
      } else {
        this.inputValue = formatWithMask(newValue, 2);
      }
      this.inputValueSlider = newValue;
    }
    this.onInputValueChangeRadio(false);
  }
}
