
import {Options, Vue} from "vue-class-component";
import {} from "@/utils/configuration/formatters-config";

@Options({
  props:{
    isSelected:{
      type: Boolean,
      required: true 
    }
  },
  computed:{
    color(){
    return !this.isSelected ? 'primary' : 'grey';
   }
  }
  
})
export default class DemandAssetCustom extends Vue {
  color: string | undefined;
  isSelected: boolean|undefined;
}
