import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3349aa38"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header bg-white" }
const _hoisted_3 = { class: "card-title mb-0 font-medium font-size-14" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-3" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-12" }
const _hoisted_9 = { class: "mb-0" }
const _hoisted_10 = { class: "col-12" }
const _hoisted_11 = { class: "mb-0" }
const _hoisted_12 = { class: "col-3" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col-12" }
const _hoisted_15 = { class: "mb-0" }
const _hoisted_16 = { class: "col-12" }
const _hoisted_17 = { class: "mb-0" }
const _hoisted_18 = { class: "col-3" }
const _hoisted_19 = { class: "row" }
const _hoisted_20 = { class: "col-12" }
const _hoisted_21 = { class: "mb-0" }
const _hoisted_22 = { class: "row" }
const _hoisted_23 = { class: "d-flex flex-column col-12" }
const _hoisted_24 = {
  key: 0,
  class: "col-12"
}
const _hoisted_25 = { class: "mb-0" }
const _hoisted_26 = { class: "col-3" }
const _hoisted_27 = { class: "row" }
const _hoisted_28 = { class: "col-12" }
const _hoisted_29 = { class: "mb-0" }
const _hoisted_30 = { class: "col-12" }
const _hoisted_31 = {
  readonly: "",
  class: "w-100"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_checkbox = _resolveComponent("q-checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("decision.label.DECISION")), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("middleOffice.decision.decisionDate")), 1)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.decisions.decisionDate ? _ctx.getDateFormat(_ctx.decisions.decisionDate) : " "), 1)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t("middleOffice.decision.enterYourDecision")), 1)
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t("decision.status.".concat(_ctx.decisions.status.resourceUid))), 1)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t("middleOffice.decision.reasonForDecision")), 1)
            ]),
            _createElementVNode("div", _hoisted_22, [
              (_ctx.decisions.decisionReasons.length >0)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.decisions.decisionReasons, (decision, index) => {
                    return (_openBlock(), _createElementBlock("div", _hoisted_23, [
                      _createVNode(_component_q_checkbox, {
                        dense: "",
                        size: "s",
                        "model-value": decision.reason?.resourceUid.includes('Reason'),
                        label: _ctx.$t('middleOffice.decision.reasons.'.concat(decision.reason?.resourceUid))
                      }, null, 8, ["model-value", "label"])
                    ]))
                  }), 256))
                : _createCommentVNode("", true)
            ]),
            (_ctx.decisions.decisionReasons.length==0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                  _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.$t("middleOffice.decision.emptyReason")), 1)
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_26, [
          _createElementVNode("div", _hoisted_27, [
            _createElementVNode("div", _hoisted_28, [
              _createElementVNode("label", _hoisted_29, _toDisplayString(_ctx.$t("middleOffice.decision.comment")), 1)
            ]),
            _createElementVNode("div", _hoisted_30, [
              _createElementVNode("textarea", _hoisted_31, " " + _toDisplayString(_ctx.decisions.comment), 1)
            ])
          ])
        ])
      ])
    ])
  ]))
}