import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex justify-between align-items-left"
}
const _hoisted_2 = {
  class: "col-2",
  style: {"width":"14%"}
}
const _hoisted_3 = { class: "d-block mb-0 text-muted mb-2" }
const _hoisted_4 = {
  class: "col-2",
  style: {"width":"24%"}
}
const _hoisted_5 = { class: "d-block mb-0 text-muted mb-2" }
const _hoisted_6 = ["title"]
const _hoisted_7 = {
  class: "col-2",
  style: {"width":"14%"}
}
const _hoisted_8 = { class: "d-block mb-0 text-muted mb-2" }
const _hoisted_9 = ["onClick", "title"]
const _hoisted_10 = {
  class: "col-2",
  style: {"width":"14%"}
}
const _hoisted_11 = { class: "d-block mb-0 text-muted mb-2" }
const _hoisted_12 = {
  class: "col-2",
  style: {"width":"14%"}
}
const _hoisted_13 = { class: "d-block mb-0 text-muted mb-2" }
const _hoisted_14 = {
  class: "col-2",
  style: {"width":"12%"}
}
const _hoisted_15 = { class: "d-block mb-0 text-muted mb-2" }
const _hoisted_16 = { class: "col-1" }
const _hoisted_17 = ["id", "onChange"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_spinner_facebook = _resolveComponent("q-spinner-facebook")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_ctx.targetEntity.expanded)
    ? _renderSlot(_ctx.$slots, "default", { key: 0 }, () => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.targetEntity.documents, (document, i) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "card tw-items-dashed-group p-2",
            key: i
          }, [
            (document.document.resourceUid)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('demand.documents.columns.justifyType')), 1),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('task.supportingDocumentLabel.'.concat(document.typeJustify))), 1)
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('demand.documents.columns.documentType')), 1),
                    _createElementVNode("span", {
                      class: "tw-ellipses-text",
                      title: _ctx.$t('task.supportingDocumentLabel.'.concat(document.documentType))
                    }, _toDisplayString(_ctx.$t('task.supportingDocumentLabel.'.concat(document.documentType))), 9, _hoisted_6)
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('demand.documents.columns.document')), 1),
                    _createElementVNode("span", null, [
                      _createElementVNode("a", {
                        class: "cursor-pointer tw-ellipses-text",
                        onClick: ($event: any) => (_ctx.downloadDocument(document)),
                        title: document.document.originalFileName || document.document.receivedFileUid 
                      }, _toDisplayString(document.document.originalFileName ? document.document.originalFileName :
              document.document.receivedFileUid), 9, _hoisted_9)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('demand.documents.columns.creationDate')), 1),
                    _createElementVNode("span", null, _toDisplayString(document.creationDate), 1)
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t('task.supportingDocumentLabel.addedby')), 1),
                    _createElementVNode("span", null, _toDisplayString(_ctx.t(document.document.metadata ? _ctx.addedBy(document.document.metadata) : document.addedBy)), 1)
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t('demand.documents.columns.status')), 1),
                    (_ctx.currentRoute === 'TaskDetail')
                      ? (_openBlock(), _createBlock(_component_q_select, {
                          key: 0,
                          dense: "",
                          outlined: "",
                          "emit-value": "",
                          "map-options": "",
                          "model-value": document.status === 'QUALIFIED' ? _ctx.$t('task.uploadDocument.headers.supportingDocumentStatus.QUALIFIED') : document.status,
                          options: _ctx.supportingDocumentStatusOptions,
                          disable: _ctx.isTaskModifyConditions,
                          "onUpdate:modelValue": ($event: any) => (_ctx.onSupportingDocumentStatusChange({
                        event: $event,
                        document: document,
                        processIndex: _ctx.gpRowIndex
                      })),
                          "label-color": _ctx.getStatusClass(document.status)
                        }, null, 8, ["model-value", "options", "disable", "onUpdate:modelValue", "label-color"]))
                      : (_openBlock(), _createElementBlock("label", {
                          key: 1,
                          class: _normalizeClass(["d-block mb-0 mb-2", _ctx.getStatusClass(document.status)])
                        }, _toDisplayString(_ctx.getStatus(document.status)), 3))
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    (_ctx.currentRoute === 'TaskDetail')
                      ? (_openBlock(), _createBlock(_component_q_btn, {
                          key: 0,
                          outline: "",
                          class: "btn btn-sm btn-action d-none",
                          loading: document.isLoading,
                          icon: "event_note",
                          onClick: _cache[0] || (_cache[0] = () => {})
                        }, {
                          loading: _withCtx(() => [
                            _createVNode(_component_q_spinner_facebook)
                          ]),
                          _: 2
                        }, 1032, ["loading"]))
                      : _createCommentVNode("", true),
                    _createElementVNode("input", {
                      id: 'inputUpdateDocument'+ _ctx.targetEntity.reference+i,
                      class: "d-none",
                      type: "file",
                      onChange: ($event: any) => (_ctx.onSelectFile({
                          event: $event,
                          processIndex: _ctx.gpRowIndex,
                          targetRef: _ctx.targetEntity.reference,
                          docRef: document.reference
                        })),
                      ref_for: true,
                      ref: "file",
                      accept: ".pdf,.jpg,.jpeg,.png"
                    }, null, 40, _hoisted_17),
                    (_ctx.currentRoute === 'TaskDetail' && !_ctx.isTaskModifyConditions)
                      ? (_openBlock(), _createBlock(_component_q_btn, {
                          key: 1,
                          outline: "",
                          class: "btn btn-sm btn-action",
                          style: {"margin-top":"1rem","margin-right":"0.2em"},
                          loading: document.isLoading,
                          icon: "autorenew",
                          onClick: ($event: any) => (_ctx.updateDocument('inputUpdateDocument'+ _ctx.targetEntity.reference+i))
                        }, {
                          loading: _withCtx(() => [
                            _createVNode(_component_q_spinner_facebook)
                          ]),
                          _: 2
                        }, 1032, ["loading", "onClick"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_q_btn, {
                      outline: "",
                      class: "btn btn-sm btn-action",
                      style: {"margin-top":"1rem","margin-right":"0.2rem"},
                      icon: "download",
                      onClick: ($event: any) => (_ctx.downloadDocument(document))
                    }, null, 8, ["onClick"]),
                    _createVNode(_component_q_btn, {
                      outline: "",
                      class: "btn btn-sm btn-action",
                      style: {"margin-top":"1rem","margin-right":"0.5rem"},
                      loading: document.isLoading,
                      icon: "visibility",
                      onClick: ($event: any) => (_ctx.viewDocument(document))
                    }, {
                      loading: _withCtx(() => [
                        _createVNode(_component_q_spinner_facebook)
                      ]),
                      _: 2
                    }, 1032, ["loading", "onClick"]),
                    (document.manual && _ctx.currentRoute === 'TaskDetail')
                      ? (_openBlock(), _createBlock(_component_q_btn, {
                          key: 2,
                          outline: "",
                          class: "btn btn-sm btn-action",
                          icon: "delete",
                          style: {"margin-top":"1rem"},
                          onClick: ($event: any) => (_ctx.deleteManualDocument(document)),
                          loading: _ctx.removing
                        }, {
                          loading: _withCtx(() => [
                            _createVNode(_component_q_spinner_facebook)
                          ]),
                          _: 2
                        }, 1032, ["onClick", "loading"]))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ])
    : _createCommentVNode("", true)
}