
import {Options, Vue} from "vue-class-component";
import store from "@/store";
import {usePDF, VuePDF} from "@tato30/vue-pdf";

@Options({
  components: {
    VuePDF,
  },
})
export default class TemplatePreview extends Vue {

  pageNav = 1;
  myPDF: any = "";
  previewTemplateName: any = "";
  get previewTemplate() {
    if (store.state.templateModule.previewTemplate && store.state.templateModule.previewTemplate.length > 0) {
      this.previewTemplateName = store.state.templateModule.previewTemplate[0].fileName ;
    }
    else {
      this.previewTemplateName = ""
    }
    return this.previewTemplateName;
  }
  get templateSelected() {
    if (store.state.printDocumentModule.previewTemplate) {
      this.myPDF = usePDF(store.state.printDocumentModule.previewTemplate.arr);
      this.pageNav = 1;
    }
    return store.state.printDocumentModule.previewTemplate;
  }

  nextPage() {
    if (this.pageNav < this.myPDF.pages) {
      this.pageNav++;
    }
  }

  previousPage() {
    if (this.pageNav > 1) {
      this.pageNav--;
    }
  }



  isLoadingDownload = false;
  onDownloadEvent(){
    store.dispatch("printDocumentModule/downloadFile", {
      request: {
        resourceUid: this.fileId
      },
      callback: this.downloadDocumentResponse,
    });
  }

  downloadDocumentResponse(response: any) {
    if (response) {
      const { content } = response;
      const linkSource = `data:${response.format};base64,${content}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = response.originalFileName;
      downloadLink.click();
      this.viewDocumentResponse()
    }
  }

  get fileId() {
    return store.state.printDocumentModule.fileDetailFileId
  }
  viewDocumentResponse() {
    this.isLoadingDownload = false;
  }


}
