import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card-title flex-grow-1 d-flex justify-content-between align-items-center mb-0" }
const _hoisted_2 = { class: "d-flex flex-column" }
const _hoisted_3 = { class: "font-size-14 text-muted" }
const _hoisted_4 = { class: "font-size-14 font-medium" }
const _hoisted_5 = { class: "card-title flex-grow-1 d-flex justify-content-between align-items-center mb-0" }
const _hoisted_6 = { class: "d-flex flex-column" }
const _hoisted_7 = { class: "font-size-14" }
const _hoisted_8 = {
  key: 0,
  class: "card-body"
}
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-lg-4 col-md-4 col-sm-12" }
const _hoisted_11 = { class: "col-lg-4 col-md-4 col-sm-12" }
const _hoisted_12 = { class: "col-lg-4 col-md-4 col-sm-12" }
const _hoisted_13 = {
  key: 1,
  class: "card-body"
}
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "col-lg-12 col-md-12 col-sm-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_FinancingForm = _resolveComponent("FinancingForm")!
  const _component_FinancingServices = _resolveComponent("FinancingServices")!
  const _component_FinancingSituation = _resolveComponent("FinancingSituation")!
  const _component_FinancingServicesDetail = _resolveComponent("FinancingServicesDetail")!
  const _component_q_tab_panel = _resolveComponent("q-tab-panel")!
  const _component_GuaranteeTab = _resolveComponent("GuaranteeTab")!
  const _component_q_tab_panels = _resolveComponent("q-tab-panels")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!
  const _component_q_list = _resolveComponent("q-list")!

  return (_openBlock(), _createBlock(_component_q_list, {
    modelValue: _ctx.internTab,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.internTab) = $event))
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.proposals, (value, proposalIndex) => {
        return (_openBlock(), _createElementBlock("div", { key: proposalIndex }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(value.proposalItems, (item, index) => {
            return (_openBlock(), _createBlock(_component_q_expansion_item, {
              name: index + 1,
              key: index,
              "switch-toggle-side": "",
              "default-opened": "",
              "header-class": "card-header bg-transparent no-border p-4",
              class: "card tw-items-dashed-group tw-level-one-group"
            }, {
              header: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t(""  + _ctx.financingProduct(proposalIndex, index))), 1),
                    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("" + _ctx.scaleName(proposalIndex, index))), 1)
                  ])
                ])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_q_tabs, {
                  modelValue: _ctx.tab,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
                  align: "left",
                  "inline-label": "",
                  "active-color": "primary",
                  "indicator-color": "primary"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_tab, {
                      class: "primary mr-3",
                      name: "simulation",
                      label: _ctx.$t('middleOffice.tabs.financialProposal')
                    }, null, 8, ["label"]),
                    _createVNode(_component_q_tab, {
                      class: "primary mr-3",
                      name: "guarantee",
                      label: _ctx.$t('middleOffice.tabs.guarantee')
                    }, null, 8, ["label"])
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createVNode(_component_q_tab_panels, {
                  modelValue: _ctx.tab,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tab) = $event)),
                  animated: "",
                  "transition-prev": "slide",
                  "transition-next": "slide"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_tab_panel, {
                      name: "simulation",
                      class: "p-0"
                    }, {
                      header: _withCtx(() => [
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("div", _hoisted_7, _toDisplayString(item.financingProductPack?.resourceUid?.replace("finance.", "")), 1)
                          ])
                        ])
                      ]),
                      default: _withCtx(() => [
                        (!_ctx.showService)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                              _createElementVNode("div", _hoisted_9, [
                                _createElementVNode("div", _hoisted_10, [
                                  _createVNode(_component_FinancingForm, { proposal: value }, null, 8, ["proposal"])
                                ]),
                                _createElementVNode("div", _hoisted_11, [
                                  _createVNode(_component_FinancingServices, {
                                    class: "q-mr-sm q-ml-sm",
                                    proposal: value
                                  }, null, 8, ["proposal"])
                                ]),
                                _createElementVNode("div", _hoisted_12, [
                                  _createVNode(_component_FinancingSituation, { proposal: value }, null, 8, ["proposal"])
                                ])
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.showService)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                              _createElementVNode("div", _hoisted_14, [
                                _createElementVNode("div", _hoisted_15, [
                                  _createVNode(_component_FinancingServicesDetail, { proposal: value }, null, 8, ["proposal"])
                                ])
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_q_tab_panel, {
                      name: "guarantee",
                      class: "p-0"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_GuaranteeTab)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1032, ["modelValue"])
              ]),
              _: 2
            }, 1032, ["name"]))
          }), 128))
        ]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}