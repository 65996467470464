
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import { Emit } from "vue-property-decorator";
import { formatDate } from "@/utils/configuration/formatters-config";

import store from "@/store";
import {
  AssociatedParty,
  Offer,
  OfferAssociatedParty,
  Opinion,
  OpinionMaker,
  OpinionEnum,
  TaskSearchCriteria,
  ThirdType,
} from "@/types";
import router from "@/router";
import i18n from "@/i18n";

@Options({
  props: ["parties"],
  components: {},
  computed: {
    refDemand() {
      return store.state.demandModule.offer?.reference
        ? store.state.demandModule.offer.reference
        : "- - - - - - -";
    },
    statusDemand() {
      if (store.state.demandModule.offer?.status) {
        const resourceUid = store.state.demandModule.offer?.status.resourceUid;
        return resourceUid
          ? i18n.global.t("enum.offer.status.".concat(resourceUid))
          : "- - - - - - -";
      } else {
        return "- - - - - - -";
      }
    },
    refClient() {
      if (store.state.demandModule.offer?.associatedParties) {
        const response = "- - - - - - -";
        const index = store.state.demandModule.offer?.associatedParties?.findIndex(
          (offerAssociatedParty: any) => offerAssociatedParty.role_code === "CUSTOMER"
        );
        if (index > -1) {
          const customer = store.state.demandModule.offer?.associatedParties[index];
          return customer.uid !== "" ? customer.uid : response;
        }
        return response;
      }
    },
    startDate() {
      if (store.state.demandModule.offer?.validity) {
        const startDate = store.state.demandModule.offer?.validity.from;
        return startDate ? startDate : ref(formatDate(new Date(), "/"));
      }
    },
  },
  methods: {
    getSelectedLabel(scope: any) {
      return {
        resourceUid: scope.opt.resourceUid,
      };
    },
  },
})
export default class Avis extends Vue {
  getSelectedLabel: any;
  internalCode: any;
  defaultValue: any;
  //comment: OpinionMaker["comment"];
  get requiredField() {
    return i18n.global.t("main.requiredField");
  }
  get opinion() {
    return store.getters["taskModule/analysisModule/getOpinion"];
  }

  // get opinionComment() {
  //   return store.getters["taskModule/analysisModule/getOpinionComment"];
  // }
  get opinions() {
    return store.state.taskModule.analysisModule.analysis?.opinionQualifications;
  }

  setGlobalOpinion(newValue: any) {
    this.$store.commit("taskModule/analysisModule/setGlobaleComment", newValue);
  }

  get itemText() {
    return {
      resourceUid: this.opinion.resourceUid,
    };
  }

  set itemText(newValue: Opinion) {
    this.$store.commit("taskModule/analysisModule/setGlobaleOpinion", {
      resourceUid: newValue.resourceUid,
    });
  }

  // set itemTextComment(newComment: OpinionMaker["comment"] ) {
  //   this.$store.commit("taskModule/analysisModule/setGlobaleOpinion", {
  //     comment: newComment.comment,
  //   });
  // }

  /**
   * Computed property that returns an array of objects representing the keys and default values of the OpinionEnum Enum object.
   *
   * @returns {Array} An array of objects, where each object has two properties: `defaultValue` (set to `true`) and `internalCode` (set to a key from the OpinionEnum Enum object).
   */
  get OpinionsList() {
    return Object.keys(OpinionEnum) // Get array of keys in OpinionEnum
      .map((item: string) => {
        // Map over each key to create new array of objects
        return {
          resourceUid: item, // Set internal code to current key
        };
      });
  }
}
