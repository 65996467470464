
import store from '@/store';
import {Options, Vue} from 'vue-class-component';
import {Emit} from "vue-property-decorator";

@Options({
    props: ["show", "label", "component", "props", "mainView", "disableAddGuarantee","css"],
    watch: {
    "$store.state.financingModule.proposalGuaranteeModule.productGuaranteeSelected"(newValue) {
        this.disableAddGuarantee =  false
    }
    }
})
export default class Dialog extends Vue {
    show?: boolean
    label?: string
    component?: object
    props?: object
    mainView?: boolean
    disableAddGuarantee?:boolean


    /**
    * get the value of show prop from the parent component
    */
    get isShow() {
        return this.show
    }

    @Emit("onClose")
    onClose(evt: any) {
        store.dispatch("assetModule/updateTempSelectedGoods",  store.state.assetModule.selectedAsset?.selectedGoods);
        store.dispatch("assetModule/updateTempSelectedCatalogs",  store.state.assetModule.selectedAsset?.selectedCatalogs);
    }

    /**
     * This function is called when the user clicks the "Save" button.
     *
     * @param {any} evt - The event object.
     */
    @Emit("onSave")
    onSave(evt: any) {
        // Dispatch an action to update the selected goods in the store
        store.dispatch("assetModule/updateSelectedGoods",  store.state.assetModule.tempSelectedGoods);
        // Dispatch an action to update the saved catalogs in the store
        store.dispatch("assetModule/updateSelectedCatalogs",  store.state.assetModule.tempSelectedCatalogs);
    }
    addFunction() {
        this.$emit("addFunction")
    }

}
