import { formatAmoutswitchCurrency, formatDateLocale, formatWithMask } from "@/utils/configuration/formatters-config";
import i18n from "@/i18n";
import { convertToCSV } from "@/utils/csv";
import { FinancingPhase } from "@/types";

export const paymentSchedulesFileName = 'paymentSchedules'

const emptyValueCurrency = formatAmoutswitchCurrency("EUR", 0.0);
const emptyValue = "- - - - - - -"

const columnSelected = [
    'stepNo',
    'type',
    'dueDate',
    'periodStartDate',
    'periodEndDate',
    'inProgress',
    'amortization',
    'interest',
    'financialInstallmentWOTax',
    'taxes',
    'financialInstallmentWTax',
    'servicesWOTax',
    'tva',
    'sumOfTotalPayments',
    'totalTva',
    'sumOfTotalPaymentsTTC',
]

const baseI18nPath = 'demand.simulation.globalPaymentSchedule.table'
const basePanelI18nPath = 'demand.simulation.globalPaymentSchedule.panel'
const baseTypeI18nPath = 'demand.simulation.globalPaymentSchedule.table.types'

const columnMapper = [
    {
        key: 'stepNo',
        mapper: (item: any) => {
            return item.stepNo || '-'
        }
    },
    {
        key: 'type',
        mapper: (item: any) => {
            if (item.stepNo === '') {
                return i18n.global.t(`${baseTypeI18nPath}.firstLine`)
            } else if (item.stepNo === null) {
                return i18n.global.t(`${baseTypeI18nPath}.lastLine`)
            } else {
                return i18n.global.t(`${baseTypeI18nPath}.defaultLine`)
            }
        }
    },
    {
        key: 'dueDate',
        mapper: (item: any) => {
            return item.dueDate ? formatDateLocale(item.dueDate, 'DD-MM-YYYY', "fr", "DD/MM/YYYY") : '----'
        }
    },
    {
        key: 'periodStartDate',
        mapper: (item: any) => {
            return item.periodStartDate ? formatDateLocale(item.periodStartDate, 'DD-MM-YYYY', "fr", "DD/MM/YYYY") : '----'
        }
    },
    {
        key: 'periodEndDate',
        mapper: (item: any) => {
            return item.periodEndDate ? formatDateLocale(item.periodEndDate, 'DD-MM-YYYY', "fr", "DD/MM/YYYY") : '----'
        }
    },
    {
        key: 'inProgress',
        mapper: (item: any, calculationResult?: any) => {
            const value = item.outstandingBalanceAfter
            return formatAmoutswitchCurrency("EUR", value)
        }
    },
    {
        key: 'amortization',
        mapper: (item: any) => {
            if (item.stepNo === '') {
                return emptyValueCurrency
            } else {
                return formatAmoutswitchCurrency("EUR", item.paidPrincipal)

            }
        }
    },
    {
        key: 'interest',
        mapper: (item: any) => {
            return formatAmoutswitchCurrency("EUR", item.interest)

        }
    },
    {
        key: 'financialInstallmentWOTax',
        mapper: (item: any) => {
            return formatAmoutswitchCurrency("EUR", item.financialInstallmentWoTax)

        }
    },
    {
        key: 'taxes',
        mapper: (item: any) => {
            //return emptyValueCurrency - TP3590
            return formatAmoutswitchCurrency("EUR", item.financialInstallmentWTax - item.financialInstallmentWoTax)
        }
    },
    {
        key: 'financialInstallmentWTax',
        mapper: (item: any) => {
            return formatAmoutswitchCurrency("EUR", item.financialInstallmentWTax)
        }
    },
    {
        key: 'servicesWOTax',
        mapper: (item: any) => {
            return formatAmoutswitchCurrency("EUR", item.paidServicesWoTax)
        }
    },
    {
        key: 'tva',
        mapper: (item: any) => {
            return formatAmoutswitchCurrency("EUR", item.paidServicesWTax - item.paidServicesWoTax)
        }
    },
    {
        key: 'totalTva',
        mapper: (item: any) => {
            return formatAmoutswitchCurrency("EUR", (item.totalInstallmentWTax - item.totalInstallmentWoTax))
        }
    },
    {
        key: 'sumOfTotalPayments',
        mapper: (item: any) => {
            return formatAmoutswitchCurrency("EUR", item.totalInstallmentWoTax)
        }
    },
    {
        key: 'sumOfTotalPaymentsTTC',
        mapper: (item: any) => {
            return formatAmoutswitchCurrency("EUR", item.totalInstallmentWTax)
        }
    },

]

export const geColumnStyleByKey = (columnKey: string) => {
    const amountColumns: any = [
        "inProgress",
        "amortization",
        "interest",
        "financialInstallmentWOTax",
        "taxes",
        "financialInstallmentWTax",
        "servicesWOTax",
        "tva",
        "totalTva",
        "sumOfTotalPayments",
        "sumOfTotalPaymentsTTC"
    ]
    if (amountColumns.find((item: string) => item === columnKey)) {
        return {
            align: "right",
            style: "width:15%;font-family: sans-serif !important;"
        };
    }

    return {
        align: 'left',
        style: 'width: 15%'
    }
}

export const getColumns = (rowsLength: number, calculationResult: any) => {
    return columnSelected.map(key => {

        return {
            name: key,
            label: i18n.global.t(`${baseI18nPath}.${key}`),
            align: geColumnStyleByKey(key).align,
            style: geColumnStyleByKey(key).style,
            field: (row: { item: any; }) => {
                const mapper = columnMapper.filter(m => m.key === key)[0]
                if (mapper) {
                    return mapper.mapper(row, calculationResult)
                } else {
                    return '----'
                }
            },
            sortable: false
        }
    })
}

export const convertPaymentScheduleObjectToDownload = (calculationResult: any, offer?: any, scaleSelected?: any) => {
    const panel = convertToCSV([convertPanelObjectToDownload(calculationResult, offer, scaleSelected)])
    const table = convertToCSV(convertPanelItemObjectToDownload(calculationResult, calculationResult.paymentSchedules))
    return panel.concat('\n')
        .concat('\n')
        .concat(table)
        .replace(/\xa0/g, ' ')
        .replace(/\u202f/g, ' ')
}

const convertPanelObjectToDownload = (calculationResult: any, offer?: any, scaleSelected?: any) => {
    const result: any = {}
    if (calculationResult) {
        const amount = calculationResult.price || 0
        result[i18n.global.t(`${basePanelI18nPath}.reference`)] = offer && offer.reference ? offer.reference : emptyValue
        result[i18n.global.t(`${basePanelI18nPath}.phase`)] = i18n.global.t(`enum.offer.financingPhase.${FinancingPhase.SIMULATION}`)
        result[i18n.global.t(`${basePanelI18nPath}.product`)] = scaleSelected?.productName || emptyValue
        result[i18n.global.t(`${basePanelI18nPath}.amount`)] = `${formatWithMask(amount, 2)} €`
        result[i18n.global.t(`${basePanelI18nPath}.downPayment`)] = `${formatWithMask(calculationResult.downPayment, 2)} €`
        result[i18n.global.t(`${basePanelI18nPath}.residualValue`)] = `${formatWithMask(calculationResult.residualValueWoTax, 2)} €`
        result[i18n.global.t(`${basePanelI18nPath}.residualValueAmount`)] = `${formatWithMask(calculationResult.financedValue, 2)} €`
        result[i18n.global.t(`${basePanelI18nPath}.duration`)] = `${calculationResult.duration} mois`
        result[i18n.global.t(`${basePanelI18nPath}.interest`)] = `${calculationResult.paymentSchedules[1] && calculationResult.paymentSchedules[1].nominalRate ? formatWithMask(calculationResult.paymentSchedules[1].nominalRate, 2) : '0,00'}`
        result[i18n.global.t(`${basePanelI18nPath}.firstPayment`)] = `${formatWithMask(calculationResult.firstPaymentWoTax, 2)} €`
        result[i18n.global.t(`${basePanelI18nPath}.taeg`)] = `${formatWithMask(calculationResult.aprc, 2)}`
        result[i18n.global.t(`${basePanelI18nPath}.taea`)] = `${formatWithMask(calculationResult.air, 2)}`
        result[i18n.global.t(`${basePanelI18nPath}.sumOfFinancialPayments`)] = `${formatWithMask(calculationResult.sumOfFinancialPaymentsWTax.amount, 2)} €`
        result[i18n.global.t(`${basePanelI18nPath}.sumOfInterests`)] = `${formatWithMask(calculationResult.sumOfInterests.amount, 2)} €`
        result[i18n.global.t(`${basePanelI18nPath}.sumOfServices`)] = `${formatWithMask(calculationResult.sumOfServicesWTax.amount, 2)} €`
        result[i18n.global.t(`${basePanelI18nPath}.creditCost`)] = `${formatWithMask(calculationResult.creditCost.amount, 2)} €`
        result[i18n.global.t(`${basePanelI18nPath}.sumOfTotalPayments`)] = `${formatWithMask(calculationResult.sumOfTotalPaymentsWoTax.amount, 2)} €`
        result[i18n.global.t(`${basePanelI18nPath}.tva`)] = `${formatWithMask(calculationResult.downpayment, 2)} €`
        result[i18n.global.t(`${basePanelI18nPath}.sumOfTotalPaymentsTTC`)] = `${formatWithMask(calculationResult.sumOfTotalPaymentsWTax.amount, 2)} €`
    }
    return result
}

const convertPanelItemObjectToDownload = (calculationResult: any, paymentSchedules: any) => {
    const result: any = []
    if (calculationResult && paymentSchedules) {
        for (let i = 0; i < paymentSchedules.length; i++) {
            const tax = 0 // TODO Lilia commented on the ticket - Tax is not yet handled → you can put 0 until it is done .
            const item = paymentSchedules[i]
            const obj: any = {}

            obj[i18n.global.t(`${baseI18nPath}.stepNo`)] = item.stepNo
            if (item.stepNo === '') {
                obj[i18n.global.t(`${baseI18nPath}.type`)] = i18n.global.t(`${baseTypeI18nPath}.firstLine`)
            } else if (item.stepNo === null) {
                obj[i18n.global.t(`${baseI18nPath}.type`)] = i18n.global.t(`${baseTypeI18nPath}.lastLine`)
            } else {
                obj[i18n.global.t(`${baseI18nPath}.type`)] = i18n.global.t(`${baseTypeI18nPath}.defaultLine`)
            }
            obj[i18n.global.t(`${baseI18nPath}.dueDate`)] = item.dueDate ? formatDateLocale(item.dueDate, 'DD-MM-YYYY', "fr", "DD/MM/YYYY") : emptyValue
            obj[i18n.global.t(`${baseI18nPath}.periodStartDate`)] = item.periodStartDate ? formatDateLocale(item.periodStartDate, 'DD-MM-YYYY', "fr", "DD/MM/YYYY") : emptyValue
            obj[i18n.global.t(`${baseI18nPath}.periodEndDate`)] = item.periodEndDate ? formatDateLocale(item.periodEndDate, 'DD-MM-YYYY', "fr", "DD/MM/YYYY") : emptyValue
            obj[i18n.global.t(`${baseI18nPath}.inProgress`)] = `${formatWithMask(item.outstandingBalanceAfter, 2)} €`
            if (item.stepNo === '') {
                obj[i18n.global.t(`${baseI18nPath}.amortization`)] = emptyValueCurrency
            } else {
                obj[i18n.global.t(`${baseI18nPath}.amortization`)] = `${formatWithMask(item.paidPrincipal, 2)} €`
            }
            obj[i18n.global.t(`${baseI18nPath}.interest`)] = `${formatWithMask(item.interest, 2)} €`
            obj[i18n.global.t(`${baseI18nPath}.financialInstallmentWOTax`)] = `${formatWithMask(item.financialInstallmentWoTax, 2)} €`
            obj[i18n.global.t(`${baseI18nPath}.taxes`)] = `${formatWithMask(item.financialInstallmentWTax - item.financialInstallmentWoTax , 2)} €`
            obj[i18n.global.t(`${baseI18nPath}.financialInstallmentWTax`)] = `${formatWithMask((item.financialInstallmentWoTax + (item.paidServicesWTax - item.paidServicesWoTax)) + ((item.financialInstallmentWTax - item.financialInstallmentWoTax) + (item.paidServicesWTax - item.paidServicesWoTax)), 2)} €`
            obj[i18n.global.t(`${baseI18nPath}.servicesWOTax`)] = `${formatWithMask(item.paidServicesWoTax, 2)} €`
            obj[i18n.global.t(`${baseI18nPath}.tva`)] = `${formatWithMask(item.paidServicesWTax - item.paidServicesWoTax, 2)} €` 
            obj[i18n.global.t(`${baseI18nPath}.sumOfTotalPayments`)] = `${formatWithMask(item.financialInstallmentWoTax + (item.paidServicesWTax - item.paidServicesWoTax), 2)} €`
            obj[i18n.global.t(`${baseI18nPath}.totalTva`)] = `${formatWithMask((item.financialInstallmentWTax - item.financialInstallmentWoTax) + (item.paidServicesWTax - item.paidServicesWoTax), 2)} €`
            obj[i18n.global.t(`${baseI18nPath}.sumOfTotalPaymentsTTC`)] = `${formatWithMask((item.financialInstallmentWoTax + (item.paidServicesWTax - item.paidServicesWoTax)) + ((item.financialInstallmentWTax - item.financialInstallmentWoTax) + (item.paidServicesWTax - item.paidServicesWoTax)), 2)} €`

            result.push(obj)
        }
    }
    return result
}
