
import {Options, Vue} from "vue-class-component";
import Study from "@/components/Analysis/Study.vue";


@Options({
  components: {
    Study
  }
})
export default class AnalysisDetails extends Vue {
  tab = "analysis";
  get currentRoute() {
    return this.$router.currentRoute;
  }
  updated() {
    this.$emit('update')
  }
   


}
