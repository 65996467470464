
import { Options, Vue } from 'vue-class-component';
import { Emit } from "vue-property-decorator";

@Options({
    props: ["show", "label", "ctaLabel", "cancelButtonLabel", "width", "component", "props"],
})

export default class SideBarEfficom extends Vue {
    show?: boolean
    label?: string
    ctaLabel?: string
    width?: boolean
    cancelButtonLabel?: string
    component?: object
    props?: object

    @Emit("onClose")
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClose(evt: any) {
    }

    @Emit("onSave")
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSave(evt: any) {
    }

    /**
* Indicates whether the drawer is open based on the value of the 'show' prop.
*
* @returns {boolean} - A boolean value indicating whether the drawer is open.
*/
    get isDrawerOpen() {
        return this.show;
    }
  updated() {
      this.$emit('update');
  }
 

}
