
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import SimulationScales from "@/components/demand/simulation/normal-mode/SimulationScales.vue";
import DemandSimulationForm from "@/components/demand/simulation/normal-mode/DemandSimulationForm.vue";
import SimulationServices from "@/components/demand/simulation/normal-mode/SimulationServices.vue";
import SimulationFinancialSituation from "@/components/demand/simulation/normal-mode/SimulationFinancialSituation.vue";
import GlobalPaymentSchedule from "@/components/demand/simulation/normal-mode/GlobalPaymentSchedule/GlobalPaymentSchedule.vue";
import store from "@/store";
import ServicesDetail from "@/components/demand/simulation/normal-mode/ServicesDetail.vue";
import Notify from 'quasar/src/plugins/Notify.js';;
import i18n from "@/i18n";
import { Analysis, FinancingStateEnum } from "@/types";
import Comment from "@/components/communication/Comment.vue";
import {
  convertPaymentScheduleObjectToDownload,
  paymentSchedulesFileName,
} from "@/store/services/financing/financingGlobalPaymentScheduleMapper";
import { useRoute } from "vue-router";
import ExpertDemandSimulationForm from "@/components/demand/simulation/expert-mode/ExpertDemandSimulationForm.vue";
import GuaranteeTab from "@/components/demand/simulation/normal-mode/guarantee/GuaranteeTab.vue";
@Options({
  components: {
    SimulationServices,
    DemandSimulationForm,
    SimulationScales,
    SimulationFinancialSituation,
    ServicesDetail,
    GlobalPaymentSchedule,
    Comment,
    ExpertDemandSimulationForm,
    GuaranteeTab
  },
  watch: {
    "$store.state.simulationModule.toShowScaleBox"(newValue) {
      this.toShowScaleBox = newValue;
    },
  },
})
export default class DemandTabFinancing extends Vue {
  tab = ref("simulation");
  simulatorTabs = ref("simulation1");
  toShowScaleBox = true;
  qualification?: Analysis;
  expansionModel = true;

  financingStateGlobalPaymentSchedule = FinancingStateEnum.GLOBAL_PAYMENT_SCHEDULE;
  financingStateGlobalInitial = FinancingStateEnum.INITIAL;

  financingStateExpertMode = FinancingStateEnum.EXPERT;

  exportGlobalPaymentScheduleAsCSV() {
    const anchor = document.createElement("a");
    anchor.href =
      "data:text/csv;charset=utf-8,%EF%BB%BF" +
      encodeURI(
        convertPaymentScheduleObjectToDownload(
          this.calculationResult,
          this.offer,
          this.scaleSelected
        )
      );
    anchor.target = "_blank";
    anchor.download = `${paymentSchedulesFileName}.csv`;
    anchor.click();
  }

  exportGlobalPaymentScheduleAsPDF() {
    store.dispatch("financingModule/getSimulationReport", {}).then((content: string) => {
      if (content) {
        const linkSource = `data:application/pdf;base64,${content}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = `${paymentSchedulesFileName}.pdf`;
        downloadLink.click();
      }
    });
  }

  openExpansion() {
    this.expansionModel = true;
  }
  get isTaskAnalysis() {
    return store.getters["taskModule/isTaskAnalysis"];
  }
  get opinionQualifications() {
    return store.getters["taskModule/analysisModule/getOpinionQualifications"];
  }

  get currentRoute() {
    return useRoute().name;
  }
  get offer() {
    return store.state.demandModule.offer;
  }

  get calculationResult() {
    return this.scaleSelected?.calculationResult;
  }

  get simulationButtonsState() {
    return this.expansionModel && this.scaleSelected;
  }

  get scaleSelected() {
    return store.state.financingModule.scalesModule.scaleSelected;
  }

  get isNormalFinancingState() {
    return (
      store.getters["financingModule/isInitialState"] ||
      store.getters["financingModule/isCalculatedState"] ||
      store.getters["financingModule/isSavedState"]
    );
  }

  get isExpertFinancingState() {
    return (
        store.getters["financingModule/isExpertModeState"]
    );
  }

  get isServiceDetailState() {
    return store.getters["financingModule/isServiceDetailState"];
  }

  get isGlobalPaymentScheduleState() {
    return store.getters["financingModule/isGlobalPaymentScheduleState"];
  }

  get price() {
    return store.getters["financingModule/simulationPrice"];
  }

  changeFinancingState(financingState: FinancingStateEnum) {
    this.openExpansion();
    store.dispatch("financingModule/updateCurrentState", { newState: financingState });
  }

  created() {
    store.dispatch('financingModule/serviceModule/refreshPartyInsuranceForAllScales', {})
    const price = this.price
    if (price <= 0) {
      Notify.create({
        timeout: 10000,
        actions: [{ icon: 'close', color: 'white' }],
        message: i18n.global.t(`demand.product.messages.priceIsRequired`),
        color: "negative",
      });
      store.dispatch("financingModule/cleanScales")
    } else {
      store
        .dispatch("financingModule/refreshState")
        .then(() => store.dispatch("financingModule/setSimulationChanged", false));
    }
  }
}
