
import { Options, Vue } from "vue-class-component";
import DocumentsPanel from "@/components/middle-office/tabs/documents/DocumentsPanel.vue";
import { formatDate } from "@/utils/configuration/formatters-config";
import { globalConfig } from "@/utils";

@Options({
  components: { DocumentsPanel },
  props: ["histories"],
})
export default class HistoriesPanel extends Vue {
  histories?: [];

  columns = globalConfig.table_config.middleOfficeTable.histories;
}
