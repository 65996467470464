
import {Options, Vue} from 'vue-class-component';


import {NoticeTemplate} from "@/types";
import {Emit} from "vue-property-decorator";
import store from "@/store";

@Options({
  props: ['noticeTemplate','notice']
})
export default class TemplateListPackItem extends Vue {
  noticeTemplate?: NoticeTemplate
  notice: any

  get templates() {
    return store.state.taskModule.printOfferModule.templates
  }

  onSelectChange(noticeTemplate: any) {
    store.dispatch('taskModule/printOfferModule/setTemplateSelectedItem',
      { template: this.noticeTemplate, selected: this.noticeTemplate?.selected , parentNotice :this.notice })  

    this.checkTemplatesItemsSelected()
  }

  @Emit('child-boolean-changed')
  checkTemplatesItemsSelected() {}

}

