
import { Options, Vue } from "vue-class-component";
import store from "@/store";
import HistoriesPanel from "@/components/middle-office/tabs/history/HistoriesPanel.vue";
import StatusHistory from "@/components/middle-office/tabs/history/StatusHistory.vue";
import { StatusHistoryVariables } from "@/types";
import moment from "moment";
@Options({
  components: { HistoriesPanel, StatusHistory },
  props: ["entityState", "historiesStep"],
})
export default class SummaryActionsHistory extends Vue {
  historiesStep?: any;
  expanded = true;
  getDateFormated(date: any) {
    if (date && date !== "") return moment(date).format("DD/MM/YYYY HH:mm");
  }
  getName(value: string) {
    if (value && value !== "") {
      return value.split("##")[0];
    }
  }

  openStatusHistory(row: any) {
    store.dispatch("middleOfficeModule/setVariablesVisible", true);
    if (typeof row.variables === 'string') {
      const obj = JSON.parse(row.variables);
      const variablesSelected: StatusHistoryVariables = {
        offerCreationDate: this.getDateFormated(`${row.creationDate || ""} `),
        customerType: obj.customerType.value,
        offerPhase: obj.offerPhase.value,
        customerReference: obj.customerReference.value,
        offerReference: obj.offerReference.value,
        offerStatus: obj.offerStatus.value,
        customerName: obj.customerName.value,
        taskDefinition: row.taskDefinition,
        comment : obj.comment && obj.comment.value ? obj.comment.value : ''
      }
      if (obj._clientDecisionStatus != undefined) {
        variablesSelected.clientDecisionStatus = obj._clientDecisionStatus.value
      }
      store.dispatch("middleOfficeModule/setStatusHistoryVariablesSelected", variablesSelected);
    }
  }
}
