
import i18n from '@/i18n';
import store from '@/store';
import { Options, Vue } from 'vue-class-component';
import {TaskModule, AssetFromEnum, InventoryStatusEnum, businessDataUtils} from "@/types";
import Dialog from "@/components/general/Dialog.vue";
import GoodOrderSearch from "@/components/order/asset/dialogueOrderAsset/GoodOrderSearch.vue";
import Notify from 'quasar/src/plugins/Notify.js';;
import { useRoute } from 'vue-router';
@Options({
  components: {
    Dialog
  },
  props: ["currentModule", "tabPosition"],
})

export default class ManageAssetFromInventory extends Vue {
  currentModule: any;
  offerModule = TaskModule.OFFER
  orderModule = TaskModule.ORDER
  tabPosition?: number;
  emptyValue?: string = ' ';
  showAssetComboBox?: boolean = false
  showStockButton?: boolean = false
  productAssetModel?: any
  assetFromModel?: any
  showDialog?: boolean = false;
  assetList = []
  componentToRender = GoodOrderSearch;
  businessDataUtils = businessDataUtils;

  get assetFrom() {
    return [
      {
        value: AssetFromEnum.INVENTORY,
        label: i18n.global.t("order.asset.selectAssetFrom.inventory"),
      },
      {
        value: AssetFromEnum.CATALOG,
        label: i18n.global.t("order.asset.selectAssetFrom.catalog"),
      }
    ];
  }

  get currentRoute() {
    return useRoute().name;
  }

  get offer() {
    return store.state.middleOfficeModule.offer || store.state.demandModule.offer;
  }
  onChangeAssetFrom(event: any) {
    if (event === AssetFromEnum.CATALOG) {
      this.showStockButton = false
      this.showAssetComboBox = true
    }
    else if (event === AssetFromEnum.INVENTORY) {
      this.showAssetComboBox = false
      this.showStockButton = true
    }
  }
  // Offer_Module ###
  get asset() {
    return store.state.demandModule.assetState.proposalAssets[this.tabPosition || 0];
  }

  get productValues() {
    if (this.asset?.config?.proposalAssetCalculated) {
      const productAssetCalculated = this.asset?.config.proposalAssetCalculated;
      return productAssetCalculated.product;
    } else {
      return {
        name: "",
        unitaryPrice: 0,
        discount: 0,
        tax: 0,
        priceWithTax: 0.0,
      };
    }
  }
  get isFormReadOnly() {
    return store.state.demandModule.isEdit && !!this.asset?.resourceUid  ;
  }
  get selectedOfferAssetFrom() {
    if (this.currentModule === TaskModule.ORDER) {
      const tabPosition = this.tabPosition || 0;
      const orderAssets = store.state.orderModule.orders[tabPosition].orderAssets;

      
      if (orderAssets && orderAssets.length > 0) {
        const value = orderAssets[0].assetInventory;
        
        if (value) {
          this.onChangeAssetFrom(AssetFromEnum.INVENTORY);
          return {
            value: AssetFromEnum.INVENTORY,
            label: i18n.global.t("order.asset.selectAssetFrom.inventory"),
          };
        } else {
          this.onChangeAssetFrom(AssetFromEnum.CATALOG);
          return {
            value: AssetFromEnum.CATALOG,
            label: i18n.global.t("order.asset.selectAssetFrom.catalog"),
          };
        }
      }
      else {
        return {};
      }
    }

    else if (this.currentModule === TaskModule.OFFER) {
  const modulePath = store.state.demandModule.offer ? 'demandModule' : 'middleOfficeModule';
  const proposals = store.state[modulePath]?.offer?.proposals || [];

  if (proposals.length > 0 && proposals[0].proposalItems) {
    const proposalItems = proposals[0].proposalItems;

    if (proposalItems.length > 0 && proposalItems[0].proposalAssets) {
      const proposalAssets = proposalItems[0].proposalAssets;

      if (proposalAssets.length > 0 && proposalAssets[this.tabPosition || 0].config?.productAssetModel?.config?.inventoryItems) {
        const inventoryItems = proposalAssets[this.tabPosition || 0].config.productAssetModel.config.inventoryItems;

        if (inventoryItems.length > 0) {
          this.onChangeAssetFrom(AssetFromEnum.INVENTORY);
          return {
            value: AssetFromEnum.INVENTORY,
            label: i18n.global.t("order.asset.selectAssetFrom.inventory")
          };
        } else {
          this.onChangeAssetFrom(AssetFromEnum.CATALOG);
          return {
            value: AssetFromEnum.CATALOG,
            label: i18n.global.t("order.asset.selectAssetFrom.catalog")
          };
        }
      }
    }
  }
  return {};
}
  }
  get selectedOfferProductAsset() {
    if (this.currentModule === TaskModule.ORDER) {
      const tabPosition = this.tabPosition || 0;
      const orderAssets = store.state.orderModule.orders[tabPosition].orderAssets;
      if (orderAssets && orderAssets.length > 0) {
      const description = this.selectedProductAsset.value ? this.selectedProductAsset.value : orderAssets[0].description

      return {
            value: description, 
            label: description
          };
    }
    else {
        return {};
      }
  }
    else if (this.currentModule === TaskModule.OFFER) {
      const modulePath = store.state.demandModule.offer ? 'demandModule' : 'middleOfficeModule';
      if (store.state[modulePath]?.offer?.proposals) {
        const proposals = store.state[modulePath]?.offer?.proposals;
        if (proposals && proposals.length > 0 && proposals[0].proposalItems) {
          const proposalItems = proposals[0].proposalItems;
          if (proposalItems.length > 0 && proposalItems[0].proposalAssets) {
            const proposalAssets = proposalItems[0].proposalAssets;
            if (proposalAssets.length > 0 && proposalAssets[this.tabPosition || 0].description) {
              const description = proposalAssets[this.tabPosition || 0].description ? proposalAssets[this.tabPosition || 0].description : proposalAssets[this.tabPosition || 0].boatName;             
              return {
            value: description, 
            label: description
          }
            }
          }
        }
        return {};
      }
    } else {
      return {};
    }
  }
  get tempSelectedOfferGoods() {
    return store.state.demandModule.tempSelectedOfferGoods
  }
  handleSave() {
    if (this.tempSelectedOrderGoods && this.tempSelectedOrderGoods.product_code && this.tempSelectedOrderGoods.product_code !== undefined) {
      const product_code = this.tempSelectedOrderGoods.product_code
      const inventoryItemCode = this.tempSelectedOrderGoods.inventory_item_code
      store.dispatch("orderModule/getProduct", {
        productCode: product_code,
        callback: (selectedProduct: any) => {
          this.changeAsset(selectedProduct);
          store.dispatch("orderModule/updateInventoryItem", {
            inventoryItemCode: inventoryItemCode,
            status: InventoryStatusEnum.RESERVED
          })
          this.showDialog = false;
        }
      })
    }
    else {
      Notify.create({
        timeout: 10000,
        actions: [{ icon: 'close', color: 'white' }],
        color: "negative",
        message: i18n.global.t(`order.asset.messages.selectAssetError`),
      });
    }
  }
  changeAsset(value: any, skipResetScales?: boolean) {
    if (!skipResetScales) {
      store.dispatch('financingModule/setSimulationRefreshing', true)
    }
    if (this.currentModule === "Offer") {
      store.dispatch('demandModule/searchAsset', {
        id: value.config.resourceUid,
        index: this.tabPosition,
        category: value.config.categoryClass,
        productAssetModel: value,
        refreshAssetItems: !skipResetScales,
        callback: () => {
          store.dispatch('demandModule/changeAssetReadOnly', {
            index: this.tabPosition, readOnly: true
          })
          setTimeout(() => {
            if (!skipResetScales) {
              store.dispatch('financingModule/resetScales')
            }
          }, 1000)
        }
      })
    }
    else if (this.currentModule === "Order") {
      store.dispatch('orderModule/setSlectedProductAsset', value);
     
      store.dispatch('orderModule/searchAsset', {
        id: value.config.resourceUid,
        index: this.tabPosition,
        category: value.config.categoryClass,
        productAssetModel: value,
        refreshAssetItems: !skipResetScales,
        isInventory:true,
        productName:value.config.productName,
        callback: () => {
          store.dispatch('orderModule/selectGoodImage', {
             productUid: value.config.productUid.resourceUid,
             callback: (res:any) => {
               store.dispatch('orderModule/setselectedGoodImage',{
                index: this.tabPosition,
                goodImage: res
               })
               
              }
          })
          store.dispatch('orderModule/changeAssetReadOnly', {
            index: this.tabPosition, readOnly: true
          })
        }
      })
    } 
  }
  initOfferAssetList() {
    store.dispatch("demandModule/searchAssetProductList", {
      callback: (res: any) => {
        this.assetList = res.items;
        if (!store.state.demandModule.isEdit) {
          const productAssetCalculated = this.asset?.config.proposalAssetCalculated || {};
          const productAssetModel = this.asset?.config.productAssetModel || undefined;
          if (productAssetModel) {
            this.changeAsset(productAssetModel, true)
            this.productAssetModel = productAssetModel
            store.dispatch('demandModule/changeAssetReadOnly', {
              index: this.tabPosition, readOnly: true
            })
          } else {
            const assetProductUid =
              this.asset?.assetProduct?.resourceUid || productAssetCalculated.resourceUid;
            const assetProduct = res.items.filter(
              (item: any) => item.id === assetProductUid
            )[0];
            if (assetProduct) {
              this.changeAsset(assetProduct, true)
              this.productAssetModel = assetProduct
            }
          }
        } else {
          this.productAssetModel = this.asset?.config.productAssetModel

        }
      },
    });
  }
  // Order_Module ###
  get selectedAssetFrom() {
    return store.state.orderModule.selectedAssetFrom
  }

  get selectedProductAsset() {
    return store.state.orderModule.selectedProductAsset
  }
  get tempSelectedOrderGoods() {
    return store.state.orderModule.tempSelectedOrderGoods
  }
  get dialogLabel() {
    return i18n.global.t("order.asset.dialog.label")
  }
  openDialog() {
    this.currentModule === TaskModule.OFFER
      ? store.dispatch("offerModule/updateTempSelectedOfferGoods", {})
      : store.dispatch("orderModule/updateTempSelectedOrderGoods", {})

    this.showDialog = true;
  }
  onCloseDialog() {
    this.showDialog = false;
  }


  created() {
    if (this.currentModule === TaskModule.OFFER) {
      this.initOfferAssetList()
      store.dispatch('demandModule/initSelectedAssetFrom');
      store.dispatch('demandModule/initSelectedProductAsset');
    }
    else if (this.currentModule === TaskModule.ORDER) {
      this.initOfferAssetList()
      store.dispatch('orderModule/initSelectedAssetFrom');
      store.dispatch('orderModule/initSelectedProductAsset');
    }

  }
}
