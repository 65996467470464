
import { Options, Vue } from "vue-class-component";
import { AssociatedParty, businessDataUtils, OfferAssociatedParty, PicklistItem, TaskDefinition, Third } from "@/types";
import store from "@/store";

import DemandTabThird from "@/components/demand/tabs/DemandTabThird.vue";
import DemandAssetTab from "@/components/demand/tabs/DemandAssetTab.vue";
import DemandSimulationSummary from "@/components/demand/simulation/DemandSimulationSummary.vue";
import DemandRegister from "@/components/demand/details/DemandRegister.vue";
import SummaryPanel from "@/components/summary-panel/SummaryPanel.vue";
import { ref } from "vue";
import i18n from "@/i18n";
import DemandTabFinancing from "@/components/demand/tabs/DemandTabFinancing.vue";
import Avis from "@/components/demand/tabs/Avis.vue";
import Documents from "@/components/middle-office/tabs/Documents.vue";
import DocumentManagment from "@/components/task/tabs/documentManagment/DocumentManagment.vue"
import Notify from 'quasar/src/plugins/Notify.js';;
import { useRoute } from "vue-router";
import Decision from "@/components/middle-office/tabs/Decisions.vue";
import Study from "@/components/Analysis/Study.vue";
import { getEntity, ValidateMandatoryFields } from "@/utils";
import Decisions from "@/components/task/tabs/Opinion/Decision.vue";
import Financing from "@/components/middle-office/tabs/Financing.vue";

@Options({
  components: {
    Financing,
    DemandTabFinancing,
    DemandTabThird,
    DemandAssetTab,
    DemandSimulationSummary,
    DemandRegister,
    SummaryPanel,
    Documents,
    Avis,
    Decision,
    Study,
    Decisions,
    DocumentManagment
  },
  computed: {
    isBtnCalendarDisabled() {
      return store.state.simulationModule.pricingCalculationResponse === undefined;
    },
    isNormalMode() {
      return store.state.simulationModule.typeSimulation === "normal";
    },
    isExpertMode() {
      return store.state.simulationModule.typeSimulation === "expert";
    },
  },
  watch: {
    "$store.state.financingModule.simulationChanged"(simulationChanged) {
      this.simulationChanged = simulationChanged;
    },
    "$store.state.financingModule.serviceModule.calculationPendent"(calculationPendent) {
      this.calculationPendent = calculationPendent;
    },
  },
})
export default class DemandEdit extends Vue {
  openDialog = "";
  showDialog?: boolean = false;
  summaryPanel?: boolean = false;
  simulationChanged?: boolean = false;
  calculationPendent?: boolean = false;
  tab = ref("tiers");
  internTab: any = ref(1);
  channel = ref("Démarche");
  date = ref(new Date());
  roles: PicklistItem[] = [];
  taskDefinition: any;
  ValidateMandatoryFields = ValidateMandatoryFields;
  get offerMO() {
    return store.state.middleOfficeModule.offer ? store.state.middleOfficeModule.offer : store.state.taskModule.offer;
  }
  get proposals() {
    return this.offerMO!.proposals;
  }

  get currentRoute() {
    console.log('test',useRoute().name)
    return useRoute().name;
  }
  get getRole() {
    const roleItems = store.getters["picklistModule/roleItems"];
    const roleIdMapping = roleItems.map((item: any) => ({
      id: item.value,
      label: item.label,
    }));
    return roleIdMapping;
  }
  get offer() {
    return store.state.demandModule?.offer;
  }

  get associatedParties() {
    if (!this.offer || !this.offer.associatedParties) {
      return [];
    }
    const associatedParties = this.offer.associatedParties.sort(
      (a: any, b: any) => a.id - b.id
    );
    associatedParties?.forEach((associatedParty) => {
      associatedParty.associatedParty?.third?.classifications?.forEach(
        async (node: any) => {
          if (!node.classType) {
            node.partyClass = {
              type: {
                value: node.partyClass.resourceUid || node.partyClass.type.value,
              },
            };
            if (node.partyClass.resourceUid || node.partyClass.type.value) {
              const data = await getEntity(
                "odm-party",
                "partyclass",
                node.partyClass.resourceUid || node.partyClass.type.value
              );
              if (data.classType && data.classType.resourceUid) {
                node.classType = data.classType.resourceUid;
                node.partyClass.resourceUid = data.resourceUid;
              }
            }

            node.isDisabled = true;
            if (node.validity) {
              node.validity.from = node.validity?.from ? node.validity.from : "";
              node.validity.until = node.validity?.until ? node.validity.until : "";
            } else {
              node.validity = {
                from: "",
                until: "",
              };
            }
          }
        }
      );
    });

    
    return associatedParties.map((el: any) => {
      if(!el.signatureMethod){
        if (el.role_code !== "DISTRIBUTOR") {
          el.signatureMethod = "ELECTRONIC";
        }
        else {
          el.signatureMethod = "MANUAL";
        }
      }
      return el;
    });
  }

  get party() {
    return this.associatedParties?.[0].associatedParty?.third;
  }

  get assetState() {
    return store.state.demandModule.assetState;
  }

  get assets() {
    return this.assetState.proposalAssets;
  }
  get isLoading() {
    return store.state.analysisModule?.isLoading;
  }


  get screenStatus() {
    let MOScreen = false;
      if (store.state.taskModule.selectedTask.taskDefinition === TaskDefinition.ANALYSIS) {
        MOScreen = true
      }

    return MOScreen;
  }

  addInternThirdTab(role: any) {
    const associatedParty: OfferAssociatedParty =
      store.getters["demandModule/getOfferAssociatedPartyInit"];
    associatedParty.id = this.associatedParties?.length
      ? this.associatedParties?.length + 1
      : associatedParty.id++;
    associatedParty.tabName = role.id;
    associatedParty.showDeleteBtn = true;
    associatedParty.role_code = role.id;
    const third: any = associatedParty?.associatedParty?.third;
    third.person.roles[0].role.resourceUid = role.id;
    third.organization.roles[0].role.resourceUid = role.id;
    store.dispatch("demandModule/addAssociatedParty", associatedParty);
    console.log('associatedParty',associatedParty)
  }

  removeAssociatedParty(index: number, associatedParty: AssociatedParty) {
    if (
      this.associatedParties?.[index] !== undefined &&
      this.associatedParties?.length > 1 &&
      this.associatedParties?.[index].id !== 1
    ) {
      this.associatedParties?.splice(index, 1);
      this.associatedParties?.forEach((value, i) => {
        value.id = i + 1;
        value.showDeleteBtn = value.id !== 1;
      });
      const customer = this.associatedParties.find(
        (el: any) => el.associatedParty?.role.resourceUid === "CUSTOMER"
      );
      if (customer) {
        const relations = customer.associatedParty?.third?.organization?.relations;
        if (relations) {
          relations.splice(
            relations.findIndex(
              (relation: any) =>
                associatedParty.third?.person &&
                relation.partyAsc.resourceUid ===
                  associatedParty.third?.person?.resourceUid
            ),
            1
          );
        }
        store.state.demandModule.deletedManager = associatedParty;
      }
    }
  }

  onClose() {
    this.showDialog = false;
  }

  get isSaveLoading() {
    return store.state.demandModule.isSaving;
  }

  onSaveClick() {
    if (!this.simulationChanged && !this.calculationPendent) {
      if (!this.isSaveLoading) {
        store.dispatch("controlsModule/evaluateOfferControls", {
          onSuccess: () =>
            store.dispatch("demandModule/saveOffer", store.state.demandModule.offer),
          onFail: (message: string) => {
            Notify.create({
            timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
              message,
              color: "warning",
            });
          },
        });
      }
    } else {
      Notify.create({
        timeout: 10000,
        actions: [{ icon: 'close', color: 'white' }],
        message: "Prière de verifier le calcul de votre quotation.",
        color: "warning",
      });
    }
  }

  thirdName(third: Third) {
    if (third) {
      if (third.organization) {
        return third.organization.name;
      } else if (third.person) {
        return `${third.person.firstName} ${third.person.familyName}`;
      } else return "";
    }
  }
  get decisionsStep() {
    if (store.state.taskModule.modifyConditionsModule.decisionStep) {
      return store.state.taskModule.modifyConditionsModule.decisionStep;
    }
    return [
      {
        config: {},
        decisions: [],
      },
    ];
  }
   get listDecision() {
    return store.state.taskModule.offer && store.state.taskModule.offer!.proposals[0] && store.state.taskModule.offer!.proposals[0].proposalDecisions 
           ? store.state.taskModule.offer!.proposals[0].proposalDecisions : [];
  }
  isTaskAnalysis() {
    return store.getters["taskModule/isTaskAnalysis"];
  }
  updateResultRows(rows: any) {
    const row = rows[0];
    store.dispatch("demandModule/setOfferQueryData", row);
  }

  created() {
    this.taskDefinition = this.$store.state.taskModule.selectedTask.taskDefinition;
    const offerId = this.$store.state.taskModule.offer?.resourceUid;
    const id = this.$route.params.id;
    if (id) {
      const resourceUid = id;
      const filters = {
        id : id
      }
      store.dispatch("demandModule/searchOffer", {
        callback: this.updateResultRows,
        filter: filters,
        offset: 0,
        limit: 1
      }).then(() => {
        store.dispatch("demandModule/editOffer", {
          resourceUid: resourceUid,
          callback: () => { },
        });
      });
    }
    if (!this.isTaskAnalysis) {
      store.dispatch("lockState");
}
    store.dispatch("demandModule/fetchRules").then((roles) => {
      roles.sort((a: any, b: any) => {
        const labelA = i18n.global.t("demand.party.roles.".concat(a.code));
        const labelB = i18n.global.t("demand.party.roles.".concat(b.code));
        return labelA.localeCompare(labelB);
      });
      // Clear the roles array before pushing the sorted roles
      this.roles = [];
      roles.forEach((role: any) =>
        this.roles.push({
          id: role.code,
          label: i18n.global.t("demand.party.roles.".concat(role.code)),
        })
      );
    });
    
    store.dispatch("taskModule/listDecision", {
      listDecision: this.listDecision,
      callback: () => {},
    });
  }
  updated() {
    this.$emit('update');
  }
  
}
