
import i18n from "@/i18n";
import { Options, Vue } from "vue-class-component";

@Options({
  props: ["data"],
  watch: {},
})
export default class Score extends Vue {
  data: any
  scoreData = {
    score: "5.11",
    eval_score: "ACCEPTER"
  }

  get score() {
    return this.$store.state.analysisModule?.analysis?.score
  }
  get scoreTraduction() {
    return (
      i18n.global.t("task.study.scoreEvaluation."+ this.score?.decision) 
    );
  }

}
