
import { Options, Vue } from "vue-class-component";
import twCurrencyInput from "@/components/general/twCurrencyInput.vue";

@Options({
  props: ["data"],
  watch: {},
  components: {
    twCurrencyInput
  }
})
export default class Ratios extends Vue {
  data: any;
  get ratiosByCategories() {
    return this.$store.state.analysisModule?.ratiosByCategories;
  }
  get ratios() {
    return this.$store.state.analysisModule?.analysis?.ratios;
  }

  getRatioValue(ratio: any) {
    let currentModel = null;
    if (this.ratios) {
      currentModel = this.ratios.find(
        (item) => item.ratioParam?.resourceUid === ratio.ratio_id
      );
    }
    return currentModel;
  }
}
