
import {Options, Vue} from "vue-class-component";

import store from "@/store";
import {NoticeTemplate, Template} from "@/types";
import { initElectronicSign } from "@/commons/eSign";

@Options({
  watch: {
    templateSelected(newValue) {
      if (!newValue || !newValue.length) {
        this.onClose();
      }
    },
  },
})
export default class TemplateSender extends Vue {
  emailSendList: any = [];

  get templateSelected() {
    const fileList = store.getters['templateModule/getSelectedTemplates']
      .filter((template: string) => {
        const templates = store.getters['templateModule/getTemplates'];
        return templates.some((t: Template) => t.processId === template && t.signatureType === store.state.taskModule.selectedTask.variables.SignatureMethod);
      }).sort((a: any, b: any) => {
        const templateA = store.getters['templateModule/getTemplates'].find((t: any) => t.processId === a);
        const templateB = store.getters['templateModule/getTemplates'].find((t: any) => t.processId === b);
        return (templateA?.documentOrder || 0) - (templateB?.documentOrder || 0);
      });
    const selectedFilesToSign = store.getters['templateModule/getTemplates'].filter((template: Template) => fileList.includes(template.processId))
    return selectedFilesToSign;
  }
  createValue(val: any, done: any) {
    
    // Check if the value already exists in emailSendList to avoid duplicates
    if (!this.emailSendList.includes(val)) {
      this.emailSendList.push({ value: val, label:val}); // Add to the list if unique
      done(val, 'add-unique'); // Add with 'add-unique' to ensure unique values
    } else {
      done(); // Call done without parameters to prevent adding duplicates
    }
  }
  created() {
    store.dispatch("printDocumentModule/getNoticeTemplateBody");

    switch (this.currentRoute) {
      case "order-edit": {
        store.dispatch('printDocumentModule/getEmailSendListOption', {
          entity: store.state.orderModule.order,
          entityType: 'order'
        })
        break
      }
      case "TaskDetail": {
        const entityType = store.getters['taskModule/isTaskModuleOffer'] ? 'offer' : 'order'
        if (entityType === 'offer') {
          store.dispatch('printDocumentModule/getEmailSendListOption', {
            entity: store.state.taskModule.offer,
            entityType: entityType
          })
        } else {
          store.dispatch('printDocumentModule/getEmailSendListOption', {
            entity: store.state.taskModule.order,
            entityType: entityType
          })
        }
        break
      }
      case "demand-edit": {
        store.dispatch('printDocumentModule/getEmailSendListOption', {
          entity: store.state.demandModule.offer,
          entityType: 'offer'
        })
        break
      }
      case "third-edit": {
        store.dispatch('printDocumentModule/getEmailSendListOption', {
          entity: store.state.thirdModule.third,
          entityType: 'party'
        })
        break
      }
    }
  }

  get body() {
    return store.state.printDocumentModule.body;
  }

  get senderEmails() {
    return store.getters['printDocumentModule/getEmailSendListOption'] ? store.getters['printDocumentModule/getEmailSendListOption'] : [];
  }

  onClose() {
    store.dispatch("printDocumentModule/changeShowSendTemplate", false);
  }

  removeFile(tempalte: NoticeTemplate) {
    store.dispatch("printDocumentModule/setTemplateSelected", {
      template: tempalte,
      selected: false,
    });
  }
  isLoadingSend = false;
  sendMail() {
    store.state.printDocumentModule!.isLoading = true; 
    const emailSendList: string[] = [];
    this.emailSendList.forEach((element: any) => {
      emailSendList.push(element.value);
    });
    initElectronicSign(null, null, null, true, emailSendList);
  }

 
  get fileId() {
    return store.state.printDocumentModule.fileDetailFileId
  }
  get currentRoute() {
    return this.$router.currentRoute.value.name;
  }
}
