import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b44a6bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-12" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-4" }
const _hoisted_4 = { class: "text-blue-8 bold" }
const _hoisted_5 = { class: "col-4 flex justify-end" }
const _hoisted_6 = { class: "bold q-ml-sm" }
const _hoisted_7 = { class: "col-4 flex flex-center" }
const _hoisted_8 = { class: "bold q-ml-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createBlock(_component_q_list, {
    bordered: "",
    class: "list-services q-mt-sm q-mr-sm q-pt-none"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.beneficiaries, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "q-mb-sm"
        }, [
          _createVNode(_component_q_item_label, {
            class: _normalizeClass(index === 0 ? 'bg-blue-1 list-item-services' : 'bg-blue-1'),
            header: ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t(`demand.product.services.names.finance_ASSUR_DEC`)), 1)
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t(`demand.product.services.columns.amountHT`)) + " : ", 1),
                    _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.amountHT), 1)
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t(`demand.product.services.columns.amountTTC`)) + " : ", 1),
                    _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.amountTTC), 1)
                  ])
                ])
              ])
            ]),
            _: 2
          }, 1032, ["class"]),
          _withDirectives((_openBlock(), _createBlock(_component_q_item, { class: "q-mb-lg q-mt-sm" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_item_section, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_table, {
                    rows: [item],
                    columns: _ctx.serviceCol,
                    "row-key": "name",
                    separator: "none",
                    "hide-pagination": "",
                    dense: ""
                  }, {
                    header: _withCtx((props) => [
                      _createVNode(_component_q_tr, { props: props }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                            return (_openBlock(), _createBlock(_component_q_th, {
                              key: col.name,
                              props: props
                            }, {
                              default: _withCtx(() => [
                                (col.name !== 'actions')
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                      _createTextVNode(_toDisplayString(_ctx.$t(col.label)), 1)
                                    ], 64))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1032, ["props"]))
                          }), 128)),
                          _createVNode(_component_q_th, { "auto-width": "" })
                        ]),
                        _: 2
                      }, 1032, ["props"])
                    ]),
                    _: 2
                  }, 1032, ["rows", "columns"])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024)), [
            [_directive_ripple]
          ])
        ]))
      }), 128))
    ]),
    _: 1
  }))
}