
import DemandTabThird from "@/components/demand/tabs/DemandTabThird.vue";
import ThirdSelector from "@/components/general/ThirdSelector.vue";
import { Options, Vue } from "vue-class-component";
import store from "@/store";
import { ref } from "vue";
import { evaluateUiControls, getMaskFormat, getMaskInput, globalConfig } from "@/utils";
import { Third } from "@/types";
import i18n from "@/i18n";
import { Watch } from "vue-property-decorator";
import {
    formatDateFromUntil,
    formatDateGlobal,
} from "@/utils/configuration/formatters-config";
@Options({

    watch: {
        'capturedParty.person'(newVal, oldVal) {
            if (newVal !== '' && newVal != undefined) {
                this.$store.dispatch('garantyModule/setGarantyPartyReference', newVal);
            }
        },
        'capturedParty.organization'(newVal, oldVal) {
            if (newVal !== '' && newVal != undefined) {
                this.$store.dispatch('garantyModule/setGarantyPartyReference', newVal);
            }
        },
        "$store.state.garantyModule.validField"(currentContext) {
            this.validField = currentContext;
        },
        "$store.state.garantyModule.selectedgarantydetails"(currentContext) {
            this.garanty = currentContext;
        }
    },
    components: {
        DemandTabThird,
        ThirdSelector,
    },
    computed: {
        /**
        * Update the save Authorized
        *
        * @function
        * @name updateSaveAuthorized
        * @return {void}
        */
        updateSaveAuthorized() {
            // List of required fields for the guarantee
            const requiredFields = [
                this.garanty?.reference,
                this.garanty?.garantietype.type.value,
                this.garanty?.validity?.from,
                this.garanty?.validity?.until,
                this.garanty?.name,
                this.garanty?.status.type.value,
                this.garanty?.role.type.value,
                this.garanty?.evaluationMethod

            ];

            // Check if all required fields are valid
            const validRequiredFields = requiredFields.filter((f) => f?.length > 0).length === requiredFields.length;

            // Check if required fields are valid
            if (validRequiredFields && typeof this.garanty?.evaluationMethod === 'string') {

                if (this.garanty?.evaluationMethod === 'Percentage' && !(this.garanty?.basis.type.value?.length > 0)) {
                    store.dispatch("garantyModule/changeSaveAuthorized", false)
                } else {
                    store.dispatch("garantyModule/changeSaveAuthorized", true);
                }
            } else {
                store.dispatch("garantyModule/changeSaveAuthorized", false);
            }
        }
    }
})

export default class GarantieDetails extends Vue {
    formatDateFromUntil = formatDateFromUntil;
    formatDateGlobal = formatDateGlobal;
    dateMask = "DD/MM/YYYY";
    internTab: any = ref(1);
    dataFilter = ["", ""];
    capturedParty: Third = {};
    datevalidité = ""
    validField: boolean = store.state.garantyModule.validField

    get third() {
        return store.state.garantyModule?.selectedguarantor;
    }
    get garanty() {
        return store.state.garantyModule?.selectedgarantydetails;

    }
    get mode() {
        return store.state.garantyModule?.mode;

    }
    toggleValue = false // Default value set to "No"
    toggleLabels = { false: { en: 'No', fr: 'Non' }, true: { en: 'Yes', fr: 'Oui' } }


    get currency() {
        return [
            { id: "1", name: "EUR" }
        ]?.map((item) => item.name);
    }

    /**
     * Handles the selection of a party and dispatches an action to set the selected guarantor in the Vuex store.
     *
     * @function
     * @name handlePartyValue
     * @param {Third} party - The selected party.
     * @return {void}
     */
    handlePartyValue(party: Third) {
        this.capturedParty = party;
        store.dispatch("garantyModule/setSelectedGarantor", party);

    }
    date = {
        maskInput: getMaskInput(),
        maskFormat: getMaskFormat()
    }


    get columns() {
        return globalConfig.table_config.partyFilterCols;
    }

    get getRole() {
        return store.getters["picklistModule/garantyRoleItems"]
            .sort((a: any, b: any) => a.label.localeCompare(b.label));
    }

    get offerAssociated() {
        return store.getters["demandModule/getOfferAssociatedPartyInit"];
    }


    get basisOptions() {
        let basis = store.getters["picklistModule/garantybasisItems"].map((item: any) => {

            return {
                ...item,
                label: item.value?.toString() !== "" ? i18n.global.t("garanty.garantyFilter.picklist.Basis." + item.value) : ''
            };
        }).sort((a: any, b: any) =>
            a.label.localeCompare(b.label)
        );
        return basis?.filter((item: any) => (item.value == 'FinancedAssetsNetValue' || item.value == 'FinancedAssetsGrossValue' || item.value == 'FinancedAmount'));
    }
    /**
     * Retrieves and maps underlying options for Garanty from the picklist module.
     *
     * @returns {Array} An array containing mapped underlying options for Garanty.
     */
    get underlyingOptions() {
        return store.getters["picklistModule/underlyingTypeItems"].map((item: any) => {
            return {
                ...item,
                label: item.value?.toString() !== "" ? i18n.global.t("garanty.garantyFilter.picklist.underlying." + item.value) : ''
            };
        }).sort((a: any, b: any) =>
            a.label.localeCompare(b.label)
        );
    }

    /**
     * Retrieves and maps status options for Garanty from the picklist module.
     *
     * @returns {Array} An array containing mapped status options for Garanty.
     */
    get statusOptions() {


        // if (this.garanty?.status.type.value !== 'USE') {
        //     Options =
        // }
        let option = store.getters["picklistModule/garantyStatusItems"].map((item: any) => {
            return {
                ...item,
                label: item.value?.toString() !== "" ? i18n.global.t("garanty.garantyFilter.picklist.status." + item.value) : ''
            };
        }).sort((a: any, b: any) =>
            a.label.localeCompare(b.label)
        )

        return this.garanty?.status.type.value === "USE" ? option?.filter((item: any) => item.value === "USE" || item.value === "SUSPENDED") : option


    }
    get typeOptions() {
        return store.getters["picklistModule/garantyTypeItems"].map((item: any) => {
            return {
                ...item,
                label: item.value?.toString() !== "" ? i18n.global.t("garanty.garantyFilter.picklist.type." + item.value) : ''
            };
        }).sort((a: any, b: any) =>
            a.label.localeCompare(b.label)
        );
    }

    /**
     * Lifecycle hook invoked when the component is created.
     * Fetches and dispatches general information for selected Garanty details.
     */
    created() {
        const getter = store.getters["garantyModule/getGarantyselectedInit"];
        store.dispatch("garantyModule/resetGaranty", getter);
        //UI Status Evaluation on create Component
        evaluateUiControls("garanty-edit", 'insert', this.$router,true);
        console.log("garanty-edit created");
    }
}
