
import i18n from "@/i18n";
import {reactive} from "vue";

export const currencyConfiguration =reactive( {
    locale: i18n.global.locale,
    currency: "EUR",
    currencyDisplay: "symbol",
    hideCurrencySymbolOnFocus: false,
    hideGroupingSeparatorOnFocus: false,
    hideNegligibleDecimalDigitsOnFocus: false,
    autoDecimalDigits: true,
    useGrouping: true,
    accountingSign: false

});
