import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bg-white card-header q-mt-md"
}
const _hoisted_2 = { class: "d-flex justify-content-between" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "font-weight-bold" }
const _hoisted_5 = { class: "pt-0" }
const _hoisted_6 = { class: "table-responsive" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "tw-sidebar-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_GuaranteeDetail = _resolveComponent("GuaranteeDetail")!
  const _component_q_scroll_area = _resolveComponent("q-scroll-area")!
  const _component_q_drawer = _resolveComponent("q-drawer")!

  return (!_ctx.displayGuarantee)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("guarantees.labels.guarantees")), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_q_table, {
              class: "shadow-0 table table-sm",
              rows: _ctx.rows,
              columns: _ctx.columns,
              "row-key": "id",
              "no-data-label": _ctx.$t('guarantees.labels.listEmpty')
            }, {
              header: _withCtx((props) => [
                _createVNode(_component_q_tr, { props: props }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                      return (_openBlock(), _createBlock(_component_q_th, {
                        key: col.name,
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t(col.label)), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]))
                    }), 128)),
                    _createVNode(_component_q_th, { "auto-width": "" })
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              body: _withCtx((props) => [
                _createVNode(_component_q_tr, { props: props }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                      return (_openBlock(), _createBlock(_component_q_td, {
                        key: col.name,
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(col.value), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]))
                    }), 128)),
                    _createVNode(_component_q_td, { "auto-width": "" }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", null, [
                          _createVNode(_component_q_btn, {
                            class: "btn btn-action",
                            loading: props.row.isLoading,
                            icon: "visibility",
                            onClick: _withModifiers(($event: any) => (_ctx.view(props.row)), ["stop"])
                          }, null, 8, ["loading", "onClick"])
                        ])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              _: 1
            }, 8, ["rows", "columns", "no-data-label"])
          ])
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_q_drawer, {
            modelValue: _ctx.displayGuarantee,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => ((_ctx.displayGuarantee) = $event)),
              _ctx.closeDetail
            ],
            breakpoint: 2000,
            "no-swipe-close": "",
            bordered: "",
            side: "right",
            class: "bg-white"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_scroll_area, { style: {"height":"100%"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_GuaranteeDetail, { onCloseDetail: _ctx.closeDetail }, null, 8, ["onCloseDetail"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue", "onUpdate:modelValue"])
        ])
      ]))
}