
import {Vue, Options} from 'vue-class-component';

import {businessDataUtils, Proposal, proposalAccessoriesObjectTypes} from "@/types";
import store from '@/store';
import { globalConfig, serviceName } from '@/utils';
import i18n from '@/i18n';
import {formatAmoutswitchCurrency } from "@/utils/configuration/formatters-config";
import {getServiceCol} from "@/store/services/financing/financingSeriviceMapper";

@Options({
  props: ['proposal'],
})
export default class FinancingServicesDetail extends Vue {

  proposal?: Proposal;
  formatAmoutswitchCurrency = formatAmoutswitchCurrency;
  serviceName = serviceName;
  proposalPartyInsuranceObjectType = proposalAccessoriesObjectTypes.proposalPartyInsuranceObjectType
  proposalsimpleservice = proposalAccessoriesObjectTypes.proposalsimpleservice
  get calculatestatus() {
    return config.VUE_APP_CALCULATE
  }
  openServicesDetail(){
    store.dispatch("middleOfficeModule/showService", {showService:false});
  }
  get partyInsurance() {
    let accessories
    if (config.VUE_APP_CALCULATE === 'false' && (this.offer?.businessData && this.offer?.businessData.API === businessDataUtils.offerApiSource)) {
      accessories = this.proposal?.proposalItems[0].proposalAccessories.filter((accessorie: any) => accessorie.objectType.includes(this.proposalPartyInsuranceObjectType)) || []
    }

    else {
      accessories = store.state.middleOfficeModule?.proposalScale?.scalePresentation?.servicePartyInsurance || []
    }
    return accessories
  }

  get serviceCol() {
    return getServiceCol()
  }

  get offer() {
    return store.state.middleOfficeModule?.offer
  }

  get servicesList() {
    let services;
    if (config.VUE_APP_CALCULATE === 'false' && (this.offer?.businessData && this.offer?.businessData.API === businessDataUtils.offerApiSource)) {
      services = this.proposal?.proposalItems[0].proposalAccessories.filter((accessorie: any) => accessorie.objectType.includes(this.proposalsimpleservice)) || []
    }

    else {
      services = store.state.middleOfficeModule?.proposalScale?.scalePresentation?.services || []
    }
    return services;
  }

  insuranceServiceName(item: any) {
    const reference = this.calculatestatus === 'false' && (this.offer?.businessData && this.offer?.businessData.API === businessDataUtils.offerApiSource) ? (item.label || item.accessoryProduct.resourceUid) : (item.accessoryProduct ? item.accessoryProduct.resourceUid : item.reference);
    return i18n.global.te(`demand.product.services.names.${reference?.split('.').join('_')}`) ? i18n.global.t(`demand.product.services.names.${reference?.split('.').join('_')}`) : reference?.split('.').join('_')
  }


  getAmountHT(service: any) {

    const amount = this.calculatestatus === 'false' && (this.offer?.businessData && this.offer?.businessData.API === businessDataUtils.offerApiSource) ? (service.amountWoTax ? service.amountWoTax.amount : '-') : (service.amountValueWoTax ? service.amountValueWoTax : '-');

    if (amount && amount !== '-') {
      return `${formatAmoutswitchCurrency("EUR", amount)}`
    }
    else {
      return "-";
    }

  }


  getAmountTTC(service: any) {
    const tax: number = service.rate ? Number(service.rate) : 0;
    const amount = this.calculatestatus === 'false' && (this.offer?.businessData && this.offer?.businessData.API === businessDataUtils.offerApiSource) ? (service.amountWTax ? service.amountWTax.amount : '-') : (service.amountValueWTax ? service.amountValueWTax : '-');
    if (amount && amount !== '-' ) {
      const value = amount * (1 + tax);
      const result = `${formatAmoutswitchCurrency("EUR", value)}`;
      return result;
    } else {
      return "-";
    }
  }
  get columns() {
    return globalConfig.table_config.middelOfficeServiceCols
    }
  getRows(service: any) {
    console.log("serviceeeeeeee", service)
    const paymentDatesOption = this.calculatestatus === 'false' && (this.offer?.businessData && this.offer?.businessData.API === businessDataUtils.offerApiSource) ? service.proposalAccessoryPaymentDatesOption.resourceUid : service.paymentDatesOption
    const calculationMethodOption = this.calculatestatus === 'false' && (this.offer?.businessData && this.offer?.businessData.API === businessDataUtils.offerApiSource) ? service.proposalAccessoryCalculationMethodOption.resourceUid : service.calculationMethodOption

    return [{ 
      rate:service.rate,
      basis:i18n.global.t(`middleOfficeService.basisValue.${service.basis}`),
      basisValue:service.basisValue,
      proposalAccessoryCalculationMethodOption: i18n.global.t(`middleOfficeService.proposalAccessoryCalculationMethodOptionValue.${calculationMethodOption}`),
      proposalAccessoryPaymentDatesOption: i18n.global.t(`middleOfficeService.proposalAccessoryPaymentDatesOptionValue.${paymentDatesOption}`),
      taxValue:(service.taxValue||0)+" %"
    }]
  }

}

