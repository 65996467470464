import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ac138f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-4 mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabelAndValue = _resolveComponent("LabelAndValue")!
  const _component_SummaryLease = _resolveComponent("SummaryLease")!
  const _component_SummaryLoan = _resolveComponent("SummaryLoan")!
  const _component_SummaryFinancingService = _resolveComponent("SummaryFinancingService")!
  const _component_SummaryFinancingGuarantee = _resolveComponent("SummaryFinancingGuarantee")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_LabelAndValue, {
        "i18n-key": 'summaryPanel.financing.financingType',
        value: _ctx.financingProduct,
        oneLine: true,
        "label-style": _ctx.labelStyleItem,
        "div-value-style": _ctx.divValueStyle
      }, null, 8, ["i18n-key", "value", "label-style", "div-value-style"])
    ]),
    (_ctx.isOfferActivityTypeLease)
      ? (_openBlock(), _createBlock(_component_SummaryLease, {
          key: 0,
          proposal: _ctx.proposal,
          "value-style": _ctx.valueStyle,
          "label-style": _ctx.labelStyleItem,
          "div-value-style": _ctx.divValueStyle
        }, null, 8, ["proposal", "value-style", "label-style", "div-value-style"]))
      : (_openBlock(), _createBlock(_component_SummaryLoan, {
          key: 1,
          proposal: _ctx.proposal,
          "value-style": _ctx.valueStyle,
          "label-style": _ctx.labelStyleItem,
          "div-value-style": _ctx.divValueStyle
        }, null, 8, ["proposal", "value-style", "label-style", "div-value-style"])),
    _createVNode(_component_SummaryFinancingService, {
      proposal: _ctx.proposal,
      "value-style": _ctx.valueStyle,
      "label-style": _ctx.labelStyleItem,
      "div-value-style": _ctx.divValueStyle
    }, null, 8, ["proposal", "value-style", "label-style", "div-value-style"]),
    _createVNode(_component_SummaryFinancingGuarantee, {
      proposal: _ctx.proposal,
      "value-style": _ctx.valueStyle,
      "label-style": _ctx.labelStyleItem,
      "div-value-style": _ctx.divValueStyle
    }, null, 8, ["proposal", "value-style", "label-style", "div-value-style"])
  ]))
}