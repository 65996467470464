// store/template.ts
import { Module } from 'vuex';
import { Template, TemplateState } from '@/types';
import { getTemplates, reprocessDocumentGeneration } from '@/store/services/document/templatesServices';
import { organizeStatusTemplates, UON } from '@/utils';
import store from '@/store';

const templateModule: Module<TemplateState, any> = {
    namespaced: true,

    state: (): TemplateState => ({
        templates: [],
        selectedTemplates: [],
        previewTemplate:[],
        isLoading: false,
        error: null
    }),

    getters: {
        getGroupedTemplates: (state) => (signature?: string,isPrintDocsModal?:boolean) => {
            // Group templates by package name
            const groupedTemplates = state.templates.reduce((acc, template) => {
                if (!acc[template.packageName]) {
                    acc[template.packageName] = [];
                }
                acc[template.packageName].push(template);
                return acc;
            }, {} as { [key: string]: Template[] });
            
            if(isPrintDocsModal && !signature){
                return groupedTemplates
            }

            // If a signature is provided, filter the grouped templates
            if (signature) {
                const filteredGroupedTemplates: { [key: string]: Template[] } = {};

                Object.keys(groupedTemplates).forEach(packageName => {
                    const filteredTemplates = groupedTemplates[packageName].filter(
                        template => template.signatureType === signature
                    );

                    if (filteredTemplates.length > 0) {
                        filteredGroupedTemplates[packageName] = filteredTemplates;
                    }
                });

                return filteredGroupedTemplates;
            }

            return groupedTemplates;
        },
        getTemplates: (state) => state.templates,
        getSelectedTemplates: (state) => state.selectedTemplates,
        isTemplateSelected: (state) => (processId: string) =>
            state.selectedTemplates.includes(processId)
    },

    mutations: {
        SET_TEMPLATES(state, templates: Template[]) {
            state.templates = templates;
        },
        SET_PREVIEW_TEMPLATE(state, template: Template) {
            state.previewTemplate = [template];
        },
        SET_LOADING(state, isLoading: boolean) {
            state.isLoading = isLoading;
        },
        SET_ERROR(state, error: string | null) {
            state.error = error;
        },
        TOGGLE_TEMPLATE_SELECTION(state, processId: string) {
            const index = state.selectedTemplates.indexOf(processId);
            if (index === -1) {
                state.selectedTemplates.push(processId);
            } else {
                state.selectedTemplates.splice(index, 1);
            }
        },
        CLEAR_SELECTION(state) {
            state.selectedTemplates = [];
        },
        SELECT_ALL_TEMPLATES(state) {
            state.selectedTemplates = state.templates.map(template => template.processId);
        },
        UPDATE_TEMPLATE_STATUS(state, template) {
            const templateIndex = state.templates.findIndex(t => t.resourceUid === template.resourceUid);
            if (templateIndex !== -1) {
                state.templates[templateIndex] = template;
            }
        }
    },

    actions: {

        setPreviewTemplates({ commit }, template:Template) {
            commit('SET_PREVIEW_TEMPLATE', template)
        },

        async fetchTemplates({ commit }, payload) {
            commit('SET_LOADING', true);
            try {
                const templates = await getTemplates(UON.SystemUid.odmOffer, "generatedDocument/checkDocumentGenerated", payload.entityId);
                // Order the statusTemplates array by the documentOrder attribute
                templates.statusTemplates.sort((a:any, b:any) => a.documentOrder - b.documentOrder);
                commit('SET_TEMPLATES', templates.statusTemplates);
                commit('SET_ERROR', null);
            } catch (error) {
                commit('SET_ERROR', 'Failed to fetch templates');
            } finally {
                commit('SET_LOADING', false);
            }
        },

        async retryFailedTemplate({ commit, state }, template: Template) {
            try {
                
                if (template) {
                    commit('SET_LOADING', true);
                    // Replace with actual retry API call
                    const updatedtemplate = await reprocessDocumentGeneration(UON.SystemUid.odmOffer, "generatedDocument/reprocessDocument/"+template.entityId, template);
                    const entityId = store.state.taskModule.offer?.resourceUid;
                    store.dispatch('templateModule/fetchTemplates', { entityId: entityId });
                  
                }
            } catch (error) {
                commit('SET_ERROR', 'Failed to retry template');
            } finally {
                commit('SET_LOADING', false);
            }
        },

        toggleSelection({ commit }, processId: string) {
            commit('TOGGLE_TEMPLATE_SELECTION', processId);
        },

        selectTemplate({ commit, getters }, processId: string) {
            if (!getters.isTemplateSelected(processId)) {
                commit('TOGGLE_TEMPLATE_SELECTION', processId);
            }
        },

        deselectTemplate({ commit, getters }, processId: string) {
            if (getters.isTemplateSelected(processId)) {
                commit('TOGGLE_TEMPLATE_SELECTION', processId);
            }
        },

        clearSelection({ commit }) {
            commit('CLEAR_SELECTION');
        },

        selectAll({ commit }) {
            commit('SELECT_ALL_TEMPLATES');
        }
    }
};

export default templateModule;