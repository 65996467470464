
import { Options, Vue } from "vue-class-component";
import store from "@/store";
import { Analysis, SupportingDocumentStatus } from "@/types";
import { Store } from "vuex";
@Options({
  props: ["qualificationType"],
})
export default class Comment extends Vue {
  qualification?: Analysis;
  qualificationType: any;
  opinionQualificationsModel: any;

  get itemText() {
    return this.opinionQualifications[
      this.opinionQualifications.findIndex(
        (item: any) => item.qualificationType.resourceUid === this.qualificationType
      )
    ].comment;
  }

  set itemText(newValue: string) {
    this.$store.commit("taskModule/analysisModule/setComment", {
      index: this.opinionQualifications.findIndex(
        (item: any) => item.qualificationType.resourceUid === this.qualificationType
      ),
      text: newValue,
    });
  }

  get opinionQualifications() {
    return store.getters["taskModule/analysisModule/getOpinionQualifications"];
  }
  get analysisSections() {
    return store.getters["taskModule/analysisModule/analysisSections"];
  }

  get isAnalysisSection() {
    return this.analysisSections.includes(this.qualificationType);
  }
}
