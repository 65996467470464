import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-53586fc2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-2" }
const _hoisted_3 = { class: "card bg-blue-7 panelBox firstBox q-mr-sm" }
const _hoisted_4 = { class: "card-body pb-0" }
const _hoisted_5 = { class: "table table-sm" }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = { class: "card panelBox" }
const _hoisted_8 = { class: "card-body pb-0" }
const _hoisted_9 = { class: "table table-sm" }
const _hoisted_10 = { class: "col-3" }
const _hoisted_11 = { class: "card bg-amber-2 panelBox q-ml-sm" }
const _hoisted_12 = { class: "card-body pb-0" }
const _hoisted_13 = { class: "table table-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabelAndValue = _resolveComponent("LabelAndValue")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("table", _hoisted_5, [
            _createElementVNode("tbody", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("td", null, [
                  _createVNode(_component_LabelAndValue, {
                    "i18n-key": 'demand.simulation.globalPaymentSchedule.panel.product',
                    value: _ctx.product,
                    "value-style": _ctx.valueStyle,
                    "label-style": _ctx.labelStyleItem
                  }, null, 8, ["i18n-key", "value", "value-style", "label-style"])
                ])
              ]),
              _createElementVNode("tr", null, [
                _createElementVNode("td", null, [
                  _createVNode(_component_LabelAndValue, {
                    "i18n-key": 'demand.simulation.globalPaymentSchedule.panel.sumOfFinancialPayments',
                    value: _ctx.sumOfFinancialPayments,
                    "value-style": _ctx.valueStyle,
                    "label-style": _ctx.labelStyleItem
                  }, null, 8, ["i18n-key", "value", "value-style", "label-style"])
                ])
              ]),
              _createElementVNode("tr", null, [
                _createElementVNode("td", null, [
                  _createVNode(_component_LabelAndValue, {
                    "i18n-key": 'demand.simulation.globalPaymentSchedule.panel.duration',
                    value: _ctx.duration,
                    "value-style": _ctx.valueStyle,
                    "label-style": _ctx.labelStyleItem
                  }, null, 8, ["i18n-key", "value", "value-style", "label-style"])
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_LabelAndValue, {
                    "i18n-key": 'demand.simulation.globalPaymentSchedule.panel.durationUnit',
                    value: _ctx.frequency,
                    "value-style": _ctx.valueStyle,
                    "label-style": _ctx.labelStyleItem
                  }, null, 8, ["i18n-key", "value", "value-style", "label-style"])
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("table", _hoisted_9, [
            _createElementVNode("tbody", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("td", null, [
                  _createVNode(_component_LabelAndValue, {
                    "i18n-key": 'demand.simulation.globalPaymentSchedule.panel.amount',
                    value: _ctx.amount,
                    "value-style": _ctx.valueStyle,
                    "label-style": _ctx.labelStyleItem
                  }, null, 8, ["i18n-key", "value", "value-style", "label-style"])
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_LabelAndValue, {
                    "i18n-key": 'demand.simulation.globalPaymentSchedule.panel.downPayment',
                    value: _ctx.downPayment,
                    "value-style": _ctx.valueStyle,
                    "label-style": _ctx.labelStyleItem
                  }, null, 8, ["i18n-key", "value", "value-style", "label-style"])
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_LabelAndValue, {
                    "i18n-key": 'demand.simulation.globalPaymentSchedule.panel.residualValue',
                    value: _ctx.residualValue,
                    "value-style": _ctx.valueStyle,
                    "label-style": _ctx.labelStyleItem
                  }, null, 8, ["i18n-key", "value", "value-style", "label-style"])
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_LabelAndValue, {
                    "i18n-key": 'demand.simulation.globalPaymentSchedule.panel.residualValueAmount',
                    value: _ctx.residualValueAmount,
                    "value-style": _ctx.valueStyle,
                    "label-style": _ctx.labelStyleItem
                  }, null, 8, ["i18n-key", "value", "value-style", "label-style"])
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_LabelAndValue, {
                    "i18n-key": 'demand.simulation.globalPaymentSchedule.panel.interest',
                    value: _ctx.rate,
                    "value-style": _ctx.valueStyle,
                    "label-style": _ctx.labelStyleItem,
                    suffix: "%"
                  }, null, 8, ["i18n-key", "value", "value-style", "label-style"])
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_LabelAndValue, {
                    "i18n-key": 'demand.simulation.globalPaymentSchedule.panel.firstPayment',
                    value: _ctx.firstPayment,
                    "value-style": _ctx.valueStyle,
                    "label-style": _ctx.labelStyleItem
                  }, null, 8, ["i18n-key", "value", "value-style", "label-style"])
                ])
              ]),
              _createElementVNode("tr", null, [
                _createElementVNode("td", null, [
                  _createVNode(_component_LabelAndValue, {
                    "i18n-key": 'demand.simulation.globalPaymentSchedule.panel.sumOfServices',
                    value: _ctx.sumOfServices,
                    "value-style": _ctx.valueStyle,
                    "label-style": _ctx.labelStyleItem
                  }, null, 8, ["i18n-key", "value", "value-style", "label-style"])
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_LabelAndValue, {
                    "i18n-key": 'demand.simulation.globalPaymentSchedule.panel.creditCost',
                    value: _ctx.creditCost,
                    "value-style": _ctx.valueStyle,
                    "label-style": _ctx.labelStyleItem
                  }, null, 8, ["i18n-key", "value", "value-style", "label-style"])
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_LabelAndValue, {
                    "i18n-key": 'demand.simulation.globalPaymentSchedule.panel.tva',
                    value: _ctx.tva,
                    "value-style": _ctx.valueStyle,
                    "label-style": _ctx.labelStyleItem
                  }, null, 8, ["i18n-key", "value", "value-style", "label-style"])
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_LabelAndValue, {
                    "i18n-key": 'demand.simulation.globalPaymentSchedule.panel.phase',
                    value: _ctx.phase,
                    "value-style": _ctx.valueStyle,
                    "label-style": _ctx.labelStyleItem
                  }, null, 8, ["i18n-key", "value", "value-style", "label-style"])
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("table", _hoisted_13, [
            _createElementVNode("tbody", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("td", null, [
                  _createVNode(_component_LabelAndValue, {
                    "i18n-key": 'demand.simulation.globalPaymentSchedule.panel.taeg',
                    value: _ctx.taeg,
                    "value-style": _ctx.valueStyle,
                    "label-style": _ctx.labelStyleItem
                  }, null, 8, ["i18n-key", "value", "value-style", "label-style"])
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_LabelAndValue, {
                    "i18n-key": 'demand.simulation.globalPaymentSchedule.panel.taea',
                    value: _ctx.taea,
                    "value-style": _ctx.valueStyle,
                    "label-style": _ctx.labelStyleItem
                  }, null, 8, ["i18n-key", "value", "value-style", "label-style"])
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_LabelAndValue, {
                    "i18n-key": 'demand.simulation.globalPaymentSchedule.panel.sumOfInterests',
                    value: _ctx.sumOfInterests,
                    "value-style": _ctx.valueStyle,
                    "label-style": _ctx.labelStyleItem
                  }, null, 8, ["i18n-key", "value", "value-style", "label-style"])
                ])
              ]),
              _createElementVNode("tr", null, [
                _createElementVNode("td", null, [
                  _createVNode(_component_LabelAndValue, {
                    "i18n-key": 'demand.simulation.globalPaymentSchedule.panel.sumOfTotalPayments',
                    value: _ctx.sumOfTotalPayments,
                    "value-style": _ctx.valueStyle,
                    "label-style": _ctx.labelStyleItem
                  }, null, 8, ["i18n-key", "value", "value-style", "label-style"])
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_LabelAndValue, {
                    "i18n-key": 'demand.simulation.globalPaymentSchedule.panel.sumOfTotalPaymentsTTC',
                    value: _ctx.sumOfTotalPaymentsTTC,
                    "value-style": _ctx.valueStyle,
                    "label-style": _ctx.labelStyleItem
                  }, null, 8, ["i18n-key", "value", "value-style", "label-style"])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}