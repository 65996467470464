
import { Options, Vue } from "vue-class-component";
import store from "@/store";
import { deleteFormatMaskAndConvertToNumber, formatAmoutswitchCurrency, } from "@/utils/configuration/formatters-config";
import { CalculationType, FinancingStateEnum, PartyInsuranceProductItem, SimpleServiceProductItem, ServiceTypes } from "@/types";
import PartyInsuranceComponent from "./PartyInsuranceComponent.vue";
import { fixMoneyValue } from "@/utils";
import i18n from "@/i18n";
@Options({
  components: {
    PartyInsuranceComponent
  }
})
export default class SimulationServices extends Vue {
  get calculationLoading() {
    return store.state.financingModule.serviceModule.calculationLoading;
  }

  get partyTaxRate() {
    return store.getters["demandModule/getPartyTaxRate"]
  }

  get calculationResult() {
    return this.selectedScale?.calculationResult
  }

  get paymentSchedule() {
    return this.calculationResult ? this.calculationResult.paymentSchedules[2] : null
  }

  get paymentHT() {
    const paymentSchedule = this.paymentSchedule;
    if (paymentSchedule && paymentSchedule.totalInstallmentWoTax) {
      return formatAmoutswitchCurrency(
        "EUR",
        paymentSchedule.totalInstallmentWoTax
      );
    }
    return formatAmoutswitchCurrency("EUR", 0);
  }

  get paymentTTC() {
    const paymentSchedule = this.paymentSchedule;
    if (paymentSchedule && paymentSchedule.totalInstallmentWTax) {
      return formatAmoutswitchCurrency(
        "EUR",
        paymentSchedule.totalInstallmentWTax
      );
    }
    return formatAmoutswitchCurrency("EUR", 0);
  }

  get scaleSelectedServices() {
    return (
      store.state.financingModule.scalesModule.scaleSelected?.scalePresentation
        .services || []
    );
  }

  private getTotalServices() {
    return this.scaleSelectedServices
      .filter(
        (service: any) =>
          service.amountValue &&
          service.config.selected &&
          service.paymentDatesOption.toUpperCase() === "INSTALLMENT"
      )
      .reduce((a: number, b: any) => a + b.amountValue, 0);
  }

  get totalAmountHT() {
    const selectedScale = this.selectedScale;
    if (selectedScale) {
      const totalServices = this.getTotalServices();
      return formatAmoutswitchCurrency(
        "EUR",
        totalServices +
        deleteFormatMaskAndConvertToNumber(
          selectedScale.scalePresentation.payment || "0"
        )
      );
    }
    return formatAmoutswitchCurrency("EUR", 0);
  }

  get totalAmountTTC() {
    // This code was commented for bug fix CF-515
    // let ttc = 0;
    // if ( this.selectedScale ) {
    //   const payment = Number(fixMoneyValue(this.selectedScale.scalePresentation.payment));
    //   if ( this.selectedScale?.taxOnInterest !== 'NO_VAT' ) {
    //     ttc = (this.getTotalServices() + (payment?? 0)) * (1 +( this.partyTaxRate/100));
    //   }
    //   else ttc = (this.getTotalServices() + (payment?? 0));
    // }
    if (this.selectedScale) {
      let ttc = 0;
      if (this.partyTaxRate) {
        const payment = Number(fixMoneyValue(this.selectedScale.scalePresentation.payment));
        if (this.selectedScale?.taxOnInterest !== 'NO_VAT') {
          ttc = (this.getTotalServices() + (payment ?? 0)) * (1 + (this.partyTaxRate / 100));
        } else {
          ttc = (this.getTotalServices() + (payment ?? 0));
        }
      } else {
        ttc = store.getters["simulationModule/getAmountTTC"];
      }
      return formatAmoutswitchCurrency("EUR", deleteFormatMaskAndConvertToNumber(ttc.toString()));
    }
    return formatAmoutswitchCurrency("EUR", 0);
  }

  get duration() {
    const selectedScale = this.selectedScale;
    if (selectedScale) {
      const result: string = selectedScale.scalePresentation.monthlyPresentation ? i18n.global.t(selectedScale.scalePresentation.monthlyPresentation, { param: this.monthly, period: this.period, duration: selectedScale.scalePresentation?.periodBetween2Installments?.duration }) : '-';
      return result;
    }
    return formatAmoutswitchCurrency("EUR", 0);
  }

  get selectedScale() {
    return store.state.financingModule.scalesModule.scaleSelected;
  }
  get partyInsuranceService() {
    return this.selectedScale?.scalePresentation.servicePartyInsurance || []
  }
  get monthly() {
    return this.selectedScale?.scalePresentation.monthly;
  }

  get periodBetween2Installments() {
    return this.selectedScale?.periodBetween2Installments;
  }

  get period() {
    const result: string = this.selectedScale?.scalePresentation?.period ? i18n.global.t('main.labels.'.concat(this.selectedScale?.scalePresentation?.period)) : "-"
    return result;
  }

  openServicesDetail() {
    store.dispatch("financingModule/updateCurrentState", {
      newState: FinancingStateEnum.SERVICE_DETAIL,
    });
  }
  findServiceType(ObjectType: string): string {
    const prefix = "scales.picklist.serviceTypes.";
    for (const key in ServiceTypes) {
      if (Object.prototype.hasOwnProperty.call(ServiceTypes, key)) {
        const value = ServiceTypes[key as keyof typeof ServiceTypes];
        if (ObjectType.toLowerCase().includes(value)) {
          return i18n.global.t(prefix + value);
        }
      }
    }
    return '-';
  }

  getAmountHT(service: any) {
    return service.amountValueWoTax
      ? `${formatAmoutswitchCurrency("EUR", service.amountValueWoTax)}`
      : "-";
  }

  // getAmountTTC(service: any) {
  //   const tax: number = service.rate ? Number(service.rate) : 0;
  //   if (service.amountValue) {
  //     const value = service.amountValue * (1 + tax);
  //     const result = `${formatAmoutswitchCurrency("EUR", value)}`;
  //     return result.lastIndexOf(",") - result.length > 2
  //       ? result
  //       : result.substring(0, result.lastIndexOf(",") + 3);
  //   } else {
  //     return "-";
  //   }
  // }

  getAmountTTC(service: any) {
    const tax: number = service.rate ? Number(service.rate) : 0;
    if (service.amountValueWTax) {
      const value = service.amountValueWTax * (1 + tax);
      const result = `${formatAmoutswitchCurrency("EUR", value)}`;
      return result;
    } else {
      return "-";
    }
  }

  onSelectService(service: SimpleServiceProductItem | PartyInsuranceProductItem) {
    store.dispatch("financingModule/serviceModule/setCalculationLoading", {
      calculationLoading: true,
    });
    store
      .dispatch("financingModule/serviceModule/setServiceSelected", { service })
      .then(() => {
        store
          .dispatch("financingModule/calculateFromState", {
            searchType: CalculationType.PAYMENT,
            scale: this.selectedScale,
            callback: () => this.handleCalculationResponse(),
          })
          .catch(() => this.handleCalculationResponse());
      });
  }

  async handleCalculationResponse() {
    await store.dispatch("financingModule/setSimulationChanged", false);
    await store.dispatch("financingModule/serviceModule/setCalculationLoading", {
      calculationLoading: false,
    });
  }
}
