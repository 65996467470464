
import {Options, Vue} from "vue-class-component";
import {CalculationType, EvaluateTaxRate, ScaleSliderConfig, SimulationAttributeEnum, SliderConfig, SlideType} from "@/types";
import store from "@/store";
import Notify from 'quasar/src/plugins/Notify.js';;
import i18n from "@/i18n";
import {
  deleteFormatMaskAndConvertToNumber,
  formatAmoutswitchCurrency,
  formatDateService,
  formatWithMask,
} from "@/utils/configuration/formatters-config";
import {ref, Ref} from "vue";
import {fixMoneyValue, TAXDEFAULT} from "@/utils";

const findSliderValue = (sliderConfig: ScaleSliderConfig[], config?: SliderConfig) => {
  const attributeList = sliderConfig.map((slider: ScaleSliderConfig) => slider.attribute);
  if (config && attributeList.indexOf(config.attribute) !== -1) {
    const newSliderConfig = sliderConfig.filter(
        (scaleSliderConfig: ScaleSliderConfig) =>
            config.attribute === scaleSliderConfig.attribute
    )[0];
    let typeSelected;
    if (newSliderConfig.typeSelected) {
      typeSelected = newSliderConfig.typeSelected;
    }
    const inputValue = newSliderConfig.value;
    return { typeSelected, inputValue };
  }
  return { typeSelected: undefined, inputValue: undefined };
};

@Options({
  props: ["config", "index"],
  watch: {
    "$i18n.locale"() {
      if (this.typeSelected ==='PERCENT'|| this.typeSelected ==='RATE'){  
        this.inputValue=this.percentMask(this.inputValue)
      }
    },
    "$store.state.simulationModule.sliderConfig"(newValue) {
      const sliderConfig: ScaleSliderConfig[] = newValue;
      const { typeSelected, inputValue } = findSliderValue(sliderConfig, this.config);
      this.typeSelected = typeSelected;
      this.inputValue = inputValue;
    },
    inputValue(newValue) {
      if (isNaN(parseFloat(newValue))) {  
        this.inputValue = this.previousInputValue;
      } else {
        this.previousInputValue = newValue;
      } 
      if (this.config) {
        this.config.model = newValue;
      }
      if (this.isSliderConfigRate) {
        const rate = deleteFormatMaskAndConvertToNumber(this.rateFinancingTax);
        const newValueNumber =
            typeof newValue === "string"
                ? deleteFormatMaskAndConvertToNumber(newValue)
                : newValue;
        this.rateMarge = this.percentMask(rate + newValueNumber);
        this.inputValue = this.percentMask(newValueNumber);
      }
    },
    "$store.state.simulationModule.isFinancingTypeTTC"(isTTC) {
      const normalModeState = store.state.simulationModule.normalModeState;
      normalModeState.attributes
          .filter((item) => item.attribute === this.config?.attribute)
          .forEach((attribute) => {
            const financingProductSelected =
                store.state.financingModule.scalesModule.scaleSelected;
            if (financingProductSelected) {
              const tax = Number(financingProductSelected.productTax?.fixedValue || 0);
              if (!tax) {
                return;
              }
              let value = deleteFormatMaskAndConvertToNumber(this.inputValue.toString());
              const isMonthlyPayment = attribute.attribute === SimulationAttributeEnum.PAYMENT;
              const isFirstPaymentOrResidualValue =
                  attribute.attribute === SimulationAttributeEnum.FIRST_PAYMENT ||
                  attribute.attribute === SimulationAttributeEnum.RESIDUAL_VALUE;
              const calculeWithTax = value * (1 + tax/100);
              const calculeWithoutTax = value / (1 + tax/100);
              if (isMonthlyPayment && isTTC) {
                if (
                    !this.reachesMinLimit(calculeWithTax) &&
                    !this.reachesMaxLimit(calculeWithTax)
                ) {
                  this.inputValue = formatWithMask(calculeWithTax, 2);
                  this.inputValueSlider = calculeWithTax;
                }
              } else if (isMonthlyPayment && !isTTC) {
                if (
                    !this.reachesMinLimit(calculeWithoutTax) &&
                    !this.reachesMaxLimit(calculeWithoutTax)
                ) {
                  this.inputValue = formatWithMask(calculeWithoutTax, 2);
                  this.inputValueSlider = calculeWithoutTax;
                }
              } else if (isFirstPaymentOrResidualValue && isTTC) {
                if (
                    !this.reachesMinLimit(calculeWithTax) &&
                    !this.reachesMaxLimit(calculeWithTax)
                ) {
                  this.inputValue = formatWithMask(calculeWithTax, 2);
                  this.inputValueSlider = calculeWithTax;
                }
              } else if (isFirstPaymentOrResidualValue && !isTTC) {
                if (
                    !this.reachesMinLimit(calculeWithoutTax) &&
                    !this.reachesMaxLimit(calculeWithoutTax)
                ) {
                  this.inputValue = formatWithMask(calculeWithoutTax, 2);
                  this.inputValueSlider = calculeWithoutTax;
                }
              }
            }
          });
    },
    "$store.state.financingModule.simulationChanged"(simulationChanged) {
      this.simulationChanged = simulationChanged;
    },
    "$store.state.simulationModule.normalModeState.attributes"(newValue) {
      if (newValue && newValue.length && this.config.attribute === SimulationAttributeEnum.RESIDUAL_VALUE) {
        const slider = newValue.filter((s: any) => s.attribute === SimulationAttributeEnum.RESIDUAL_VALUE)[0];
        this.inputValueSlider = slider
            ? slider.percentValue || slider.value
            : this.inputValueSlider;
        this.inputValue = this.percentMask(this.inputValueSlider);
        this.inputValue = this.percentMask((this.inputValueSlider || 0) % 1 === 0 
                                ?(this.inputValueSlider || 0).toFixed(0)
                                :(this.inputValueSlider || 0).toFixed(2));
      }
      if (newValue && newValue.length && this.config.attribute === SimulationAttributeEnum.MILEAGE) {
        const slider = newValue.filter((s: any) => s.attribute === SimulationAttributeEnum.MILEAGE)[0];
        this.inputValueSlider = slider ? slider.value : this.inputValueSlider;
        this.inputValue = `${this.inputValueSlider}`;
      }
    },
  },
})
export default class OdmSliderHT extends Vue {

  valueStyle = {
    "font-weight": "bold",
    "margin-left": "20px",
    color: "black",
  };

  labelText = 'HT'

  index: any;
  simulationChanged = false;
  isEnabled: boolean | undefined = true;
  rateToggle = false;
  rateMarge = 10;
  inputValue: any = "0";
  inputValueSlider = 0;
  config: SliderConfig | undefined;
  typeSelected: string | undefined = "PERCENT";

  taxCode?: string | undefined;
  taxGeographicZone?: any;
  country?: string | undefined;
  evaluationDate: string | Ref<string> | undefined;
  tax?: Number = 0.2;
  previousInputValue: any = '0'

  percentMask(value: any): string {
      if (i18n.global.locale.value === 'fr-FR') {
        return value.toString().substring(0, 8).replace(".", ",");
      } else if (i18n.global.locale.value === 'en-US') {
        return value.toString().substring(0, 8).replace(",", ".");
      }
      return value.toString();
    }

  reachesMinLimit(value: number) {
    if(typeof value === 'boolean' && value === false){
      return false;
    }
    if (value < (this.min || 0)  || (this.config?.attribute !== SimulationAttributeEnum.FIRST_PAYMENT && value == 0)) {
      Notify.create({
        timeout: 10000,
        actions: [{ icon: 'close', color: 'white' }],
        message: i18n.global.t("demand.simulation.invalidSlideValue"),
        color: "negative",
      });
      return true;
    }
    return false;
  }
  reachesMaxLimit(value: number) {
    if (value > (this.max || 100)) {
      Notify.create({
        timeout: 10000,
        actions: [{ icon: 'close', color: 'white' }],
        message: i18n.global.t("demand.simulation.invalidSlideValue"),
        color: "negative",
      });
      return true;
    }
    return false;
  }

  reachesMinLimitRate(value: number) {
    if (value < (this.minSpread || 0)) {
      Notify.create({
        timeout: 10000,
        actions: [{ icon: 'close', color: 'white' }],
        message: i18n.global.t("demand.simulation.invalidSlideValue"),
        color: "negative",
      });
      return true;
    }
    return false;
  }
  reachesMaxLimitRate(value: number) {
    if (value > (this.maxSpread || 100)) {
      Notify.create({
        timeout: 10000,
        actions: [{ icon: 'close', color: 'white' }],
        message: i18n.global.t("demand.simulation.invalidSlideValue"),
        color: "negative",
      });
      return true;
    }
    return false;
  }

  created() {
    const simulationChanged = store.state.financingModule.simulationChanged;
    if (simulationChanged) {
      this.simulationChanged = simulationChanged;
    }
    const normalModeState = store.state.simulationModule.normalModeState;
    const attribute = normalModeState.attributes.filter(
        (item) => item.attribute === this.config?.attribute
    )[0];
    const price = deleteFormatMaskAndConvertToNumber(this.price.toString());
    if (price === 0) {
      this.inputValue = this.min?.toString() || this.config?.min.toString() || "0";
      this.typeSelected = attribute
          ? attribute.type
          : this.config && this.config.slideType
              ? this.config.slideType
              : SlideType.DEFAULT;
    } else {
      if (attribute) {
        switch (attribute.type) {
          case "RATE":
            const value =
                (attribute.percentValue || 0) +
                (this.scaleSelected?.scalePresentation?.rateFinancingTax || 0);
            this.inputValue = this.percentMask(Number((attribute.percentValue || 0).toFixed(2)));            
            this.inputValueSlider = value;
            break;
          case "PERCENT":
            this.inputValue = this.percentMask((attribute.percentValue || 0));
            this.inputValueSlider = attribute.percentValue || 0;
            break;
          default:
            if (attribute.attribute === SimulationAttributeEnum.PAYMENT) {
              this.inputValue = formatWithMask(attribute.value || 0, 2);
            } else {
              this.inputValue = attribute.value?.toString() || "0";
            }
            this.inputValueSlider = attribute.value || 0;
            break;
        }
        this.typeSelected = attribute.type;
      } else {
        this.inputValue = this.config?.value.toString() || "0";
        this.typeSelected =
            this.config && this.config.slideType
                ? this.config.slideType
                : SlideType.DEFAULT;
      }
    }
  }

  get hasTtcOrHt() {
    const isPercent = this.typeSelected === "PERCENT";
    return this.config?.attribute === SimulationAttributeEnum.PAYMENT ||
        (!isPercent && (this.config?.attribute === SimulationAttributeEnum.FIRST_PAYMENT ||
            this.config?.attribute === SimulationAttributeEnum.RESIDUAL_VALUE ||
            this.config?.attribute === SimulationAttributeEnum.DOWN_PAYMENT))
  }

  get scaleSelectedServices() {
    return (
        store.state.financingModule.scalesModule.scaleSelected?.scalePresentation
            .services || []
    );
  }

  private getTotalServices() {
    return this.scaleSelectedServices
               .filter(
                   (service: any) =>
                       service.amountValue &&
                       service.config.selected &&
                       service.paymentDatesOption.toUpperCase() === "INSTALLMENT"
               )
               .reduce((a: number, b: any) => a + b.amountValue, 0);
  }

  get amountTTC() {
    if(this.simulationChanged)
      return '- € TTC';

    const type = this.third?.type?.id
    const body = type?.includes("Organization") ? {...this.third?.organization} : {...this.third?.person}
    this.taxGeographicZone = body.taxGeographicZone
    this.taxCode = this.scaleSelected?.taxOnInterest

    if (type?.includes("Organization")) {
      this.country = this.third?.organization?.addresses && this.third?.organization?.addresses?.length !== 0 ? this.third?.organization?.addresses[0]?.address.postalCode?.country?.resourceUid : "FRA"
    } else {
      this.country = this.third?.addresses && this.third?.addresses?.length !== 0 ? this.third?.addresses[0].address?.postalCode?.country?.resourceUid : "FRA"
    }

    this.evaluationDate = this.startDate

    const requestBody = {
      objectType: "",
      systemUid: "odm-product",
      resourceUid: "",
      country: this.country,
      geographicZone: this.taxGeographicZone,
      evaluationDate: this.evaluationDate,
      daaq: ""
    };

    const partyTaxValue = store.getters["demandModule/getPartyTaxRate"];
    if ( this.taxCode && this.country && this.taxGeographicZone && this.evaluationDate && !partyTaxValue)
      store.dispatch("demandModule/setEvaluatedTaxRate", {
        taxCode: this.taxCode, requestBody, callback: (result: EvaluateTaxRate[]) => {
          if ( this.scaleSelected?.productName ) {
            this.scaleSelected.productTax.fixedValue = result[0].taxRate
            this.tax = result[0].taxRate
          }
        }
      });      
    if (this.scaleSelected?.taxOnInterest && this.scaleSelected?.taxOnInterest !== 'NO_VAT' ) {
      let value = 0;
      let paymentSchedule = 2
      if(this.config?.attribute !== "payment") {        
        if(store.state.financingModule.scalesModule.scaleSelected) {
          const gracePeriod = store.state.financingModule.scalesModule.scaleSelected.scalePresentation.sliders.filter((s: any) => s.attribute === 'gracePeriodTerms')[0]
          if (gracePeriod) {            
            paymentSchedule = +gracePeriod.model + 2 // Number 2 because as describe by DA this payment schedule dont change if you skip one step more or not
          }
          
        } 
         
      } else {
       // const tax = Number(this.scaleSelected?.productTax?.fixedValue || TAXDEFAULT.val)
        //value = (this.inputValueSlider * (1 + (tax/100)))
      }
      if(this.config?.attribute === SimulationAttributeEnum.FIRST_PAYMENT) {
        value = this.scaleSelected.calculationResult.firstPaymentWTax;
        return formatWithMask(value, 2) + ' € TTC'
      }
      if(this.config?.attribute === SimulationAttributeEnum.RESIDUAL_VALUE) {        
        value = this.scaleSelected.calculationResult.residualValueWTax;
        return formatWithMask(value, 2) + ' € TTC'
      }
      const payment = Number(fixMoneyValue(this.scaleSelected.scalePresentation.payment));     
      value = (this.getTotalServices() + (payment?? 0)) * (1 +(partyTaxValue/100));
      store.dispatch("simulationModule/setAmountTTC", value)
      return formatWithMask(value, 2) + ' € TTC'
    }
    store.dispatch("simulationModule/setAmountTTC", this.inputValueSlider)
    return this.simulationChanged ? '- € TTC' : formatWithMask(this.inputValueSlider, 2) + ' € TTC'
  }

  get calculationDisabled() {
    return !this.calculationType || this.price < 1;
  }

  /**
   * Getter method that returns the CSS class name for the calculation button based on calculationDisabled conditions and display from SliderConfig.
   * @returns {string} The CSS class name for the calculation button.
   */
  get calculationButtonClass() {
    let classList;
    if (this.calculationDisabled) {
      classList = "tw-disabled-action";
    }
    if (!this.calculationDisabled && !this.config?.hideCalculationButton) {
      classList = this.simulationChanged
          ? "badge-danger tw-blink-btn"
          : "badge-success text-success border-success border";
    }
    return classList;
  }

  get rateFinancingTax() {
    return formatWithMask(
        this.scaleSelected?.scalePresentation?.rateFinancingTax || 0,
        1
    );
  }

  get scaleSelected() {
    return store.state.financingModule.scalesModule.scaleSelected;
  }
  get third() {
    return store.state.thirdModule.third;
  }

  get startDate() {
      if (store.state.demandModule.offer?.validity) {
        const startDate = store.state.demandModule.offer?.validity.from;
        return startDate ? startDate : ref(formatDateService(new Date(), "-")).value;
      }
    }

  get price() {
    return store.getters["financingModule/simulationPrice"];
  }

  get markerLabel() {
    if (this.suffix === '€' && this.min && this.maxPresentation)
    { return [
      {
        value: this.min,
        label: this.isCustomSuffix ? this.minSuffix : formatAmoutswitchCurrency("EUR", this.min),
      },
      {
        value: this.max,
        label: this.isCustomSuffix ? this.maxSuffix :  formatAmoutswitchCurrency("EUR", this.maxPresentation),
      },
    ];}

      return [
      {
        value: this.min,
        label: this.isCustomSuffix ? this.minSuffix : `${this.min}${this.suffix}`,
      },
      {
        value: this.max,
        label: this.isCustomSuffix ? this.maxSuffix : `${this.maxPresentation}${this.suffix}`,
      },
    ];
  }

  get sliderConfigType(): SlideType {
    if (this.config) return this.config.slideType || SlideType.DEFAULT;
    return SlideType.DEFAULT;
  }

  get isSliderConfigPercent() {
    return this.sliderConfigType === SlideType.PERCENT;
  }

  get isSliderConfigRate() {
    return this.sliderConfigType === SlideType.RATE;
  }

  get isSliderPercent() {
    return this.typeSelected === SlideType.PERCENT;
  }

  get suffixTTC() {
    if (this.hasTtcOrHt) {
      return this.suffix + ' ' + this.labelText
    }
    return this.suffix
  }

  get suffix() {
    return this.isSliderPercent || this.isSliderConfigRate ? "%" : this.configSuffix;
  }

  get suffixReadOnly() {
    return this.isSliderPercent ? "€ HT" : "%";
  }

  get valueReadOnly() {
    let values = this.calculateValues();
    return !this.isSliderPercent
        ? this.percentMask(Number(values.percentValue).toFixed(2))
        : formatWithMask(values.amountValue, 2);
  }

  calculateValues() {
    const selectedValue = this.inputValueSlider;
    const price = this.price;
    let percentValue;
    let amountValue;
    if (this.isSliderPercent) {
      amountValue = Number(((selectedValue * price) / 100).toFixed(2));
      percentValue = (Number(((selectedValue * price) / 100)) / price) * 100;
    } else {
      amountValue = Number(selectedValue.toFixed(2));
      percentValue = (Number(selectedValue)/ price) * 100;
    }
    return {
      percentValue,
      amountValue,
    };
  }

  get configSuffix() {
    return this.config?.suffix;
  }

  get minSuffix() {
    return this.config?.minSuffix;
  }

  get maxSuffix() {
    return this.config?.maxSuffix;
  }

  get isCustomSuffix() {
    return this.config?.isCustomSuffix;
  }

  get minSpread() {
    return this.config?.minSpread;
  }

  get maxSpread() {
    return this.config?.maxSpread;
  }

  get min() {
    return this.isSliderPercent || this.isSliderConfigRate
        ? this.config?.percentMin
        : this.config?.min;
  }

  get max() {
    return this.isSliderPercent || this.isSliderConfigRate
        ? this.config?.percentMax
        : this.config?.max;
  }

  get maxPresentation() {
    let result = this.config?.max;
    if (this.isSliderPercent || this.isSliderConfigRate) {
      result = this.config?.percentMax;
    }
    return result;
  }

  // getters

  get financingProductSelected() {
    return store.state.simulationModule.financingProductSelected;
  }

  get calculationType() {
    return this.config?.calculationType;
  }

  // events
  onInputValueEvent(value: number, changeSimulation?: boolean) {
    if (SlideType.DEFAULT !== this.typeSelected) {
      if (value < 0 || value > 100) {
        Notify.create({
        timeout: 10000,
        actions: [{ icon: 'close', color: 'white' }],
          message: "Error",
          color: "negative",
        });
        return;
      }
    }
    store
        .dispatch("simulationModule/changeSliderValue", {
          attribute: this.config?.attribute,
          value: value,
          typeSelected: this.typeSelected,
        })
        .then(() => {
          store.dispatch("financingModule/setSimulationChanged", changeSimulation);
          if (this.config?.attribute === SimulationAttributeEnum.MILEAGE) {
            store.dispatch("financingModule/refreshResidualValue");
          }
          if (this.config?.attribute === "duration") {
            store
                .dispatch("financingModule/refreshMillage")
                .then(() => {
                  store.dispatch("financingModule/refreshResidualValue")
                  store.dispatch('financingModule/serviceModule/refreshPartyInsurance', {duration: value})
                });
          }
        });
  }

  onInputValueSliderChange() {
    const isPercent = this.typeSelected === "PERCENT";
    let value = 0;
    if (isPercent) {
      value = deleteFormatMaskAndConvertToNumber(this.inputValueSlider.toFixed(2));
    } else if (this.typeSelected === "RATE") {
      value =
          this.inputValueSlider -
          (this.scaleSelected?.scalePresentation?.rateFinancingTax || 0);
    } else {
      value = Number(this.inputValueSlider);
    }
    if (this.config?.attribute !== "duration" && this.config?.attribute !== "gracePeriodTerms" ) {
      this.inputValue = this.percentMask(value);
    } else {
      this.inputValue = this.percentMask(value);
    }
    this.onInputValueEvent(value, true);
  }
  // events
  onInputValueChange(changeSimulation: any) {
    const value = parseFloat(this.inputValue.replace(',', '.'));
    if (isNaN(value)) {
      this.inputValue = this.previousInputValue;
      return;
    }
    const isPercent = this.typeSelected === "PERCENT";
    if (this.reachesMinLimit(changeSimulation) || this.reachesMaxLimit(changeSimulation)) {
      const calculatedValue = this.calculateValues();
      this.inputValue = isPercent ? calculatedValue.percentValue : calculatedValue.amountValue;
    } else {               
      if (
          this.config?.attribute === SimulationAttributeEnum.MONTHLY_PAYMENT ||
          (!isPercent &&
              (this.config?.attribute === SimulationAttributeEnum.FIRST_PAYMENT ||
                  this.config?.attribute === SimulationAttributeEnum.RESIDUAL_VALUE ||
                  this.config?.attribute === SimulationAttributeEnum.DOWN_PAYMENT))
      ) {
        let value = deleteFormatMaskAndConvertToNumber(this.inputValue.toString());
        if (!this.reachesMinLimit(value) && !this.reachesMaxLimit(value)) {
          this.onInputValueEvent(value, changeSimulation);
          this.inputValue = formatWithMask(value, 2);
          this.inputValueSlider = value;
        } else {
          this.inputValue = formatWithMask(this.inputValueSlider, 2);
        }
      } else if (
          this.config?.attribute === SimulationAttributeEnum.MONTHLY_PAYMENT ||
          (isPercent &&
              (this.config?.attribute === SimulationAttributeEnum.FIRST_PAYMENT ||
                  this.config?.attribute === SimulationAttributeEnum.RESIDUAL_VALUE ||
                  this.config?.attribute === SimulationAttributeEnum.DOWN_PAYMENT))
      ) {
        let value = deleteFormatMaskAndConvertToNumber(this.inputValue.toString());
        if (!this.reachesMinLimit(value) && !this.reachesMaxLimit(value)) {
          this.inputValueSlider = value;
          const calculatedValue = this.calculateValues();
          this.inputValueSlider = calculatedValue.percentValue;
          value = calculatedValue.percentValue;
          this.inputValue = this.percentMask(calculatedValue.percentValue.toFixed(2));
          this.onInputValueEvent(value, changeSimulation);
        } else {
          this.inputValue = this.inputValueSlider.toString();
        }
      } else {
        if (this.typeSelected === "RATE") {
          let value = deleteFormatMaskAndConvertToNumber(this.inputValue.toString());
          let totalValue =
              value + (this.scaleSelected?.scalePresentation?.rateFinancingTax || 0);
          if (!this.reachesMinLimitRate(value) && !this.reachesMaxLimitRate(value)) {
            this.onInputValueEvent(value, changeSimulation);
            this.inputValueSlider = totalValue;
          } else {
            value =
                this.inputValueSlider -
                (this.scaleSelected?.scalePresentation?.rateFinancingTax || 0);
            this.inputValue = value.toString();
            this.onInputValueEvent(value, changeSimulation);
          }
        } else {
          let value = deleteFormatMaskAndConvertToNumber(this.inputValue.toString());
          if (!this.reachesMinLimit(value) && !this.reachesMaxLimit(value)) {
            this.onInputValueEvent(value, changeSimulation);
            this.inputValueSlider = value;
          } else {
            value = this.inputValueSlider;
            this.inputValue = this.inputValueSlider.toString();
            this.onInputValueEvent(value, changeSimulation);
          }
        }
      }
    }
  }

  calculateClick() {
    store.dispatch("financingModule/calculateFromState", {
      searchType: this.calculationType,
      financingType: 'HT',
      scale: store.state.financingModule.scalesModule.scaleSelected,
      callback: this.handleCalculationResponse,
    });
  }

  handleAfterCalculation() {
    store.dispatch("financingModule/setSimulationChanged", false);
  }

  handleCalculationResponse(res: any) {
    const isPercent = this.typeSelected === "PERCENT";
    let result;
    switch (this.calculationType) {
      case CalculationType.PAYMENT:
        let paymentSchedule = 2
        if(store.state.financingModule.scalesModule.scaleSelected) {
          const gracePeriod = store.state.financingModule.scalesModule.scaleSelected.scalePresentation.sliders.filter((s: any) => s.attribute === 'gracePeriodTerms')[0]
          if (gracePeriod) {
            paymentSchedule = +gracePeriod.model + 2 // Number 2 because as describe by DA this payment schedule dont change if you skip one step more or not
          }
        }
        this.inputValue = this.validateCalculationValue(
            res.calculationResult.paymentSchedules[paymentSchedule].financialInstallmentWoTax,
            () => {
              this.typeSelected = SlideType.DEFAULT;
              store.dispatch("financingModule/refreshScales");
              this.inputValueSlider =
                  res.calculationResult.paymentSchedules[paymentSchedule].financialInstallmentWoTax;
            }
        );
        break;
      case CalculationType.FIRST_PAYMENT:
        result = this.validateCalculationValue(res.calculationResult.result, () => {
          store.dispatch("financingModule/refreshScales");
        });
        if (result) {
          if (!isPercent) {
            this.inputValue = result;
            this.inputValueSlider = res.calculationResult.result;
          } else {
            result = (Number(res.calculationResult.result.toFixed(2)) / this.price) * 100;
            this.inputValue = this.percentMask(result.toFixed(2));
            this.inputValueSlider = result;
          }
        }
        break;
      case CalculationType.OUTSTANDING_BALANCE_BEFORE:
        result = this.validateCalculationValue(res.calculationResult.downPayment, () => {
          store.dispatch("financingModule/refreshScales");
        });
        if (result) {
          if (!isPercent) {
            this.inputValue = result;
            this.inputValueSlider = res.calculationResult.downPayment;
          } else {
            result =
                (Number(res.calculationResult.downPayment.toFixed(2)) / this.price) * 100;
            this.inputValue = this.percentMask(result);
            this.inputValueSlider = result;
          }
        }
        break;
      case CalculationType.OUTSTANDING_BALANCE_AFTER:
        result = this.validateCalculationValue(
            res.calculationResult.residualValueWoTax,
            () => {
              store.dispatch("financingModule/refreshScales");
            }
        );
        if (result) {
          if (!isPercent) {
            this.inputValue = result;
            this.inputValueSlider = res.calculationResult.residualValueWoTax;
          } else {
            result =
                (Number(res.calculationResult.residualValueWoTax.toFixed(2)) / this.price) * 100;
            this.inputValue = this.percentMask(result);
            this.inputValueSlider = result;
          }
        }
        break;
      case CalculationType.ANNUAL_RATE:
        const item = res.calculationResult.paymentSchedules.filter(
            (item: any) => !!item.stepNo
        )[0];
        if (item) {
          const nominalRate = item.nominalRate;
          const value =
              nominalRate - (this.scaleSelected?.scalePresentation?.rateFinancingTax || 0);
          if (nominalRate > 0) {
            result = this.validateCalculationValue(nominalRate, () => {
              store.dispatch("financingModule/refreshScales");
            });
            this.inputValue = this.percentMask(value.toFixed(2));
            this.inputValueSlider = nominalRate;
          }
        }
        break;
    }
    this.handleAfterCalculation();
  }

  validateCalculationValue(value?: number, callback?: any) {
    if (value && value > 0) {
      Notify.create({
        timeout: 10000,
        actions: [{ icon: 'close', color: 'white' }],
        message: `${i18n.global.t("main.dialog.validation.success")}`,
        color: "positive",
      });
      if (callback) {
        callback(value);
      }
      return formatWithMask(Number(value.toFixed(2)), 2);
    } else {
      Notify.create({
        timeout: 10000,
        actions: [{ icon: 'close', color: 'white' }],
        message: `Calculation returned an invalid result ${value}`,
        color: "warning",
      });
      return this.inputValue;
    }
  }

  onTypeChange() {
    let newValue;
    const price = deleteFormatMaskAndConvertToNumber(this.price.toString());
    const calculatedValue = this.calculateValues();
    const isPercent = this.typeSelected === "PERCENT";
    if (isPercent) {
      const value = deleteFormatMaskAndConvertToNumber(calculatedValue.percentValue.toString());
      newValue = Number(((value / price) * 100).toFixed(2));
    } else {
      const value = deleteFormatMaskAndConvertToNumber(calculatedValue.amountValue.toString());
      newValue = Number(((price * value) / 100).toFixed(2));
    }
    if (newValue < (this.min || 0)) {
      Notify.create({
        timeout: 10000,
        actions: [{ icon: 'close', color: 'white' }],
        message: i18n.global.t("demand.simulation.invalidSlideValue"),
        color: "negative",
      });
      const min = this.min || 0;
      this.inputValue = formatWithMask(min, 2);
    } else if (newValue > (this.max || 100)) {
      Notify.create({
        timeout: 10000,
        actions: [{ icon: 'close', color: 'white' }],
        message: i18n.global.t("demand.simulation.invalidSlideValue"),
        color: "negative",
      });
      const max = this.max || 100;
      this.inputValue = formatWithMask(max, 2);
    } else {
      if (isPercent) {
        this.inputValue = this.percentMask(newValue.toFixed(2));
      } else {
        this.inputValue = formatWithMask(newValue, 2);
      }
      this.inputValueSlider = newValue;
    }
    this.onInputValueChange(false);
  }
}
