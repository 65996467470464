
import { Options, Vue } from 'vue-class-component';
import { demandProgressStatusItems } from "@/utils/configuration/demand-progress-config"; // Import configuration for workflow items
import i18n from '@/i18n';

@Options({
  props: ["taskDefinition"], // Define 'taskDefinition' as a prop using the props array
  watch: {
    // Watcher for taskDefinition to reactively update task index when it changes
    taskDefinition(newTaskDefinition: string) {
      if (newTaskDefinition) {
        this.updateTaskIndex();
      }
    }
  },
})
export default class DemandProgress extends Vue {
  taskDefinition!: string; // taskDefinition prop passed from the parent component
  nextItems: any; // Holds tasks after the current task
  previousItems: any; // Holds tasks before the current task
  thisTaskIndex: any = -1; // Index of the current task in the workflow

  get workflowItems() {
    return Object.values(demandProgressStatusItems);
  }

  isStepDone(index: number) {
    return this.thisTaskIndex > -1 && index <= this.thisTaskIndex;
  }

  getStepColor(index: number) {
    if (index < this.thisTaskIndex) {
      return 'green'; // Color for completed steps
    } else if (index === this.thisTaskIndex) {
      return 'orange'; // Color for the current step
    } else {
      return 'grey'; // Color for upcoming steps
    }
  }

  mounted() {
    this.updateTaskIndex();
  }
  getTranslationForCurrentStatus(status: string) {
  return i18n.global.t(`enum.offer.ClientTaskDefinition.${status}`);
}


  updateTaskIndex() {
  console.log("taskDefinition:", this.taskDefinition);
  this.thisTaskIndex = this.workflowItems.findIndex((item: any) =>
    item.status.includes(this.taskDefinition)
  );
  console.log("thisTaskIndex:", this.thisTaskIndex);

  this.previousItems = this.workflowItems.filter(
    (item: any, index: number) => index < this.thisTaskIndex
  );
  console.log("previousItems:", this.previousItems);

  this.nextItems = this.workflowItems.filter(
    (item: any, index: number) => index > this.thisTaskIndex
  );
  console.log("nextItems:", this.nextItems);
}

}
