
import store from "@/store";
import { Options, Vue } from "vue-class-component";
import ThirdSearchDialog from "../../dialogs/ThirdSearchDialog.vue";
import AddressSearchDialog from "../../dialogs/AddressSearchDialog.vue";
import { Proposal, ProposalAssetCar, ProposalAssetTruck } from "@/types";
import { useRoute } from "vue-router";
import i18n from "@/i18n";
import { formatDateLocale } from "@/utils/configuration/formatters-config";
import { asset } from "@/utils/configuration/table-config";

@Options({
  props: ["tabPosition"],
  components: {
    ThirdSearchDialog,
    AddressSearchDialog,
  },
  watch: {
    proposalAssetCalculated() {
      setTimeout(this.buildItemsAssetSimulation, 300);
    },
    flagNew(newValue) {
      this.asset.flagNew = newValue === "vn";
      store.dispatch("demandModule/setProposalAssetByIndex", {
        proposalAsset: this.asset,
        index: this.tabPosition,
      });
    },
    quantity(newValue) {
      this.asset.quantity = newValue;
      store.dispatch("demandModule/setProposalAssetByIndex", {
        proposalAsset: this.asset,
        index: this.tabPosition,
      });
    },
  },
})
export default class DemandVehicleDetailForm extends Vue {
  category = ""
  dateMask = "DD/MM/YYYY"
  subCategory = ""
  tabPosition?: number;
  showDialog?: boolean = false;
  showDialogAddress?: boolean = false;
  openDialog = "";
  bodyCarList = [];
  brandList = [];
  modelList = [];
  versionList = [];
  energyList = [];
  subCategoryList = []
  flagNew = "vn";
  quantity = 1;

  get currentRoute() {
    return useRoute().name;
  }
  get asset() {
    if (this.currentRoute !== "TaskDetail") {
      return this.assetState.proposalAssets[this.tabPosition!];
    } else {
      let asset: ProposalAssetCar | ProposalAssetTruck = this.assetState.proposalAssets[
        this.tabPosition!
      ];
      const proposalSelected: Proposal = store.getters["taskModule/getProposalSelected"];
      if (proposalSelected && proposalSelected.proposalItems[0].proposalAssets[this.tabPosition || 0]) {
        asset = proposalSelected.proposalItems[0].proposalAssets[this.tabPosition || 0];
      }
      if (this.currentRoute === 'TaskDetail' ) {
        
        if (!asset.guarantee) {
          asset.guarantee = { from: '', until: '' }
        }
        if (!asset.assetDelivery) {
          asset.assetDelivery = {
            deliveryDate: '',
            deliveryAddress: ''
          }
        }
        if (!asset.proposalAssetCategories || asset.proposalAssetCategories.length === 0) {
          asset.proposalAssetCategories =[{
            category: {
              value: ''
            },
            categoryClass: {
              value: ''
            },
          }]
        }
        else {
          if (asset.proposalAssetCategories[0].category && asset.proposalAssetCategories[0].category.value) {
            store.dispatch("demandModule/assetSubCategory", {
              callback: (res: any) => this.updateSubCategoryList(res),
              filter: {
                id: "asset.good.Good.Family.SubFamily",
                category_id: "asset.good.Good.Family." + asset.proposalAssetCategories[0].category.value,
              },
            });
          }

        }
        if (asset.color && !asset.color.label && asset.color.value) {
          asset.color.label = asset.color.value || "-" 
        }
      }

      return asset;
    }
  }

  get isFormReadOnly() {
    return this.asset.config.readOnly;
  }

  get categoryList() {

    return store.getters["picklistModule/assetCategoryItems"].map((item: any) => {
      return {
        ...item,
        label: item.label?.toString() !== "" ? i18n.global.t("demand.asset.categoryItems." + item.label) : ''
      };
    });
  }

  get colorsList() {
    return store.getters["picklistModule/colorsList"].map((item: any) => {
      return {
        ...item,
        label: item.label?.toString() !== "" ? i18n.global.t("demand.asset.colors." + item.label) : ''
      };
    });
  }

  get usageAssetlist() {
    return store.getters["picklistModule/usageAssetItems"].map((item: any) => {
      return {
        ...item,
        label: item.value?.toString() !== "" ? i18n.global.t("demand.asset.usageItems." + item.value) : ''
      };
    });

  }

  get proposalAssetCalculated() {
    const asset = this.assetState.proposalAssets[this.tabPosition!];
    if (asset && asset.config) {
      return this.assetState.proposalAssets[this.tabPosition!].config
        .proposalAssetCalculated;
    } else {
      return {
        proposalAssetCalculated: {},
      };
    }
  }

  get assetState() {
    return store.state.demandModule.assetState;
  }

  updateBrand(res: any) {
    this.brandList = res.items;
  }

  updateModel(res: any, skipRefreshSimulation?: boolean) {
    this.modelList = res.items;
    this.onChangeModel(this.asset.config.formBind.range);
    this.refreshSimulationState(skipRefreshSimulation);
  }

  updateVersion(res: any, skipRefreshSimulation?: boolean) {
    this.versionList = res.items;
    this.refreshSimulationState(skipRefreshSimulation);
  }

  updateBodyStyle(res: any, skipRefreshSimulation?: boolean) {
    this.bodyCarList = res.items;
    this.refreshSimulationState(skipRefreshSimulation);
  }
  updateSubCategoryList(res: any) {
    this.subCategoryList = res.items.map((item: any) => {
      return {
        ...item,
        label: item.label?.toString() !== "" ? i18n.global.t("demand.asset.subCategoryItems." + item.value.substring(item.value.lastIndexOf(".") + 1)) : ''
      };
    });
  }

  updateEnergy(res: any, skipRefreshSimulation?: boolean) {
    this.energyList = res.items.map((item: any) => {
      return {
        ...item,
        label: item.label?.toString() !== "" ? i18n.global.t("demand.asset.vehicle.energyLabels." + item.label) : ''
      };
    });
    this.refreshSimulationState(skipRefreshSimulation);
  }

  refreshSimulationState(skipRefreshSimulation?: boolean) {
    store
      .dispatch("demandModule/updateAssetState", {
        offerAssets: [this.asset],
        index: this.tabPosition,
      })
      .then(() => {
        if (!skipRefreshSimulation) {
          store.dispatch("financingModule/refreshState");
        }
      });
  }

  onSelectedItem(row: any) {
    store.dispatch("thirdModule/executeSelectThird", {
      id: row.id,
      type: row._discriminator,
      callback: (provider: any) => {
        this.asset.config.formBind.supplier.name = provider.organization.name;
        this.asset.party = {
          party: {
            resourceUid: provider.organization.resourceUid,
            objectType: provider.organization.objectType,
            systemUid: provider.organization.systemUid,
          },
          role: {
            resourceUid: "SUPPLIER",
            objectType: "odm.offer.associatedpartyrole",
            systemUid: "odm-offer",
          },
        };
      },
    });
    this.onClose();
  }
  onSelectedAddressItem(row: any) {
    this.asset.assetDelivery.deliveryAddress = row.addressid
    const country_code = row.country_code ? i18n.global.t("demand.party.country." + row.country_code) : ''
    this.asset.config.formBind.supplierAddress = 'N° ' + row.street_num + ',' + row.street + ' ,' + row.postal_code + ' ' + row.city + ' ' + country_code
    this.onCloseAddressSearchDialog()

  }

  onChangeEnergy(event: any) {
    this.asset.engineType = event.id;
    this.refreshSimulationState();
  }

  onChangeBodyStyle(event: any, skipRefreshSimulation?: boolean) {
    const carBodyStyle = event.id;
    this.asset.carBodyStyle = carBodyStyle;
    store.dispatch("demandModule/assetBodyStyle", {
      callback: (res: any) => this.updateEnergy(res, skipRefreshSimulation),
      filter: {
        id: "asset.good.vehicle.energytype",
      },
    });
  }
  onChangeBrand(event: any, skipRefreshSimulation?: boolean) {
    const brand = event.id;
    this.asset.brand = brand;
    store.dispatch("demandModule/assetModel", {
      callback: (res: any) => this.updateModel(res, skipRefreshSimulation),
      filter: {
        id: "asset.good.vehicle.brand.range",
        marque_id: brand,
      },
    });
  }
  onChangeCategory(event: any) {
    store.dispatch("demandModule/assetSubCategory", {
      callback: (res: any) => this.updateSubCategoryList(res),
      filter: {
        id: "asset.good.Good.Family.SubFamily",
        category_id: "asset.good.Good.Family." + event.id.substring(event.id.lastIndexOf(".") + 1),
      },
    });
   
  }
  onChangeSubCategory(event: any) {
    console.log(event.value)
  }

  onChangeModel(event: any, skipRefreshSimulation?: boolean) {
    const model = event.id;
    this.asset.range = model;
    store.dispatch("demandModule/assetVersion", {
      callback: (res: any) => this.updateVersion(res, skipRefreshSimulation),
      filter: {
        id: "asset.good.vehicle.brand.range.version",
        marque_id: model,
      },
    });
  }

  onChangeVersion(event: any, skipRefreshSimulation?: boolean) {
    const version = event.id;
    this.asset.version = version;
    store.dispatch("demandModule/assetBodyStyle", {
      callback: (res: any) => this.updateBodyStyle(res, skipRefreshSimulation),
      filter: {
        id: "asset.good.vehicle.car.bodyStyle",
      },
    });
  }
  get offer() {
    return store.state.taskModule?.offer;
  }
  get associatedParties() {
    return this.offer?.associatedParties || [];
  }

  get supplierName(){
    const supplier:any = this.associatedParties?.find((party:any)=> party.associatedParty.role_code === 'SUPPLIER');
    return  supplier?.associatedParty.third?.commercialName || "";
  }

  searchAssetSupplier() {
    this.showDialog = true;
    this.openDialog = "party-supplier";
  }
  searchAssetSupplierAddress() {
    this.showDialogAddress = true;
    this.openDialog = "party-supplier";
  }

  onClose() {
    this.showDialog = false;
  }
  onCloseAddressSearchDialog() {
    this.showDialogAddress = false;
  }

  created() {
    store.dispatch("demandModule/assetBrand", {
      callback: this.updateBrand,
      filter: {
        id: "asset.good.vehicle.brand",
      },
    });

    if (store.state.demandModule.isEdit) {
      this.buildItemsAssetSimulation();
    }
    const asset = this.asset;
    if (asset) {
      this.quantity = asset.quantity || 1;
    }
  }

  onQuantityChange(value: number) {
    store.dispatch("demandModule/calculateProposalAsset", {
      discount: value,
      index: this.tabPosition,
    });
  }
  formatDate(date: string) {
    return formatDateLocale(date, this.dateMask, "fr", "YYYY/MM/DD");
  }
  buildItemsAssetSimulation() {
    if (this.asset) {
      this.onChangeBrand({ id: this.asset.brand }, true);
      this.onChangeModel({ id: this.asset.range }, true);
      this.onChangeVersion({ id: this.asset.version }, true);
      this.onChangeBodyStyle({ id: this.asset.carBodyStyle }, true);
      this.flagNew = this.asset.flagNew ? "vn" : "vo";
    }
    if (
      store.state.financingModule.proposalAssetTotalWithTax ||
      (0 > 0 && !store.state.financingModule.isSimulationRefreshing)
    ) {
      store
        .dispatch("financingModule/refreshState")
        .then(() => store.dispatch("financingModule/setSimulationChanged", false));
    }
  }
}
