import {
  ActionType,
  Analysis,
  AnalysisRating,
  ComboBox,
  GatheringProcess,
  Offer,
  QcHeader,
  SearchTask,
  TaskActionEvent,
  TaskDefinition,
  TaskExecuteAction,
  TaskSearchCriteria,
  TaskState,
  UniformOdmName
} from "@/types";
import { settings } from "@/settings";
import { api } from "@/auth/api";
import store from "@/store";
import { executeCompleteOfferAction } from "@/store/services/task/actions/completeOfferService";
import {
  deepClone,
  deleteAttributesRecursively,
  deleteNonRequiredAttributes,
  formatDateRecursively,
  globalConfig,
  headers,
  Masks,
  removeAttributesRecursively,
  sleep,
  UON
} from "@/utils";
import { executeFinancingRequestOfferAction } from "@/store/services/task/actions/financingRequestService";
import { executeDecisionAction, executeDecisionOpinionAction, executeEnterDecisionAction, } from "@/store/services/task/actions/decisionService";
import { executeAnalysisAction, updateAnalysis } from "@/store/services/task/actions/analysisService";


import { claimTask, executeTask, getTaskListByUser, initWorkflowProcess, unClaimTask } from "@/store/services/task/workflow";
import { executeValidateDocumentAction } from "@/store/services/task/actions/validateDocumentService";
import { buildAssociatedParties, buildProposalGuarantees, getOffer, saveOffer } from "@/store/services/offerService";

import { executeUploadDocumentAction } from "./actions/uploadDocumentService";
import { executePrintDocument, getOperator } from "./actions/printOfferService";
import { executeModifyConditionAction } from './actions/modifyCondition';
import { saveAssociatedParty } from '../simulation';
import { executeValidateCompletePreconditions } from "@/store/services/task/actions/order/completePreconditionsService";
import { executeStartOrder } from "@/store/services/task/actions/order/startOrderService";
import { executeDelegationLevelAction } from "@/store/services/task/actions/delegationLevelService";
import { executeValidateOrder } from "./actions/order/validationOrderService";
import axios from "axios";
import {executeCompletePreconditions} from "@/store/services/task/actions/completePreconditionsService";
import i18n from "@/i18n";
const api_query_url = settings.api_query_url;
const odm_decision = "odm-decision";
const odm_user = "odm-user";
const odm_analysis = "odm_analysis";
const odm_offer = "odm-offer";
const odm_supportingdocument = 'odm-supportingdocument'

export async function getTasks(filter?: TaskSearchCriteria, offset?: any, limit?: any, sortBy?:any, sortOrder?:any) {
  return getTaskListByUser(filter || {}, offset, limit, sortBy, sortOrder)
}

export function getTasksUploadMock(filter: SearchTask): Promise<SearchTask[]> {
  return Promise.resolve<SearchTask[]>([
    {
      actionDate: "16/09/2022",
      actionIssueDate: "17/09/2022",
      status: "Créé",
      reference: "35736150",
      clientName: "Adeline Martin",
      commercialName: "",
      clientReference: "39-JD-39",
      typeAction: "Saisir acceptation client",
    },
    {
      actionDate: "05/09/2022",
      actionIssueDate: "06/09/2022",
      status: "Documents complétés",
      reference: "d62e87ac2166",
      clientName: "Gustave Gauthier",
      commercialName: "",
      clientReference: "MB-85-15",
      typeAction: "Envoyer vers étude",
    },
    {
      actionDate: "29/09/2022",
      actionIssueDate: "29/09/2022",
      status: "En attente Décision",
      reference: "11eda6e2",
      clientName: "Emanuel Dupond",
      commercialName: "",
      clientReference: "46-22-TG ",
      typeAction: "Saisir Décision",
    },
  ]);
}

export const getTasksDecisionMock = () => [
  {
    actionDate: "16/09/2022",
    actionIssueDate: "17/09/2022",
    status: "Créé",
    reference: "35736150",
    clientName: "Adeline Martin",
    commercialName: "",
    clientReference: "39-JD-39",
    typeAction: "Saisir acceptation client",
  },
  {
    actionDate: "05/09/2022",
    actionIssueDate: "06/09/2022",
    status: "Documents complétés",
    reference: "d62e87ac2166",
    clientName: "Gustave Gauthier",
    commercialName: "",
    clientReference: "MB-85-15",
    typeAction: "Envoyer vers étude",
  },
  {
    actionDate: "29/09/2022",
    actionIssueDate: "29/09/2022",
    status: "Offre accepté",
    reference: "11eda6e2",
    clientName: "Emanuel Dupond",
    commercialName: "",
    clientReference: "46-22-TG ",
    typeAction: "Edition Contrat",
  },
];

export async function updateReserveStatus(reserveId: string, payload: any,path:any) {
  try {
    // Construct the base URL using settings and constants
    const baseUrl = `${settings.api_url}/${odm_decision}/api/1/${odm_decision}`;

    // Make the API call
    const response = await api().put(
      `${baseUrl}/${path}/${reserveId}/`,
      payload
    );

    return response.data;
  } catch (error) {
    console.error('Error updating reserve status:', error);
    throw error; // Re-throw to allow calling code to handle
  }
}

export function takeDecision(decision: any, ressourceUid: string) {
  const baseUrl = `${settings.api_url}/${odm_decision}/api/1/${odm_decision}`;
  const body = {
    defaultValue: false,
    label: "",
    internalCode: decision.decision.value,
    objectType: "odm.decision.decisionstatus",
    systemUid: "odm-decision",
    resourceUid: ressourceUid,
    businessData: {},
    daaq: "/",
  };

  api().put(`${baseUrl}/decisionstatus/` + ressourceUid + "/", body);
}

export function analysis(analysis: any, ressourceUid: string) {
  const baseUrl = `${settings.api_url}/${odm_analysis}/api/1/${odm_analysis}`;
  const body = {
    defaultValue: false,
    label: "",
    internalCode: analysis.decision.value,
    objectType: "odm.analysis.analysisstatus",
    systemUid: "odm-analysis",
    resourceUid: ressourceUid,
    businessData: {},
    daaq: "/",
  };

  api().put(`${baseUrl}/analysisstatus/` + ressourceUid + "/", body);
}

export function getGroup(): Promise<ComboBox> {
  const baseUrl = `${settings.api_url}/${odm_user}/api/1/${odm_user}/`;
  const username = store.state.authModule?.user.username;
  return new Promise<ComboBox>(async (resolve, reject) => {
    try {
      const result = await api().get(`${baseUrl}usergroup/` + username + "/");
      resolve({
        items: [
          {
            value: result.data.group.resourceUid,
            label: result.data.group.resourceUid,
          },
        ],
      });
    } catch (e) {
      reject(e);
    }
  });
}
export function getRoles(): Promise<ComboBox> {
  const authorities = store.state.authModule?.user.authorities || [];
  return new Promise<ComboBox>((resolve, reject) => {
    resolve({
      items: authorities.map((resItem: any) => {
        return {
          value: resItem.resourceUid,
          label: resItem.authority,
        };
      }),
    });
  });
}

export async function cancelAction(task: any, user: UniformOdmName,variables:any) {

    const taskExecuteAction: TaskExecuteAction = {
      taskId: task.taskId || '',
      variables: variables
    }
    try {
      return executeTask(taskExecuteAction)
    } catch (e) {
      console.error(e)
      return Promise.reject(e)
    }

  
}

export async function executeOrderAction(taskState: TaskState, user: UniformOdmName, action: ActionType) {
  const order = taskState.order
  const userId = user.resourceUid || ''
  const task = taskState.selectedTask
  if (order) {
    const taskDefinition = task.taskDefinition
    try {
      let result;
      switch (taskDefinition) {
        case TaskDefinition.START_ORDER:
          // TODO EF-162 - extract isDecisionAccept from state
          result = await executeStartOrder(taskState, order, task)
          break
        case TaskDefinition.COMPLETE_PRECONDITIONS:
          result = await executeValidateCompletePreconditions(userId, order, task)
          break
        case TaskDefinition.VALIDATE_ORDER:
          result = await executeValidateOrder(taskState, order, task)
          break
      }
      return Promise.resolve({
        result,
        task
      })
    } catch (e) {
      return Promise.reject(e)
    }
  }

}

export async function executeAction(taskState: TaskState, user: UniformOdmName, action: ActionType) {
  const offer = taskState.offer
  const userId = user.resourceUid || ''
  const task = taskState.selectedTask
  const formValid = taskState.formValid || false
  const isPreviousformValid = taskState.isFormValid !== undefined ? taskState.isFormValid : true
  if (offer) {
    const taskDefinition = task.taskDefinition
    try {
      let result;
      const opinion = taskDefinition && Masks.regexOpinion.test(taskDefinition) ? taskDefinition : null;
      switch (taskDefinition) {
        case TaskDefinition.COMPLETE_OFFER_INFO:
          result = await executeCompleteOfferAction(userId, offer, task, action, formValid, isPreviousformValid)
          break
        case TaskDefinition.STEP_START_FINANCING_REQUEST:
          result = await executeFinancingRequestOfferAction(userId, offer, task, action)
          break
        case TaskDefinition.ENTER_DECISION:
          result = await executeEnterDecisionAction(task)
          break
        case TaskDefinition.ENTER_CUSTOMER_DECISION:
          result = await executeDecisionAction(taskState, userId, offer, task)
          break
        case TaskDefinition.ANALYSIS:
          result = await executeAnalysisAction(taskState, userId, offer, task)
          break
        case TaskDefinition.DELEGATION_LEVEL:
          result = await executeDelegationLevelAction({ taskState, task })
          break
        case TaskDefinition.VALIDATE_DOCUMENTS:
          result = await executeValidateDocumentAction(taskState, userId, offer, task)
          break
        case TaskDefinition.UPLOAD_DOCUMENTS:
          result = await executeUploadDocumentAction(task)
          break
        case TaskDefinition.PRINT_DOCUMENT:
          result = await executePrintDocument(taskState, action, task)
          break
        case TaskDefinition.MODIFY_CONDITIONS:
          result = await executeModifyConditionAction(taskState, userId, offer, task)
          break
        case opinion:
          result = await executeDecisionOpinionAction(task)
          break
        case TaskDefinition.COMPLETE_PRECONDITIONS:
          result = await executeCompletePreconditions(task)
          break
        case TaskDefinition.SIGN_CONTRACT:
          return Promise.reject(`Action ${taskDefinition} not implemented!`)

      }
      return Promise.resolve(result)
    } catch (e) {
      return Promise.reject(e)
    }
  }

}

export async function getFormValidate(form?: any) {
  let validate = false
  if (form) {
    validate = await form.validate()
  }
  return validate
}

export async function saveOfferFromTask(offerToSave: Offer): Promise<Offer> {
  const offerCloned = deepClone(offerToSave)
  deleteAttributesRecursively(offerCloned, ['isDisable', 'isDisabled', 'config'])
  await buildProposalGuarantees(offerCloned.proposals);
  if (offerCloned.proposals[0].proposalItems[0].proposalGuarantees && offerCloned.proposals[0].proposalItems[0].proposalGuarantees.length > 0) {
    for (const proposalGuarantee of offerCloned.proposals[0].proposalItems[0].proposalGuarantees) {
      deleteNonRequiredAttributes(proposalGuarantee.productGuarantee, ['objectType', 'resourceUid', 'systemUid'])
      deleteNonRequiredAttributes(proposalGuarantee.associatedParty, ['objectType', 'resourceUid', 'systemUid'])
    }
  }
  if (offerCloned.proposals[0].proposalItems[0].contractDuration) {
    delete offerCloned.proposals[0].proposalItems[0].contractDuration
  }
  if (offerCloned.proposals[0].proposalItems[0].proposalAssets && offerCloned.proposals[0].proposalItems[0].proposalAssets.length > 0) {
    for (const proposalAsset of offerCloned.proposals[0].proposalItems[0].proposalAssets) {
      if (proposalAsset.party && proposalAsset.party.party && proposalAsset.party.party.resourceUid) {
        const associatedPartyResourceUid = await saveAssociatedParty(proposalAsset.party)
        proposalAsset.party = {
          resourceUid: associatedPartyResourceUid,
          objectType: 'odm.offer.associatedparty',
          systemUid: 'odm-offer'
        }
      }

      if (proposalAsset.usage && proposalAsset.usage.value) {
        proposalAsset.usage.resourceUid = proposalAsset.usage.value
        deleteNonRequiredAttributes(proposalAsset.usage, ['objectType', 'resourceUid', 'systemUid'])
      }
      if (proposalAsset.color && proposalAsset.color.value) {
        proposalAsset.color = proposalAsset.color.value
      }
      if (proposalAsset.proposalAssetCategories && proposalAsset.proposalAssetCategories.length !== 0) {
        proposalAsset.proposalAssetCategories = proposalAsset.proposalAssetCategories.filter((item: any) => item.category !== '' && item.categoryClass !== '');
        for (const proposalAssetCategorie of proposalAsset.proposalAssetCategories) {
          if (proposalAssetCategorie.category && proposalAssetCategorie.category.value) {
            proposalAssetCategorie.category = proposalAssetCategorie.category.value
          }
          else{
            delete proposalAssetCategorie.category 
          } 
          if (proposalAssetCategorie.categoryClass && proposalAssetCategorie.categoryClass.value) {
            proposalAssetCategorie.categoryClass = proposalAssetCategorie.categoryClass.value
          }
          else{
            delete  proposalAssetCategorie.categoryClass 
          }
        }
        if ( proposalAsset.proposalAssetCategories.length === 1 && Object.keys(proposalAsset.proposalAssetCategories[0]).length === 0 ) {
           delete  proposalAsset.proposalAssetCategories
        }

      }
      if (proposalAsset.assetDelivery && proposalAsset.assetDelivery.deliveryAddress && proposalAsset.assetDelivery.deliveryAddress !== "") {
        const assetDelivery = await getAssetDelivery(proposalAsset.assetDelivery)
        deleteNonRequiredAttributes(assetDelivery, ['objectType', 'resourceUid', 'systemUid'])
        proposalAsset.assetDelivery = assetDelivery
      }
      else {
        delete proposalAsset.assetDelivery
      }
    }
  }
  formatDateRecursively(offerCloned.associatedParties, 'DD/MM/YYYY', Masks.dateMask)
  await buildAssociatedParties(offerCloned)
  await saveOffer(offerCloned)
  const { offer } = await getOffer(offerCloned.resourceUid)
  return offer
}

export async function updateAnalysisFromTask(analysisToUpdate: Analysis, offerId?: string): Promise<Analysis> {
  store.state.analysisModule!.isLoading = true;
  try {
    const currentDate = new Date();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const year = currentDate.getFullYear();
    const currentDate1 = new Date(year, month - 1, day);
    const filtre = {
      resourceUid: analysisToUpdate.resourceUid
    };

    // Resolve historyRating promise
    const historyRating = await getHistoryRating(filtre);



    if (analysisToUpdate.analysisRatings) {
      analysisToUpdate.analysisRatings = analysisToUpdate.analysisRatings.map(rating => {
        delete rating.resourceUid;
        return {
          ...rating,
          validity: {
            ...rating.validity,
            from: currentDate.toISOString().split('T')[0],
          },
        };
      });
    }


    if (historyRating) {

      historyRating.data.forEach((rating: any) => {
        if (analysisToUpdate.analysisRatings) {
          const existingRatingIndex = analysisToUpdate.analysisRatings.findIndex((existingRating: any) => {
            return existingRating.updatedValue === rating.updatedvalue && existingRating.type === rating.typerating;
          });

          if (existingRatingIndex !== -1) {
            return analysisToUpdate.analysisRatings
          } else {
            // Add new rating from historyRating
            const transformedRating = {
              resourceUid: rating.id,
              objectType: 'odm.analysis.rating',
              systemUid: 'odm-analysis',
              businessData: null,
              daaq: 'null',
              calculatedValue: rating.calculatedvalue,
              updatedValue: rating.updatedvalue,
              type: rating.typerating,
              decision: '',
              comment: '',
              validity: {
                until: rating.validity_until ? rating.validity_until : currentDate.toISOString().split('T')[0],
                from: rating.validity_from
              },
            };

            // Remove properties not present in the desired structure
            delete rating.id;
            delete rating.whenmodified;
            delete rating.whomodified;

            // Push transformed rating into analysisToUpdate.analysisRatings
            if (!analysisToUpdate.analysisRatings) {
              analysisToUpdate.analysisRatings = [];
            }
            analysisToUpdate.analysisRatings.push(transformedRating);

          }
        }
      });
    }

    const analysisCloned = deepClone(analysisToUpdate);
    delete analysisCloned.score;
    delete analysisCloned.historyRating;

    await updateAnalysis(analysisCloned, offerId);

    return analysisCloned; // Return the entire analysisToUpdate object
  } catch (error) {
    // Handle errors appropriately
    console.error("Error in updateAnalysisFromTask:", error);
    throw error; // Rethrow the error to be handled by the caller
  }
}

export function groupAndSortRatings(ratings: any[]) {
  if (!ratings) return [];

  // Group ratings by typerating and sort within each group
  const groupedAndSortedRatings: any[] = [];
  const uniqueTypes = [...new Set(ratings.map(rating => rating.typerating))];
  uniqueTypes.forEach(type => {
    const ratingsOfType = ratings.filter(rating => rating.typerating === type);

    groupedAndSortedRatings.push({ type, ratings: ratingsOfType });
  });

  return groupedAndSortedRatings;
}
export function getGroupsByUserId(userId: string): Promise<ComboBox> {
  //https://api-dev.teamwill-digital.com/odm-user/api/1/odm-user/leaseforgeuser/ConnectedUserId/usergroup/
  const baseUrl = `${settings.api_url}/${odm_user}/api/1/${odm_user}/leaseforgeuser/${userId}/usergroup/`;
  return new Promise<ComboBox>(async (resolve, reject) => {
    try {
      let result: any[] = await (await api().get(`${baseUrl}`)).data;

      const promises = result.map(async item => await getAuthoritiesByGroup(item))
      await Promise.all(promises)
      result = result.filter(item => item.value)
      resolve({
        items: result.map((resItem: any) => {
          return {
            value: resItem.value,
            label: resItem.group.resourceUid,
          };
        }),
      });

    } catch (e) {
      reject(e);
    }
  });
}
export async function getAssetDelivery(assetDelivery: any) {
  try {
    const clone = deepClone(assetDelivery)
    if (clone.deliveryAddress && typeof clone.deliveryAddress === "string") {
      clone.deliveryAddress = {
        systemUid: UON.SystemUid.odmParty,
        objectType: UON.ObjectType.address,
        resourceUid: clone.deliveryAddress
      }
    }
   
    formatDateRecursively(clone, 'DD/MM/YYYY', Masks.dateMask)
    const body = clone
    const baseUrl = `${settings.api_url}/${odm_offer}/api/1/${odm_offer}/proposalassetdelivery/`;
    let result: any[]
    if (clone.resourceUid) {
      result = await (await api().put(`${settings.api_url}/${odm_offer}/api/1/${odm_offer}/proposalassetdelivery/${clone.resourceUid}/`, body)).data;
    } else {
      result = await (await api().post(`${baseUrl}`, body)).data;
    }


    return result
  } catch (e: any) {
    console.error(`ERROR => ${e.response.data.message}`)
  }
}

export async function getAuthoritiesByGroup(group: any) {
  //https://api-dev.teamwill-digital.com/odm-user/api/1/odm-user/securitygroup/{selected_Group}/groupauthority/
  try {
    const baseUrl = `${settings.api_url}/${odm_user}/api/1/${odm_user}/securitygroup/${group.group.resourceUid}/groupauthority/`;
    const result: any[] = await (await api().get(`${baseUrl}`)).data;

    const authorityValue: any[] = []
    result.map((resItem: any) => {
      authorityValue.push(resItem.authority.resourceUid)
    })
    group.value = authorityValue
    return group
  } catch (e: any) {
    group.value = ''
    console.error(`ERROR => ${e.response.data.message}`)
  }
}

export async function initWorkflow(offerParam: any) {
  const offer = deepClone(offerParam)
  const proposal = offer.proposals[0]
  const proposalItem = proposal.proposalItems[0]
  const accessoryProductInfo = proposalItem.proposalAccessories
    .map((item: any) => {
      const {
        amountWoTax,
        taxValue,
        basisValue,
        quantity,
        proposalAccessoryCalculationMethodOption,
        proposalAccessoryPaymentDatesOption
      } = item
      return {
        quantity,
        basis: basisValue,
        currency: amountWoTax.currency,
        paymentDatesOption: proposalAccessoryPaymentDatesOption.resourceUid,
        amount: amountWoTax,
      }
    })

  const assetList = proposal.proposalItems
    .map((item: any) => item.proposalAssets)
    .reduce((a: any, b: any) => {
      a.push(deepClone(b));
      return a
    }, [])

  const partyInfo = (offer.associatedParties || []).map((offerAssociatedParty: any) => {
    const { role_code } = offerAssociatedParty
    const { third } = offerAssociatedParty.associatedParty
    const type = third.type.id
    const body = type.includes("Organization") ? { ...third.organization } : { ...third.person }
    removeAttributesRecursively(body)
    deleteAttributesRecursively(body, ['isDisabled', 'config'])
    return {
      partyData: JSON.stringify({
        type,
        role: role_code || 'CLIENT',
        ...body
      })
    }
  })

  const assetInfo = assetList.map((asset: any) => {
    removeAttributesRecursively(asset)
    deleteAttributesRecursively(asset, ['isDisabled', 'config'])
    return {
      assetData: JSON.stringify(asset)
    }
  })
  const quoteInfo = deepClone(proposal)
  deleteAttributesRecursively(quoteInfo, ['isDisabled', 'config'])
  const offerObject = {
    partyInfo,
    assetInfo,
    accessoryProductInfo,
    quoteInfo,
  };
  const processDefinitionId = { processDefinitionId: settings.processDefinitionId }
  const variables = {
    variables: JSON.stringify([
      {
        variableName: "applicationName",
        value: "TWDDemo"
      },
      {
        variableName: "env",
        value: settings.env
      },
      {
        variableName: "offerReference",
        value: offer.reference
      },
      {
        variableName: "proposalReference",
        value: offer.proposalReference
      },
      {
        variableName: "daaq",
        value: offer.daaq || ''
      },
      {
        variableName: "offerCreationDate",
        value: offer.validity.from
      },
      {
        variableName: "associated_networks_id",
        value: typeof offer.associatedNetworks === 'string'
          ? offer.associatedNetworks
          : offer.associatedNetworks?.resourceUid || ''
      },
      {
        variableName: "_decisionAnalysisProcessId",
        value: typeof offer._decisionAnalysisProcessId === 'string'
          ? offer._decisionAnalysisProcessId
          : offer._decisionAnalysisProcessId?.resourceUid || ''
      },
      {
        variableName: "associated_network_nodes_id",
        value: typeof offer.associatedNetworkNodes === 'string'
          ? offer.associatedNetworkNodes
          : offer.associatedNetworkNodes?.resourceUid || ''
      },
      {
        variableName: "offerStatus",
        value: offer.status.resourceUid
      },
      {
        variableName: "offerPhase",
        value: offer.status.resourceUid
      },
      {
        variableName: "customerName",
        value: offer.associatedParties.filter((con: any) => con.associatedParty.role_code.includes('CUSTOMER'))[0].associatedParty.third.type.id === 'party-FrenchOrganization'
          ? offer.associatedParties.filter((con: any) => con.associatedParty.role_code.includes('CUSTOMER'))[0].associatedParty.third.name
          : `${offer.associatedParties.filter((con: any) => con.associatedParty.role_code.includes('CUSTOMER'))[0].associatedParty.third.firstName} ${offer.associatedParties.filter((con: any) => con.associatedParty.role_code.includes('CUSTOMER'))[0].associatedParty.third.familyName}`
      },
      {
        variableName: "customerRessourceUid",
        value: offer.associatedParties[0].associatedParty.third.resourceUid
      },
      {
        variableName: "customerReference",
        value: offer.associatedParties[0].associatedParty.third.reference
      },
      {
        variableName: "customerType",
        value: offer.associatedParties[0].associatedParty.third.type.id
      },
      {
        variableName: "customerBirthDate",
        value: offer.associatedParties[0].associatedParty.third.birthDate
      },
      {
        variableName: "offerId",
        value: offer.resourceUid
      },
      {
        variableName: "offer",
        value: JSON.stringify(offerObject)
      },
      {
        variableName: "module",
        value: 'Offer'
      },
    ])
  };
  return initWorkflowProcess(Object.assign({}, processDefinitionId, variables))
}
export async function takeChargeTask(taskId: any, userId: any) {
  const taskActionEvents: TaskActionEvent = {
    taskId: taskId,
    userId: userId
  }
  return claimTask(taskActionEvents);
}
export async function abandonTask(taskId: any, userId: any) {
  const taskActionEvents: TaskActionEvent = {
    taskId: taskId,
    userId: userId
  }
  return unClaimTask(taskActionEvents);
}


export const getProcess = async (processId: any) => {
  const baseUrl = `${settings.api_url}/${odm_supportingdocument}/api/1/${odm_supportingdocument}/`
  return new Promise<GatheringProcess>(async (resolve, reject) => {
    try {
      const result = await api().get(`${baseUrl}process/` + processId + '/')
      resolve(result.data)
    } catch (e) {
      reject(e)
    }
  });
}

export const getGhatheringProcessItemByDiscriminator = async (processId: string, discriminator: string) => {
  const process = await getProcess(processId)
  const gatheringProcessItem: any[] = process.gatheringProcessItem.filter(
    (item) => item.objectType.includes(discriminator)
  )

  return gatheringProcessItem
}

export const getGhatheringProcessItem = async (processId: string) => {
  const process = await getProcess(processId)
  return process
}

export const buildReserveOpinion = async (reserves: any) => {
  let listReserve: any[] = []
  reserves.map((el: any) => {
    const reserve = {
      config: el,
      value: el.resourceUid,
      label: el.reserveName
    };
    listReserve.push(reserve)
  })
  return listReserve
}



export const getWaitTask = async (taskFilter: any, taskDefinition?: TaskDefinition, count = 5, sleepTime = 2000) => {
  let attempt = 0
  let result
  while (attempt < count) {
    try {
      const response: any = await getTasks(taskFilter)
      if (response && response.data && response.data.length) {
        if (!taskDefinition) {
          result = response.data[0]
          break
        } else {
          if (response.data[0] && response.data[0].taskDefinition !== taskDefinition) {
            result = response.data[0]
            break
          }
        }
      }
    } catch (e) {
      console.error(e)
    } finally {
      attempt++
    }
    await sleep(sleepTime)
  }
  return result
}

export function getHistoryRating(filter: any): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    const url = settings.api_query_url;

    const qc_header: any = {
      qc: {
        queryId: 'analysis-rating-history-values',
        "offset": 0,
        "limit": 100,
      }
    }

    qc_header.qc.parameters = {
      ...filter
    }
    const operators = []

    if (filter.resourceUid) {
      operators.push(getOperator("lower(analysis_rating", "process_id)", "LIKE", globalConfig.formatters.getLikeValue(filter.resourceUid).toLowerCase(), "process_id"))
    }



    if (operators.length > 0) {
      qc_header.qc.otherFilters = {
        expressions: [
          {
            and: {
              operators
            }
          }
        ]
      }
    }
    const _headers = headers({
      qc: JSON.stringify(qc_header.qc)
    })
    axios.get(url, {
      headers: _headers
    }).then(res => {

      resolve(res)
    }).catch(err => reject(err))
  })
}

export function setNbrReceivedDocuments(row: any , taskDefinition: any) {
  let countQualified = 0;
  let countTotal =0
  row[0].entityTargets.forEach((entity: any) => {
    entity.documents.forEach((doc: any) => {
      if(doc.document.originalFileName || ((taskDefinition === TaskDefinition.ANALYSIS || taskDefinition === TaskDefinition.VALIDATE_DOCUMENTS || taskDefinition === TaskDefinition.MODIFY_CONDITIONS ) && doc.document.resourceUid))
        countQualified++;
      
      countTotal++
    })
  })
  return `${countQualified} / ${countTotal}`;
}

export function changeNbrReceeivedDoc (value: string) {
  let nbrReceeivedDoc = store.getters["taskModule/getNbrReceivedDocuments"];
  let [countQualified, countTotal] = nbrReceeivedDoc.split(" / ");
  if(value === 'INCREMENT'){
    countQualified = parseInt(countQualified) + 1;
  }else{
    countQualified = parseInt(countQualified) - 1;
  }
  nbrReceeivedDoc = `${countQualified} / ${countTotal}`;
  store.commit("taskModule/setNbrReceivedDocuments" , nbrReceeivedDoc);
}
