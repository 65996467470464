import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3424247e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tw-aside-tools-inner" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = {
  class: "card-header bg-white d-flex justify-content-between align-items-center mt-4",
  style: {"height":"50px","position":"relative"}
}
const _hoisted_4 = { class: "bold taskDef" }
const _hoisted_5 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_dialog, {
      modelValue: _ctx.visible,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.visible) = $event)),
      persistent: "",
      position: "right"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(`${_ctx.$t(`task.actions.${_ctx.statusHistoryVariableSelected?.taskDefinition}`)}`), 1),
            _createVNode(_component_q_icon, {
              name: "close",
              class: "cursor-pointer closeBTN",
              onClick: _ctx.closeStatusHistory
            }, null, 8, ["onClick"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_q_table, {
              rows: _ctx.rows,
              columns: _ctx.columns,
              "row-key": "id",
              "hide-pagination": "",
              pagination: _ctx.pagination,
              "onUpdate:pagination": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagination) = $event)),
              class: "shadow-0 table table-sm bg-white mb-0"
            }, {
              header: _withCtx((props) => [
                _createVNode(_component_q_tr, { props: props }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                      return (_openBlock(), _createBlock(_component_q_th, {
                        key: col.name,
                        props: props
                      }, {
                        default: _withCtx(() => [
                          (col.name !== 'actions')
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createTextVNode(_toDisplayString(_ctx.$t(col.label)), 1)
                              ], 64))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["props"]))
                    }), 128)),
                    _createVNode(_component_q_th, { "auto-width": "" })
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              body: _withCtx((props) => [
                _createVNode(_component_q_tr, { props: props }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                      return (_openBlock(), _createBlock(_component_q_td, {
                        key: col.name,
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(col.value), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              _: 1
            }, 8, ["rows", "columns", "pagination"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}