
import { Vue } from "vue-class-component";
import { Masks, globalConfig } from "@/utils";
import store from "@/store";
import moment from "moment";

import {
  formatDate,
} from "@/utils/configuration/formatters-config";
export default class RatingHistory extends Vue {
  componentToRenderSB = RatingHistory;
  data: any;
  reCalculation: any;
  showLabels = false;
  ratingsData: any[] = [];
  valueChanged: any;
  get columns() {
    return globalConfig.table_config.ratingHistoryCols;
  }
  get ratiosHistory() {
  const historyRating = store.state.analysisModule?.analysis?.historyRating || [];
  const ratings = this.$store.state.analysisModule?.analysis?.analysisRatings || [];
  return historyRating.map((item: any) => {
    return {
      title: item.type,
      rows: item.ratings.map((rating: any) => {
        const correspondingRating = ratings.find((r: any) => r.type === item.type);
        return {
          calculatedValue: 
            (rating.calculatedvalue === null || rating.calculatedvalue === "null") 
              ? correspondingRating?.decision 
              : rating.calculatedvalue,
          updatedValue: rating.updatedvalue,
          validityFrom: rating.validity_from,
          validityUntil: rating.validity_until,
          whenModified: rating.whenmodified,
          whoModified: rating.whomodified,
        };
        })
    };
  });
  
}
  formatDate(date: string) {
    return formatDate(date);
  }
  formatSystemTimeStamp = (date: string) => {
    const formattedDate = new Date(moment(date).format(Masks.dateMask));
    return moment(formattedDate.setTime(new Date().getTime())).format(
      Masks.timestampMask_Sys
    );
  };
  
  get historyRating() {
    return this.$store.state.analysisModule?.analysis?.analysisRatings;
  }
  get ratingsDecision() {
    const historyRating = this.$store.state.analysisModule?.analysis?.historyRating
      ? this.$store.state.analysisModule?.analysis?.historyRating
      : [];

    this.$store.state.analysisModule?.analysis?.analysisRatings?.forEach((rating) => {
      const correspondingHistory = historyRating.find(
        (history: any) => history.type === rating.type
      );
      if (correspondingHistory) {
        const correspondingHistoryRating =
          correspondingHistory.ratings[correspondingHistory.ratings.length - 1];
        rating.updatedValue = correspondingHistoryRating.updatedvalue; 
      }
    });
    return this.$store.state.analysisModule?.analysis?.analysisRatings;
  }


}

