import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e166a438"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card p-2 d-flex justify-content-between flex-row align-items-center mb-2" }
const _hoisted_2 = {
  class: "d-flex justify-between align-items-center",
  style: {"gap":"30px"}
}
const _hoisted_3 = { class: "d-flex flex-column" }
const _hoisted_4 = { class: "text-muted" }
const _hoisted_5 = { class: "d-flex flex-column" }
const _hoisted_6 = { class: "text-muted" }
const _hoisted_7 = { class: "d-flex flex-column" }
const _hoisted_8 = { class: "text-muted" }
const _hoisted_9 = { class: "full-width row flex-center text-accent q-gutter-sm" }
const _hoisted_10 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_DocumentsPanel = _resolveComponent("DocumentsPanel")!
  const _component_UploadConditionsDocuments = _resolveComponent("UploadConditionsDocuments")!
  const _component_q_spinner_facebook = _resolveComponent("q-spinner-facebook")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createBlock(_component_q_table, {
    class: "shadow-0 table bg-white mb-0",
    bordered: "",
    rows: _ctx.gatheringProcessList,
    "hide-pagination": ""
  }, {
    header: _withCtx((props) => [
      _createVNode(_component_q_tr, { props: props }, {
        default: _withCtx(() => [
          _createVNode(_component_q_th, { "auto-width": "" }),
          _createVNode(_component_q_th, { class: "font-size-13" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("task.validateDocument.headers.checkListAcceptation")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_q_th, { class: "font-size-13" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("task.validateDocument.headers.documentsToProcess")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_q_th, { class: "font-size-13" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("task.validateDocument.headers.documentsValid")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_q_th, { class: "font-size-13" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("task.validateDocument.headers.status")), 1)
            ]),
            _: 1
          })
        ]),
        _: 2
      }, 1032, ["props"])
    ]),
    body: _withCtx((props) => [
      _createVNode(_component_q_tr, { props: props }, {
        default: _withCtx(() => [
          _createVNode(_component_q_td, { "auto-width": "" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                class: "btn btn-sm btn-primary",
                size: "sm",
                color: "accent",
                round: "",
                dense: "",
                onClick: ($event: any) => (props.expand = !props.expand),
                icon: props.expand ? 'remove' : 'add'
              }, null, 8, ["onClick", "icon"])
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_q_td, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('middleOffice.documents.labels.list')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_q_td, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(`${_ctx.gatheringProcessDocuments(props.row)} `), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_q_td, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(`${_ctx.gatheringProcessDocumentsAccepted(props.row)} `), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_q_td, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(`${_ctx.$t(`task.validateDocument.headers.gatheringProcessStatus.${props.row.status}`)}`), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1032, ["props"]),
      _withDirectives(_createVNode(_component_q_tr, {
        props: props,
        class: "tw-expanded-table-row"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_td, { colspan: "100%" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.entityTargets, (targetEntity, i) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "card-body p-0",
                  key: i
                }, [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_q_btn, {
                        class: "btn btn-sm btn-primary",
                        size: "sm",
                        color: "accent",
                        round: "",
                        dense: "",
                        icon: targetEntity.expanded ? 'remove' : 'add',
                        onClick: ($event: any) => (targetEntity.expanded = !targetEntity.expanded)
                      }, null, 8, ["icon", "onClick"]),
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('demand.documents.columns.reference')), 1),
                        _createElementVNode("span", null, _toDisplayString(targetEntity.reference), 1)
                      ]),
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('demand.documents.columns.type')), 1),
                        _createElementVNode("span", null, _toDisplayString(_ctx.setType(targetEntity.objectType)), 1)
                      ]),
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('demand.documents.columns.information')), 1),
                        _createElementVNode("span", null, _toDisplayString(targetEntity.information) + " (" + _toDisplayString(_ctx.setRoles(targetEntity.resourceUid)) + ")", 1)
                      ])
                    ])
                  ]),
                  _createVNode(_component_DocumentsPanel, {
                    targetEntity: targetEntity,
                    gpRowIndex: props.rowIndex
                  }, null, 8, ["targetEntity", "gpRowIndex"])
                ]))
              }), 128)),
              (_ctx.currentRoute === 'TaskDetail')
                ? (_openBlock(), _createBlock(_component_UploadConditionsDocuments, {
                    key: 0,
                    "gathering-process-list-item": props.row
                  }, null, 8, ["gathering-process-list-item"]))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1032, ["props"]), [
        [_vShow, props.expand]
      ])
    ]),
    "no-data": _withCtx(() => [
      _createElementVNode("div", _hoisted_9, [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_q_spinner_facebook, {
              key: 0,
              size: "3em",
              color: "primary"
            }))
          : _createCommentVNode("", true),
        (!_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.EmptyGatheringProcessListMessage), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["rows"]))
}