import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withModifiers as _withModifiers, vShow as _vShow, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-201319d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "card tw-custom-tabs-nav" }
const _hoisted_3 = { class: "card-body pt-0 pb-0" }
const _hoisted_4 = { class: "mt-3" }
const _hoisted_5 = {
  key: 0,
  class: "card mb-3"
}
const _hoisted_6 = { class: "card-title flex-grow-1 d-flex justify-content-between align-items-center mb-0" }
const _hoisted_7 = { class: "d-flex flex-column" }
const _hoisted_8 = {
  key: 0,
  class: "font-size-14 text-muted"
}
const _hoisted_9 = {
  key: 1,
  class: "font-size-14 text-muted"
}
const _hoisted_10 = { class: "font-size-14 font-medium" }
const _hoisted_11 = { class: "card-body pt-0" }
const _hoisted_12 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DemandRegister = _resolveComponent("DemandRegister")!
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_spinner_facebook = _resolveComponent("q-spinner-facebook")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_DemandTabThird = _resolveComponent("DemandTabThird")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!
  const _component_q_tab_panel = _resolveComponent("q-tab-panel")!
  const _component_DemandAssetTab = _resolveComponent("DemandAssetTab")!
  const _component_Financing = _resolveComponent("Financing")!
  const _component_DemandTabFinancing = _resolveComponent("DemandTabFinancing")!
  const _component_DocumentManagment = _resolveComponent("DocumentManagment")!
  const _component_Avis = _resolveComponent("Avis")!
  const _component_Decisions = _resolveComponent("Decisions")!
  const _component_Study = _resolveComponent("Study")!
  const _component_q_tab_panels = _resolveComponent("q-tab-panels")!
  const _component_term_dialog = _resolveComponent("term-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_ctx.offer)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.currentRoute !== 'TaskDetail')
          ? (_openBlock(), _createBlock(_component_DemandRegister, {
              key: 0,
              parties: _ctx.associatedParties,
              isSaveLoading: _ctx.isSaveLoading,
              onRegisterClick: _ctx.onSaveClick
            }, null, 8, ["parties", "isSaveLoading", "onRegisterClick"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_q_tabs, {
              modelValue: _ctx.tab,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
              align: "left",
              "inline-label": "",
              "active-color": "primary",
              "indicator-color": "primary"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_tab, {
                  class: "primary mr-3",
                  icon: "people",
                  name: "tiers",
                  label: _ctx.$t('demand.tabs.tiers')
                }, null, 8, ["label"]),
                _createVNode(_component_q_tab, {
                  class: "primary mr-3",
                  icon: "dashboard_customize",
                  name: "asset",
                  label: _ctx.$t('demand.tabs.asset')
                }, null, 8, ["label"]),
                _createVNode(_component_q_tab, {
                  class: "primary mr-3",
                  icon: "calculate",
                  name: "financing",
                  label: _ctx.$t('demand.tabs.financing')
                }, null, 8, ["label"]),
                (_ctx.currentRoute === 'TaskDetail')
                  ? (_openBlock(), _createBlock(_component_q_tab, {
                      key: 0,
                      class: "primary mr-3",
                      icon: "description",
                      name: "documents",
                      label: _ctx.$t('demand.tabs.documents')
                    }, null, 8, ["label"]))
                  : _createCommentVNode("", true),
                (_ctx.taskDefinition === 'STEP_START_ANALYSIS')
                  ? (_openBlock(), _createBlock(_component_q_tab, {
                      key: 1,
                      class: "primary mr-3 d-none",
                      icon: "calculate",
                      name: "avis",
                      label: _ctx.$t('Analysis.Avis')
                    }, null, 8, ["label"]))
                  : _createCommentVNode("", true),
                (_ctx.taskDefinition === 'STEP_MODIFY_CONDITIONS')
                  ? (_openBlock(), _createBlock(_component_q_tab, {
                      key: 2,
                      class: "primary mr-3",
                      icon: "gavel",
                      name: "decision",
                      label: _ctx.$t('demand.tabs.decision')
                    }, null, 8, ["label"]))
                  : _createCommentVNode("", true),
                (_ctx.taskDefinition === 'STEP_START_ANALYSIS')
                  ? (_openBlock(), _createBlock(_component_q_tab, {
                      key: 3,
                      disable: _ctx.isLoading,
                      class: _normalizeClass(["primary mr-3", _ctx.isLoading ? 'tw-hard-disabled-content' : '']),
                      icon: "dashboard_customize",
                      name: "study",
                      label: _ctx.$t('task.tabs.study')
                    }, {
                      default: _withCtx(() => [
                        (_ctx.isLoading)
                          ? (_openBlock(), _createBlock(_component_q_spinner_facebook, {
                              key: 0,
                              size: "2em",
                              color: "primary",
                              class: "tw-overlap-spinner"
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["disable", "class", "label"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_q_tab_panels, {
            modelValue: _ctx.tab,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.tab) = $event)),
            animated: "",
            "transition-prev": "slide",
            "transition-next": "slide",
            "keep-alive": "",
            onBeforeTransition: _cache[3] || (_cache[3] = ($event: any) => (_ctx.ValidateMandatoryFields()))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_tab_panel, {
                name: "tiers",
                class: "p-0"
              }, {
                default: _withCtx(() => [
                  (_ctx.currentRoute !== 'TaskDetail')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createVNode(_component_q_btn, {
                          icon: "add",
                          class: "btn btn-action shadow-0 p-4",
                          label: _ctx.$t('demand.button.addTier'),
                          disable: _ctx.$store.getters['authModule/hasMiddleOffice']
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_tooltip, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("demand.button.addTier")), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_q_menu, {
                              anchor: "bottom right",
                              self: "top right"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_list, {
                                  style: {"min-width":"100px"},
                                  dense: ""
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getRole, (role, index) => {
                                      return _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                        clickable: "",
                                        key: index
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_q_item_section, {
                                            style: {"margin-left":"1em","margin-right":"1em"},
                                            onClick: ($event: any) => {
                        _ctx.addInternThirdTab(role);
                        _ctx.internTab = _ctx.associatedParties?.length;
                      }
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(role.label), 1)
                                            ]),
                                            _: 2
                                          }, 1032, ["onClick"])
                                        ]),
                                        _: 2
                                      }, 1024)), [
                                        [_directive_close_popup]
                                      ])
                                    }), 128))
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["label", "disable"])
                      ]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_q_list, {
                    modelValue: _ctx.internTab,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.internTab) = $event))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.associatedParties, (offerAssociatedParty, index) => {
                        return (_openBlock(), _createBlock(_component_q_expansion_item, {
                          key: index,
                          "switch-toggle-side": "",
                          "header-class": "card-header bg-transparent no-border p-4",
                          class: "card tw-items-dashed-group tw-level-one-group"
                        }, {
                          header: _withCtx(() => [
                            _createElementVNode("div", _hoisted_6, [
                              _createElementVNode("div", _hoisted_7, [
                                (
                        _ctx.currentRoute === 'TaskDetail' &&
                        _ctx.taskDefinition === 'STEP_START_ANALYSIS'
                      )
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t(
                          "demand.party.roles." +
                          (offerAssociatedParty.tabName?.toLocaleUpperCase() ||
                            offerAssociatedParty.associatedParty?.role_code?.toLocaleUpperCase())
                        )), 1))
                                  : (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.$t(
                      "demand.party.roles." +
                      (offerAssociatedParty.tabName?.toLocaleUpperCase() ||
                      offerAssociatedParty.associatedParty?.role_code?.toLocaleUpperCase())
                      )), 1)),
                                _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.thirdName(offerAssociatedParty.associatedParty.third)), 1)
                              ]),
                              _withDirectives(_createVNode(_component_q_btn, {
                                icon: "delete",
                                class: "btn btn-outline-gray-1",
                                onClick: _withModifiers(($event: any) => {
                      _ctx.removeAssociatedParty(index, offerAssociatedParty.associatedParty);
                      _ctx.internTab = _ctx.associatedParties.length;
                    }, ["stop"])
                              }, null, 8, ["onClick"]), [
                                [_vShow, offerAssociatedParty.showDeleteBtn]
                              ])
                            ])
                          ]),
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_11, [
                              _createVNode(_component_DemandTabThird, {
                                party: offerAssociatedParty.associatedParty.third,
                                associatedParty: offerAssociatedParty.associatedParty,
                                role: offerAssociatedParty,
                                associatedPartyId: offerAssociatedParty.id
                              }, null, 8, ["party", "associatedParty", "role", "associatedPartyId"])
                            ])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_q_tab_panel, {
                name: "asset",
                class: "p-0"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DemandAssetTab)
                ]),
                _: 1
              }),
              _createVNode(_component_q_tab_panel, {
                name: "financing",
                class: "p-0"
              }, {
                default: _withCtx(() => [
                  (_ctx.screenStatus)
                    ? (_openBlock(), _createBlock(_component_Financing, {
                        key: 0,
                        proposals: _ctx.proposals
                      }, null, 8, ["proposals"]))
                    : _createCommentVNode("", true),
                  (!_ctx.screenStatus)
                    ? (_openBlock(), _createBlock(_component_DemandTabFinancing, { key: 1 }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              (_ctx.currentRoute === 'TaskDetail')
                ? (_openBlock(), _createBlock(_component_q_tab_panel, {
                    key: 0,
                    name: "documents",
                    class: "p-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DocumentManagment)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.currentRoute === 'TaskDetail')
                ? (_openBlock(), _createBlock(_component_q_tab_panel, {
                    key: 1,
                    name: "avis",
                    class: "p-0 d-none"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Avis)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.taskDefinition === 'STEP_MODIFY_CONDITIONS')
                ? (_openBlock(), _createBlock(_component_q_tab_panel, {
                    key: 2,
                    name: "decision",
                    class: "p-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Decisions)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.currentRoute === 'TaskDetail')
                ? (_openBlock(), _createBlock(_component_q_tab_panel, {
                    key: 3,
                    name: "study",
                    class: "p-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Study, { ref: "study" }, null, 512)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        (_ctx.showDialog)
          ? (_openBlock(), _createBlock(_component_term_dialog, {
              key: 1,
              openDialog: _ctx.openDialog,
              onOnClose: _ctx.onClose
            }, null, 8, ["openDialog", "onOnClose"]))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_12, [
        _createVNode(_component_q_spinner_facebook, {
          size: "2em",
          color: "primary"
        })
      ]))
}