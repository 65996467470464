
import { Options, Vue } from "vue-class-component";
import store from "@/store";
import { reactive, Ref, ref } from "vue";
import { ComboBox, EvaluateTaxRate, FinancingProductPackInfo, SelectComboBox } from "@/types";
import { groupBy } from "@/utils";
import SimulationScaleItem from "@/components/demand/simulation/normal-mode/SimulationScaleItem.vue";
import { formatDateService } from "@/utils/configuration/formatters-config";

@Options({
  components: {
    SimulationScaleItem,
  },
  watch: {
    "$store.state.simulationModule.toShowScaleBox"(newValue) {
      this.toShowScaleBox = newValue;
    },
    selected(newValue, oldValue) {
      if (newValue && oldValue) {
        const value = newValue[0];
        if (value && value.sliderConfig) {
          store.dispatch("simulationModule/changeSliderConfig", {
            label: value.name,
            sliderConfig: value.sliderConfig,
            scale: value,
          });
        }
      }
    },
    financingProductModel(value: any) {
      this.calculationTypeSelected = "HRS";
      if (value.label === "LOA") {
        this.searchProductTax("FR_TAX");
      } else if (value.label === "LLD") {
        this.searchProductTax("TN_TAX");
      } else {
        this.searchProductTax("FR_TAX_EXO");
      }
      store.dispatch("simulationModule/setScalesVisible", value);
    },
  },
})
export default class SimulationScales extends Vue {
  expanded = ref(false);
  financingProductModel: SelectComboBox = reactive({
    type: {
      value: "NULL",
    },
  });
  toShowScaleBox = true;

  taxCode?: string | undefined;
  taxGeographicZone?: any;
  country?: string | undefined;
  evaluationDate: string | Ref<string> | undefined;

  groupBy = groupBy;

  filter: any = ref("");
  selected: any = ref([]);

  get packsCombobox() {
    return store.getters["financingModule/packsCombobox"];
  }

  get isInitialState() {
    return store.getters["financingModule/isInitialState"];
  }

  get financingProduct() {
    return store.state.picklistModule.financingProduct?.items;
  }

  showBoxScales() {
    store.state.simulationModule.toShowScaleBox = !store.state.simulationModule
      .toShowScaleBox;
  }

  get financingProductSelected() {
    return store.state.simulationModule.financingProductSelected;
  }

  get scaleGroup() {
  const categoryClass = store.state.financingModule.categoryClassSelected;
  const scalesGroup = (categoryClass && categoryClass !== "NULL"
    ? this.scales.filter((s) => s.categoryClass.indexOf(categoryClass) !== -1)
    : this.scales
  )
  .filter(s => !s.scalePresentation.loadingError)
  .sort(
    (a: FinancingProductPackInfo, b: FinancingProductPackInfo) => 
      (a.scalePresentation.index || 0) - (b.scalePresentation.index || 0)
  );
  return scalesGroup;
}


  get scales() {
    return store.state.financingModule.scalesModule.scales;
  }

  get scaleSelected() {
    return store.state.financingModule.scalesModule.scaleSelected;
  }
  get third() {
    return store.state.thirdModule.third;
  }

  get startDate() {
    if (store.state.demandModule.offer?.validity) {
      const startDate = store.state.demandModule.offer?.validity.from;
      return startDate ? startDate : ref(formatDateService(new Date(), "-")).value;
    }
  }

  isScaleSelected(scale: FinancingProductPackInfo) {
    return this.scaleSelected?.resourceUid === scale.resourceUid;
  }

  searchProductTax(code: string) {
    store.dispatch("demandModule/searchProductTax", {
      code: code,
      callback: this.updateProductTax,
    });
  }
  updateProductTax(tax: any) {
    store.state.simulationModule.productTax = tax;
}

  onSelectScale(item: FinancingProductPackInfo) {
    const type = this.third?.type?.id
    const body = type?.includes("Organization") ? { ...this.third?.organization } : { ...this.third?.person }
    this.taxGeographicZone = body.taxGeographicZone
    this.taxCode = this.scaleSelected?.taxOnInterest
    if (type?.includes("Organization")) {
      this.country = this.third?.organization?.addresses && this.third?.organization?.addresses?.length !== 0 ? this.third?.organization?.addresses[0]?.address.postalCode?.country?.resourceUid : "FRA"
    } else {
      this.country = this.third?.addresses && this.third?.addresses?.length !== 0 ? this.third?.addresses[0].address?.postalCode?.country?.resourceUid : "FRA"
    }
    this.evaluationDate = this.startDate

    const requestBody = {
      objectType: "",
      systemUid: "odm-product",
      resourceUid: "",
      businessData: {},
      country: this.country,
      geographicZone: this.taxGeographicZone,
      evaluationDate: this.evaluationDate,
      daaq: ""
    };
    if (this.taxCode && this.country && this.taxGeographicZone && this.evaluationDate)
      store.dispatch("demandModule/setEvaluatedTaxRate", {
        taxCode: this.taxCode, requestBody, callback: (result: EvaluateTaxRate[]) => {
          if (this.scaleSelected?.productName)
            this.scaleSelected.productTax.fixedValue = result[0].taxRate
        }
      });
    store
      .dispatch("financingModule/scalesModule/setScaleSelected", { scale: item })
      .then(() => store.dispatch("financingModule/setSimulationChanged", false));
  }

  filterScales(value: any) {
    store.dispatch("financingModule/setCategoryClassSelected", value);
  }
}
