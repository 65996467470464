import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "col-md-12" }
const _hoisted_2 = { class: "row tw-reserves-panels align-items-center border-bottom border-left-0 pb-2 mb-2" }
const _hoisted_3 = { class: "tw-reserve-title font-medium" }
const _hoisted_4 = { class: "tw-reserve-actions" }
const _hoisted_5 = { class: "tw-reserve-description text-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_toggle = _resolveComponent("q-toggle")!

  return ((_ctx.reserves.length > 0))
    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.reserves, (item, index) => {
        return (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, [
                _createVNode(_component_q_toggle, {
                  "model-value": _ctx.reserves[index].statusReserve ? _ctx.reserves[index].statusReserve.resourceUid : null,
                  "true-value": _ctx.ReserveStatusItem.APPROVED,
                  "false-value": _ctx.ReserveStatusItem.DECLINED,
                  "checked-icon": "check",
                  color: "green",
                  "unchecked-icon": "clear",
                  "onUpdate:modelValue": ($event: any) => (_ctx.changeReserveStatus($event, index, _ctx.entryIndex))
                }, null, 8, ["model-value", "true-value", "false-value", "onUpdate:modelValue"])
              ]),
              _createElementVNode("span", null, _toDisplayString(_ctx.reserveTraduction(item.reserve.resourceUid)) + " : ", 1)
            ]),
            _createElementVNode("div", _hoisted_5, _toDisplayString(item.comment), 1)
          ])
        ]))
      }), 256))
    : _createCommentVNode("", true)
}