
  import { Options, Vue } from "vue-class-component";
  import store from "@/store";
  import { removeExpandedItem } from "@/utils";
  import { ComboBoxItem, PartyClassification } from "@/types";
  import { formatDate, formatDateLocale, formatDateServiceAddDays, formatDateToMoment } from "@/utils/configuration/formatters-config";
  @Options({
    computed: {
      isClassificationBtnDisabled: function () {
        return !this.classificationBtn;
      },
    },
    props: ["selectedClassifications"],
  })
  export default class TabContact extends Vue {
    classificationBtn = false;
    dateMask = "DD/MM/YYYY";
    removeExpandedItem = removeExpandedItem;
    startDate!: string;
    partyClassificationItems: [ComboBoxItem[]] = [[]];
    selectedClassifications?: PartyClassification[];

    get partyClassificationTypeItems() {
      return store.getters["picklistModule/partyClassificationTypeItems"]
    }
  
    get partyClassificationInit() {
      return store.getters["thirdModule/getPartyClassificationInit"];
    }
  
    edit(value: { isDisabled: boolean }) {
      value.isDisabled = !value.isDisabled;
    }
  
    addExpandClassification(items: PartyClassification[]) {
      if (items && items.length === 0) {
        const element = store.getters["thirdModule/getPartyClassificationInit"];
        this.selectedClassifications?.push(element);
      }
    }
    add<T>(items: T[]) {
      const getter = store.getters["thirdModule/getPartyClassificationInit"];
      items.push(getter);
    }
  
    remove<T>(index: number, items: T[]) {
      if (items && items.length > 0) {
        items.splice(index, 1);
      }
      for (let i = index; i < items.length; i++) {
      this.partyClassificationItems[i] = this.partyClassificationItems[i+1];
    }
    this.partyClassificationItems[items.length] = [];
    }
    formatDateUntil(value: any) {
      value.validity.until = value.validity.until.includes("/")
        ? formatDateLocale(value.validity.until, "DD/MM/YYYY", "fr", "DD/MM/YYYY")
        : formatDateLocale(value.validity.until, "DDMMYYYY", "fr", "DD/MM/YYYY");
    }
    formatDateFrom(value: any) {
      value.validity.from = value.validity.from.includes("/")
        ? formatDateLocale(value.validity.from, "DD/MM/YYYY", "fr", "DD/MM/YYYY")
        : formatDateLocale(value.validity.from, "DDMMYYYY", "fr", "DD/MM/YYYY");
    }
    formatDate(date: string) {
      return formatDateLocale(date, this.dateMask, "fr", "YYYY/MM/DD");
    }
    onChangeType(classType: any, index: number) {
      const type = classType;
      store.dispatch("thirdModule/getPartyClass", {
        callback: (res: any) => this.updateModel(res,index),
        filter: {
          class_type_code: type
        },
      });
  
      let startDate =''
      this.selectedClassifications?.forEach((classification: any) => {
        if(classification.classType&&(classification.classType===type) && classification.partyClass.type.value){
          startDate=classification.validity.until ?classification.validity.until :''
        }
      })
      const getter = store.getters["thirdModule/getPartyClassificationInit"];
      getter.validity.from=startDate===''?'':formatDate(formatDateServiceAddDays(1,formatDateToMoment(startDate)))
      getter.classType=type
      if(this.selectedClassifications ){
        this.selectedClassifications[index]=getter;
      }
    }
    updateModel(res: any,index: number) {
    this.partyClassificationItems[index] = res.items;
  }
    getDisable(value:any){
      const getResourceUid = store.getters["thirdModule/getResourceUid"]
      if(value.validity.from && value.validity.from!='' && value.validity.until && value.validity.until!='' && getResourceUid){
        const list =this.selectedClassifications?.filter((el: any) => {
            if(el.classType===value.classType)
              return el
          
      });
      if(list&& list.length>1 && value.validity.from!==value.validity.until){
        return true
      }else{
        return false
      }
      }else{
        return false
      }
      
    }
    testStartDate(date:any,value:any){
      let startDate=''
      this.selectedClassifications?.forEach((classification: any) => {
        if(classification.classType&&(classification.classType===value.classType) && classification.partyClass.type.value&&classification.validity.from){
          startDate=classification.validity.until ?classification.validity.until :classification.validity.from
        }
      })
      if(startDate!==''){
        return (date > this.formatDate(startDate)+1 )
      }
      return true
    }
    testEndDate(date:any,value:any){
      let startDate=''
      this.selectedClassifications?.forEach((classification: any) => {
        if(classification.classType&&(classification.classType===value.classType)){
          startDate=classification.validity.from
        }
      })
      if(startDate!==''&& value.validity.from!==startDate){
        return (date < this.formatDate(startDate)+1 )
      }
      return true
    }
    onChangeStartDate(value:any){
      this.selectedClassifications?.forEach((classification: any) => {
        if(classification.classType==value.classType && classification.validity.until=='' && classification.validity.from!==value.validity.from){
          classification.validity.until= formatDate(formatDateServiceAddDays(0,formatDateToMoment(value.validity.from)))
        }
      })
      value
  
    }
    onChangeEndDate(value:any){
      this.selectedClassifications?.forEach((classification: any) => {
        if(classification.classType==value.classType && classification.validity.from==''){
          classification.validity.from= formatDate(formatDateServiceAddDays(1,formatDateToMoment(value.validity.until)))
        }
      })
      value
  
    }
    created() {
      if (this.selectedClassifications && this.selectedClassifications?.length > 0) {
      for (let i = 0; i < this.selectedClassifications.length; i++) {
        const type = this.selectedClassifications[i].classType;
        store.dispatch("thirdModule/getPartyClass", {
        callback: (res: any) => this.updateModel(res,i),
        filter: {
          class_type_code: type
        },
      });
      }
    }
  }
  }
  