
import { Options, Vue } from "vue-class-component";

import OdmSliderGroup from "@/components/general/OdmSliderGroup.vue";
import { ref } from "vue";
import store from "@/store";
import i18n from "@/i18n";
import {
  deleteFormatMaskAndConvertToNumber,
  formatAmoutswitchCurrency,
  formatWithMask,
  getGraceTimePeriod,
} from "@/utils/configuration/formatters-config";

@Options({
  components: {
    OdmSliderGroup,
  },
  watch: {
    calculationTypeSelected(value: string) {
      store.dispatch("simulationModule/changeFinancingTypeTTC", {
        isTTC: value === "TTC",
      });
    },
  },
})
export default class DemandSimulationForm extends Vue {
  calculationTypeSelected: string | undefined = "HRS";
  get gracePeriod() {
    return this.presentation?.gracePeriod;
  }
  get gracePeriodPresentation() {
    const result:string =this.presentation?.gracePeriodPresentation ? i18n.global.t(this.presentation?.gracePeriodPresentation, {value: this.gracePeriod, period: this.gracePeriodUnit}) : "-"
    return result ;
  }

  get period() {
    const result: string = this.presentation?.period ? i18n.global.t('main.labels.'.concat(this.presentation?.period)) : "-"
    return result ;
  }
  get gracePeriodUnit(){
    return i18n.global.t(`main.labels.${getGraceTimePeriod(this.presentation?.periodBetween2Installments)}`);
  }
  get presentation() {
    return this.scaleSelected?.scalePresentation
  }

  get financingProductSelected() {
    return store.state.simulationModule.financingProductSelected;
  }

  get isPriceEditable() {
    return store.state.simulationModule.isPriceEditable;
  }

  get scaleSelectedCategoryClass() {
    return this.scaleSelected ? this.scaleSelected.categoryClass : "";
  }
  get priceWithTax() {
   if ( store.state.financingModule.proposalAssetTotalWithTax)
    return formatAmoutswitchCurrency(
        "EUR", deleteFormatMaskAndConvertToNumber(store.state.financingModule.proposalAssetTotalWithTax.toString())
    );
  }
  get priceWOTax() {
    if ( store.state.financingModule.proposalAssetItemsTotalWoTax)
    return formatAmoutswitchCurrency(
        "EUR", deleteFormatMaskAndConvertToNumber(store.state.financingModule.proposalAssetItemsTotalWoTax.toString())
    );
  }

  get scaleSelected() {
    return store.state.financingModule.scalesModule.scaleSelected;
  }

  get sliders() {
    return this.scaleSelected?.scalePresentation.sliders || [];
  }

  get productName() {
    return this.scaleSelected?.productName || "-";
  }

  get simulationPrice() {
    return formatAmoutswitchCurrency(
      "EUR",
      store.getters["financingModule/simulationPrice"]
    );
  }


}
