
import { Options, Vue } from "vue-class-component";
import DecisionsPanel from "@/components/middle-office/tabs/decision/DecisionsPanel.vue";
import { Offer } from "@/types";
import i18n from "@/i18n";
import moment from "moment";

@Options({
  components: { DecisionsPanel },
  props: ["decisionsStep"],
})
export default class Decision extends Vue {
  opened = true;
  decisionsStep?: [];

  get EmptyDecisionListMessage() {
    return i18n.global.t("middleOffice.decision.empty");
  }

  getDateFormat(date: any){
      return moment(date).format("DD/MM/YYYY HH:mm") || ' - '
  }
}
