<template>
  <q-input
      ref="inputRef"
      v-model="displayValue"
      outlined
      dense
      input-class="text-right"
      style="font-family: sans-serif"
  />
</template>

<script>
import { useCurrencyInput } from 'vue-currency-input';
import { computed, ref, watch } from 'vue';
import { formatAmoutswitchCurrency } from '@/utils/configuration/formatters-config';
import i18n from "@/i18n";

export default {
  name: 'twCurrentInput',
  props: {
    modelValue: Number,
    options: Object,
  },

  setup(props) {
    const { inputRef, numberValue, setValue } = useCurrencyInput(props.options);
    const locale = ref(i18n.global.locale.value);
    const displayValue = ref(formatAmoutswitchCurrency('EUR', props.modelValue || 0));

    const errorMessage = computed(() =>
        numberValue.value <= 100 ? "Value must be greater than 100" : undefined
    );

    watch(
        () => props.modelValue,
        (value) => {
          setValue(value);
          displayValue.value = formatAmoutswitchCurrency('EUR', value || 0);
        }
    );

    watch(
        () => i18n.global.locale.value,
        (newLocale) => {
          locale.value = newLocale;
          displayValue.value = formatAmoutswitchCurrency('EUR', props.modelValue || 0);
        }
    );
    return { inputRef, displayValue, errorMessage };
  },
};
</script>
