
import { Options, Vue } from "vue-class-component";
import LabelAndValue from "@/components/general/LabelAndValue.vue";
import { formatAmoutswitchCurrency, formatWithMask } from "@/utils/configuration/formatters-config";
import i18n from "@/i18n";

@Options({
  components: {
    LabelAndValue,
  },
  props: ["proposal", "valueStyle", "labelStyle", "divValueStyle"],
})
export default class SummaryLease extends Vue {
  proposal?: any;
  valueStyle?: any;
  labelStyle?: any;
  divValueStyle?: any;

  get price() {
    return `${formatAmoutswitchCurrency('EUR', this.proposal.financedValue)} `;
  }
  get monthly() {
    return `${formatAmoutswitchCurrency('EUR', this.proposal.financialPaymentWTax)} `;
  }
  get amountRefunded() {
    return !this.proposal.sumOfTotalPayments
      ? "-"
      : `${formatAmoutswitchCurrency('EUR', this.proposal.sumOfTotalPayments.amount)} `;
  }
  get duration() {
    return `${this.proposal.numberOfPaymentTerm} ` + this.durationUnit(undefined,'duration');
  }
  get tax() {
    return `${formatWithMask(this.proposal.nominalRate, 2)} %`;
  }
  get residualValue() {
    return !this.proposal.residualValueAmountWTax
      ? "-"
      : `${formatWithMask(this.proposal.residualValueAmountWTax, 2)} || ${
          this.proposal.residualValuePercentage
        } %`;
  }
  get firstPayment() {
    return !this.proposal.firstPaymentWTax
      ? "-"
      : `${formatWithMask(this.proposal.firstPaymentWTax, 2)} || ${
          this.proposal.firstPaymentPercentage
        } %`;
  }

  camelSentence(str?: string) {
    return  (" " + str).toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, function(match, chr)
    {
      return chr.toUpperCase();
    });
  }

  get frequency() {
    return this.durationUnit('frequency').replace(/[\])[(]/g, '');
  }

  get gracePeriodTerms() {
    // TODO TP-3015
    return !this.proposal.defaultNumberGracePeriodTerms ? "-" : formatWithMask(this.proposal.defaultNumberGracePeriodTerms, 2)
  }

  get gracePeriodTermsType() {
    // TODO TP-3015
    return !this.proposal.gracePeriodTermsType ? "-" : this.proposal.gracePeriodTermsType
  }

  get gracePeriodTermsTax() {
    // TODO TP-3015
    return !this.proposal.gracePeriodTermsTax ? "-" : `${formatWithMask(this.proposal.gracePeriodTermsTax, 2)} %`
  }



  durationUnit(type?: string, context?: any) {
  const basePath = 'main.labels.sliders';
  const duration = this.proposal.periodBetween2Installments.duration;
  const unit = this.proposal.periodBetween2Installments.unit;
  const numberOfPaymentTerm = this.proposal.numberOfPaymentTerm;

  const getLabel = (suffix: string, extraParams: object = {}) =>
    i18n.global.t(`${basePath}.${suffix}`, { ...extraParams, period2: this.camelSentence(unit) });

  const durationSuffix = (context === 'duration') 
    ? `${duration}_months.slider` 
    : `${duration}_months.slider_frequency`;

  if ([1, 3, 6, 12].includes(duration)) {
    return getLabel(durationSuffix);
  }

  const params = { duration, param2: numberOfPaymentTerm };
  if (type) {
    return getLabel('default.slider', params);
  } else {
    return i18n.global.t(`${basePath}.default.label`, { ...params, period: this.camelSentence(unit) });
  }
}


}
