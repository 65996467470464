
import { Options, Vue } from "vue-class-component";
import store from "@/store";
import { useRoute } from "vue-router";
import {AssetImage, businessDataUtils, ProposalAssetCar, ProposalAssetTruck} from "@/types"; // Adjust the import paths as necessary
import { downloadFile} from "@/store/services/offerService";
import { ref } from "vue";

@Options({
  props: ["tabPosition"],
  watch: {
    "$store.state.demandModule.assetState.toShowAssetBox"(newValue) {
      this.toShowAssetBox = newValue;
    },

    "$store.state.demandModule.assetState.proposalAssets": {
      handler: async function (newValue) {
        const index = this.tabPosition || 0;
     
        if (newValue && newValue[index] && newValue[index].config &&
          newValue[index].config.productAssetModel &&
          newValue[index].config.productAssetModel.config.images) {
          const file = await this.getAssetImage(); 
          this.fileData = file
          this.showImage = true;
        }
      },
      deep: true, // Ensures deep watching for nested objects/arrays
      immediate: true // Trigger immediately on component creation/mount
    }

  },
})
export default class DemandModelVehicleBox extends Vue {
  logoImage = require("@/assets/images/Car.svg");
  toShowAssetBox = true;
  tabPosition?: number;
  fileData?: any = ref(null);
  showImage?: boolean = false;
  businessDataUtils = businessDataUtils;

  get currentRoute() {
    return useRoute().name;
  }

  get asset(): ProposalAssetCar | ProposalAssetTruck {
    return store.state.demandModule.assetState.proposalAssets[this.tabPosition || 0];
  }

  get productValues() {
    const productAssetCalculated = this.asset?.config?.proposalAssetCalculated;
    if (productAssetCalculated) {
      return productAssetCalculated.product;
    } else {
      return {
        name: "",
        unitaryPrice: 0,
        discount: 0,
        tax: 0,
        priceWithTax: 0.0,
      };
    }
  }


  isAssetImage(image: any): image is AssetImage {
    return image && typeof image.content === 'string' && typeof image.format === 'string';
  }

  get imageStatus() {
    return this.showImage
  }
  get imageData() {
    return this.fileData
  }

  async created() {
    this.fileData = await this.getAssetImage()
    this.showImage = true;
  }

  async getAssetImage() {
    const assetProductImage = this.asset?.config?.productAssetModel?.config?.images?.[0]?.fileId?.resourceUid;
    let fileImage;
    let base64Image;
    if (assetProductImage) {
      try {
        fileImage = await downloadFile(assetProductImage);
        base64Image = `data:${fileImage.format};base64,${fileImage.content}`

      } catch (error) {
        base64Image = this.logoImage
        console.error("Error downloading file:", error);
        // Handle the error accordingly, e.g., you can set images to null or log the error

      }
      return base64Image
    }
    else {
      const assetImage = this.asset?.image;
      if (this.isAssetImage(assetImage)) {
       return fileImage = `data:${assetImage.format};base64,${assetImage.content}`;
      }
      else {
        return fileImage = this.logoImage
      }
    }



  }
  get offer() {
    return store.state.demandModule?.offer;
  }

}
