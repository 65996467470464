import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GoodOrderFilter = _resolveComponent("GoodOrderFilter")!
  const _component_GoodOrderList = _resolveComponent("GoodOrderList")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, {
    class: _normalizeClass(["q-page q-layout-padding items-start pt-0", true ? 'p-0' : '']),
    style: true ? 'min-height: unset;' : ''
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["bg-white col-lg-12 col-md-12 col-sm-12 col-xl-12 default-border-radius q-layout-padding", true ? 'p-0' : '']),
          id: "SimulationLayout"
        }, [
          _createVNode(_component_GoodOrderFilter, { onCallGoodListRows: _ctx.callGoodListRows }, null, 8, ["onCallGoodListRows"]),
          _createVNode(_component_GoodOrderList, {
            ref: "assetListRows",
            mainView: _ctx.mainView
          }, null, 8, ["mainView"])
        ])
      ])
    ]),
    _: 1
  }))
}