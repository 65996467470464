
import i18n from "@/i18n";
import store from "@/store";
import { isFilterDAAQMatching } from "@/store/services/filter/filterService";
import { Options, Vue } from "vue-class-component";

@Options({
  props: ["query"],
})
export default class FilterList extends Vue {
  get isLoading() {
    return store.getters["filterModule/getIsLoading"];
  }

  get listOfFilters() {
    return store.getters["filterModule/listOfFilters"];
  }

  get currentUserDAAQ() {
    return store.getters["authModule/getDefaultDAAQConfig"];
  }

  /**
   * Searches for order based on the filter selected.
   * @return {void}
   */
  onSelectedFilter(filter: any) {
    store.dispatch("filterModule/setSelectedFilter", filter);
  }

  onDeleteFilter(filter: any) {
    store.dispatch("showConfirmationDeleteDialog", {
      message: i18n.global.t('main.filter.deleteMsg', {
        filter: filter.description
    }),
      deleteAction: 'QueryFilter',
      payload: filter,
      confirmation: true
    })
  }

  onEditFilter(filter: any) {
    store.dispatch("filterModule/editSelectedFilter", filter);
  }

  checkFilterActionPermission(isActionAllowed: boolean, filterDaaq: string, hasPublicAccess?:boolean): boolean {
    const { readDAAQ, defaultCreateDAAQ } = this.currentUserDAAQ;
    return isActionAllowed && isFilterDAAQMatching(filterDaaq, { readDAAQ, defaultCreateDAAQ }, hasPublicAccess);
  }
}
