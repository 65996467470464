import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "col-lg-4 col-md-4 col-sm-12 tw-analysis-comment-inner"
}
const _hoisted_2 = {
  class: "form-control d-flex flex-column form-control h-100 no-border",
  id: "demand_edit_asset_vehicle_comment"
}
const _hoisted_3 = { class: "d-block mb-0 small text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!

  return (_ctx.isAnalysisSection)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("main.labels.comment")), 1),
          _createVNode(_component_q_input, {
            modelValue: _ctx.itemText,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.itemText) = $event)),
            outlined: "",
            type: "textarea"
          }, null, 8, ["modelValue"])
        ])
      ]))
    : _createCommentVNode("", true)
}