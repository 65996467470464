import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10652f91"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "card border-0 pt-3 pr-3"
}
const _hoisted_2 = {
  class: "bg-primary d-flex default-border-radius justify-content-between text-white overflow-hidden",
  style: {"min-height":"7rem"}
}
const _hoisted_3 = { class: "d-flex flex-column justify-content-between p-3" }
const _hoisted_4 = { class: "font-size-14 font-weight-bold mb-3" }
const _hoisted_5 = { class: "d-flex align-items-center" }
const _hoisted_6 = { class: "mb-0" }
const _hoisted_7 = { class: "font-medium font-size-14 m-0" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "mb-0 ml-5" }
const _hoisted_10 = { class: "font-medium font-size-14 m-0 ml-5" }
const _hoisted_11 = { class: "p-3 text-center" }
const _hoisted_12 = { class: "mb-0" }
const _hoisted_13 = { class: "text-right" }
const _hoisted_14 = {
  class: "font-medium h6 m-0 tw-formattedNumberFont",
  id: "inputPriceHT"
}
const _hoisted_15 = { class: "text-left" }
const _hoisted_16 = {
  class: "font-medium h6 m-0 tw-formattedNumberFont ml-1",
  id: "inputPriceHT"
}
const _hoisted_17 = { class: "text-right" }
const _hoisted_18 = {
  class: "font-medium h6 m-0 tw-formattedNumberFont",
  id: "inputPriceTTC"
}
const _hoisted_19 = { class: "text-left" }
const _hoisted_20 = {
  class: "font-medium h6 m-0 tw-formattedNumberFont ml-1",
  id: "inputPriceTTC"
}
const _hoisted_21 = { class: "p-3 bg-blue-8 text-right" }
const _hoisted_22 = { class: "mb-0" }
const _hoisted_23 = {
  class: "font-medium h5 m-0 tw-formattedNumberFont",
  id: "inputPrice"
}
const _hoisted_24 = { class: "card-body p-0 pt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OdmSliderGroup = _resolveComponent("OdmSliderGroup")!

  return (_ctx.sliders)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.productName), 1),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", null, [
                _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("middleOfficeFinancingForm.label.financingProduct")), 1),
                _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.scaleSelectedCategoryClass), 1)
              ]),
              (_ctx.gracePeriod)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('middleOfficeFinancingService.label.gracePeriod')), 1),
                    _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.gracePeriodPresentation), 1)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t("demand.simulation.bien.label")), 1),
            _createElementVNode("table", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("td", _hoisted_13, [
                  _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.priceWOTax), 1)
                ]),
                _createElementVNode("td", _hoisted_15, [
                  _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.$t('middleOfficeFinancingService.label.withOutTax')), 1)
                ])
              ]),
              _createElementVNode("tr", null, [
                _createElementVNode("td", _hoisted_17, [
                  _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.priceWithTax), 1)
                ]),
                _createElementVNode("td", _hoisted_19, [
                  _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.$t('middleOfficeFinancingService.label.withTax')), 1)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.$t("demand.simulation.form.label")), 1),
            _createElementVNode("p", _hoisted_23, _toDisplayString(_ctx.simulationPrice), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_24, [
          (_ctx.sliders)
            ? (_openBlock(), _createBlock(_component_OdmSliderGroup, {
                key: 0,
                sliders: _ctx.sliders
              }, null, 8, ["sliders"]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}