import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "card-title flex-grow-1 d-flex justify-content-between align-items-center mb-0" }
const _hoisted_3 = { class: "d-flex flex-column" }
const _hoisted_4 = { class: "font-size-14 text-dark" }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = { class: "row form-group" }
const _hoisted_7 = {
  class: "col-lg-6 form-control no-border",
  id: "demand_edit_asset_vehicle_generalInformations_category"
}
const _hoisted_8 = { class: "d-block mb-0 small text-muted" }
const _hoisted_9 = {
  class: "col-lg-6 form-control no-border",
  id: "demand_edit_asset_vehicle_generalInformations_subCategory"
}
const _hoisted_10 = { class: "d-block mb-0 small text-muted" }
const _hoisted_11 = {
  class: "col-lg-6 form-control no-border",
  id: "demand_edit_asset_vehicle_generalInformations_usage"
}
const _hoisted_12 = { class: "d-block mb-0 small text-muted" }
const _hoisted_13 = {
  class: "col-lg-6 form-control no-border",
  id: "demand_edit_asset_vehicle_generalInformations_type"
}
const _hoisted_14 = { class: "d-block mb-0 small text-muted" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = {
  class: "col-lg-6 form-control no-border",
  id: "demand_edit_asset_vehicle_generalInformations_quantity"
}
const _hoisted_17 = { class: "d-block mb-0 small text-muted" }
const _hoisted_18 = {
  class: "col-lg-6 form-control no-border",
  id: "demand_edit_asset_vehicle_generalInformations_brand"
}
const _hoisted_19 = { class: "d-block mb-0 small text-muted" }
const _hoisted_20 = {
  class: "col-lg-6 form-control no-border",
  id: "demand_edit_asset_vehicle_generalInformations_model"
}
const _hoisted_21 = { class: "d-block mb-0 small text-muted" }
const _hoisted_22 = {
  class: "col-lg-6 form-control no-border",
  id: "demand_edit_asset_vehicle_generalInformations_engine"
}
const _hoisted_23 = { class: "d-block mb-0 small text-muted" }
const _hoisted_24 = {
  class: "col-lg-6 form-control no-border",
  id: "demand_edit_asset_vehicle_generalInformations_description"
}
const _hoisted_25 = { class: "d-block mb-0 small text-muted" }
const _hoisted_26 = {
  class: "col-lg-6 form-control no-border",
  id: "demand_edit_asset_vehicle_generalInformations_bodywork"
}
const _hoisted_27 = { class: "d-block mb-0 small text-muted" }
const _hoisted_28 = {
  class: "col-lg-6 form-control no-border",
  id: "demand_edit_asset_vehicle_generalInformations_energy"
}
const _hoisted_29 = { class: "d-block mb-0 small text-muted" }
const _hoisted_30 = {
  class: "col-lg-6 form-control no-border",
  id: "demand_edit_asset_vehicle_generalInformations_power"
}
const _hoisted_31 = { class: "d-block mb-0 small text-muted" }
const _hoisted_32 = {
  class: "col-lg-6 form-control no-border",
  id: "demand_edit_asset_vehicle_generalInformations_emmissionCO2"
}
const _hoisted_33 = { class: "d-block mb-0 small text-muted" }
const _hoisted_34 = {
  class: "col-lg-6 form-control no-border",
  id: "demand_edit_asset_vehicle_generalInformations_vehicleLength"
}
const _hoisted_35 = { class: "d-block mb-0 small text-muted" }
const _hoisted_36 = {
  class: "col-lg-6 form-control no-border",
  id: "demand_edit_asset_vehicle_generalInformations_doorsNumber"
}
const _hoisted_37 = { class: "d-block mb-0 small text-muted" }
const _hoisted_38 = {
  class: "col-lg-6 form-control no-border",
  id: "demand_edit_asset_vehicle_generalInformations_color"
}
const _hoisted_39 = { class: "d-block mb-0 small text-muted" }
const _hoisted_40 = { class: "card-title flex-grow-1 d-flex justify-content-between align-items-center mb-0" }
const _hoisted_41 = { class: "d-flex flex-column" }
const _hoisted_42 = { class: "font-size-14 text-dark" }
const _hoisted_43 = { class: "card-body" }
const _hoisted_44 = { class: "row form-group" }
const _hoisted_45 = {
  class: "col-lg-6 form-control no-border",
  id: "demand_edit_asset_vehicle_characteristics_serialNumber"
}
const _hoisted_46 = { class: "d-block mb-0 small text-muted" }
const _hoisted_47 = {
  class: "col-lg-6 form-control no-border",
  id: "demand_edit_asset_vehicle_characteristics_currentMileage"
}
const _hoisted_48 = { class: "d-block mb-0 small text-muted" }
const _hoisted_49 = {
  class: "col-lg-6 form-control no-border",
  id: "demand_edit_asset_vehicle_characteristics_constructionDate"
}
const _hoisted_50 = { class: "d-block mb-0 small text-muted" }
const _hoisted_51 = { class: "row items-center justify-end" }
const _hoisted_52 = {
  class: "col-lg-6 form-control no-border",
  id: "demand_edit_asset_vehicle_characteristics_guaranteeStartingDate"
}
const _hoisted_53 = { class: "d-block mb-0 small text-muted" }
const _hoisted_54 = { class: "row items-center justify-end" }
const _hoisted_55 = {
  class: "col-lg-6 form-control no-border",
  id: "demand_edit_asset_vehicle_characteristics_guaranteeEndingDate"
}
const _hoisted_56 = { class: "d-block mb-0 small text-muted" }
const _hoisted_57 = { class: "row items-center justify-end" }
const _hoisted_58 = {
  class: "col-lg-6 form-control no-border",
  id: "demand_edit_asset_vehicle_characteristics_matriculation"
}
const _hoisted_59 = { class: "d-block mb-0 small text-muted" }
const _hoisted_60 = {
  class: "col-lg-6 form-control no-border",
  id: "demand_edit_asset_vehicle_characteristics_matriculationDate"
}
const _hoisted_61 = { class: "d-block mb-0 small text-muted" }
const _hoisted_62 = { class: "row items-center justify-end" }
const _hoisted_63 = { class: "card-title flex-grow-1 d-flex justify-content-between align-items-center mb-0" }
const _hoisted_64 = { class: "d-flex flex-column" }
const _hoisted_65 = { class: "font-size-14 text-dark" }
const _hoisted_66 = { class: "card-body" }
const _hoisted_67 = { class: "row form-group" }
const _hoisted_68 = {
  class: "col-lg-6 form-control no-border",
  id: "demand_edit_asset_vehicle_deliveryDetails_deliveryDate"
}
const _hoisted_69 = { class: "d-block mb-0 small text-muted" }
const _hoisted_70 = { class: "row items-center justify-end" }
const _hoisted_71 = {
  class: "col-lg-6 form-control no-border",
  id: "demand_edit_asset_vehicle_deliveryDetails_supplier"
}
const _hoisted_72 = { class: "d-block mb-0 small text-muted" }
const _hoisted_73 = {
  class: "col-lg-12 form-control no-border",
  id: "demand_edit_asset_vehicle_deliveryDetails_address"
}
const _hoisted_74 = { class: "d-block mb-0 small text-muted" }
const _hoisted_75 = {
  key: 1,
  class: "border-0 card mb-3 bg-light"
}
const _hoisted_76 = { class: "card-header bg-light no-border" }
const _hoisted_77 = {
  class: "card-title font-medium text-dark",
  id: "demand_edit_asset_vehicle_title"
}
const _hoisted_78 = { class: "card-body" }
const _hoisted_79 = { class: "row" }
const _hoisted_80 = { class: "row form-group" }
const _hoisted_81 = {
  class: "col-lg-3 form-control no-border",
  id: "demand_edit_asset_vehicle_quantity"
}
const _hoisted_82 = { class: "d-block mb-0 small text-muted" }
const _hoisted_83 = {
  class: "col-lg-9 form-control no-border",
  id: "demand_edit_asset_vehicle_vendor"
}
const _hoisted_84 = { class: "d-block mb-0 small text-muted" }
const _hoisted_85 = { class: "row form-group" }
const _hoisted_86 = {
  class: "col-lg-6 form-control no-border",
  id: "demand_edit_asset_vehicle_brand"
}
const _hoisted_87 = { class: "d-block mb-0 small text-muted" }
const _hoisted_88 = {
  class: "col-lg-6 form-control no-border",
  id: "demand_edit_asset_vehicle_model"
}
const _hoisted_89 = { class: "d-block mb-0 small text-muted" }
const _hoisted_90 = {
  class: "col-lg-6 form-control no-border",
  id: "demand_edit_asset_vehicle_engine"
}
const _hoisted_91 = { class: "d-block mb-0 small text-muted" }
const _hoisted_92 = {
  class: "col-lg-6 form-control no-border",
  id: "demand_edit_asset_vehicle_description"
}
const _hoisted_93 = { class: "d-block mb-0 small text-muted" }
const _hoisted_94 = {
  class: "col-lg-6 form-control no-border",
  id: "demand_edit_asset_vehicle_bodyWork"
}
const _hoisted_95 = { class: "d-block mb-0 small text-muted" }
const _hoisted_96 = {
  class: "col-lg-6 form-control no-border",
  id: "demand_edit_asset_vehicle_energy"
}
const _hoisted_97 = { class: "d-block mb-0 small text-muted" }
const _hoisted_98 = {
  class: "col-lg-6 form-control no-border",
  id: "demand_edit_asset_vehicle_power"
}
const _hoisted_99 = { class: "d-block mb-0 small text-muted" }
const _hoisted_100 = {
  class: "col-lg-6 form-control no-border",
  id: "demand_edit_asset_vehicle_emissionCO2"
}
const _hoisted_101 = { class: "d-block mb-0 small text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_radio = _resolveComponent("q-radio")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_third_search_dialog = _resolveComponent("third-search-dialog")!
  const _component_AddressSearchDialog = _resolveComponent("AddressSearchDialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.currentRoute === 'TaskDetail')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_q_expansion_item, {
            "model-value": "true",
            "switch-toggle-side": "",
            "header-class": "card-header bg-transparent no-border p-4",
            class: "card-title font-medium text-dark mb-0"
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("good.goodDetails.goodGeneralInfo.generalInformations")), 1)
                ])
              ])
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t("demand.asset.category")), 1),
                    _createVNode(_component_q_select, {
                      "data-testid": "demand_edit_asset_vehicle_generalInformations_category",
                      disable: _ctx.isFormReadOnly,
                      dense: "",
                      outlined: "",
                      modelValue: _ctx.asset.proposalAssetCategories[0].category.value,
                      "onUpdate:modelValue": [
                        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.asset.proposalAssetCategories[0].category.value) = $event)),
                        _cache[1] || (_cache[1] = ($event: any) => (_ctx.onChangeCategory($event)))
                      ],
                      "display-value": `${_ctx.asset.proposalAssetCategories[0].category.value ? _ctx.asset.proposalAssetCategories[0].category.value.label : '-'}`,
                      options: _ctx.categoryList
                    }, null, 8, ["disable", "modelValue", "display-value", "options"])
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("demand.asset.subCategory")), 1),
                    _createVNode(_component_q_select, {
                      "data-testid": "demand_edit_asset_vehicle_generalInformations_subCategory",
                      disable: _ctx.isFormReadOnly,
                      dense: "",
                      outlined: "",
                      modelValue: _ctx.asset.proposalAssetCategories[0].categoryClass.value,
                      "onUpdate:modelValue": [
                        _cache[2] || (_cache[2] = ($event: any) => ((_ctx.asset.proposalAssetCategories[0].categoryClass.value) = $event)),
                        _cache[3] || (_cache[3] = ($event: any) => (_ctx.onChangeSubCategory($event)))
                      ],
                      "display-value": `${_ctx.asset.proposalAssetCategories[0].categoryClass.value ?_ctx.asset.proposalAssetCategories[0].categoryClass.value.label : '-'}`,
                      options: _ctx.subCategoryList
                    }, null, 8, ["disable", "modelValue", "display-value", "options"])
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t("demand.asset.usage")), 1),
                    _createVNode(_component_q_select, {
                      "data-testid": "demand_edit_asset_vehicle_generalInformations_usage",
                      dense: "",
                      outlined: "",
                      disable: _ctx.isFormReadOnly,
                      modelValue: _ctx.asset.usage,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.asset.usage) = $event)),
                      options: _ctx.usageAssetlist,
                      "display-value": `${_ctx.asset.usage ? _ctx.asset.usage.label : '-'}`
                    }, null, 8, ["disable", "modelValue", "options", "display-value"])
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t("main.type")), 1),
                    _createElementVNode("div", _hoisted_15, [
                      _createVNode(_component_q_radio, {
                        "data-testid": "demand_edit_asset_vehicle_generalInformations_VN",
                        id: "demand_edit_asset_vehicle_generalInformations_VN",
                        modelValue: _ctx.flagNew,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.flagNew) = $event)),
                        dense: "",
                        val: "vn",
                        label: _ctx.$t('asset.vehicleType.vn'),
                        class: "mr-3",
                        disable: _ctx.isFormReadOnly
                      }, null, 8, ["modelValue", "label", "disable"]),
                      _createVNode(_component_q_radio, {
                        "data-testid": "demand_edit_asset_vehicle_generalInformations_VO",
                        id: "demand_edit_asset_vehicle_generalInformations_VO",
                        modelValue: _ctx.flagNew,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.flagNew) = $event)),
                        dense: "",
                        val: "vo",
                        label: _ctx.$t('asset.vehicleType.vo'),
                        disable: _ctx.isFormReadOnly
                      }, null, 8, ["modelValue", "label", "disable"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t("demand.asset.vehicle.quantity")), 1),
                    _createVNode(_component_q_input, {
                      "data-testid": "demand_edit_asset_vehicle_generalInformations_quantity",
                      dense: "",
                      outlined: "",
                      modelValue: _ctx.quantity,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.quantity) = $event)),
                      modelModifiers: { number: true },
                      type: "number",
                      min: "1",
                      onChange: _ctx.onQuantityChange,
                      disable: _ctx.isFormReadOnly
                    }, null, 8, ["modelValue", "onChange", "disable"])
                  ]),
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t("demand.asset.vehicle.brand")), 1),
                    _createVNode(_component_q_select, {
                      "data-testid": "demand_edit_asset_vehicle_generalInformations_brand",
                      disable: _ctx.isFormReadOnly,
                      dense: "",
                      outlined: "",
                      modelValue: _ctx.asset.config.formBind.brand,
                      "onUpdate:modelValue": [
                        _cache[8] || (_cache[8] = ($event: any) => ((_ctx.asset.config.formBind.brand) = $event)),
                        _cache[9] || (_cache[9] = ($event: any) => (_ctx.onChangeBrand($event)))
                      ],
                      options: _ctx.brandList
                    }, null, 8, ["disable", "modelValue", "options"])
                  ]),
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t("demand.asset.vehicle.model")), 1),
                    _createVNode(_component_q_select, {
                      "data-testid": "demand_edit_asset_vehicle_generalInformations_model",
                      disable: _ctx.isFormReadOnly,
                      dense: "",
                      outlined: "",
                      modelValue: _ctx.asset.config.formBind.range,
                      "onUpdate:modelValue": [
                        _cache[10] || (_cache[10] = ($event: any) => ((_ctx.asset.config.formBind.range) = $event)),
                        _cache[11] || (_cache[11] = ($event: any) => (_ctx.onChangeModel($event)))
                      ],
                      options: _ctx.modelList
                    }, null, 8, ["disable", "modelValue", "options"])
                  ]),
                  _createElementVNode("div", _hoisted_22, [
                    _createElementVNode("label", _hoisted_23, _toDisplayString(_ctx.$t("demand.asset.vehicle.engine")), 1),
                    _createVNode(_component_q_select, {
                      "data-testid": "demand_edit_asset_vehicle_generalInformations_engine",
                      disable: _ctx.isFormReadOnly,
                      dense: "",
                      outlined: "",
                      modelValue: _ctx.asset.config.formBind.version,
                      "onUpdate:modelValue": [
                        _cache[12] || (_cache[12] = ($event: any) => ((_ctx.asset.config.formBind.version) = $event)),
                        _cache[13] || (_cache[13] = ($event: any) => (_ctx.onChangeVersion($event)))
                      ],
                      options: _ctx.versionList
                    }, null, 8, ["disable", "modelValue", "options"])
                  ]),
                  _createElementVNode("div", _hoisted_24, [
                    _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.$t("demand.asset.vehicle.description")), 1),
                    _createVNode(_component_q_input, {
                      "data-testid": "demand_edit_asset_vehicle_generalInformations_description",
                      disable: _ctx.isFormReadOnly,
                      dense: "",
                      outlined: "",
                      modelValue: _ctx.asset.description,
                      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.asset.description) = $event))
                    }, null, 8, ["disable", "modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_26, [
                    _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.$t("demand.asset.bodywork")), 1),
                    _createVNode(_component_q_select, {
                      "data-testid": "demand_edit_asset_vehicle_generalInformations_bodywork",
                      disable: _ctx.isFormReadOnly,
                      dense: "",
                      outlined: "",
                      modelValue: _ctx.asset.config.formBind.carBodyStyle,
                      "onUpdate:modelValue": [
                        _cache[15] || (_cache[15] = ($event: any) => ((_ctx.asset.config.formBind.carBodyStyle) = $event)),
                        _cache[16] || (_cache[16] = ($event: any) => (_ctx.onChangeBodyStyle($event)))
                      ],
                      options: _ctx.bodyCarList
                    }, null, 8, ["disable", "modelValue", "options"])
                  ]),
                  _createElementVNode("div", _hoisted_28, [
                    _createElementVNode("label", _hoisted_29, _toDisplayString(_ctx.$t("demand.asset.energy")), 1),
                    _createVNode(_component_q_select, {
                      "data-testid": "demand_edit_asset_vehicle_generalInformations_energy",
                      disable: _ctx.isFormReadOnly,
                      dense: "",
                      outlined: "",
                      modelValue: _ctx.asset.config.formBind.engineType,
                      "onUpdate:modelValue": [
                        _cache[17] || (_cache[17] = ($event: any) => ((_ctx.asset.config.formBind.engineType) = $event)),
                        _cache[18] || (_cache[18] = ($event: any) => (_ctx.onChangeEnergy($event)))
                      ],
                      options: _ctx.energyList
                    }, null, 8, ["disable", "modelValue", "options"])
                  ]),
                  _createElementVNode("div", _hoisted_30, [
                    _createElementVNode("label", _hoisted_31, _toDisplayString(_ctx.$t("demand.asset.vehicle.power")), 1),
                    _createVNode(_component_q_input, {
                      "data-testid": "demand_edit_asset_vehicle_generalInformations_power",
                      disable: _ctx.isFormReadOnly,
                      dense: "",
                      outlined: "",
                      modelValue: _ctx.asset.horsePower,
                      "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.asset.horsePower) = $event))
                    }, null, 8, ["disable", "modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_32, [
                    _createElementVNode("label", _hoisted_33, _toDisplayString(_ctx.$t("demand.asset.vehicle.emissionCO2")), 1),
                    _createVNode(_component_q_input, {
                      "data-testid": "demand_edit_asset_vehicle_generalInformations_emmissionCO2",
                      disable: _ctx.isFormReadOnly,
                      dense: "",
                      outlined: "",
                      modelValue: _ctx.asset.co2Emissions,
                      "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.asset.co2Emissions) = $event)),
                      type: "number"
                    }, null, 8, ["disable", "modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_34, [
                    _createElementVNode("label", _hoisted_35, _toDisplayString(_ctx.$t("demand.asset.length")), 1),
                    _createVNode(_component_q_input, {
                      "data-testid": "demand_edit_asset_vehicle_generalInformations_vehicleLength",
                      disable: _ctx.isFormReadOnly,
                      dense: "",
                      outlined: "",
                      modelValue: _ctx.asset.vehicleLength,
                      "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.asset.vehicleLength) = $event)),
                      type: "number",
                      append: ".example.com"
                    }, null, 8, ["disable", "modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_36, [
                    _createElementVNode("label", _hoisted_37, _toDisplayString(_ctx.$t("demand.asset.doorsNumber")), 1),
                    _createVNode(_component_q_input, {
                      "data-testid": "demand_edit_asset_vehicle_generalInformations_doorsNumber",
                      disable: _ctx.isFormReadOnly,
                      dense: "",
                      outlined: "",
                      modelValue: _ctx.asset.nbOfDoor,
                      "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.asset.nbOfDoor) = $event)),
                      type: "number"
                    }, null, 8, ["disable", "modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_38, [
                    _createElementVNode("label", _hoisted_39, _toDisplayString(_ctx.$t("demand.asset.color")), 1),
                    _createVNode(_component_q_select, {
                      "data-testid": "demand_edit_asset_vehicle_generalInformations_color",
                      disable: _ctx.isFormReadOnly,
                      dense: "",
                      outlined: "",
                      modelValue: _ctx.asset.color,
                      "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.asset.color) = $event)),
                      options: _ctx.colorsList
                    }, null, 8, ["disable", "modelValue", "options"])
                  ])
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_q_expansion_item, {
            "switch-toggle-side": "",
            "header-class": "card-header bg-transparent no-border p-4",
            class: "card-title font-medium text-dark mb-0"
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_40, [
                _createElementVNode("div", _hoisted_41, [
                  _createElementVNode("div", _hoisted_42, _toDisplayString(_ctx.$t("demand.asset.characteristics")), 1)
                ])
              ])
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_43, [
                _createElementVNode("div", _hoisted_44, [
                  _createElementVNode("div", _hoisted_45, [
                    _createElementVNode("label", _hoisted_46, _toDisplayString(_ctx.$t("demand.asset.serialNumber")), 1),
                    _createVNode(_component_q_input, {
                      "data-testid": "demand_edit_asset_vehicle_characteristics_serialNumber",
                      dense: "",
                      outlined: "",
                      modelValue: _ctx.asset.serialNumber,
                      "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.asset.serialNumber) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_47, [
                    _createElementVNode("label", _hoisted_48, _toDisplayString(_ctx.$t("demand.asset.currentMileage")), 1),
                    _createVNode(_component_q_input, {
                      "data-testid": "demand_edit_asset_vehicle_characteristics_currentMileage",
                      dense: "",
                      outlined: "",
                      modelValue: _ctx.asset.currentMileage,
                      "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.asset.currentMileage) = $event)),
                      type: "number"
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_49, [
                    _createElementVNode("label", _hoisted_50, _toDisplayString(_ctx.$t("demand.asset.constructionDate")), 1),
                    _createVNode(_component_q_input, {
                      "data-testid": "demand_edit_asset_vehicle_characteristics_constructionDate",
                      class: "tw-date-picker-event",
                      readonly: "",
                      dense: "",
                      outlined: "",
                      modelValue: _ctx.asset.constructionDate,
                      "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.asset.constructionDate) = $event))
                    }, {
                      append: _withCtx(() => [
                        _createVNode(_component_q_icon, {
                          "data-testid": "demand_edit_asset_vehicle_characteristics_constructionDate_icon",
                          name: "event",
                          class: "cursor-pointer"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_popup_proxy, {
                              "data-testid": "demand_edit_asset_vehicle_characteristics_constructionDate_proxy",
                              ref: "qDateProxy",
                              cover: "",
                              "transition-show": "scale",
                              "transition-hide": "scale"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_date, {
                                  "data-testid": "demand_edit_asset_vehicle_characteristics_constructionDate_date",
                                  modelValue: _ctx.asset.constructionDate,
                                  "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.asset.constructionDate) = $event)),
                                  mask: _ctx.dateMask
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_51, [
                                      _withDirectives(_createVNode(_component_q_btn, {
                                        "data-testid": "demand_edit_asset_vehicle_characteristics_constructionDate_btn",
                                        label: _ctx.$t('task.completeDocument.party.person.financialSituation.close'),
                                        color: "primary",
                                        flat: ""
                                      }, null, 8, ["label"]), [
                                        [_directive_close_popup]
                                      ])
                                    ])
                                  ]),
                                  _: 1
                                }, 8, ["modelValue", "mask"])
                              ]),
                              _: 1
                            }, 512)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_52, [
                    _createElementVNode("label", _hoisted_53, _toDisplayString(_ctx.$t("demand.asset.guaranteeStartingDate")), 1),
                    _createVNode(_component_q_input, {
                      "data-testid": "demand_edit_asset_vehicle_characteristics_guaranteeStartingDate_input",
                      class: "tw-date-picker-event",
                      readonly: "",
                      dense: "",
                      outlined: "",
                      modelValue: _ctx.asset.guarantee.from,
                      "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.asset.guarantee.from) = $event))
                    }, {
                      append: _withCtx(() => [
                        _createVNode(_component_q_icon, {
                          "data-testid": "demand_edit_asset_vehicle_characteristics_guaranteeStartingDate_icon",
                          name: "event",
                          class: "cursor-pointer"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_popup_proxy, {
                              "data-testid": "demand_edit_asset_vehicle_characteristics_guaranteeStartingDate_proxy",
                              ref: "qDateProxy",
                              cover: "",
                              "transition-show": "scale",
                              "transition-hide": "scale"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_date, {
                                  "data-testid": "demand_edit_asset_vehicle_characteristics_guaranteeStartingDate_date",
                                  modelValue: _ctx.asset.guarantee.from,
                                  "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.asset.guarantee.from) = $event)),
                                  mask: _ctx.dateMask,
                                  options: (date) =>
                      _ctx.asset.constructionDate
                        ? date > _ctx.formatDate(_ctx.asset.constructionDate)
                        : true
                      
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_54, [
                                      _withDirectives(_createVNode(_component_q_btn, {
                                        "data-testid": "demand_edit_asset_vehicle_characteristics_guaranteeStartingDate_btn",
                                        label: _ctx.$t('task.completeDocument.party.person.financialSituation.close'),
                                        color: "primary",
                                        flat: ""
                                      }, null, 8, ["label"]), [
                                        [_directive_close_popup]
                                      ])
                                    ])
                                  ]),
                                  _: 1
                                }, 8, ["modelValue", "mask", "options"])
                              ]),
                              _: 1
                            }, 512)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_55, [
                    _createElementVNode("label", _hoisted_56, _toDisplayString(_ctx.$t("demand.asset.guaranteeEndingDate")), 1),
                    _createVNode(_component_q_input, {
                      "data-testid": "demand_edit_asset_vehicle_characteristics_guaranteeEndingDate_input",
                      class: "tw-date-picker-event",
                      readonly: "",
                      dense: "",
                      outlined: "",
                      "no-passive": "",
                      modelValue: _ctx.asset.guarantee.until,
                      "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.asset.guarantee.until) = $event))
                    }, {
                      append: _withCtx(() => [
                        _createVNode(_component_q_icon, {
                          "data-testid": "demand_edit_asset_vehicle_characteristics_guaranteeEndingDate_icon",
                          name: "event",
                          class: "cursor-pointer"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_popup_proxy, {
                              "data-testid": "demand_edit_asset_vehicle_characteristics_guaranteeEndingDate_proxy",
                              ref: "qDateProxy",
                              cover: "",
                              "transition-show": "scale",
                              "transition-hide": "scale"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_date, {
                                  "data-testid": "demand_edit_asset_vehicle_characteristics_guaranteeEndingDate_date",
                                  modelValue: _ctx.asset.guarantee.until,
                                  "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.asset.guarantee.until) = $event)),
                                  mask: _ctx.dateMask,
                                  options: (date) =>
                      _ctx.asset.guarantee.from
                        ? date > _ctx.formatDate(_ctx.asset.guarantee.from)
                        : true
                      
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_57, [
                                      _withDirectives(_createVNode(_component_q_btn, {
                                        "data-testid": "demand_edit_asset_vehicle_characteristics_guaranteeEndingDate_btn",
                                        label: _ctx.$t('task.completeDocument.party.person.financialSituation.close'),
                                        color: "primary",
                                        flat: ""
                                      }, null, 8, ["label"]), [
                                        [_directive_close_popup]
                                      ])
                                    ])
                                  ]),
                                  _: 1
                                }, 8, ["modelValue", "mask", "options"])
                              ]),
                              _: 1
                            }, 512)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_58, [
                    _createElementVNode("label", _hoisted_59, _toDisplayString(_ctx.$t("demand.asset.matriculation")), 1),
                    _createVNode(_component_q_input, {
                      "data-testid": "demand_edit_asset_vehicle_characteristics_matriculation",
                      dense: "",
                      outlined: "",
                      modelValue: _ctx.asset.matriculation,
                      "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.asset.matriculation) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_60, [
                    _createElementVNode("label", _hoisted_61, _toDisplayString(_ctx.$t("demand.asset.matriculationDate")), 1),
                    _createVNode(_component_q_input, {
                      "data-testid": "demand_edit_asset_vehicle_characteristics_matriculationDate_input",
                      class: "tw-date-picker-event",
                      readonly: "",
                      dense: "",
                      outlined: "",
                      modelValue: _ctx.asset.matriculationDate,
                      "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => ((_ctx.asset.matriculationDate) = $event))
                    }, {
                      append: _withCtx(() => [
                        _createVNode(_component_q_icon, {
                          "data-testid": "demand_edit_asset_vehicle_characteristics_matriculationDate_icon",
                          name: "event",
                          class: "cursor-pointer"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_popup_proxy, {
                              "data-testid": "demand_edit_asset_vehicle_characteristics_matriculationDate_proxy",
                              ref: "qDateProxy",
                              cover: "",
                              "transition-show": "scale",
                              "transition-hide": "scale"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_date, {
                                  "data-testid": "demand_edit_asset_vehicle_characteristics_matriculationDate_date",
                                  modelValue: _ctx.asset.matriculationDate,
                                  "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.asset.matriculationDate) = $event)),
                                  mask: _ctx.dateMask,
                                  options: (date) =>
                      _ctx.asset.constructionDate
                        ? date > _ctx.formatDate(_ctx.asset.constructionDate)
                        : true
                      
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_62, [
                                      _withDirectives(_createVNode(_component_q_btn, {
                                        "data-testid": "demand_edit_asset_vehicle_characteristics_matriculationDate_btn",
                                        label: _ctx.$t('task.completeDocument.party.person.financialSituation.close'),
                                        color: "primary",
                                        flat: ""
                                      }, null, 8, ["label"]), [
                                        [_directive_close_popup]
                                      ])
                                    ])
                                  ]),
                                  _: 1
                                }, 8, ["modelValue", "mask", "options"])
                              ]),
                              _: 1
                            }, 512)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ])
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_q_expansion_item, {
            "switch-toggle-side": "",
            "header-class": "card-header bg-transparent no-border p-4",
            class: "card-title font-medium text-dark mb-0"
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_63, [
                _createElementVNode("div", _hoisted_64, [
                  _createElementVNode("div", _hoisted_65, _toDisplayString(_ctx.$t("demand.asset.deliveryDetails")), 1)
                ])
              ])
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_66, [
                _createElementVNode("div", _hoisted_67, [
                  _createElementVNode("div", _hoisted_68, [
                    _createElementVNode("label", _hoisted_69, _toDisplayString(_ctx.$t("demand.asset.deliveryDate")), 1),
                    _createVNode(_component_q_input, {
                      "data-testid": "demand_edit_asset_vehicle_deliveryDetails_deliveryDate_input",
                      class: "tw-date-picker-event",
                      readonly: "",
                      dense: "",
                      outlined: "",
                      modelValue: _ctx.asset.assetDelivery.deliveryDate,
                      "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.asset.assetDelivery.deliveryDate) = $event))
                    }, {
                      append: _withCtx(() => [
                        _createVNode(_component_q_icon, {
                          "data-testid": "demand_edit_asset_vehicle_deliveryDetails_deliveryDate_icon",
                          name: "event",
                          class: "cursor-pointer"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_popup_proxy, {
                              "data-testid": "demand_edit_asset_vehicle_deliveryDetails_deliveryDate_proxy",
                              ref: "qDateProxy",
                              cover: "",
                              "transition-show": "scale",
                              "transition-hide": "scale"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_date, {
                                  "data-testid": "demand_edit_asset_vehicle_deliveryDetails_deliveryDate_date",
                                  modelValue: _ctx.asset.assetDelivery.deliveryDate,
                                  "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => ((_ctx.asset.assetDelivery.deliveryDate) = $event)),
                                  mask: _ctx.dateMask,
                                  options: (date) =>
                      _ctx.asset.constructionDate
                        ? date > _ctx.formatDate(_ctx.asset.constructionDate)
                        : true
                      
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_70, [
                                      _withDirectives(_createVNode(_component_q_btn, {
                                        "data-testid": "demand_edit_asset_vehicle_deliveryDetails_deliveryDate_btn",
                                        label: _ctx.$t('task.completeDocument.party.person.financialSituation.close'),
                                        color: "primary",
                                        flat: ""
                                      }, null, 8, ["label"]), [
                                        [_directive_close_popup]
                                      ])
                                    ])
                                  ]),
                                  _: 1
                                }, 8, ["modelValue", "mask", "options"])
                              ]),
                              _: 1
                            }, 512)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_71, [
                    _createElementVNode("label", _hoisted_72, _toDisplayString(_ctx.$t("demand.asset.supplier")), 1),
                    _createVNode(_component_q_input, {
                      "data-testid": "demand_edit_asset_vehicle_deliveryDetails_supplier_input",
                      dense: "",
                      outlined: "",
                      modelValue: _ctx.supplierName,
                      "onUpdate:modelValue": _cache[37] || (_cache[37] = ($event: any) => ((_ctx.supplierName) = $event)),
                      class: "tw-date-picker-event",
                      readonly: ""
                    }, {
                      append: _withCtx(() => [
                        _createVNode(_component_q_btn, {
                          "data-testid": "demand_edit_asset_vehicle_deliveryDetails_supplier_btn",
                          rounded: "",
                          dense: "",
                          flat: "",
                          icon: "search",
                          onClick: _ctx.searchAssetSupplier,
                          color: "primary",
                          disable: _ctx.isFormReadOnly
                        }, null, 8, ["onClick", "disable"])
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_73, [
                    _createElementVNode("label", _hoisted_74, _toDisplayString(_ctx.$t("demand.asset.address")), 1),
                    _createVNode(_component_q_input, {
                      "data-testid": "demand_edit_asset_vehicle_deliveryDetails_address_input",
                      dense: "",
                      outlined: "",
                      modelValue: _ctx.asset.config.formBind.supplierAddress,
                      "onUpdate:modelValue": _cache[38] || (_cache[38] = ($event: any) => ((_ctx.asset.config.formBind.supplierAddress) = $event)),
                      class: "tw-date-picker-event",
                      readonly: ""
                    }, {
                      append: _withCtx(() => [
                        _createVNode(_component_q_btn, {
                          "data-testid": "demand_edit_asset_vehicle_deliveryDetails_address_btn",
                          rounded: "",
                          dense: "",
                          flat: "",
                          icon: "search",
                          onClick: _ctx.searchAssetSupplierAddress,
                          color: "primary"
                        }, null, 8, ["onClick"])
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ])
                ])
              ])
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.currentRoute !== 'TaskDetail')
      ? (_openBlock(), _createElementBlock("div", _hoisted_75, [
          _createElementVNode("div", _hoisted_76, [
            _createElementVNode("div", _hoisted_77, _toDisplayString(_ctx.$t("demand.asset.vehicle.title")), 1)
          ]),
          _createElementVNode("div", _hoisted_78, [
            _createElementVNode("div", _hoisted_79, [
              _createVNode(_component_q_radio, {
                "data-testid": "demand_edit_asset_vehicle_VN",
                id: "demand_edit_asset_vehicle_VN",
                modelValue: _ctx.flagNew,
                "onUpdate:modelValue": _cache[39] || (_cache[39] = ($event: any) => ((_ctx.flagNew) = $event)),
                dense: "",
                val: "vn",
                label: _ctx.$t('asset.vehicleType.vn'),
                class: "mr-3"
              }, null, 8, ["modelValue", "label"]),
              _createVNode(_component_q_radio, {
                "data-testid": "demand_edit_asset_vehicle_VO",
                id: "demand_edit_asset_vehicle_VO",
                modelValue: _ctx.flagNew,
                "onUpdate:modelValue": _cache[40] || (_cache[40] = ($event: any) => ((_ctx.flagNew) = $event)),
                dense: "",
                val: "vo",
                label: _ctx.$t('asset.vehicleType.vo')
              }, null, 8, ["modelValue", "label"])
            ]),
            _createElementVNode("div", _hoisted_80, [
              _createElementVNode("div", _hoisted_81, [
                _createElementVNode("label", _hoisted_82, _toDisplayString(_ctx.$t("demand.asset.vehicle.quantity")), 1),
                _createVNode(_component_q_input, {
                  "data-testid": "demand_edit_asset_vehicle_quantity",
                  dense: "",
                  outlined: "",
                  modelValue: _ctx.quantity,
                  "onUpdate:modelValue": _cache[41] || (_cache[41] = ($event: any) => ((_ctx.quantity) = $event)),
                  modelModifiers: { number: true },
                  type: "number",
                  min: "1",
                  onChange: _ctx.onQuantityChange
                }, null, 8, ["modelValue", "onChange"])
              ]),
              _createElementVNode("div", _hoisted_83, [
                _createElementVNode("label", _hoisted_84, _toDisplayString(_ctx.$t("demand.asset.vehicle.fournisseur")), 1),
                _createVNode(_component_q_input, {
                  "data-testid": "demand_edit_asset_vehicle_vendor_input",
                  dense: "",
                  outlined: "",
                  modelValue: _ctx.asset.config.formBind.supplier.name,
                  "onUpdate:modelValue": _cache[42] || (_cache[42] = ($event: any) => ((_ctx.asset.config.formBind.supplier.name) = $event)),
                  readonly: ""
                }, {
                  append: _withCtx(() => [
                    _createVNode(_component_q_btn, {
                      "data-testid": "demand_edit_asset_vehicle_vendor_btn",
                      rounded: "",
                      dense: "",
                      flat: "",
                      icon: "search",
                      color: "primary"
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_85, [
              _createElementVNode("div", _hoisted_86, [
                _createElementVNode("label", _hoisted_87, _toDisplayString(_ctx.$t("demand.asset.vehicle.brand")), 1),
                _createVNode(_component_q_select, {
                  "data-testid": "demand_edit_asset_vehicle_brand",
                  disable: _ctx.isFormReadOnly,
                  dense: "",
                  outlined: "",
                  modelValue: _ctx.asset.config.formBind.brand,
                  "onUpdate:modelValue": [
                    _cache[43] || (_cache[43] = ($event: any) => ((_ctx.asset.config.formBind.brand) = $event)),
                    _cache[44] || (_cache[44] = ($event: any) => (_ctx.onChangeBrand($event)))
                  ],
                  options: _ctx.brandList
                }, null, 8, ["disable", "modelValue", "options"])
              ]),
              _createElementVNode("div", _hoisted_88, [
                _createElementVNode("label", _hoisted_89, _toDisplayString(_ctx.$t("demand.asset.vehicle.model")), 1),
                _createVNode(_component_q_select, {
                  "data-testid": "demand_edit_asset_vehicle_model",
                  disable: _ctx.isFormReadOnly,
                  dense: "",
                  outlined: "",
                  modelValue: _ctx.asset.config.formBind.range,
                  "onUpdate:modelValue": [
                    _cache[45] || (_cache[45] = ($event: any) => ((_ctx.asset.config.formBind.range) = $event)),
                    _cache[46] || (_cache[46] = ($event: any) => (_ctx.onChangeModel($event)))
                  ],
                  options: _ctx.modelList
                }, null, 8, ["disable", "modelValue", "options"])
              ]),
              _createElementVNode("div", _hoisted_90, [
                _createElementVNode("label", _hoisted_91, _toDisplayString(_ctx.$t("demand.asset.vehicle.engine")), 1),
                _createVNode(_component_q_select, {
                  "data-testid": "demand_edit_asset_vehicle_engine",
                  disable: _ctx.isFormReadOnly,
                  dense: "",
                  outlined: "",
                  modelValue: _ctx.asset.config.formBind.version,
                  "onUpdate:modelValue": [
                    _cache[47] || (_cache[47] = ($event: any) => ((_ctx.asset.config.formBind.version) = $event)),
                    _cache[48] || (_cache[48] = ($event: any) => (_ctx.onChangeVersion($event)))
                  ],
                  options: _ctx.versionList
                }, null, 8, ["disable", "modelValue", "options"])
              ]),
              _createElementVNode("div", _hoisted_92, [
                _createElementVNode("label", _hoisted_93, _toDisplayString(_ctx.$t("demand.asset.vehicle.description")), 1),
                _createVNode(_component_q_input, {
                  "data-testid": "demand_edit_asset_vehicle_description",
                  disable: _ctx.isFormReadOnly,
                  dense: "",
                  outlined: "",
                  modelValue: _ctx.asset.description,
                  "onUpdate:modelValue": _cache[49] || (_cache[49] = ($event: any) => ((_ctx.asset.description) = $event))
                }, null, 8, ["disable", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_94, [
                _createElementVNode("label", _hoisted_95, _toDisplayString(_ctx.$t("demand.asset.bodywork")), 1),
                _createVNode(_component_q_select, {
                  "data-testid": "demand_edit_asset_vehicle_bodyWork",
                  disable: _ctx.isFormReadOnly,
                  dense: "",
                  outlined: "",
                  modelValue: _ctx.asset.config.formBind.carBodyStyle,
                  "onUpdate:modelValue": [
                    _cache[50] || (_cache[50] = ($event: any) => ((_ctx.asset.config.formBind.carBodyStyle) = $event)),
                    _cache[51] || (_cache[51] = ($event: any) => (_ctx.onChangeBodyStyle($event)))
                  ],
                  options: _ctx.bodyCarList
                }, null, 8, ["disable", "modelValue", "options"])
              ]),
              _createElementVNode("div", _hoisted_96, [
                _createElementVNode("label", _hoisted_97, _toDisplayString(_ctx.$t("demand.asset.energy")), 1),
                _createVNode(_component_q_select, {
                  "data-testid": "demand_edit_asset_vehicle_energy",
                  disable: _ctx.isFormReadOnly,
                  dense: "",
                  outlined: "",
                  modelValue: _ctx.asset.config.formBind.engineType,
                  "onUpdate:modelValue": [
                    _cache[52] || (_cache[52] = ($event: any) => ((_ctx.asset.config.formBind.engineType) = $event)),
                    _cache[53] || (_cache[53] = ($event: any) => (_ctx.onChangeEnergy($event)))
                  ],
                  options: _ctx.energyList
                }, null, 8, ["disable", "modelValue", "options"])
              ]),
              _createElementVNode("div", _hoisted_98, [
                _createElementVNode("label", _hoisted_99, _toDisplayString(_ctx.$t("demand.asset.vehicle.power")), 1),
                _createVNode(_component_q_input, {
                  "data-testid": "demand_edit_asset_vehicle_power",
                  disable: _ctx.isFormReadOnly,
                  dense: "",
                  outlined: "",
                  modelValue: _ctx.asset.horsePower,
                  "onUpdate:modelValue": _cache[54] || (_cache[54] = ($event: any) => ((_ctx.asset.horsePower) = $event))
                }, null, 8, ["disable", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_100, [
                _createElementVNode("label", _hoisted_101, _toDisplayString(_ctx.$t("demand.asset.vehicle.emissionCO2")), 1),
                _createVNode(_component_q_input, {
                  "data-testid": "demand_edit_asset_vehicle_emissionCO2",
                  disable: _ctx.isFormReadOnly,
                  dense: "",
                  outlined: "",
                  modelValue: _ctx.asset.co2Emissions,
                  "onUpdate:modelValue": _cache[55] || (_cache[55] = ($event: any) => ((_ctx.asset.co2Emissions) = $event)),
                  type: "number"
                }, null, 8, ["disable", "modelValue"])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.showDialog)
      ? (_openBlock(), _createBlock(_component_third_search_dialog, {
          key: 2,
          openDialog: _ctx.openDialog,
          assetResourceUid: _ctx.asset.packAssetProduct?.resourceUid,
          onOnSelectedItem: _ctx.onSelectedItem,
          onOnClose: _ctx.onClose
        }, null, 8, ["openDialog", "assetResourceUid", "onOnSelectedItem", "onOnClose"]))
      : _createCommentVNode("", true),
    (_ctx.showDialogAddress)
      ? (_openBlock(), _createBlock(_component_AddressSearchDialog, {
          key: 3,
          party: _ctx.asset.party,
          onOnSelectedItem: _ctx.onSelectedAddressItem,
          onOnClose: _ctx.onCloseAddressSearchDialog
        }, null, 8, ["party", "onOnSelectedItem", "onOnClose"]))
      : _createCommentVNode("", true)
  ], 64))
}