
import { Options, Vue } from "vue-class-component";
import GuaranteeList from "./GuaranteeList.vue";

@Options({
  components: {
    GuaranteeList,
  }
})
export default class GuaranteeSearchDialog extends Vue {
}
