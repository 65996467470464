
import store from "@/store";
import {Options, Vue} from "vue-class-component";
import i18n from "@/i18n";
import {ReserveStatusItem} from "@/types"
import { UON } from "@/utils";

@Options({
    props: ["reserves", "path","entryIndex"],

})
export default class OpinionReservesCard extends Vue {
    reserves?: any;
    path?: any;
    entryIndex?: any;
    ReserveStatusItem = ReserveStatusItem
    get reserveOpinion() {
        return store.state.taskModule?.Openionreserves;
    }
    get isTaskEnterOpinion() {
    return store.getters["taskModule/isTaskEnterOpinion"];
  }
  get isTaskTypeMonoDecision() {
    return store.getters["taskModule/isTaskTypeMonoDecision"];
  }


    async changeReserveStatus(status: string, index: number, entryIndex:number) {
            try {
                // Prepare the payload based on the reserve item
                const currentReserve = this.reserves[index];
                const payload = {
                    ...currentReserve,
                    statusReserve: {
                        objectType: UON.ObjectType.odmDecisionReserveStatus,
                        systemUid: UON.SystemUid.odmDecision,
                        resourceUid: status
                    }
                };

                // Dispatch the action to update reserve status
                await store.dispatch('taskModule/updateReserveStatus', {
                    reserveId: currentReserve.resourceUid,
                    payload: payload,
                    path: this.path,
                    entryIndex: entryIndex
                });

            } catch (error) {
                console.error('Error updating reserve status:', error);
                // Optionally, show an error notification to the user
            }
    }

    reserveTraduction(resourceUid: any) {
      return (
        resourceUid != '' ? i18n.global.t("task.reserves.".concat(resourceUid)) : ''
      );
    }
}
