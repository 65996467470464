import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21dfddf2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-5" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = {
  key: 0,
  class: "col-12 mt-3"
}
const _hoisted_5 = { class: "col-7" }
const _hoisted_6 = { class: "text-danger h6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TemplateList = _resolveComponent("TemplateList")!
  const _component_TemplateSender = _resolveComponent("TemplateSender")!
  const _component_TemplatePreview = _resolveComponent("TemplatePreview")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card-body", _ctx.disable ? 'disabled-wrapper' : ''])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.disable ? 'disabled' : '')
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["row", _ctx.isDocGenerated ? '' : 'd-none'])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_TemplateList, { modal: _ctx.modal }, null, 8, ["modal"])
            ]),
            (!_ctx.modal)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (_ctx.showSendTemplate)
                    ? (_openBlock(), _createBlock(_component_TemplateSender, {
                        key: 0,
                        class: "tw-send-popup"
                      }))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_TemplatePreview, { modal: _ctx.modal }, null, 8, ["modal"])
        ])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["row", !_ctx.isDocGenerated ? '' : 'd-none'])
      }, [
        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('good.globalLabels.noGeneratedDocs')), 1)
      ], 2)
    ], 2)
  ], 2))
}