
import {Options, Vue} from "vue-class-component";

import store from "@/store";

@Options({
    props: ["reCalculation"],
    watch: {
        "$store.state.analysisModule.associatedAnalysisProcess"(newValue) {

            this.selectedReserve = this.analysisReserves.map((item: any) => {
                return {
                    ...item,
                    comment: ""
                }

            })

        },
    }
})

export default class Reserves extends Vue {
    reCalculation: any;
    selectedReserve: any
    updateSelectedReserve() {
        // Dispatch the Vuex action to update the store
        this.$store.dispatch('analysisModule/updateAnalysisReserves', this.selectedReserve);
    }
    deleteReserve(ressourceUid: string) {
        this.selectedReserve = this.selectedReserve.filter((item: any) => item.resourceUid !== ressourceUid)
        this.updateSelectedReserve()
    }
    get reservesOptions() {
        return store.state.analysisModule?.evaluatedReserves
    }
    get analysisReserves() {
        return store.state.analysisModule?.reserves
    }

}
