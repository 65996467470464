
import {Options, Vue} from "vue-class-component";
import {SupportingDocumentStatus} from "@/types";
import store from "@/store";
import i18n from "@/i18n";
import {openFileInNewTab} from "@/commons/index";
import {useRoute} from "vue-router";
import {uploadDocument} from "@/commons/gathering-process";
import {ProcessedDocument, TargetEntity} from "@/commons/gathering-process";
import {addedBySystem} from "@/utils";

@Options({
  props: ["targetEntity", "gpRowIndex"],
})
export default class DocumentsPanel extends Vue {
  //columns = globalConfig.table_config.middleOfficeTable.documents;
  removing = false
  gpRowIndex: any;
  get currentRoute() {
    return useRoute().name;
  }
  get taskDefinition() {
    return store.getters["taskModule/taskDefinition"];
  }

  get supportingDocumentStatusOptions() {
    if(this.taskDefinition === 'STEP_START_ANALYSIS') {
      let statusOptions = Object.keys(SupportingDocumentStatus).map((item: string) => {
          if(item.includes('REJECTED') || item.includes('VALID')) {
            return {
              label: i18n.global.t(
                  `task.validateDocument.headers.supportingDocumentStatus.${item}`
              ),
                value: item,
            }
          }
      })
      statusOptions =  statusOptions.filter(item => item?.value)
      return statusOptions
    }else{
      return Object.keys(SupportingDocumentStatus).map((item: string) => {
        return {
          label: i18n.global.t(
              `task.validateDocument.headers.supportingDocumentStatus.${item}`
          ),
          value: item,
        };
      });
    }
  }

  getStatusClass(value: string) {
    if (value === 'VALID' || value === i18n.global.t(`task.uploadDocument.headers.supportingDocumentStatus.VALID`)) {
      return "positive";
    } else if (value === 'REJECTED' || value === i18n.global.t(`task.uploadDocument.headers.supportingDocumentStatus.REJECTED`)) {
      return "negative";
    } else {
      return "black";
    }
  }
getStatus(status:string){
  return i18n.global.t(`task.uploadDocument.headers.supportingDocumentStatus.${status}`)
}
  viewDocument = (row: any) => {
    row.isLoading = true;
    store.dispatch("taskModule/uploadDocumentModule/downloadFile", {
      request: row.document,
      row: row,
      callback: this.viewDocumentResponse,
    });
  };

  viewDocumentResponse = async (response: any, row: any) => {
    if (response) {
      const { format, content } = response;
      openFileInNewTab({
        name: response.originalFileName,
        mimeType: format,
        base64: content,
      });
      if (row) row.isLoading = false;
    }
  };

  downloadDocument(row: any) {
    store.dispatch("taskModule/uploadDocumentModule/downloadFile", {
      request: row.document,
      row: row,
      callback: this.downloadDocumentResponse,
    });
  }

  downloadDocumentResponse(response: any) {
    if (response) {
      const { content } = response;
      const linkSource = `data:${response.format};base64,${content}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = response.originalFileName;
      downloadLink.click();
    }
  }

  deleteManualDocument(document: any){
    this.removing = true
    store.dispatch('taskModule/validateDocumentsModule/deleteManualDocument', {
      request: document,
      callback: store.dispatch("taskModule/validateDocumentsModule/getGatheringProcessList")
    })
    setTimeout(() => this.removing = false, 1000);
  }

  get gatheringProcessList() {
    return store.state.taskModule.gatheringProcess
  }

  onSupportingDocumentStatusChange(payload: {event: any, document: any, processIndex: number}) {
    const {event, processIndex, document} = payload;
    const gatheringProcess = this.gatheringProcessList[processIndex];
    store.dispatch("taskModule/validateDocumentsModule/changeSupportingDocumentStatus", {
      status: event,
      gatheringProcessRow: document,
      processIndex,
      supportingDocumentIndex: gatheringProcess,
    });
    document.status = i18n.global.t(`task.uploadDocument.headers.supportingDocumentStatus.${document.status}`)
  }
   onModify(payload: {processIndex: number, files: FileList, targetRef: string, docRef: string}) {
    const {processIndex, files, targetRef, docRef} = payload;
    const gatheringProcess = this.getGatheringProcess[processIndex];
    const document = gatheringProcess.entityTargets
                                     .find((entity: TargetEntity) => entity.reference === targetRef).documents
                                     .find((document: ProcessedDocument) => document.reference === docRef);                                 
   const status=SupportingDocumentStatus.REJECTED
     store.dispatch("taskModule/validateDocumentsModule/changeSupportingDocumentStatus", {
       status: status,
      gatheringProcessRow: document,
      processIndex,
      supportingDocumentIndex: gatheringProcess,
    });
    document.status = i18n.global.t(`task.uploadDocument.headers.supportingDocumentStatus.${document.status}`)
    this.handleWithDocument({document, files, gatheringProcess});
  }

  async handleWithDocument(attr: {
    document: ProcessedDocument,
    files?: FileList,
    gatheringProcess: any
  }) {

    const {document, files, gatheringProcess} = attr;

    const targetEntityAttr = {
      documentType: document.documentType,
      targetEntityObjectType: document.targetEntity.objectType as string,
      targetEntityUID: document.targetEntity.resourceUid as string
    }

    const offerReference = store.state.taskModule.offer?.reference as string;
    const connectedUser = store.getters["authModule/getUserConnected"];

    const metadataMap = new Map<string, string>();
    metadataMap.set("associatedOffer", offerReference);

    document.addedBy = `${connectedUser.firstName} ${connectedUser.lastName}`;
    metadataMap.set("addedBy", `${offerReference};${connectedUser.username}`);
    metadataMap.set("documentType", targetEntityAttr.documentType);
    metadataMap.set("targetEntityObjectType", targetEntityAttr.targetEntityObjectType);
    metadataMap.set("targetEntityUID", targetEntityAttr.targetEntityUID);
    if (files) {
      uploadDocument({files, document, gatheringProcess, metadataMap})
    }
  }
    onSelectFile(payload: {event: Event, processIndex: number, targetRef: string, docRef: string}) {          
    const {event, processIndex, targetRef, docRef} = payload;
    const files = (event.target as HTMLInputElement).files as FileList;
    this.onModify({files, processIndex, targetRef, docRef})
  }

  updateDocument(inputId: string){
    document.getElementById(inputId)?.click()

  }
   get getGatheringProcess() {
    return store.state.taskModule.gatheringProcess;
  }
   get isTaskModifyConditions() {
    return store.getters["taskModule/isTaskModifyConditions"];
  }
  addedBy(metadata: any) {
    const AssociatedOfferReference = metadata.find((item: any) => {
      if (item.key === 'addedBy') {
       const offerRef = this.currentRoute === 'TaskDetail' ? store.state.taskModule.offer?.reference : store.state.middleOfficeModule.offer?.reference;
        return  item.value.split(";")[0]=== offerRef;
      }
      return false;
    });
    return  AssociatedOfferReference ? AssociatedOfferReference.value.split(";")[1] : null;
  }
  t(value:any){
    if (addedBySystem.includes(value)){
      return i18n.global.t('task.supportingDocumentLabel.system')
    }
    else return value
  }
}
