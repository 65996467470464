import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card-title flex-grow-1 justify-content-between align-items-center mb-0" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { class: "font-semi-bold font-size-12" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = { class: "d-flex" }
const _hoisted_6 = { class: "flex-grow-1" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = ["id"]
const _hoisted_9 = { class: "d-block mb-0 small text-muted" }
const _hoisted_10 = ["id"]
const _hoisted_11 = { class: "d-block mb-0 small text-muted" }
const _hoisted_12 = ["id"]
const _hoisted_13 = { class: "d-block mb-0 small text-muted" }
const _hoisted_14 = { class: "row items-center justify-end" }
const _hoisted_15 = ["id"]
const _hoisted_16 = { class: "d-block mb-0 small text-muted" }
const _hoisted_17 = { class: "row items-center justify-end" }
const _hoisted_18 = ["id"]
const _hoisted_19 = { class: "d-block mb-0 small text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_form = _resolveComponent("q-form")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_form, { onSubmit: _ctx.onSaveClick }, {
    default: _withCtx(() => [
      _createVNode(_component_q_list, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_expansion_item, {
            "switch-toggle-side": "",
            "header-class": "card-header bg-transparent no-border p-4",
            class: "card tw-items-dashed-group tw-items-group-with-bg tw-level-two-group",
            onShow: _cache[1] || (_cache[1] = ($event: any) => {
            _ctx.classificationBtn = !_ctx.classificationBtn;
            _ctx.addExpandClassification(_ctx.selectedClassifications);
          }),
            onHide: _cache[2] || (_cache[2] = ($event: any) => {
            _ctx.classificationBtn = !_ctx.classificationBtn;
            _ctx.removeExpandedItem < _ctx.selectedClassifications > _ctx.selectedClassifications;
          })
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('middleOffice.party.organization.classification.partyClass').toUpperCase()), 1),
                  _createVNode(_component_q_btn, {
                    "data-listid": "demand_edit_classification_addBtn",
                    class: "btn btn-primary btn-sm ml-3",
                    icon: "add",
                    onClickCapture: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.add(_ctx.selectedClassifications)), ["stop"])),
                    disabled: _ctx.isClassificationBtnDisabled
                  }, null, 8, ["disabled"])
                ])
              ])
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedClassifications, (value, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "p-2 bg-white default-border-radius mb-3",
                    key: index
                  }, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("div", {
                            class: "col-lg-2 col-md-6 col-sm-12 form-control no-border",
                            id: 'demand_edit_classification_type_'+index
                          }, [
                            _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("main.type")), 1),
                            _createVNode(_component_q_select, {
                              "data-listid": 'demand_edit_classification_type_'+index,
                              dense: "",
                              outlined: "",
                              "emit-value": "",
                              "map-options": "",
                              modelValue: value.classType,
                              "onUpdate:modelValue": [($event: any) => ((value.classType) = $event), ($event: any) => (_ctx.onChangeType(value.classType, index))],
                              options: _ctx.partyClassificationTypeItems
                            }, null, 8, ["data-listid", "modelValue", "onUpdate:modelValue", "options"])
                          ], 8, _hoisted_8),
                          _createElementVNode("div", {
                            class: "col-lg-2 col-md-6 col-sm-12 form-control no-border",
                            id: 'demand_edit_classification_cotation_'+index
                          }, [
                            _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t("middleOffice.party.organization.classification.partyClass")), 1),
                            _createVNode(_component_q_select, {
                              "data-listid": 'demand_edit_classification_cotation_'+index,
                              dense: "",
                              outlined: "",
                              "emit-value": "",
                              "map-options": "",
                              modelValue: value.partyClass.type.value,
                              "onUpdate:modelValue": ($event: any) => ((value.partyClass.type.value) = $event),
                              options: _ctx.partyClassificationItems[index]
                            }, null, 8, ["data-listid", "modelValue", "onUpdate:modelValue", "options"])
                          ], 8, _hoisted_10),
                          _createElementVNode("div", {
                            class: "col-lg-2 col-md-6 col-sm-12 form-control no-border",
                            id: 'demand_edit_classification_startDate_'+index
                          }, [
                            _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t("main.labels.startDate")), 1),
                            _createVNode(_component_q_input, {
                              "data-listid": 'demand_edit_classification_startDate_input_'+index,
                              readonly: "",
                              onChange: ($event: any) => (_ctx.formatDateFrom(value)),
                              dense: "",
                              outlined: "",
                              modelValue: value.validity.from,
                              "onUpdate:modelValue": ($event: any) => ((value.validity.from) = $event),
                              disable: _ctx.getDisable(value)
                            }, {
                              append: _withCtx(() => [
                                _createVNode(_component_q_icon, {
                                  "data-listid": 'demand_edit_classification_startDate_icon_'+index,
                                  name: "event",
                                  class: "cursor-pointer"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_popup_proxy, {
                                      "data-listid": 'demand_edit_classification_startDate_proxy_'+index,
                                      ref_for: true,
                                      ref: "qDateProxy",
                                      cover: "",
                                      "transition-show": "scale",
                                      "transition-hide": "scale"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_q_date, {
                                          "data-listid": 'demand_edit_classification_startDate_date_'+index,
                                          modelValue: value.validity.from,
                                          "onUpdate:modelValue": [($event: any) => ((value.validity.from) = $event), ($event: any) => (_ctx.onChangeStartDate(value))],
                                          mask: _ctx.dateMask,
                                          options: 
                                  (date) =>{
                                    return  _ctx.testStartDate(date,value)
                                  }
                                    
                                
                                        }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("div", _hoisted_14, [
                                              _withDirectives(_createVNode(_component_q_btn, {
                                                "data-listid": 'demand_edit_classification_startDate_btn_'+index,
                                                label: _ctx.$t('task.completeDocument.party.person.financialSituation.close'),
                                                color: "primary",
                                                flat: ""
                                              }, null, 8, ["data-listid", "label"]), [
                                                [_directive_close_popup]
                                              ])
                                            ])
                                          ]),
                                          _: 2
                                        }, 1032, ["data-listid", "modelValue", "onUpdate:modelValue", "mask", "options"])
                                      ]),
                                      _: 2
                                    }, 1032, ["data-listid"])
                                  ]),
                                  _: 2
                                }, 1032, ["data-listid"])
                              ]),
                              _: 2
                            }, 1032, ["data-listid", "onChange", "modelValue", "onUpdate:modelValue", "disable"])
                          ], 8, _hoisted_12),
                          _createElementVNode("div", {
                            class: "col-lg-2 col-md-6 col-sm-12 form-control no-border",
                            id: 'demand_edit_classification_endDate_'+index
                          }, [
                            _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t("main.labels.endDate")), 1),
                            _createVNode(_component_q_input, {
                              "data-listid": 'demand_edit_classification_endDate_input_'+index,
                              readonly: "",
                              onChange: ($event: any) => (_ctx.formatDateUntil(value)),
                              dense: "",
                              outlined: "",
                              modelValue: value.validity.until,
                              "onUpdate:modelValue": ($event: any) => ((value.validity.until) = $event),
                              disable: _ctx.getDisable(value)
                            }, {
                              append: _withCtx(() => [
                                _createVNode(_component_q_icon, {
                                  "data-listid": 'demand_edit_classification_endDate_icon_'+index,
                                  name: "event",
                                  class: "cursor-pointer"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_popup_proxy, {
                                      "data-listid": 'demand_edit_classification_endDate_proxy_'+index,
                                      ref_for: true,
                                      ref: "qDateProxy",
                                      cover: "",
                                      "transition-show": "scale",
                                      "transition-hide": "scale"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_q_date, {
                                          "data-listid": 'demand_edit_classification_endDate_date_'+index,
                                          modelValue: value.validity.until,
                                          "onUpdate:modelValue": [($event: any) => ((value.validity.until) = $event), ($event: any) => (_ctx.onChangeEndDate(value))],
                                          mask: _ctx.dateMask,
                                          options: 
                                  (date) =>
                                    value.validity.from
                                      ? (date >= _ctx.formatDate(value.validity.from)) && _ctx.testEndDate(date,value)
                                      : true
                                
                                        }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("div", _hoisted_17, [
                                              _withDirectives(_createVNode(_component_q_btn, {
                                                "data-listid": 'demand_edit_classification_endDate_btn_'+index,
                                                label: _ctx.$t('task.completeDocument.party.person.financialSituation.close'),
                                                color: "primary",
                                                flat: ""
                                              }, null, 8, ["data-listid", "label"]), [
                                                [_directive_close_popup]
                                              ])
                                            ])
                                          ]),
                                          _: 2
                                        }, 1032, ["data-listid", "modelValue", "onUpdate:modelValue", "mask", "options"])
                                      ]),
                                      _: 2
                                    }, 1032, ["data-listid"])
                                  ]),
                                  _: 2
                                }, 1032, ["data-listid"])
                              ]),
                              _: 2
                            }, 1032, ["data-listid", "onChange", "modelValue", "onUpdate:modelValue", "disable"])
                          ], 8, _hoisted_15),
                          _createElementVNode("div", {
                            class: "col-lg-2 col-md-6 col-sm-12 form-control no-border",
                            id: 'demand_edit_classification_classificationDate_'+index
                          }, [
                            _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t("main.labels.classificationDate")), 1),
                            _createVNode(_component_q_input, {
                              "data-listid": 'demand_edit_classification_classificationDate_input_'+index,
                              readonly: "",
                              onChange: ($event: any) => (_ctx.formatDateUntil(value)),
                              dense: "",
                              outlined: "",
                              modelValue: value.classificationDate,
                              "onUpdate:modelValue": ($event: any) => ((value.classificationDate) = $event)
                            }, {
                              append: _withCtx(() => [
                                _createVNode(_component_q_icon, {
                                  "data-listid": 'demand_edit_classification_classificationDate_icon_'+index,
                                  name: "event",
                                  class: "cursor-pointer"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_popup_proxy, {
                                      "data-listid": 'demand_edit_classification_classificationDate_proxy_'+index,
                                      ref_for: true,
                                      ref: "qDateProxy",
                                      cover: "",
                                      "transition-show": "scale",
                                      "transition-hide": "scale"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_q_date, {
                                          "data-listid": 'demand_edit_classification_classificationDate_date_'+index,
                                          modelValue: value.classificationDate,
                                          "onUpdate:modelValue": ($event: any) => ((value.classificationDate) = $event),
                                          mask: _ctx.dateMask
                                        }, null, 8, ["data-listid", "modelValue", "onUpdate:modelValue", "mask"])
                                      ]),
                                      _: 2
                                    }, 1032, ["data-listid"])
                                  ]),
                                  _: 2
                                }, 1032, ["data-listid"])
                              ]),
                              _: 2
                            }, 1032, ["data-listid", "onChange", "modelValue", "onUpdate:modelValue"])
                          ], 8, _hoisted_18)
                        ])
                      ]),
                      _createElementVNode("div", null, [
                        _createVNode(_component_q_btn, {
                          "data-listid": 'demand_edit_classification_deleteBtn_'+index,
                          icon: "delete",
                          class: "btn btn-outline-danger mt-3",
                          disable: value.resourceUid != null,
                          onClick: ($event: any) => (_ctx.remove(index, _ctx.selectedClassifications))
                        }, null, 8, ["data-listid", "disable", "onClick"])
                      ])
                    ])
                  ]))
                }), 128))
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onSubmit"]))
}