import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "d-flex justify-between align-content-center" }
const _hoisted_4 = { class: "d-flex justify-content-between align-items-center" }
const _hoisted_5 = {
  key: 0,
  class: "col-12"
}
const _hoisted_6 = { class: "list-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_spinner_facebook = _resolveComponent("q-spinner-facebook")!
  const _component_TemplateListPackItem = _resolveComponent("TemplateListPackItem")!

  return (_openBlock(), _createElementBlock("div", {
    class: "row",
    id: 'task_taskdetails_printDocuments_doc' + _ctx.index
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_q_checkbox, {
          outlined: "",
          dense: "",
          onClick: _ctx.onSelectChange,
          modelValue: _ctx.noticeTemplate.selected,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.noticeTemplate.selected) = $event)),
          label: _ctx.$t('task.printDocument.filesName.'+ _ctx.noticeTemplate.fileName)
        }, null, 8, ["onClick", "modelValue", "label"]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_q_btn, {
            icon: "upload_file",
            size: "sm",
            padding: "xs",
            class: "btn btn-sm btn-outline-primary",
            onClick: _ctx.onSendEvent
          }, null, 8, ["onClick"]),
          _createVNode(_component_q_btn, {
            class: "btn btn-sm btn-outline-primary",
            size: "sm",
            padding: "xs",
            icon: "visibility",
            loading: _ctx.isLoadingView,
            onClick: _ctx.onViewEvent
          }, {
            loading: _withCtx(() => [
              _createVNode(_component_q_spinner_facebook)
            ]),
            _: 1
          }, 8, ["loading", "onClick"]),
          _createVNode(_component_q_btn, {
            class: "btn btn-sm btn-outline-primary",
            size: "sm",
            padding: "xs",
            icon: "download",
            loading: _ctx.isLoadingDownload,
            onClick: _ctx.onDownloadEvent
          }, {
            loading: _withCtx(() => [
              _createVNode(_component_q_spinner_facebook)
            ]),
            _: 1
          }, 8, ["loading", "onClick"]),
          (_ctx.isPack)
            ? (_openBlock(), _createBlock(_component_q_btn, {
                key: 0,
                class: "btn btn-sm btn-outline-primary",
                size: "sm",
                padding: "xs",
                icon: "list",
                onClick: _ctx.openPackDetail
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    (_ctx.isPack && _ctx.opened)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("ul", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.noticeTemplate.items, (template, index) => {
              return (_openBlock(), _createElementBlock("li", {
                class: "list-group-item",
                key: index
              }, [
                _createVNode(_component_TemplateListPackItem, {
                  onChildBooleanChanged: _ctx.updateParentParentBoolean,
                  noticeTemplate: template,
                  notice: _ctx.noticeTemplate
                }, null, 8, ["onChildBooleanChanged", "noticeTemplate", "notice"])
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}