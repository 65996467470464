import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = {
  key: 0,
  style: {"color":"blue","text-decoration":"underline"}
}
const _hoisted_3 = {
  key: 1,
  style: {"color":"blue","text-decoration":"underline"}
}
const _hoisted_4 = {
  key: 2,
  style: {"margin-right":"5px"}
}
const _hoisted_5 = {
  key: 3,
  style: {"color":"blue","text-decoration":"underline"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_third_search_dialog = _resolveComponent("third-search-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_q_select, {
        dense: "",
        outlined: "",
        modelValue: _ctx.party.type,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.party.type) = $event)),
        options: _ctx.clientType,
        class: "bg-white w-50",
        loading: !_ctx.clientType,
        disable: !_ctx.clientType
      }, null, 8, ["modelValue", "options", "loading", "disable"]),
      _createElementVNode("div", null, [
        _createVNode(_component_q_btn, {
          label: _ctx.$t('main.labels.search'),
          onClick: _ctx.searchThird,
          class: "btn btn-primary ml-3",
          icon: "search"
        }, null, 8, ["label", "onClick"])
      ])
    ]),
    _createElementVNode("div", null, [
      (_ctx.party.type?.label === _ctx.$t('enum.party.type.party-FrenchOrganization'))
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.party.organization?.name), 1))
        : _createCommentVNode("", true),
      (_ctx.party.type?.label === _ctx.$t('enum.party.type.party-FrenchPerson'))
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.party.person?.firstName), 1))
        : _createCommentVNode("", true),
      (_ctx.party.type?.label === _ctx.$t('enum.party.type.party-FrenchPerson'))
        ? (_openBlock(), _createElementBlock("span", _hoisted_4))
        : _createCommentVNode("", true),
      (_ctx.party.type?.label === _ctx.$t('enum.party.type.party-FrenchPerson'))
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.party.person?.familyName), 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.showDialog)
      ? (_openBlock(), _createBlock(_component_third_search_dialog, {
          key: 0,
          openDialog: _ctx.openDialog,
          party: _ctx.party,
          role: _ctx.roleThird,
          onOnSelectedItem: _ctx.onSelectedItem,
          onOnClose: _ctx.onCloseThird
        }, null, 8, ["openDialog", "party", "role", "onOnSelectedItem", "onOnClose"]))
      : _createCommentVNode("", true)
  ], 64))
}