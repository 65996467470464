
import { Options, Vue } from "vue-class-component";
import moment from "moment";

@Options({
  props: ["decisions"],
})
export default class DecisionCard extends Vue {
  decisions?: any;

  getDateFormat(date: any){
    return moment(date).format("DD/MM/YYYY HH:mm") || ' - '
  }
}
