
import {Options, Vue} from "vue-class-component";

import store from "@/store";
import router from "@/router";
import TemplateSender from "@/components/task/tabs/printDocument/TemplateSender.vue";
import TemplatePreview from "@/components/task/tabs/printDocument/TemplatePreview.vue";
import TemplateList from "@/components/task/tabs/printDocument/TemplateList.vue";
import { ActionType } from "@/types";
import  Analyse from "@/components/task/tabs/Analyse.vue";
import {evaluateUiControls} from "@/utils";

@Options({
  props: {
    disable: {
      type: Boolean,
      required: false,  
      default: false,
    },
    modal: {
      type: Boolean,
      required: false,  
      default: false,
    },
  },  
  components: {
    Analyse,
    TemplateSender,
    TemplatePreview,
    TemplateList,
  },
  watch: {
    "$store.state.taskModule.executedAction"(action: ActionType) {
      if(!this.modal){
        if (action === ActionType.VALIDATE) {
        if (!store.state.taskModule.selectedTask.proceed) {
              store.commit("setLockState", false);
              store.commit("setConfirmationDialogState", {
                show: false,
              });
             router.push("/task/list");
        }
      }
      }
    },
    "$store.state.printDocumentModule.showSendTemplate"(showSendTemplate) {
      if(!this.modal){
      this.showSendTemplate = showSendTemplate;
      }
    },
    "$store.state.templateModule.templates": {
      handler(newvalue) {
        console.log("changed offer", newvalue);
       if(store.state.templateModule.templates.length > 0){
       
          this.isDocGenerated = true;
     
       }
       else {
          this.isDocGenerated = false;
       }
      },
      deep: true, // Watch nested properties
    }
  },
  created(){
    if(!this.modal){
      evaluateUiControls('print-document-task', 'insert', this.$router, true)
    }
    
  },
  updated() {
    if(!this.modal){
      this.$emit('update')
    }
    
  },
})
export default class PrintDocument extends Vue {
  disable!: boolean;
  modal!: boolean;
  isDocGenerated = false;
    get showSendTemplate() {
    return store.state.printDocumentModule.showSendTemplate;
  }


  get currentRoute() {
    return this.$router.currentRoute.value.name;
  }
   
  // created() {
  //   store.dispatch("printDocumentModule/getTemplates", {
  //     currentRoute: this.currentRoute
  //   });
  // }
}
