import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7722581a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body pt-0" }
const _hoisted_3 = { class: "table-responsive" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "d-flex flex-column" }
const _hoisted_6 = { class: "d-flex flex-column" }
const _hoisted_7 = { class: "text-grey" }
const _hoisted_8 = { class: "d-flex flex-column" }
const _hoisted_9 = { class: "text-grey" }
const _hoisted_10 = { class: "d-flex flex-column" }
const _hoisted_11 = { class: "text-grey" }
const _hoisted_12 = { class: "d-flex flex-column" }
const _hoisted_13 = { class: "d-flex flex-column" }
const _hoisted_14 = { class: "text-grey" }
const _hoisted_15 = { class: "d-flex flex-column" }
const _hoisted_16 = { class: "text-grey" }
const _hoisted_17 = { class: "d-flex flex-column" }
const _hoisted_18 = { class: "text-grey" }
const _hoisted_19 = { class: "d-flex flex-column" }
const _hoisted_20 = { class: "d-flex flex-column" }
const _hoisted_21 = { class: "text-grey" }
const _hoisted_22 = { class: "d-flex flex-column" }
const _hoisted_23 = { class: "text-grey" }
const _hoisted_24 = { class: "d-flex flex-column" }
const _hoisted_25 = { class: "text-grey" }
const _hoisted_26 = { class: "d-flex flex-column" }
const _hoisted_27 = { class: "d-flex flex-column" }
const _hoisted_28 = { class: "text-grey" }
const _hoisted_29 = { class: "d-flex flex-column" }
const _hoisted_30 = { class: "text-grey" }
const _hoisted_31 = { class: "d-flex flex-column" }
const _hoisted_32 = { class: "text-grey" }
const _hoisted_33 = { class: "d-flex flex-column" }
const _hoisted_34 = { class: "d-flex flex-column" }
const _hoisted_35 = { class: "text-grey" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_q_table, {
          class: "shadow-0 table table-sm",
          rows: _ctx.rows,
          columns: _ctx.columns,
          "row-key": "product_code",
          selection: "single",
          selected: _ctx.selected,
          "onUpdate:selected": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selected) = $event))
        }, {
          header: _withCtx((props) => [
            _createVNode(_component_q_tr, { props: props }, {
              default: _withCtx(() => [
                _createVNode(_component_q_th, { "auto-width": "" }),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                  return (_openBlock(), _createBlock(_component_q_th, {
                    key: col.name,
                    props: props
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t(col.label)), 1)
                    ]),
                    _: 2
                  }, 1032, ["props"]))
                }), 128))
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          body: _withCtx((props) => [
            _createVNode(_component_q_tr, { props: props }, {
              default: _withCtx(() => [
                _createVNode(_component_q_td, { "auto-width": "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_checkbox, {
                      modelValue: props.selected,
                      "onUpdate:modelValue": ($event: any) => ((props.selected) = $event),
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onCheck()))
                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                  ]),
                  _: 2
                }, 1024),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                  return (_openBlock(), _createBlock(_component_q_td, {
                    key: col.name,
                    props: props
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(col.name==='color' && col.value   ? _ctx.$t("demand.asset.colors." + col.value.replace("COLOR.", "")) : col.value), 1)
                    ]),
                    _: 2
                  }, 1032, ["props"]))
                }), 128)),
                _createVNode(_component_q_td, { style: {"max-width":"60px"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_btn, {
                      class: "btn bg-transparent p-0 btn-link text-primary",
                      onClick: _withModifiers(($event: any) => (_ctx.onSelect(props.row)), ["stop","prevent"]),
                      label: _ctx.getLabel(props.row)
                    }, null, 8, ["onClick", "label"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["props"]),
            (props.row.opendetailInfo)
              ? (_openBlock(), _createBlock(_component_q_tr, {
                  key: 0,
                  props: props,
                  class: "bg-secondary"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_td, { colspan: "3" }, {
                      default: _withCtx(() => [
                        _createElementVNode("img", {
                          class: "d-block m-auto img-info-commande",
                          src: props.row.brand.toUpperCase().includes('HUYNDAI') ? _ctx.hyundaImage : _ctx.logoImage,
                          alt: "Vehicule"
                        }, null, 8, _hoisted_4)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_q_td, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("order.asset.inventoryCols.immatriculation")), 1),
                            _createElementVNode("span", null, _toDisplayString(props.row.matriculation ? props.row.matriculation : '-'), 1)
                          ]),
                          _createElementVNode("div", _hoisted_8, [
                            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("order.asset.inventoryCols.immatriculationDate")), 1),
                            _createElementVNode("span", null, _toDisplayString(props.row.matriculation_date ? props.row.matriculation_date : '-'), 1)
                          ]),
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t("order.asset.inventoryCols.constructionDate")), 1),
                            _createElementVNode("span", null, _toDisplayString(props.row.construction_date ? props.row.construction_date : '-'), 1)
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_q_td, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("div", _hoisted_13, [
                            _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t("order.asset.inventoryCols.numberOfDoors")), 1),
                            _createElementVNode("span", null, _toDisplayString(props.row.product_nb_of_door ? props.row.product_nb_of_door : '-'), 1)
                          ]),
                          _createElementVNode("div", _hoisted_15, [
                            _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t("order.asset.inventoryCols.numberOfSeats")), 1),
                            _createElementVNode("span", null, _toDisplayString(props.row.product_nb_of_seat ? props.row.product_nb_of_seat : '-'), 1)
                          ]),
                          _createElementVNode("div", _hoisted_17, [
                            _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$t("order.asset.inventoryCols.numberOfCylinders")), 1),
                            _createElementVNode("span", null, _toDisplayString(props.row.product_nb_of_cylinder ? props.row.product_nb_of_cylinder : ''), 1)
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_q_td, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_19, [
                          _createElementVNode("div", _hoisted_20, [
                            _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.$t("order.asset.inventoryCols.gearType")), 1),
                            _createElementVNode("span", null, _toDisplayString(props.row.product_mine_type ? props.row.product_mine_type : '-'), 1)
                          ]),
                          _createElementVNode("div", _hoisted_22, [
                            _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.$t("order.asset.inventoryCols.horsePower")), 1),
                            _createElementVNode("span", null, _toDisplayString(props.row.horse_power ? props.row.horse_power : '-'), 1)
                          ]),
                          _createElementVNode("div", _hoisted_24, [
                            _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.$t("order.asset.inventoryCols.taxHorsePower")), 1),
                            _createElementVNode("span", null, _toDisplayString(props.row.tax_horse_power ? props.row.tax_horse_power : '-'), 1)
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_q_td, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_26, [
                          _createElementVNode("div", _hoisted_27, [
                            _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.$t("order.asset.inventoryCols.bodywork")), 1),
                            _createElementVNode("span", null, _toDisplayString(props.row.body_style ? props.row.body_style : '-'), 1)
                          ]),
                          _createElementVNode("div", _hoisted_29, [
                            _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.$t("order.asset.inventoryCols.serialNumber")), 1),
                            _createElementVNode("span", null, _toDisplayString(props.row.serial_number  ? props.row.serial_number : '-'), 1)
                          ]),
                          _createElementVNode("div", _hoisted_31, [
                            _createElementVNode("span", _hoisted_32, _toDisplayString(_ctx.$t("order.asset.inventoryCols.numberOfGears")), 1),
                            _createElementVNode("span", null, _toDisplayString(props.row.product_nb_of_gear ? props.row.product_nb_of_gear : '-'), 1)
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_q_td, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_33, [
                          _createElementVNode("div", _hoisted_34, [
                            _createElementVNode("span", _hoisted_35, _toDisplayString(_ctx.$t("order.asset.inventoryCols.length")), 1),
                            _createElementVNode("span", null, _toDisplayString(props.row.vehicle_length ? props.row.vehicle_length : '-'), 1)
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_q_td),
                    _createVNode(_component_q_td)
                  ]),
                  _: 2
                }, 1032, ["props"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["rows", "columns", "selected"])
      ])
    ])
  ]))
}