const env = config.VUE_APP_ENV;
const basePath = config.VUE_APP_BACKEND_BASEPATH;
const supersetBasePath = config.VUE_APP_SUPERSET_BASEPATH;
const processDefinitionId = "start-process";
const appVersion = "2.0.9-RELEASE";
const serverEmail = config.VUE_SERVER_EMAIL;
const serverFullName = config.VUE_SERVER_FULLNAME;
const serverPhoneNumber = config.VUE_SERVER_PHONENUMBER;
export const settings = {
  api_url: `${basePath}`,
  api_query_url: `${basePath}/odm-dataservice/api/1/odm-dataservice/query/`,
  api_query_count_url: `${basePath}/odm-dataservice/api/1/odm-dataservice/queryCount/`,
  auth_anonymous_api_url: `${basePath}/odm-authentication/api/1/anonymous/token/?applicationName=TWDDemo`,
  auth_api_url: `${basePath}/odm-authentication/api/1/odm-authentication/token/?applicationName=TWDDemo`,
  env,
  processDefinitionId,
  appVersion,
  serverEmail : serverEmail,
  serverFullName : serverFullName ,
  serverPhoneNumber : serverPhoneNumber,
  supersetBasePath: supersetBasePath,
  superset_api_url: `${supersetBasePath}/api/v1/`,
  adminConfig: {
    password: process.env.VUE_APP_SUPERSET_ADMINCONFIG_PASSWORD,
    username: process.env.VUE_APP_SUPERSET_ADMINCONFIG_USERNAME,
    provider: process.env.VUE_APP_SUPERSET_ADMINCONFIG_PROVIDER,
    refresh: process.env.VUE_APP_SUPERSET_ADMINCONFIG_REFRESH,
  },
  guestConfig: {
    user: {
      username: process.env.VUE_APP_SUPERSET_GUESTCONFIG_USER_USERNAME,
      first_name: process.env.VUE_APP_SUPERSET_GUESTCONFIG_USER_FIRSTNAME,
      last_name: process.env.VUE_APP_SUPERSET_GUESTCONFIG_USER_LASTNAME,
    },
    resources: [
      {
        type: process.env.VUE_APP_SUPERSET_GUESTCONFIG_RESOURCES_TYPE,
        id: process.env.VUE_APP_SUPERSET_GUESTCONFIG_RESOURCES_ID,
      },
    ],
    rls: [],
  },
  theme: config.VUE_APP_THEME|| process.env.VUE_APP_THEME
};
// TO DO refactor the settings