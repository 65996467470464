import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6cc8ae69"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "card mb-3"
}
const _hoisted_2 = { class: "card-title flex-grow-1 d-flex justify-content-between align-items-center mb-0" }
const _hoisted_3 = { class: "d-flex flex-column" }
const _hoisted_4 = { class: "text-bold" }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = { class: "row mt-0" }
const _hoisted_7 = { class: "row mt-4" }
const _hoisted_8 = { class: "col-4" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-12 tw-asset-selector-wrapper" }
const _hoisted_11 = { class: "bg-light border-right col-12 pr-3 tw-asset-details-wrapper" }
const _hoisted_12 = { class: "col-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_ManageAssetFromInventory = _resolveComponent("ManageAssetFromInventory")!
  const _component_DemandModelVehicleBox = _resolveComponent("DemandModelVehicleBox")!
  const _component_DemandModelAssetBox = _resolveComponent("DemandModelAssetBox")!
  const _component_DemandVehicleDetailForm = _resolveComponent("DemandVehicleDetailForm")!
  const _component_DemandAssetDetailForm = _resolveComponent("DemandAssetDetailForm")!
  const _component_DemandVehicleCustom = _resolveComponent("DemandVehicleCustom")!
  const _component_DemandAssetCustom = _resolveComponent("DemandAssetCustom")!
  const _component_Comment = _resolveComponent("Comment")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isTaskAnalysis)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_q_btn, {
            icon: "add",
            label: _ctx.startLabel,
            class: "btn btn-action shadow-0 p-4",
            disable: _ctx.$store.getters['authModule/hasMiddleOffice'],
            "no-caps": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_tooltip, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("demand.button.addAsset")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_q_menu, {
                anchor: "bottom right",
                self: "top right"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_list, {
                    style: {"min-width":"100px"},
                    dense: ""
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roles, (role, index) => {
                        return _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                          clickable: "",
                          key: index
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_item_section, {
                              style: {"margin-left":"1em","margin-right":"1em"},
                              onClick: ($event: any) => {
                _ctx.addInternAssetTab(role);
                _ctx.internTab = _ctx.assets?.length;
              }
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("demand.asset."+role.value+".name")), 1)
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          _: 2
                        }, 1024)), [
                          [_directive_close_popup]
                        ])
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["label", "disable"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass({ selected: !_ctx.assets[0]?.config?.assetType })
    }, [
      _createVNode(_component_q_list, {
        modelValue: _ctx.internTab,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internTab) = $event))
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assets, (value, index) => {
            return (_openBlock(), _createBlock(_component_q_expansion_item, {
              name: index + 1,
              key: index,
              "switch-toggle-side": "",
              "default-opened": "",
              "header-class": "card-header bg-transparent no-border p-4",
              class: _normalizeClass(["card tw-items-dashed-group tw-level-one-group", 
          _ctx.currentRoute === 'TaskDetail'
            ? 'card tw-items-dashed-group tw-items-group-with-bg tw-level-two-group'
            : ''
        ])
            }, {
              header: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.getAssetIndexString(value, index, _ctx.assets)), 1)
                  ]),
                  (_ctx.assets.length > 1)
                    ? (_openBlock(), _createBlock(_component_q_btn, {
                        key: 0,
                        icon: "delete",
                        class: "btn btn-outline-gray-1",
                        onClick: _withModifiers(($event: any) => {
                _ctx.deleteAsset(index);
                _ctx.internTab = _ctx.assets.length;
              }, ["stop"])
                      }, null, 8, ["onClick"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_ManageAssetFromInventory, {
                      currentModule: _ctx.currentModule,
                      tabPosition: index
                    }, null, 8, ["currentModule", "tabPosition"])
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("div", _hoisted_10, [
                          (value.config?.assetType === 'vehicle')
                            ? (_openBlock(), _createBlock(_component_DemandModelVehicleBox, {
                                key: 0,
                                tabPosition: index
                              }, null, 8, ["tabPosition"]))
                            : (_openBlock(), _createBlock(_component_DemandModelAssetBox, {
                                key: 1,
                                isSelected: Boolean(!_ctx.assets[0].config?.assetType)
                              }, null, 8, ["isSelected"]))
                        ]),
                        _createElementVNode("div", _hoisted_11, [
                          (value.config?.assetType === 'vehicle')
                            ? (_openBlock(), _createBlock(_component_DemandVehicleDetailForm, {
                                key: 0,
                                tabPosition: index
                              }, null, 8, ["tabPosition"]))
                            : (_openBlock(), _createBlock(_component_DemandAssetDetailForm, {
                                key: 1,
                                isSelected: Boolean(!_ctx.assets[0].config?.assetType)
                              }, null, 8, ["isSelected"]))
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      (value.config?.assetType === 'vehicle')
                        ? (_openBlock(), _createBlock(_component_DemandVehicleCustom, {
                            key: 0,
                            class: _normalizeClass(_ctx.isTaskAnalysis ? 'tw-disabled-components-wrapper' : ''),
                            tabPosition: index
                          }, null, 8, ["class", "tabPosition"]))
                        : (_openBlock(), _createBlock(_component_DemandAssetCustom, {
                            key: 1,
                            isSelected: Boolean(!_ctx.assets[0].config?.assetType),
                            class: _normalizeClass(_ctx.isTaskAnalysis ? 'tw-disabled-components-wrapper' : '')
                          }, null, 8, ["isSelected", "class"]))
                    ])
                  ])
                ]),
                (_ctx.currentRoute === 'TaskDetail' && _ctx.opinionQualifications.length > 0)
                  ? (_openBlock(), _createBlock(_component_Comment, {
                      key: 0,
                      qualificationType: 'AST-01'
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["name", "class"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"])
    ], 2)
  ], 64))
}