import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00b7bf9e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card bg-light" }
const _hoisted_2 = { class: "card-header bg-transparent no-border" }
const _hoisted_3 = { class: "card-title flex-grow-1 justify-content-between align-items-center mb-0" }
const _hoisted_4 = {
  class: "d-flex align-items-center",
  id: "demand_edit_finnacement_filterByFinancialProduct"
}
const _hoisted_5 = { class: "d-block font-medium mb-0 mr-3" }
const _hoisted_6 = {
  class: "form-control no-border w-auto p-0",
  style: {"min-width":"235px"}
}
const _hoisted_7 = { class: "card-body pt-0 pb-0" }
const _hoisted_8 = { class: "row form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_select = _resolveComponent("q-select")!
  const _component_SimulationScaleItem = _resolveComponent("SimulationScaleItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("demand.financialSituation.filterByFinancialProduct")), 1),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_q_select, {
              "emit-value": "",
              "map-options": "",
              outlined: "",
              dense: "",
              "data-testid": "demand_edit_financement_simulation1_Filter-by-financial-product",
              id: "productTypeInput",
              options: _ctx.packsCombobox,
              modelValue: _ctx.financingProductModel.type.value,
              "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = ($event: any) => ((_ctx.financingProductModel.type.value) = $event)),
                _cache[1] || (_cache[1] = ($event: any) => (_ctx.filterScales($event)))
              ]
            }, null, 8, ["options", "modelValue"])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.scaleGroup, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "col-lg-3 col-md-3 col-sm-3 form-control no-border"
          }, [
            _createVNode(_component_SimulationScaleItem, {
              scale: item,
              onOnSelectScale: _ctx.onSelectScale,
              isScaleSelected: _ctx.isScaleSelected(item)
            }, null, 8, ["scale", "onOnSelectScale", "isScaleSelected"])
          ]))
        }), 128))
      ])
    ])
  ]))
}