
import { Third, ThirdType } from "@/types";
import { Options, Vue } from "vue-class-component";
import store from "@/store";
import i18n from "@/i18n";
import ThirdSearchDialog from "../demand/dialogs/ThirdSearchDialog.vue";
@Options({
    watch: {
        'party1'(newVal, oldVal) {
            if (newVal !== '') {
               this.party = this.party1
            }
        }
    },
    components: {
        ThirdSearchDialog,
    },
})
export default class ThirdSelector extends Vue {
    party  = this.party1
    role?: any={};
    showDialog?: boolean = false;
    openDialog = "";
    selectedPerson = true;

    get party1 (){
         return store.state.garantyModule?.selectedguarantor
        }
          

   
    get clientType() {
        return store.getters["picklistModule/clientTypeItems"]?.map((item: any) => {
            return {
                ...item,
                label: item.id?.toString() !== "" ? i18n.global.t("enum.party.type." + item.id) : ''
            };
        });
    }
    get offerAssociated() {
        return store.getters["demandModule/getOfferAssociatedPartyInit"];
    }
    
    get mode() {
        return store.state.garantyModule?.mode;

    }

    get selectedGaranty(){
        return store.state.garantyModule?.selectedgarantydetails
    }
    get roleThird(){
        this.role.role_code=this.selectedGaranty?.role?.type.value
        return this.role
    }

    searchThird() {
        this.showDialog = true;
        this.openDialog = this.party?.type?.id ?? "party-FrenchPerson";
    }
    emitParty() {
        this.$emit('partyValue', this.party);
    }
    
    onSelectedItem(row: any) {
        store.dispatch("thirdModule/executeSelectThird", {
        //store.dispatch("garantyModule/executeSelectedGarantor", {
            id: row.id,
            type: row._discriminator,
            callback: () => {
                if (this.party) {
                    if (row._discriminator === ThirdType.PERSON) {
                        this.buildPerson(this.party);
                    } else {
                        this.buildOrganization(this.party);

                        this.selectedPerson = false;
                    }
                }
            },
        });
        this.onCloseThird();
        this.emitParty();
    }
    private buildOrganization(party: Third) {
        party.organization = store.state.thirdModule.third?.organization;
        if (party.organization?.addresses) {
            party.organization.addresses =
                store.state.thirdModule.third?.organization?.addresses;
            if (store.state.thirdModule.third?.organization?.roles?.length === 0) {
                party.organization.roles = [store.getters["demandModule/getRoleInit"]];
            }
        }
    }
    private buildPerson(party: Third) {
        party.person = store.state.thirdModule.third?.person;
        if (party.person?.addresses) {
            party.person.addresses = store.state.thirdModule.third?.person?.addresses;
            if (store.state.thirdModule.third?.person?.roles?.length === 0) {
                party.person.roles = [store.getters["demandModule/getRoleInit"]];
            }
        }
    }
    onCloseThird() {
        this.showDialog = false;
        store.dispatch('financingModule/serviceModule/refreshPartyInsurance', {})
    }

}
