
import { Options, Vue } from "vue-class-component";
import store from "@/store";
import OdmSliderHT from "@/components/general/OdmSliderHT.vue";
import OdmSliderTTC from "@/components/general/OdmSliderTTC.vue";

@Options({
  props: ["sliders"],
  components: {
    OdmSliderHT,
    OdmSliderTTC
  },
  watch: {
    calculationTypeSelected(value: string) {
      store.dispatch("simulationModule/changeFinancingTypeTTC", {
        isTTC: value === "TTC",
      });
    },
  },
})
export default class OdmSliderGroup extends Vue {
  calculationTypeSelected = 'HRS'

  sliders?: any;

  get isFinancingTypeTTC() {
    return store.state.simulationModule.isFinancingTypeTTC
  }

}
