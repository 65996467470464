
import { Options, Vue } from "vue-class-component";
import { globalConfig } from "@/utils";
import { ProposalGuarantee } from "@/types";
import GuaranteeDetail from "@/components/middle-office/tabs/financing/guarantee/GuaranteeDetail.vue"
import i18n from "@/i18n";
import Notify from 'quasar/src/plugins/Notify.js';;
import store from "@/store";

@Options({
  components: {GuaranteeDetail}
})
export default class GuaranteeTab extends Vue {


  displayGuarantee =false;
  /**
   * Retrieves the columns of the table.
   *
   * @return {any} The columns of the table.
   */
  get columns(): any {
    return globalConfig.table_config.guaranteeCols;
  }

  /**
   * Retrieves the rows of proposalGuarantees.
   *
   * @return {Array<ProposalGuarantee>} The array of guarantee rows.
   */
  get rows(): Array<ProposalGuarantee> {
    return this.proposalGuarantees;
  }

  /**
   * Edits a row.
   *
   * @param {IGuaranteeProduct} row - The row to be edited.
   */
  view(row: ProposalGuarantee) {
    store.dispatch('middleOfficeModule/viewProposalGuarantee', row);
    this.displayGuarantee=true

  }

  closeDetail() {
    this.displayGuarantee = false
  }

  /**
   * Get the proposalGuarantees.
   *
   * @return {Array<ProposalGuarantee>} The proposalGuarantees.
   */
  get proposalGuarantees(): Array<ProposalGuarantee> {
    return store.getters["middleOfficeModule/getProposalGuarantees"];
  }



}
