import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bg-white card-header q-mt-md" }
const _hoisted_2 = { class: "d-flex justify-content-between" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "font-weight-bold" }
const _hoisted_5 = { class: "pt-0" }
const _hoisted_6 = { class: "table-responsive" }
const _hoisted_7 = { class: "q-ml-sm" }
const _hoisted_8 = { class: "tw-sidebar-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_guaranteeDetail = _resolveComponent("guaranteeDetail")!
  const _component_q_scroll_area = _resolveComponent("q-scroll-area")!
  const _component_q_drawer = _resolveComponent("q-drawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("guarantees.labels.guarantees")), 1),
          _createVNode(_component_q_btn, {
            "data-testid": "demand_edit_financement_simulation1_guarentee_btn-addGuarantee",
            class: "btn btn-primary ml-3",
            icon: "add",
            label: _ctx.$t('guarantees.labels.addGuarantee'),
            onClick: _ctx.openDialog
          }, null, 8, ["label", "onClick"])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_q_table, {
            class: "shadow-0 table table-sm",
            rows: _ctx.rows,
            columns: _ctx.columns,
            "row-key": "id",
            "no-data-label": _ctx.$t('guarantees.labels.listEmpty')
          }, {
            header: _withCtx((props) => [
              _createVNode(_component_q_tr, { props: props }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                    return (_openBlock(), _createBlock(_component_q_th, {
                      key: col.name,
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t(col.label)), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  }), 128)),
                  _createVNode(_component_q_th, { "auto-width": "" })
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            body: _withCtx((props) => [
              _createVNode(_component_q_tr, { props: props }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                    return (_openBlock(), _createBlock(_component_q_td, {
                      key: col.name,
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(col.value), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  }), 128)),
                  _createVNode(_component_q_td, { "auto-width": "" }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", null, [
                        _createVNode(_component_q_btn, {
                          class: "btn btn-action",
                          loading: props.row.isLoading,
                          icon: "edit",
                          onClick: _withModifiers(($event: any) => (_ctx.edit(props.row, props.rowIndex)), ["stop"])
                        }, null, 8, ["loading", "onClick"])
                      ]),
                      _createElementVNode("span", _hoisted_7, [
                        _createVNode(_component_q_btn, {
                          class: "btn btn-action",
                          loading: props.row.isLoading,
                          icon: "delete",
                          onClick: _withModifiers(($event: any) => (_ctx.remove(props.row, props.rowIndex)), ["stop"])
                        }, null, 8, ["loading", "onClick"])
                      ])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            _: 1
          }, 8, ["rows", "columns", "no-data-label"])
        ])
      ])
    ]),
    _createVNode(_component_Dialog, {
      onOnClose: _ctx.closeProductGuaranteeDialog,
      onAddFunction: _ctx.addNewProposalGuarantee,
      show: _ctx.showDialog,
      label: _ctx.$t('guarantees.labels.selectGuarantee'),
      component: _ctx.componentToRender,
      mainView: false
    }, null, 8, ["onOnClose", "onAddFunction", "show", "label", "component"]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_q_drawer, {
        modelValue: _ctx.showDetailGuarantee,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showDetailGuarantee) = $event)),
          _ctx.closeScaleDetail
        ],
        breakpoint: 2000,
        "no-swipe-close": "",
        bordered: "",
        side: "right",
        class: "bg-white"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_scroll_area, { style: {"height":"100%"} }, {
            default: _withCtx(() => [
              (_ctx.showDetailGuarantee)
                ? (_openBlock(), _createBlock(_component_guaranteeDetail, {
                    key: 0,
                    onAddProposalGuarantee: _ctx.addProposalGuarantee
                  }, null, 8, ["onAddProposalGuarantee"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue", "onUpdate:modelValue"])
    ])
  ], 64))
}