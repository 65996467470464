import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-center"
}
const _hoisted_2 = {
  key: 1,
  class: "flex flex-center"
}
const _hoisted_3 = { key: 2 }
const _hoisted_4 = ["model-value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_spinner_gears = _resolveComponent("q-spinner-gears")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_icon = _resolveComponent("q-icon")!

  return (!_ctx.scale || _ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_q_spinner_gears, {
          color: "primary",
          size: "sm"
        })
      ]))
    : (_ctx.scale && _ctx.loadingError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_q_icon, {
            name: "error",
            size: "sm",
            color: "negative"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_tooltip, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("main.dialog.error")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
      : (_ctx.scale)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", {
              class: _normalizeClass(["cursor-pointer border bg-white default-border-radius p-2 d-flex flex-column", { 'border-primary': _ctx.isScaleSelected }]),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onSelectScale && _ctx.onSelectScale(...args))),
              "model-value": _ctx.isScaleSelected
            }, [
              _createElementVNode("span", {
                class: _normalizeClass(["font-size-14 m-0", { 'text-primary': _ctx.isScaleSelected }])
              }, _toDisplayString(_ctx.scale.productName), 3),
              _createElementVNode("span", {
                class: _normalizeClass(["font-size-14 font-semi-bold", { 'text-primary': _ctx.isScaleSelected }])
              }, _toDisplayString(_ctx.tittle) + " " + _toDisplayString(_ctx.subTittle), 3),
              (_ctx.gracePeriod)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: _normalizeClass(["font-size-14 font-semi-bold", { 'text-primary': _ctx.isScaleSelected }])
                  }, _toDisplayString(_ctx.gracePeriodPresentation), 3))
                : _createCommentVNode("", true)
            ], 10, _hoisted_4)
          ]))
        : _createCommentVNode("", true)
}