
import { Options, Vue } from "vue-class-component";
import { globalConfig } from "@/utils";
import DecisionReserve from "@/components/middle-office/tabs/decision/DecisionReserve.vue";
import DecisionCard from "@/components/middle-office/tabs/decision/DecisionCard.vue";

@Options({
  components: { DecisionCard, DecisionReserve },
  props: ["decisions"],
})
export default class DecisionsPanel extends Vue {
  decisions?: any;

  columns = globalConfig.table_config.middleOfficeTable.decisionsReserve;
}
