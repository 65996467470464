
import i18n from "@/i18n";
import store from "@/store";
import { ComboBoxItem, ProposalGuarantee, UniformOdmName } from "@/types";
import { Options, Vue } from "vue-class-component";


export default class GuaranteeDetail extends Vue {

    partyList: any = []

    asset: any = []



    get offer() {
        return store.state.middleOfficeModule?.offer;
    }

    get associatedParties() {
        this.offer?.associatedParties?.forEach(party => {
            if (party.associatedParty?.third && party.associatedParty.third.type) {
                if (party.associatedParty.third.type.id === "party-FrenchPerson") {
                    const firstName = party.associatedParty.third.person?.firstName || "";
                    const familyName = party.associatedParty.third.person?.familyName || "";
                    const fullName = `${firstName} ${familyName}`.trim();
                    if (fullName) {
                        this.partyList.push(fullName);
                    }
                } else if (party.associatedParty.third.type.id === "party-FrenchOrganization") {
                    const organizationName = party.associatedParty.third.organization?.name || "";
                    if (organizationName) {
                        this.partyList.push(organizationName);
                    }
                }
            }
        });
        return this.partyList;
    }

    get percentage() {
        return [
            { resourceUid: this.$t("garantie.garantiedetails.Basis.FinancedAmount") },

            { resourceUid: this.$t("garantie.garantiedetails.Basis.TTC") },

            { resourceUid: this.$t("garantie.garantiedetails.Basis.HT") },

            { resourceUid: this.$t("garantie.garantiedetails.Basis.OutstandingAmount") }
        ]?.map((item) => item.resourceUid);
    }

    get underlyingType() {
        return [
            { resourceUid: this.$t("garantie.garantiedetails.Asset") },

            { resourceUid: this.$t("garantie.garantiedetails.Properties") },

        ]?.map((item) => item.resourceUid);
    }

    get guarantyProposalType() {
        const productGuarantee = this.proposalGuaranteeSelected.productGuarantee;
        if (productGuarantee?.guaranteeType) {
            return i18n.global.t(
                "garanty.guaranteeType." +
                (productGuarantee.guaranteeType as string).replace("product-", "")
            );
        }
        return "";
    }

    get guaranteeType() {
        return store.getters["picklistModule/garantyTypeItems"].map((item: any) => {
            return {
                ...item,
                label: item.value?.toString() !== "" ? i18n.global.t("garanty.garantyFilter.picklist.type." + item.value) : ''
            };
        })
    }

    get proposalGuaranteeSelected() {
        const test = store.getters["middleOfficeModule/getProposalGuaranteeSelected"];

        return test
    }

    get getUnderlyingTypeModel(): string | ComboBoxItem | undefined | UniformOdmName {
        const productGuarantee = this.proposalGuaranteeSelected.productGuarantee;
        return productGuarantee?.underlyingType.resourceUid;
    }

    get associatedPartyName(): any {
        let associatedPartyName: ComboBoxItem = {
            value: "",
        };

        const party = this.proposalGuaranteeSelected.associatedParty?.third;
        let partyName = ''
        if (party.person) {
            partyName = party.person.firstName + ' ' + party.person.familyName
        }
        if (party.organization) {
            partyName = party.organization.name
        }
        associatedPartyName = {
            value: partyName,
            label: partyName
        }

        return associatedPartyName;
    }


    closeDetail() {
        this.$emit('closeDetail');
    }
}
