
import { Options, Vue } from "vue-class-component";
import store from "@/store";
import PrintDocument from "@/components/task/tabs/PrintDocument.vue";

@Options({
  components: {
    PrintDocument
  }
})
export default class PrintDocumentDialog extends Vue {
  get show() {
    return store.state.printDocumentModule.show
  }

  get showSendTemplate() {
    return store.state.printDocumentModule.showSendTemplate;
  }

  get reference() {
    switch (this.currentRoute) {
      case "order-edit": {
        return store.state.orderModule.order?.reference
      }
      case "TaskDetail": {
        const entityType = store.getters['taskModule/isTaskModuleOffer'] ? 'offer' : 'order'
        if (entityType === 'offer') {
          return store.state.taskModule.offer?.reference
        } else {
          return store.state.taskModule.order?.reference
        }
      }
      case "demand-edit": {
        return store.state.demandModule.offer?.reference
      }
      case "third-edit": {
        return store.state.thirdModule.third?.person?.reference
      }
      case "mo-demand-detail": {
        return store.state.middleOfficeModule.offer?.reference
      }

    }
    return '-'
  }

  get dialogState() {
    return store.state.printDocumentModule.printDocumentConfirmationDialogState
      && store.state.printDocumentModule.printDocumentConfirmationDialogState.show
  }

  toggleShowControls() {
    return store.dispatch('printDocumentModule/toggleShow')
  }

  get currentRoute() {
    return this.$router.currentRoute.value.name;
  }

  // created() {
  //   store.dispatch("printDocumentModule/getTemplates", {
  //     currentRoute: this.currentRoute
  //   });
  // }

}
