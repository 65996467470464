
import store from '@/store';
import { styleClasses } from '@/types';
import { Options, Vue } from 'vue-class-component';
import { Emit } from "vue-property-decorator";

@Options({
    props: ["show", "label", "component", "props", "size"],
    watch: {
        "$store.state.garantyModule.saveAuthorized"(currentContext) {
            this.saveAuthorized = currentContext;
        },
    },
})

export default class SideBar extends Vue {
    show?: boolean
    label?: string
    component?: object
    props?: object
    size?: string;
    saveAuthorized: boolean = store.state.garantyModule.saveAuthorized

    @Emit("onClose")
    onClose(evt: any) { }

    @Emit("onSave")
    onSave(evt: any) { }

    /**
     * Indicates whether the drawer is open based on the value of the 'show' prop.
     *
     * @returns {boolean} - A boolean value indicating whether the drawer is open.
     */
    get isDrawerOpen() {
      return this.show;
    }

    get disableSave(){
      return (this.props as any)?.disableSave;

    }

    get notDisplaySave(){
      return !(this.props as any)?.notDisplaySave;
    }

}

