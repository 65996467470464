import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "default-border-radius table-responsive p-3 pt-0 pb-0 bg-white"
}
const _hoisted_2 = {
  key: 1,
  class: "default-border-radius table-responsive p-3 pt-0 pb-0 bg-white"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_DecisionReservesPanel = _resolveComponent("DecisionReservesPanel")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_SideBarEfficom = _resolveComponent("SideBarEfficom")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isTaskEnterOpinion || _ctx.isTaskTypeMonoDecision)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_q_table, {
            rows: _ctx.selectedDecision,
            "row-key": "resourceUid",
            class: "shadow-0 table table-sm bg-white mb-0",
            "hide-pagination": ""
          }, {
            header: _withCtx((props) => [
              _createVNode(_component_q_tr, { props: props }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_th, { "auto-width": "" }),
                  _createVNode(_component_q_th, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('opinion.enterDecisionTab.decision.decisionDate')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_q_th, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('opinion.decisionTab.decision')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_q_th, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('opinion.decisionTab.analysisReference')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_q_th, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('opinion.decisionTab.decisionMaker')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_q_th, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('opinion.decisionTab.comment')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            body: _withCtx((props) => [
              _createVNode(_component_q_tr, { props: props }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_td, { "auto-width": "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_btn, {
                        class: "btn btn-sm btn-primary",
                        size: "sm",
                        color: "accent",
                        round: "",
                        dense: "",
                        onClick: ($event: any) => (props.expand = !props.expand),
                        icon: props.expand ? 'remove' : 'add',
                        disable: props.row.length === 0
                      }, null, 8, ["onClick", "icon", "disable"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_q_td, { style: {"max-width":"60px"} }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(props.row.decisionDate ? _ctx.getDateFormat(props.row.decisionDate) :
            _ctx.getDateFormat()), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_q_td, { style: {"max-width":"60px"} }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(props.row.status.resourceUid ?
            _ctx.$t("enum.offer.status.".concat(props.row.status.resourceUid) ): "-"), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_q_td, { style: {"max-width":"60px"} }, {
                    default: _withCtx(() => [
                      (_ctx.analysis && _ctx.analysis.reference && _ctx.analysis.resourceUid)
                        ? (_openBlock(), _createBlock(_component_q_btn, {
                            key: 0,
                            class: "btn bg-transparent p-0 btn-link text-primary",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onSelect())),
                            label: _ctx.analysis?.reference ? _ctx.analysis?.reference : '-'
                          }, null, 8, ["label"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_q_td, { style: {"max-width":"60px"} }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.connectedUser.userName ? _ctx.connectedUser.userName : "-"), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_q_td, { style: {"min-width":"130px"} }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(props.row.comment ? props.row.comment : "-"), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["props"]),
              _withDirectives(_createVNode(_component_q_tr, {
                props: props,
                class: "tw-expanded-table-row"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_td, {
                    colspan: "100%",
                    class: "p-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DecisionReservesPanel, {
                        decisionReserves: props.row.decisionReserves
                      }, null, 8, ["decisionReserves"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["props"]), [
                [_vShow, props.expand]
              ])
            ]),
            _: 1
          }, 8, ["rows"])
        ]))
      : (_ctx.isTaskModifyConditions)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_q_table, {
              rows: [_ctx.decisionParam],
              "row-key": "resourceUid",
              class: "shadow-0 table table-sm bg-white mb-0",
              "hide-pagination": ""
            }, {
              header: _withCtx((props) => [
                _createVNode(_component_q_tr, { props: props }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_th, { "auto-width": "" }),
                    _createVNode(_component_q_th, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('opinion.enterDecisionTab.decision.decisionDate')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_th, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('opinion.decisionTab.decision')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_th, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('opinion.decisionTab.analysisReference')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_th, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('opinion.decisionTab.decisionMaker')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_th, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('opinion.decisionTab.comment')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              body: _withCtx((props) => [
                _createVNode(_component_q_tr, { props: props }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_td, { "auto-width": "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_btn, {
                          class: "btn btn-sm btn-primary",
                          size: "sm",
                          color: "accent",
                          round: "",
                          dense: "",
                          onClick: ($event: any) => (props.expand = !props.expand),
                          icon: props.expand ? 'remove' : 'add',
                          disable: props.row.length === 0
                        }, null, 8, ["onClick", "icon", "disable"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_q_td, { style: {"max-width":"60px"} }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.decisionDate ? _ctx.getDateFormat(props.row.decisionDate) :
            _ctx.getDateFormat()), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_q_td, { style: {"max-width":"60px"} }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.status.resourceUid ?
            _ctx.$t("enum.offer.status.".concat(props.row.status.resourceUid) ): "-"), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_q_td, { style: {"max-width":"60px"} }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_btn, {
                          class: "btn bg-transparent p-0 btn-link text-primary",
                          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.onSelect1(_ctx.analysisRessourceUid,_ctx.analysisReference)), ["stop","prevent"])),
                          label: _ctx.analysisReference
                        }, null, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_td, { style: {"max-width":"60px"} }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.decisionMaker ? props.row.decisionMaker : "-"), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_q_td, { style: {"min-width":"130px"} }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.comment ? props.row.comment : "-"), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _withDirectives(_createVNode(_component_q_tr, {
                  props: props,
                  class: "tw-expanded-table-row"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_td, {
                      colspan: "100%",
                      class: "p-0"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DecisionReservesPanel, {
                          decisionReserves: props.row.decisionReserves
                        }, null, 8, ["decisionReserves"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["props"]), [
                  [_vShow, props.expand]
                ])
              ]),
              _: 1
            }, 8, ["rows"])
          ]))
        : _createCommentVNode("", true),
    _createVNode(_component_SideBarEfficom, {
      cancelButtonLabel: _ctx.cancelButtonLabel,
      component: _ctx.componentToRenderSB,
      ctaLabel: _ctx.sidebarCtaLabel,
      label: _ctx.sidebarLabel,
      props: _ctx.componentPropsSB,
      show: _ctx.openAnalysisReferenceDetails,
      onOnClose: _ctx.onCloseAnalysisReferenceDetails
    }, null, 8, ["cancelButtonLabel", "component", "ctaLabel", "label", "props", "show", "onOnClose"])
  ], 64))
}