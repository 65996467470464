import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card pt-0" }
const _hoisted_2 = { class: "table-responsive" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_q_table, {
        rows: _ctx.rows,
        columns: _ctx.columns,
        "row-key": "name",
        separator: "none",
        dense: "",
        "hide-pagination": "",
        rowsPerPageOptions: [0],
        tableHeaderClass: "bg-blue-1 ",
        class: "table table-sm"
      }, null, 8, ["rows", "columns"])
    ])
  ]))
}