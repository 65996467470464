
import store from "@/store";
import {Options, Vue} from "vue-class-component";

@Options({
  props: ["data", "reCalculation"],
  watch: {},
})
export default class QuestionsByCategory extends Vue {
  data: any;
  reCalculation: any;
  get answersState() {
    return store.state.analysisModule?.selectedAnswersOptions;
  }
}
