
import { Vue, Options } from "vue-class-component";
import { formatWithMask,formatAmoutswitchCurrency } from "@/utils/configuration/formatters-config";
import { Proposal } from "@/types";
import LabelAndValue from "@/components/general/LabelAndValue.vue";

@Options({
  components: { LabelAndValue },
  props: ["proposal"],
})
export default class FinancingForm extends Vue {
  formatWithMask = formatWithMask;
  proposal?: Proposal;
  formatAmoutswitchCurrency = formatAmoutswitchCurrency;
  valueStyle = {
    "font-weight": "bold",
    "font-size": "1rem",
    "margin-left": "20px",
  };
}
