import {Module} from "vuex";
import {AuthState, AuthUser, EmailMessage, FileContent, HomePageRoles} from "@/types";
import {
    codeConfirmation,
    forgotPassword,
    forgotPasswordCodeConfirmation,
    getAuthorities,
    getUser,
    signIn,
    signUp,
    updateLanguage,
    updatePassWordService
} from "@/store/services/auth";
import {getAccesToken} from "@/store/services/dashboard"
import Notify from 'quasar/src/plugins/Notify.js';;
import router from "@/router";
import {checkUserByRole, fetchAvatar, globalConfig, parseJwt, SESSION_TOKEN_KEY, tokenIsValid, UON} from "@/utils";
import {getTranslations} from "@/i18n.service.handler";
import {getSpecificLanguage} from "../services/picklist";
import i18n from "@/i18n";
import {formatDateLocale} from "@/utils/configuration/formatters-config";
import store from "@/store";
import {sendMail} from "../services/task/contactMechanism";
import moment from "moment";
import {getBase64} from "@/commons/gathering-process-order";
import {saveFile} from "../services/offerService";

function errorMessage(err: any) {
    Notify.create({
        timeout: 10000,
        actions: [{ icon: 'close', color: 'white' }],
        message: err.message || err.error || err,
        color: 'negative'
    });
}

function handlingError(err: any) {
    if (err.response) {
        if (err.response.status === 401) {
            errorMessage(`Error! Unauthorized.`);
        }
        else if (err.response.status === 403) {
            errorMessage(i18n.global.t('main.connect.wrongCredentials'));
        }
        else {
            errorMessage(`Error! ${err.response.data.message ? err.response.data.message.returnMessage : err.response.status + ': ' + err.response.data}`);
        }
    } else {
        errorMessage(`Error! ${err.message}`);
    }
    store.state.isLoading = false
    console.error(err);
}

async function load_i18nTranslations(loggedUser: AuthUser) {
    const lang = loggedUser.defaultLanguage;
    await getTranslations(lang);

}

const authModule: Module<AuthState, any> = {
    namespaced: true,
    state: {
        user: {
            username: '',
            password: '',
            daaqList: [],
        },
        daaqResourceUid: '',
        daaqLabel: '',
        daaq: '/',
        msgTemplate: true,
        changePassworIn: true,
        userAuthorities: [],
        selectedAvatar: null,
        groups: [],
    },
    getters: {
        getUserInit() {
            return {
                _controls: {
                    attToRemove: ['smsValidated'],
                },
                applicationName: 'TWDDemo',
                smsValidated: false
            }
        },
        hasMiddleOffice(state) {
            const middleOffice = state.user.authorities?.filter((authority: { resourceUid: string }) => authority.resourceUid.includes('GRP_MIDDLEOFFICE'))
            return middleOffice ? middleOffice.length > 0 : false
        },
        getUserConnected(state) {
            return state.user;
        },
        getDefaultDAAQConfig(state) {
            return state.user?.daaqList?.[0]?.config || {};
        },

        getIsAllowedToCreateOffer(state: any): boolean {
            const allowedGroups = ["GRP_SALESMANAGER", "GRP_ADMIN", "GRP_SALES"];
            const userGroups = state.groups;
            return userGroups?.some((group: any) =>
                allowedGroups.includes(group.group.resourceUid)
            );
        },
        getClassificationAccess(state: any): boolean {
            return !state.groups?.some((group: any) =>
                group.group.resourceUid === "GRP_SALES"
            );
        }
    },
    actions: {
        selectDAAQ({ commit }, payload) {
            if (payload) {
                const { daaq, daaqResourceUid, daaqLabel } = payload
                if (daaq && daaqResourceUid) {
                    commit('setDAAQ', { daaq, daaqResourceUid, daaqLabel })
                }
            }
        },
        recoverState({ commit }) {
            const prevState = localStorage.getItem(globalConfig.storageKeys.authModule)
            if (prevState) {
                const obj = JSON.parse(prevState)
                commit('setRecoverState', obj)
            }
        },
        async initializeAuth({ commit, getters }) {
            // Check if auth user exists in local storage
            const existingAuthUser = localStorage.getItem(globalConfig.storageKeys.authModule);
           
            const tokenValid = tokenIsValid();
            if (tokenValid) {
                if (!existingAuthUser) {
                    const authUser = getters.getUserInit;
                    const tokenData = parseJwt(sessionStorage.getItem(SESSION_TOKEN_KEY));
                    authUser.loggedIn = tokenValid;
                    authUser.username = tokenData.username;
                    authUser.firstName = tokenData.firstName;
                    authUser.lastName = tokenData.lastName;
                    authUser.email = tokenData.email;
                    authUser.userId = tokenData.userResourceUid;
                    authUser.applicationName = tokenData.applicationName;

                    const { daaq } = tokenData
                    if (daaq && daaq.length) {
                        authUser.daaqList = tokenData.daaq.map((item: any) => {
                            return {
                                label: item.label,
                                value: item.resourceUid,
                                config: { ...item }
                            }
                        });
                        const firstDAAQ = authUser.daaqList[0].config
                        commit('setDAAQ', { daaq: firstDAAQ.daaq, daaqResourceUid: firstDAAQ.resourceUid, daaqLabel: firstDAAQ.label })
                    }
                    const user = await getUser(tokenData.userResourceUid);
                    if (user.data.avatarFileId) {
                        store.state.authModule!.selectedAvatar = await fetchAvatar(user.data.avatarFileId.resourceUid) || null
                    }
                    if (user.data.userGroups) {
                        store.state.authModule!.groups = user.data.userGroups
                    }
                    const response = await getAuthorities()
                    authUser.authorities = response.data.authorities
                    const defaultLanguage = await getSpecificLanguage({ language: user.data.defaultLanguage })
                    authUser.defaultLanguage = defaultLanguage.items[0]

                    const preferredLanguages = []
                    for (const language of user.data.preferredLanguages) {
                        const defaultLanguage = await getSpecificLanguage({ language: language.language })
                        preferredLanguages.push(defaultLanguage.items[0])
                    }

                    authUser.preferredLanguages = preferredLanguages
                    commit('setUser', authUser);

                    await getTranslations(authUser.defaultLanguage, true);


                    if (user && user.data && user.data.associatedParty && user.data.associatedParty) {
                        commit('setPartyUid', user.data.associatedParty);

                    }
                    // console.log('ret',authModule.state)
                    // // Save the authenticated user to local storage
                    // localStorage.setItem(globalConfig.storageKeys.authModule, JSON.stringify(authModule.state));


                }
                else {
                    const existingUser = JSON.parse(existingAuthUser)
                    this.state.authModule.user = existingUser.user
                    this.state.authModule.daaqResourceUid = existingUser.daaqResourceUid
                    this.state.authModule.daaqLabel = existingUser.daaqLabel
                    this.state.authModule.daaq = existingUser.daaq
                    this.state.authModule.msgTemplate = existingUser.msgTemplate
                    this.state.authModule.changePassworIn = existingUser.changePassworIn
                    this.state.authModule.userAuthorities = existingUser.userAuthorities
                    this.state.authModule.selectedAvatar = existingUser.selectedAvatar
                    this.state.authModule.groups = existingUser.groups
                    await getTranslations(this.state.authModule.user.defaultLanguage, true);
                }
            }
            else {
                router.push('/login').catch(err => errorMessage('Error! ' + err));
            }
        },
        async signIn({ commit, getters, dispatch }, payload) {
            store.state.isLoading = true
            const redirectToChangePassword = () => {
                commit('setChangePassWordIn', false);
                router.push('/changePassword').catch(err => errorMessage('Error! ' + err));
            };
            payload.login = payload.username;
            payload.rememberMe = true;
            payload.applicationName = getters.getUserInit.applicationName;
            try {
                const token = await signIn(payload)
                sessionStorage.setItem(SESSION_TOKEN_KEY, token);
                const loggedUser = getters.getUserInit;

                const response = await getAuthorities()
                loggedUser.authorities = response.data.authorities
                loggedUser.temporaryPassword = response.data.temporaryPassword
                loggedUser.passwordExpirationDate = response.data.passwordExpirationDate
                const tokenData = parseJwt(token);
                loggedUser.resourceUid = tokenData.userResourceUid;
                loggedUser.userId = tokenData.userResourceUid;
                loggedUser.username = tokenData.username;
                loggedUser.firstName = tokenData.firstName;
                loggedUser.lastName = tokenData.lastName;
                loggedUser.email = tokenData.email;
                loggedUser.applicationName = tokenData.applicationName;
                const { daaq } = tokenData
                if (daaq && daaq.length) {
                    loggedUser.daaqList = tokenData.daaq.map((item: any) => {
                        return {
                            label: item.label,
                            value: item.resourceUid,
                            config: { ...item }
                        }
                    });
                    const firstDAAQ = loggedUser.daaqList[0].config
                    commit('setDAAQ', { daaq: firstDAAQ.daaq, daaqResourceUid: firstDAAQ.resourceUid, daaqLabel: firstDAAQ.label })
                }

                const user = await getUser(tokenData.userResourceUid);
                const defaultLanguage = await getSpecificLanguage({ language: user.data.defaultLanguage })

                loggedUser.defaultLanguage = defaultLanguage.items[0]
                loggedUser.selectedLanguage = loggedUser.defaultLanguage

                await load_i18nTranslations(loggedUser);
                const preferredLanguages = []
                for (const language of user.data.preferredLanguages) {
                    const defaultLanguage = await getSpecificLanguage({ language: language.language })
                    preferredLanguages.push(defaultLanguage.items[0])
                }
                loggedUser.preferredLanguages = preferredLanguages

                if ((loggedUser.temporaryPassword ||
                    (loggedUser.passwordExpirationDate && moment(loggedUser.passwordExpirationDate, "DD/MM/YYYY").isBefore(moment())))) {
                    commit('setMsgTemplate', loggedUser.temporaryPassword);
                    commit('setUser', loggedUser);
                    if (user && user.data && user.data.associatedParty && user.data.associatedParty) {
                        commit('setPartyUid', user.data.associatedParty);

                    }
                    redirectToChangePassword();

                }
                else {
                    // this.dispatch("picklistModule/initializePicklists");   
                    let redirected = false
                    loggedUser.loggedIn = true;

                    commit('setUser', loggedUser);
                    if (user && user.data && user.data.associatedParty && user.data.associatedParty) {
                        commit('setPartyUid', user.data.associatedParty);
                    }
                    const supersetAccesToken = await getAccesToken(null);
                    const homePageRolesKeys = Object.keys(HomePageRoles); // Get an array of all HomePageRoles enum keys


                    for (let key of homePageRolesKeys) {
                        const role: any = key;
                        const value: any = (HomePageRoles as any)[key];

                        if (checkUserByRole([key])) {
                            redirected = true
                            router.push('/' + value).catch(err => errorMessage('Error! ' + err));
                            break;  // Exit the loop once the user is routed
                        }
                    }

                    if (!redirected) {
                        router.push('/home').catch(err => errorMessage('Error! ' + err));
                    }

                    if (user.data.avatarFileId) {
                        store.state.authModule!.selectedAvatar = await fetchAvatar(user.data.avatarFileId.resourceUid) || null
                    }
                    if (user.data.userGroups) {
                        store.state.authModule!.groups = user.data.userGroups
                    }
                }

                store.state.isLoading = false


            } catch (err) {
                handlingError(err)
            }
        },
        signUp({ commit, getters }, payload) {
            const { username } = payload
            store.state.isLoading = true
            signUp(payload).then(res => {
                res.smsCode = '';
                res.username = username;
                commit('setUser', { ...getters.getUserInit, ...res });
                Notify.create({
                    timeout: 10000,
                    actions: [{ icon: 'close', color: 'white' }],
                    message: `Success! User ${res.userId} created.`,
                    color: 'positive'
                });
                store.state.isLoading = false
            }).catch(err => {
                handlingError(err);
                commit('setUser', getters.getUserInit);
                router.push('/login').catch(() => errorMessage('Error! ' + err));
            });
        },
        signOut({ dispatch, getters }) {
            const loggedUser = getters.getUserInit;
            loggedUser.loggedIn = false;
            sessionStorage.clear();
            dispatch('cleanUser');
            router.push('/login').catch(err => errorMessage('Error! ' + err));
        },
        codeConfirmation({ commit, getters }, payload) {
            store.state.isLoading = true
            codeConfirmation(payload).then(res => {
                commit('setUser', getters.getUserInit);
                commit('validateCode', res);
                Notify.create({
                    timeout: 10000,
                    actions: [{ icon: 'close', color: 'white' }],
                    message: `Success! ${res.result}`,
                    color: 'positive'
                });
                store.state.isLoading = false
                // router.push('/login').catch(err => errorMessage('Error! ' + err));
                errorMessage('Error! ' + i18n.global.t("main.connect.changePasswordMessageUnchanged"));
            }).catch(err => handlingError(err));
        },
        forgotPassword({ commit, getters }, payload) {
            store.state.isLoading = true
            forgotPassword(payload).then(() => {
                commit('setUser', getters.getUserInit);
                commit('forgotPassword', payload);
                Notify.create({
                    timeout: 10000,
                    actions: [{ icon: 'close', color: 'white' }],
                    message: i18n.global.t("main.connect.sendCodeMessage"),
                    color: 'positive'
                });
                store.state.isLoading = false
            }).catch(err => handlingError(err));
        },
        forgotPasswordCodeConfirmation({ commit, getters }, payload) {
            store.state.isLoading = true
            forgotPasswordCodeConfirmation(payload).then(() => {
                Notify.create({
                    timeout: 10000,
                    actions: [{ icon: 'close', color: 'white' }],
                    message: `Success! Forgot Password code validated.`,
                    color: 'positive'
                });
                store.dispatch("authModule/sendEmailNotification", store.state.authModule?.user);
                commit('setUser', getters.getUserInit);
                store.state.isLoading = false
            }).catch(err => {
                handlingError(err)
            });
        },
        cleanUser({ commit }, payload) {
            commit('setInitial');
        },
         saveLanguage({ commit, getters }, payload) {
            const { userId, defaultLanguage, listAvailableLanguage } = payload
             getUser(userId).then(async res => {
                res.data.preferredLanguages = listAvailableLanguage
                res.data.defaultLanguage = defaultLanguage
                if (res.data.passwordExpirationDate) {
                    res.data.passwordExpirationDate = formatDateLocale(res.data.passwordExpirationDate, i18n.global.t("mask.format"), 'en', 'YYYY-MM-DD')
                }
                try {
                   await getBase64(store.state.authModule?.selectedAvatar).then(async (base64: any) => {
                        const fileContent: FileContent = {
                            originalFileName: "User Avatar",
                            format: "image/png",
                            content: base64,
                            relations: {
                                items: [
                                    {
                                        qualifier: { rawValue: "User Avatar" },
                                        objectUON: {
                                            objectType: "odm.filemngt.filecontent",
                                            systemUid: UON.SystemUid.odmFileManagement,
                                        },
                                    },
                                ],
                            },
                        };
                        if (store.state.userModule.isAvatarEdited) {
                            const image = await saveFile(fileContent);
                            res.data.avatarFileId = {
                                "resourceUid": image.resourceUid,
                                "objectType": "odm.filemngt.filedescriptor",
                                "systemUid": "odm-filemngt",
                            };
                        }
                       
                    });
                } catch (error) {
                    Notify.create({
                        timeout: 3000,
                        message: i18n.global.t('Upload Failed'),
                        color: 'negative',
                    });
                } finally{
                    updateLanguage(res.data, userId).then((response: any) => {
                        commit('setLanguage', response);
                    })
                };
              
            })
        },
        async sendEmailNotification({ commit }, payload) {
            const body: EmailMessage = {
                objectType: "odm.contactmechanism.communicationrequest.emailmessage",
                clientApplication: {
                    objectType: "odm.contactmechanism.contactmechanismclient",
                    systemUid: "odm-contactmechanism",
                    resourceUid: "ApplicationClient"
                },
                emailTemplate: {
                    objectType: "contactmechanism-EmailTemplate",
                    systemUid: "odm-contactmechanism",
                    resourceUid: "UpdatePasswordNotificationTemplate"
                },
                parameters: {
                    email: payload.email,
                    signatory_cc: payload.email
                }
            }
            await sendMail(body)
        },
        async updatePassword({ commit }, payload) {
            try {
                await updatePassWordService(payload);
                Notify.create({
                    timeout: 10000,
                    actions: [{ icon: 'close', color: 'white' }],
                    message: i18n.global.t("main.connect.changePasswordMessageSuccessfully"),
                    color: "positive",
                });
                commit('setChangePassWordIn', true)
                store.dispatch("authModule/sendEmailNotification", store.state.authModule?.user);
                store.dispatch("authModule/signOut");
            } catch (error) {

                Notify.create({
                    timeout: 10000,
                    actions: [{ icon: 'close', color: 'white' }],
                    message: i18n.global.t("main.connect.changePasswordMessageUnchanged"),
                    color: "negative",
                });

            }

        }
    },
    mutations: {
        setInitial(state) {
            state.user = {
                username: '',
                password: '',
                daaqList: []
            },
                state.daaqResourceUid = '',
                state.daaqLabel = '',
                state.daaq = '/'
        },
        setRecoverState(state, payload) {
            state.user = payload.user ? payload.user : ''
        },
        setUser(state, payload) {
            state.user = payload;
        },
        validateCode(state, payload) {
            state.user.smsValidated = !!payload;
        },
        forgotPassword(state, payload) {
            state.user.forgotPassword = true;
            state.user.smsCode = '';
            state.user.username = payload.username;
        },
        setDAAQ(state, payload) {
            state.daaq = payload.daaq
            state.daaqResourceUid = payload.daaqResourceUid
            state.daaqLabel = payload.daaqLabel
        },
        setMsgTemplate(state, payload) {
            state.msgTemplate = payload;
        },
        setChangePassWordIn(state, payload) {
            state.changePassworIn = payload;
        },
        setPartyUid(state, payload) {
            state.user.partyPersonId = payload
        },
        async setLanguage(state, payload) {
            try {
                const { defaultLanguage, preferredLanguages } = payload.data;

                const defaultLanguagePromise = getSpecificLanguage({ language: defaultLanguage });
                const preferredLanguagesPromises = preferredLanguages.map((language: any) =>
                    getSpecificLanguage({ language: language.language })
                );

                const [defaultLanguageResult, ...preferredLanguagesResults] = await Promise.all([
                    defaultLanguagePromise,
                    ...preferredLanguagesPromises,
                ]);

                state.user.defaultLanguage = defaultLanguageResult.items[0];
                state.user.preferredLanguages = preferredLanguagesResults.map((result: any) => result.items[0]);
                state.user.selectedLanguage = defaultLanguageResult.items[0];
            } catch (error) {
                console.error("Error setting language:", error);
            }
        }
    },
}

export default authModule;