import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "card mt-3" }
const _hoisted_2 = { class: "card-header bg-white" }
const _hoisted_3 = { class: "card-title mb-0 font-medium font-size-14" }
const _hoisted_4 = { class: "card-body pt-0" }
const _hoisted_5 = { class: "default-border-radius table-responsive pt-0 pb-0 bg-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t(`decision.label.RESERVE`)), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_q_table, {
          rows: _ctx.decisionsReserve,
          columns: _ctx.columns,
          "row-key": "name",
          class: "shadow-0 table table-sm bg-white mb-0"
        }, {
          header: _withCtx((props) => [
            _createVNode(_component_q_tr, { props: props }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                  return (_openBlock(), _createBlock(_component_q_th, {
                    key: col.name,
                    props: props
                  }, {
                    default: _withCtx(() => [
                      (col.name !== 'actions')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createTextVNode(_toDisplayString(_ctx.$t(col.label)), 1)
                          ], 64))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1032, ["props"]))
                }), 128)),
                _createVNode(_component_q_th, { "auto-width": "" })
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          _: 1
        }, 8, ["rows", "columns"])
      ])
    ])
  ]))
}