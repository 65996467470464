
import { defineComponent, ref, watch, computed } from "vue";
import Notify from 'quasar/src/plugins/Notify.js';;
import i18n from "@/i18n";

export default defineComponent({
  name: "twPercent",
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const inputValue = ref(props.modelValue != null ? props.modelValue.toString() : "0");

    watch(
      () => props.modelValue,
      (newVal) => {
        inputValue.value = newVal != null ? newVal.toString() : "0";
      }
    );

    const formattedValue = computed({
      get() {
        return percentMask(inputValue.value);
      },
      set(value:any) {
        inputValue.value = value;
      }
    });

    function validateInput() {
      let value = parseFloat(inputValue.value.replace(",", "."));
      let result = 0;

      if (isNaN(value)) {
        result = 0;
      } else if (value < 0) {
        Notify.create({
          timeout: 10000,
          actions: [{ icon: 'close', color: 'white' }],
          message: `${i18n.global.t("main.dialog.validation.negative")}`,
          color: "warning",
        });
        result = 0;
      } else if (value > 100) {
        Notify.create({
          timeout: 10000,
          actions: [{ icon: 'close', color: 'white' }],
          message: `${i18n.global.t("main.dialog.validation.greater100")}`,
          color: "warning",
        });
        result = 100;
      } else {
        result = value;
      }

      if (inputValue.value.includes(".")||inputValue.value.includes(",")) {
        result = parseFloat(result.toFixed(2));
      }

      inputValue.value = result.toString();
      emit("update:modelValue", result);
    }

    function percentMask(value: string): string {
      if (i18n.global.locale.value === "fr-FR") {
        return value.toString().substring(0, 8).replace(".", ",");
      } else if (i18n.global.locale.value === "en-US") {
        return value.toString().substring(0, 8).replace(",", ".");
      }
      return value.toString();
    }

    return {
      inputValue,
      formattedValue,
      validateInput,
    };
  },
});
