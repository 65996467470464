
import {Options, Vue} from "vue-class-component";
import store from "@/store";
import GlobalPaymentScheduleTable from "@/components/demand/simulation/normal-mode/GlobalPaymentSchedule/GlobalPaymentScheduleTable.vue";
import GlobalPaymentSchedulePanel from "@/components/demand/simulation/normal-mode/GlobalPaymentSchedule/GlobalPaymentSchedulePanel.vue";
import {FinancingStateEnum} from "@/types";

@Options({
  components: {GlobalPaymentSchedulePanel, GlobalPaymentScheduleTable}
})
export default class GlobalPaymentSchedule extends Vue {

  backPreviousState() {
    store.dispatch('financingModule/updateCurrentState', {newState: FinancingStateEnum.INITIAL});
  }


}
