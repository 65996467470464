import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f51e3ff"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-12" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-4" }
const _hoisted_4 = { class: "text-blue-8 bold" }
const _hoisted_5 = { class: "col-4 flex justify-end" }
const _hoisted_6 = { class: "bold q-ml-sm" }
const _hoisted_7 = { class: "col-4 flex flex-center" }
const _hoisted_8 = { class: "bold q-ml-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_ServiceInsurance = _resolveComponent("ServiceInsurance")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("span", {
      class: "bold q-mb-lg cursor-pointer",
      style: {"color":"#1976D2"},
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeServicesDetail()))
    }, [
      _createVNode(_component_q_icon, {
        size: "sm",
        name: "arrow_left",
        class: "text-gray-1"
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('demand.simulation.tabs.servicesDetail')), 1)
    ]),
    _createVNode(_component_q_list, {
      bordered: "",
      class: "list-services q-mt-sm q-mr-sm q-pt-none"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.scaleSelectedServices, (item, index) => {
          return (_openBlock(), _createElementBlock("div", { key: index }, [
            _createVNode(_component_q_item_label, {
              class: _normalizeClass(index === 0 ? 'bg-blue-1 list-item-services' : 'bg-blue-1'),
              header: ""
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t(`demand.product.services.names.${item.reference.split('.').join('_')}`)), 1)
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t(`demand.product.services.columns.amountHT`)) + " : ", 1),
                      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.getAmountHT(item)), 1)
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t(`demand.product.services.columns.amountTTC`)) + " : ", 1),
                      _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.getAmountTTC(item)), 1)
                    ])
                  ])
                ])
              ]),
              _: 2
            }, 1032, ["class"]),
            _withDirectives((_openBlock(), _createBlock(_component_q_item, { class: "q-mb-lg q-mt-sm" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_item_section, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_table, {
                      rows: [item],
                      columns: _ctx.serviceCol,
                      "row-key": "name",
                      separator: "none",
                      "hide-pagination": "",
                      dense: ""
                    }, null, 8, ["rows", "columns"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024)), [
              [_directive_ripple]
            ])
          ]))
        }), 128))
      ]),
      _: 1
    }),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partyInsuranceService, (item, index) => {
      return (_openBlock(), _createBlock(_component_ServiceInsurance, {
        key: index,
        data: item
      }, null, 8, ["data"]))
    }), 128))
  ], 64))
}