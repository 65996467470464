
import store from "@/store";
import {Options, Vue} from "vue-class-component";
import i18n from "@/i18n";


@Options({
    props: ["decisionReserves"],

})
export default class OpinionReservesPanel extends Vue {
    decisionReserves?: any;

    get reserveOpinion() {
        return store.state.taskModule?.Openionreserves;
    }
    get isTaskEnterOpinion() {
    return store.getters["taskModule/isTaskEnterOpinion"];
  }
  get isTaskTypeMonoDecision() {
    return store.getters["taskModule/isTaskTypeMonoDecision"];
  }
  

    reserveTraduction(resourceUid: any) {
      return (
        resourceUid != '' ? i18n.global.t("task.reserves.".concat(resourceUid)) : ''
      );
    }
  updated() {
    this.$emit('update')
  }
   

}
