import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card mb-3" }
const _hoisted_2 = { class: "card-header bg-white no-border" }
const _hoisted_3 = { class: "card-title m-0" }
const _hoisted_4 = { class: "font-weight-bold" }
const _hoisted_5 = { class: "card-body pb-0" }
const _hoisted_6 = { class: "row form-group" }
const _hoisted_7 = { class: "col-lg-4 col-md-4 col-sm-6 form-control no-border" }
const _hoisted_8 = { class: "d-block mb-0 small text-muted mb-2" }
const _hoisted_9 = { class: "col-lg-4 col-md-4 col-sm-6 form-control no-border" }
const _hoisted_10 = { class: "d-block mb-0 small text-muted mb-2" }
const _hoisted_11 = { class: "col-lg-4 col-md-4 col-sm-6 form-control no-border" }
const _hoisted_12 = { class: "d-block mb-0 small text-muted mb-2" }
const _hoisted_13 = { class: "card-footer bg-white no-border flex justify-content-end pt-0" }
const _hoisted_14 = { class: "card" }
const _hoisted_15 = { class: "card-body pt-0" }
const _hoisted_16 = { class: "table-responsive" }
const _hoisted_17 = ["value", "onUpdate:modelValue", "onChange"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_SideBar = _resolveComponent("SideBar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("garanty.GuaranteeViewList.filtres")), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t("communication.comMessage.referenceLabel")), 1),
            _createVNode(_component_q_input, {
              "data-testid": "demand_edit_financement_simulation1_guarentee_reference",
              dense: "",
              outlined: "",
              modelValue: _ctx.filter.productReference,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filter.productReference) = $event))
            }, _createSlots({ _: 2 }, [
              (_ctx.filter.productReference)
                ? {
                    name: "append",
                    fn: _withCtx(() => [
                      _createVNode(_component_q_icon, {
                        name: "cancel",
                        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.filter.productReference = ''), ["stop","prevent"])),
                        class: "cursor-pointer",
                        size: "xs"
                      })
                    ])
                  }
                : undefined
            ]), 1032, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("guarantees.labels.guaranteeType")), 1),
            _createVNode(_component_q_select, {
              dense: "",
              outlined: "",
              "emit-value": "",
              "map-options": "",
              modelValue: _ctx.filter.categoryClass,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filter.categoryClass) = $event)),
              options: _ctx.typeOptions,
              loading: !_ctx.typeOptions,
              disable: !_ctx.typeOptions,
              "data-testid": "demand_edit_financement_simulation1_guarentee_guaranteeType"
            }, {
              append: _withCtx(() => [
                (_ctx.filter.categoryClass !== undefined)
                  ? (_openBlock(), _createBlock(_component_q_icon, {
                      key: 0,
                      name: "cancel",
                      onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.filter.categoryClass = undefined), ["stop","prevent"])),
                      class: "cursor-pointer",
                      size: "xs"
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["modelValue", "options", "loading", "disable"])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t("guarantees.labels.label")), 1),
            _createVNode(_component_q_input, {
              dense: "",
              outlined: "",
              modelValue: _ctx.filter.productName,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.filter.productName) = $event)),
              "data-testid": "demand_edit_financement_simulation1_guarentee_label"
            }, _createSlots({ _: 2 }, [
              (_ctx.filter.productName)
                ? {
                    name: "append",
                    fn: _withCtx(() => [
                      _createVNode(_component_q_icon, {
                        name: "cancel",
                        onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.filter.productName = ''), ["stop","prevent"])),
                        class: "cursor-pointer",
                        size: "xs"
                      })
                    ])
                  }
                : undefined
            ]), 1032, ["modelValue"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_q_btn, {
          icon: "restart_alt",
          label: _ctx.$t('garanty.globalLabels.reset'),
          type: "reset",
          class: "btn btn-outline-primary mr-2",
          outline: "",
          onClick: _ctx.onReset,
          "data-testid": "demand_edit_financement_simulation1_guarantee_btn-reset"
        }, null, 8, ["label", "onClick"]),
        _createVNode(_component_q_btn, {
          class: "btn btn-primary",
          label: _ctx.$t('garanty.globalLabels.search'),
          icon: "search",
          onClick: _ctx.onSearch,
          "data-testid": "demand_edit_financement_simulation1_guarantee_btn-search"
        }, null, 8, ["label", "onClick"])
      ])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, [
          _createVNode(_component_q_table, {
            class: "shadow-0 table table-sm",
            rows: _ctx.rows,
            columns: _ctx.columns,
            "row-key": "reference"
          }, {
            header: _withCtx((props) => [
              _createVNode(_component_q_tr, { props: props }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_th, { "auto-width": "" }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                    return (_openBlock(), _createBlock(_component_q_th, {
                      key: col.name,
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t(col.label)), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  }), 128)),
                  _createVNode(_component_q_th, { "auto-width": "" })
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            body: _withCtx((props) => [
              _createVNode(_component_q_tr, { props: props }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_td, { "auto-width": "" }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        type: "checkbox",
                        value: props.row.guaranty_name,
                        id: "props.row.guaranty_name",
                        "onUpdate:modelValue": ($event: any) => ((props.row.checked) = $event),
                        onChange: ($event: any) => (_ctx.updateCheck(props.row.resourceUid)),
                        "data-testid": "demand_edit_financement_simulation1_guarantee_checkbox-guarantee_name"
                      }, null, 40, _hoisted_17), [
                        [_vModelCheckbox, props.row.checked]
                      ])
                    ]),
                    _: 2
                  }, 1024),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                    return (_openBlock(), _createBlock(_component_q_td, {
                      key: col.name,
                      props: props
                    }, {
                      default: _withCtx(() => [
                        (col.name === 'status_code')
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 0,
                              class: _normalizeClass('badge badge-' + col.value)
                            }, _toDisplayString(_ctx.$t("garanty.garantyFilter.picklist.status." + col.value)), 3))
                          : (col.name === 'categoryClass')
                            ? (_openBlock(), _createElementBlock("span", {
                                key: 1,
                                class: _normalizeClass('badge badge-' + col.value)
                              }, _toDisplayString(_ctx.$t(
                                            "garanty.guaranteeType." +
                                            col.value.replace("product-", "")
                                        )), 3))
                            : (_openBlock(), _createElementBlock("span", {
                                key: 2,
                                class: _normalizeClass('badge badge-' + col.value)
                              }, _toDisplayString(col.value), 3))
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            _: 1
          }, 8, ["rows", "columns"])
        ])
      ])
    ]),
    (_ctx.mainView)
      ? (_openBlock(), _createBlock(_component_SideBar, {
          key: 0,
          show: _ctx.openGaranty,
          component: _ctx.componentToRenderSB,
          props: _ctx.componentPropsSB,
          label: _ctx.$store.state.garantyModule?.mode + 'Garanty',
          onOnClose: _ctx.onCloseGaranty,
          onOnSave: _ctx.onSaveGaranty
        }, null, 8, ["show", "component", "props", "label", "onOnClose", "onOnSave"]))
      : _createCommentVNode("", true)
  ], 64))
}