
import {Options, Vue} from "vue-class-component";
import store from "@/store";
import i18n from "@/i18n";

@Options({
  props:{
    isSelected:{
      type: Boolean,
      required: true 
    }
  },
  watch: {
    '$store.state.demandModule.assetState.toShowAssetBox'(newValue) {
      this.toShowAssetBox = newValue;
    }
  }
})
export default class DemandModelAssetBox extends Vue {
  isSelected: boolean|undefined;
  toShowAssetBox = true;
  assetList=[]

  get description (){
    return i18n.global.t('demand.asset.desciption')
  }
  get prix (){
    return i18n.global.t('demand.asset.price')
  }

  get assetEquipementInforamtion (){
    return i18n.global.t('demand.asset.assetEquipement')
  }
  get equipementInforamtion (){
    return i18n.global.t('demand.asset.equipement')
  }
  get assetlistinfo (){
    return i18n.global.t('demand.asset.assetListInfo')
  } 
  showBoxAsset() {
    store.state.demandModule.assetState.toShowAssetBox = !store.state.demandModule.assetState.toShowAssetBox;
  }


}
