import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row"
}
const _hoisted_2 = { class: "col-12 mb-3 flex justify-content-end" }
const _hoisted_3 = { class: "bg-light default-border-radius" }
const _hoisted_4 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_radio = _resolveComponent("q-radio")!
  const _component_OdmSliderTTC = _resolveComponent("OdmSliderTTC")!
  const _component_OdmSliderHT = _resolveComponent("OdmSliderHT")!

  return (_ctx.sliders && _ctx.sliders.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_q_radio, {
              "data-testid": "demand_edit_financement_simulation1_with-tax",
              class: "default-border-radius p-2 mr-3 text-dark font-size-12",
              modelValue: _ctx.calculationTypeSelected,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.calculationTypeSelected) = $event)),
              dense: "",
              val: "TTC",
              label: _ctx.$t('middleOfficeFinancingService.label.withTax')
            }, null, 8, ["modelValue", "label"]),
            _createVNode(_component_q_radio, {
              class: "default-border-radius p-2 text-dark font-size-12",
              modelValue: _ctx.calculationTypeSelected,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.calculationTypeSelected) = $event)),
              dense: "",
              val: "HRS",
              label: _ctx.$t('middleOfficeFinancingService.label.withOutTax'),
              "data-testid": "demand_edit_financement_simulation1_without-tax"
            }, null, 8, ["modelValue", "label"])
          ])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sliders, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "col-12 mb-3",
            key: item.id,
            id: 'demand_edit_financement_simulation_'+ index
          }, [
            (_ctx.isFinancingTypeTTC)
              ? (_openBlock(), _createBlock(_component_OdmSliderTTC, {
                  key: 0,
                  index: index,
                  config: item
                }, null, 8, ["index", "config"]))
              : (_openBlock(), _createBlock(_component_OdmSliderHT, {
                  key: 1,
                  index: index,
                  config: item
                }, null, 8, ["index", "config"]))
          ], 8, _hoisted_4))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}