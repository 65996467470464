
import {Options, Vue} from "vue-class-component";
import store from "@/store";

import {getServiceCol} from "@/store/services/financing/financingSeriviceMapper";
import {formatAmoutswitchCurrency, formatMask} from "@/utils/configuration/formatters-config";
import {globalConfig} from "@/utils";
import i18n from "@/i18n";
import ServiceInsurance from "@/components/demand/simulation/normal-mode/ServiceInsurance.vue";

@Options({
  computed: {
    ServiceInsurance() {
      return ServiceInsurance
    }
  },
  components: {ServiceInsurance},
  methods: {formatAmoutswitchCurrency},

})
export default class ServicesDetail extends Vue {

  closeServicesDetail(){
    store.dispatch('financingModule/backPreviousState');
  }

  get scaleSelectedServices() {
    return (
      store.state.financingModule.scalesModule.scaleSelected?.scalePresentation.services?.filter(
        (service:any) => service.config.selected
      ) || []
    );
  }

  get selectedScale() {
    return store.state.financingModule.scalesModule.scaleSelected;
  }
  get partyInsuranceService() {
    return this.selectedScale?.scalePresentation.servicePartyInsurance || []
  }

  get serviceCol() {
    return getServiceCol()
  }

  getAmountHT(service: any) {
    return service.amountValueWoTax
        ? `${formatAmoutswitchCurrency("EUR", service.amountValueWoTax)}`
        : "-";
  }


  getAmountTTC(service: any) {
    const tax: number = service.rate ? Number(service.rate) : 0;
    if (service.amountValueWTax) {
      const value = service.amountValueWTax * (1 + tax);
      const result = `${formatAmoutswitchCurrency("EUR", value)}`;
      return result;
    } else {
      return "-";
    }
  }
  get columns() {
    return globalConfig.table_config.middelOfficeServiceCols
  }
  getRows(service:any){
    return [{
      rate:service.rate,
      basis:i18n.global.t(`middleOfficeService.basisValue.${service.basis}`),
      basisValue:service.basisValue,
      proposalAccessoryCalculationMethodOption:i18n.global.t(`middleOfficeService.proposalAccessoryCalculationMethodOptionValue.${service.proposalAccessoryCalculationMethodOption.resourceUid}`),
      proposalAccessoryPaymentDatesOption:i18n.global.t(`middleOfficeService.proposalAccessoryPaymentDatesOptionValue.${service.proposalAccessoryPaymentDatesOption.resourceUid}`),
      taxValue:(service.taxValue||0)+" %"
    }]
  }

}
