import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row border-bottom border-left-0 pb-3 mb-3 tw-entry"
}
const _hoisted_2 = { class: "col-md-12" }
const _hoisted_3 = { class: "d-flex align-items-center tw-comment-header" }
const _hoisted_4 = {
  key: 0,
  class: "font-medium"
}
const _hoisted_5 = {
  key: 1,
  class: "font-medium"
}
const _hoisted_6 = {
  key: 2,
  class: "text-muted"
}
const _hoisted_7 = {
  key: 3,
  class: "text-muted"
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "col-md-12" }
const _hoisted_10 = { class: "row w-100 text-dark tw-reserves-badge p-3" }
const _hoisted_11 = { class: "col-md-12 text-left font-size-13 font-weight-bold mb-2" }
const _hoisted_12 = {
  key: 1,
  class: "default-border-radius table-responsive p-3 pt-0 pb-0 bg-white"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OpinionReservesCard = _resolveComponent("OpinionReservesCard")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_SideBarEfficom = _resolveComponent("SideBarEfficom")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isTaskEnterOpinion || _ctx.isTaskTypeMonoDecision)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.decisions, (item, index) => {
          return (_openBlock(), _createElementBlock("div", { key: index }, [
            (item.reserves.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      (item.objectType.includes('associatedanalysisprocess'))
                        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(item.analysisMaker), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(item.opinionMaker || "--"), 1)),
                      (item.objectType.includes('associatedanalysisprocess'))
                        ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(item.analysisDate ?
            _ctx.getDateFormat(item.analysisDate) :
            _ctx.getDateFormat()), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(item.opinionDate ?
            _ctx.getDateFormat(item.opinionDate) :
            _ctx.getDateFormat()), 1)),
                      (!item.objectType.includes('associatedanalysisprocess'))
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 4,
                            class: _normalizeClass(["badge", item.opinion.resourceUid === 'OP01' ? 'badge-success' : 'badge-danger'])
                          }, _toDisplayString(item.opinion.resourceUid ?
              _ctx.$t("opinion.enterOpinionTab.opinion.decisionOpinion.".concat(item.opinion.resourceUid)) : "-"), 3))
                        : _createCommentVNode("", true)
                    ]),
                    (!item.objectType.includes('associatedanalysisprocess'))
                      ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(item.comment ? item.comment : "-"), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('opinion.decisionTab.reserve')), 1)
                      ]),
                      _createVNode(_component_OpinionReservesCard, {
                        reserves: item.reserves,
                        entryIndex: index,
                        path: item.objectType.includes('associatedanalysisprocess') ? this.Paths.analysisreserve : this.Paths.opinionreserve
                      }, null, 8, ["reserves", "entryIndex", "path"])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      : (_ctx.isTaskModifyConditions)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createVNode(_component_q_table, {
              rows: _ctx.decisions,
              "row-key": "resourceUid",
              class: "shadow-0 table table-sm bg-white mb-0",
              "hide-pagination": ""
            }, {
              header: _withCtx((props) => [
                _createVNode(_component_q_tr, { props: props }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_th, { "auto-width": "" }),
                    _createVNode(_component_q_th, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('opinion.decisionTab.opinionDate')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_th, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('opinion.decisionTab.opinion')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_th, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('opinion.decisionTab.analysisReference')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_th, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('opinion.decisionTab.opinionMaker')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_th, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('opinion.decisionTab.comment')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              body: _withCtx((props) => [
                _createVNode(_component_q_tr, { props: props }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_td, { "auto-width": "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_btn, {
                          class: "btn btn-sm btn-primary",
                          size: "sm",
                          color: "accent",
                          round: "",
                          dense: "",
                          onClick: ($event: any) => (props.expand = !props.expand),
                          icon: props.expand ? 'remove' : 'add',
                          disable: props.row.reserves.length === 0
                        }, null, 8, ["onClick", "icon", "disable"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_q_td, { style: {"max-width":"60px"} }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.opinionDate ? _ctx.getDateFormat(props.row.opinionDate) :
            _ctx.getDateFormat()), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_q_td, { style: {"max-width":"60px"} }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.opinion.resourceUid ?
            _ctx.$t("opinion.enterOpinionTab.opinion.decisionOpinion.".concat(props.row.opinion.resourceUid) ): "-"), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_q_td, { style: {"max-width":"60px"} }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_btn, {
                          class: "btn bg-transparent p-0 btn-link text-primary",
                          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.onSelect1(_ctx.analysisRessourceUid,_ctx.analysisReference)), ["stop","prevent"])),
                          label: props.row.opinionAnalysisProcessReference ? props.row.opinionAnalysisProcessReference : '-'
                        }, null, 8, ["label"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_q_td, { style: {"max-width":"60px"} }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.opinionMaker ? props.row.opinionMaker : "-"), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_q_td, { style: {"min-width":"130px"} }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.comment ? props.row.comment : "-"), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _withDirectives(_createVNode(_component_q_tr, {
                  props: props,
                  class: "tw-expanded-table-row"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_td, {
                      colspan: "100%",
                      class: "p-0"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_OpinionReservesCard, {
                          reserves: props.row.reserves
                        }, null, 8, ["reserves"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["props"]), [
                  [_vShow, props.expand]
                ])
              ]),
              _: 1
            }, 8, ["rows"])
          ]))
        : _createCommentVNode("", true),
    _createVNode(_component_SideBarEfficom, {
      cancelButtonLabel: _ctx.cancelButtonLabel,
      component: _ctx.componentToRenderSB,
      ctaLabel: _ctx.sidebarCtaLabel,
      label: _ctx.sidebarLabel,
      props: _ctx.componentPropsSB,
      show: _ctx.openAnalysisReferenceDetails,
      onOnClose: _ctx.onCloseAnalysisReferenceDetails
    }, null, 8, ["cancelButtonLabel", "component", "ctaLabel", "label", "props", "show", "onOnClose"])
  ], 64))
}