import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createSlots as _createSlots, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-h6"
}
const _hoisted_2 = {
  key: 1,
  class: "text-h6"
}
const _hoisted_3 = {
  key: 2,
  class: "text-h6"
}
const _hoisted_4 = {
  key: 3,
  class: "text-h6"
}
const _hoisted_5 = { class: "row" }
const _hoisted_6 = {
  class: "col-lg-3 col-md-6 col-sm-12",
  id: "demand_edit_asset_vendor_filter_reference"
}
const _hoisted_7 = {
  key: 0,
  class: "col-lg-3 col-md-6 col-sm-12",
  id: "demand_edit_asset_vendor_filter_name"
}
const _hoisted_8 = {
  key: 1,
  class: "col-lg-3 col-md-6 col-sm-12",
  id: "demand_edit_asset_vendor_filter_person_name"
}
const _hoisted_9 = {
  key: 2,
  class: "col-lg-3 col-md-6 col-sm-12",
  id: "demand_edit_asset_vendor_filter_person_firstName"
}
const _hoisted_10 = {
  key: 3,
  class: "col-lg-3 col-md-6 col-sm-12",
  id: "demand_edit_asset_vendor_filter_person_birthDate"
}
const _hoisted_11 = { class: "row items-center justify-end" }
const _hoisted_12 = {
  key: 4,
  class: "col-lg-3 col-md-6 col-sm-12",
  id: "demand_edit_asset_vendor_filter_person_phoneNumber"
}
const _hoisted_13 = {
  class: "col-lg-3 col-md-6 col-sm-12",
  id: "demand_edit_asset_vendor_filter_city"
}
const _hoisted_14 = {
  class: "col-lg-3 col-md-6 col-sm-12",
  id: "demand_edit_asset_vendor_filter_zipCode"
}
const _hoisted_15 = {
  key: 5,
  class: "col-lg-3 col-md-6 col-sm-12",
  id: "demand_edit_asset_vendor_filter_SIREN"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: _ctx.openModalDialog,
    "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.openModalDialog) = $event)),
    "full-width": "",
    onHide: _ctx.onClose
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              (_ctx.person)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t("enum.party.type.party-FrenchPerson")), 1))
                : _createCommentVNode("", true),
              (_ctx.manager)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t("main.labels.manager")+'s'), 1))
                : _createCommentVNode("", true),
              (_ctx.supplier)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t("demand.asset.vehicle.fournisseur")), 1))
                : _createCommentVNode("", true),
              (_ctx.organization && !_ctx.asset)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t("enum.party.type.party-FrenchOrganization")), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, { class: "q-pt-none" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_q_input, {
                    "data-testid": "demand_edit_asset_vendor_filter_reference",
                    dense: "",
                    outlined: "",
                    modelValue: _ctx.partyReference,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.partyReference) = $event)),
                    label: _ctx.$t('middleOffice.party.organization.identity.reference'),
                    style: {"margin-bottom":"20px","margin-right":"10px"}
                  }, _createSlots({ _: 2 }, [
                    (_ctx.partyReference)
                      ? {
                          name: "append",
                          fn: _withCtx(() => [
                            _createVNode(_component_q_icon, {
                              name: "cancel",
                              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.partyReference = ''), ["stop","prevent"])),
                              class: "cursor-pointer",
                              size: "xs"
                            })
                          ])
                        }
                      : undefined
                  ]), 1032, ["modelValue", "label"])
                ]),
                (_ctx.organization || _ctx.supplier)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createVNode(_component_q_input, {
                        "data-testid": "demand_edit_asset_vendor_filter_name",
                        dense: "",
                        outlined: "",
                        modelValue: _ctx.partyName,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.partyName) = $event)),
                        label: _ctx.$t('middleOffice.party.organization.identity.name'),
                        style: {"margin-bottom":"20px","margin-right":"10px"}
                      }, _createSlots({ _: 2 }, [
                        (_ctx.partyName)
                          ? {
                              name: "append",
                              fn: _withCtx(() => [
                                _createVNode(_component_q_icon, {
                                  name: "cancel",
                                  onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.partyName = ''), ["stop","prevent"])),
                                  class: "cursor-pointer",
                                  size: "xs"
                                })
                              ])
                            }
                          : undefined
                      ]), 1032, ["modelValue", "label"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.person)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(_component_q_input, {
                        "data-testid": "demand_edit_asset_vendor_filter_person_name",
                        dense: "",
                        outlined: "",
                        modelValue: _ctx.lastName,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.lastName) = $event)),
                        label: _ctx.$t('middleOffice.party.organization.identity.name'),
                        style: {"margin-bottom":"20px","margin-right":"10px"}
                      }, _createSlots({ _: 2 }, [
                        (_ctx.lastName)
                          ? {
                              name: "append",
                              fn: _withCtx(() => [
                                _createVNode(_component_q_icon, {
                                  name: "cancel",
                                  onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.lastName = ''), ["stop","prevent"])),
                                  class: "cursor-pointer",
                                  size: "xs"
                                })
                              ])
                            }
                          : undefined
                      ]), 1032, ["modelValue", "label"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.person)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createVNode(_component_q_input, {
                        "data-testid": "demand_edit_asset_vendor_filter_person_firstName",
                        dense: "",
                        outlined: "",
                        modelValue: _ctx.firstName,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.firstName) = $event)),
                        label: _ctx.$t('main.labels.firstName'),
                        style: {"margin-bottom":"20px","margin-right":"10px"}
                      }, _createSlots({ _: 2 }, [
                        (_ctx.firstName)
                          ? {
                              name: "append",
                              fn: _withCtx(() => [
                                _createVNode(_component_q_icon, {
                                  name: "cancel",
                                  onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.firstName = ''), ["stop","prevent"])),
                                  class: "cursor-pointer",
                                  size: "xs"
                                })
                              ])
                            }
                          : undefined
                      ]), 1032, ["modelValue", "label"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.person)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createVNode(_component_q_input, {
                        "data-testid": "demand_edit_asset_vendor_filter_person_birthDate_input",
                        dense: "",
                        outlined: "",
                        modelValue: _ctx.birthdate,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.birthdate) = $event)),
                        label: _ctx.$t('middleOffice.party.person.identity.birthDate'),
                        style: {"margin-right":"10px"},
                        mask: _ctx.maskInput
                      }, {
                        append: _withCtx(() => [
                          _createVNode(_component_q_icon, {
                            "data-testid": "demand_edit_asset_vendor_filter_person_birthDate_icon",
                            name: "event",
                            class: "cursor-pointer"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_popup_proxy, {
                                "data-testid": "demand_edit_asset_vendor_filter_person_birthDate_proxy",
                                ref: "qDateProxy",
                                cover: "",
                                "transition-show": "scale",
                                "transition-hide": "scale"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_q_date, {
                                    modelValue: _ctx.birthdate,
                                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.birthdate) = $event)),
                                    mask: _ctx.maskFormat
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_11, [
                                        _withDirectives(_createVNode(_component_q_btn, {
                                          "data-testid": "demand_edit_asset_vendor_filter_person_birthDate_btn",
                                          label: 
                            _ctx.$t(
                              'task.completeDocument.party.person.financialSituation.close'
                            )
                          ,
                                          color: "primary",
                                          flat: ""
                                        }, null, 8, ["label"]), [
                                          [_directive_close_popup]
                                        ])
                                      ])
                                    ]),
                                    _: 1
                                  }, 8, ["modelValue", "mask"])
                                ]),
                                _: 1
                              }, 512)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue", "label", "mask"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.person)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createVNode(_component_q_input, {
                        "data-testid": "demand_edit_asset_vendor_filter_person_phoneNumber",
                        dense: "",
                        outlined: "",
                        modelValue: _ctx.phoneNumber,
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.phoneNumber) = $event)),
                        label: _ctx.$t('middleOffice.party.person.identity.phoneNumber'),
                        style: {"margin-bottom":"20px","margin-right":"10px"}
                      }, _createSlots({ _: 2 }, [
                        (_ctx.phoneNumber)
                          ? {
                              name: "append",
                              fn: _withCtx(() => [
                                _createVNode(_component_q_icon, {
                                  name: "cancel",
                                  onClick: _cache[10] || (_cache[10] = _withModifiers(($event: any) => (_ctx.phoneNumber = ''), ["stop","prevent"])),
                                  class: "cursor-pointer",
                                  size: "xs"
                                })
                              ])
                            }
                          : undefined
                      ]), 1032, ["modelValue", "label"])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_q_input, {
                    "data-testid": "demand_edit_asset_vendor_filter_city",
                    dense: "",
                    outlined: "",
                    label: _ctx.$t('middleOffice.party.organization.address.city'),
                    style: {"margin-bottom":"20px","margin-right":"10px"},
                    modelValue: _ctx.city,
                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.city) = $event))
                  }, _createSlots({ _: 2 }, [
                    (_ctx.city)
                      ? {
                          name: "append",
                          fn: _withCtx(() => [
                            _createVNode(_component_q_icon, {
                              name: "cancel",
                              onClick: _cache[12] || (_cache[12] = _withModifiers(($event: any) => (_ctx.city = ''), ["stop","prevent"])),
                              class: "cursor-pointer",
                              size: "xs"
                            })
                          ])
                        }
                      : undefined
                  ]), 1032, ["label", "modelValue"])
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_q_select, {
                    "data-testid": "demand_edit_asset_vendor_filter_zipCode",
                    dense: "",
                    outlined: "",
                    "use-input": "",
                    "hide-selected": "",
                    "fill-input": "",
                    options: _ctx.postalCodes,
                    label: _ctx.$t('middleOffice.party.organization.address.codePostal'),
                    style: {"margin-bottom":"20px","margin-right":"10px"},
                    onFilter: _ctx.filterPostalCode,
                    modelValue: _ctx.postalCode,
                    "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.postalCode) = $event))
                  }, {
                    "no-option": _withCtx(() => [
                      _createVNode(_component_q_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_item_section, { class: "text-grey" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("main.noResults")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    append: _withCtx(() => [
                      _createVNode(_component_q_icon, {
                        name: "search",
                        onClick: _cache[14] || (_cache[14] = _withModifiers(() => {}, ["stop"]))
                      }),
                      (_ctx.postalCode)
                        ? (_openBlock(), _createBlock(_component_q_icon, {
                            key: 0,
                            name: "cancel",
                            onClick: _cache[15] || (_cache[15] = _withModifiers(($event: any) => (_ctx.postalCode = ''), ["stop","prevent"])),
                            class: "cursor-pointer",
                            size: "xs"
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["options", "label", "onFilter", "modelValue"])
                ]),
                (_ctx.organization || _ctx.supplier)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      _createVNode(_component_q_input, {
                        "data-testid": "demand_edit_asset_vendor_filter_SIREN",
                        dense: "",
                        outlined: "",
                        modelValue: _ctx.sirenSiret,
                        "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.sirenSiret) = $event)),
                        label: "N SIREN/SIRET",
                        style: {"margin-bottom":"20px","margin-right":"10px"}
                      }, null, 8, ["modelValue"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }),
          (_ctx.rows.length > 0)
            ? (_openBlock(), _createBlock(_component_q_table, {
                key: 0,
                rows: _ctx.rows,
                columns: _ctx.columns,
                "row-key": "id",
                onRowDblclick: _ctx.onSelectedItem
              }, {
                header: _withCtx((props) => [
                  _createVNode(_component_q_tr, { props: props }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                        return (_openBlock(), _createBlock(_component_q_th, {
                          key: col.name,
                          props: props
                        }, {
                          default: _withCtx(() => [
                            (col.name !== 'actions')
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _createTextVNode(_toDisplayString(_ctx.$t(col.label)), 1)
                                ], 64))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1032, ["props"]))
                      }), 128)),
                      _createVNode(_component_q_th, { "auto-width": "" })
                    ]),
                    _: 2
                  }, 1032, ["props"])
                ]),
                _: 1
              }, 8, ["rows", "columns", "onRowDblclick"]))
            : _createCommentVNode("", true),
          _createVNode(_component_q_card_actions, {
            align: "right",
            class: "bg-white text-teal"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                "data-testid": "demand_edit_asset_vendor_filter_searchBtn",
                color: "primary",
                label: _ctx.$t('task.completeDocument.party.person.financialSituation.filter'),
                onClick: _ctx.onSearchClick
              }, null, 8, ["label", "onClick"]),
              _withDirectives(_createVNode(_component_q_btn, {
                color: "dark",
                "data-testid": "demand_edit_asset_vendor_filter_closeBtn",
                label: _ctx.$t('task.completeDocument.party.person.financialSituation.close')
              }, null, 8, ["label"]), [
                [_directive_close_popup]
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "onHide"]))
}