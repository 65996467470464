import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "card bg-light h-100" }
const _hoisted_2 = { class: "card-header bg-transparent border-0" }
const _hoisted_3 = { class: "default-border-radius bg-warning p-3" }
const _hoisted_4 = {
  class: "d-flex text-white",
  style: {"gap":"15px"}
}
const _hoisted_5 = { class: "font-medium h5 m-0" }
const _hoisted_6 = { class: "mb-0" }
const _hoisted_7 = { class: "font-medium h5 m-0" }
const _hoisted_8 = { class: "mb-0" }
const _hoisted_9 = { class: "card-body pt-0 font-size-12" }
const _hoisted_10 = { class: "d-block mb-3 cursor-pointer text-right" }
const _hoisted_11 = { class: "col-lg-4" }
const _hoisted_12 = { class: "col-lg-4 font-weight-bold" }
const _hoisted_13 = { class: "col-lg-4 font-weight-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FinancingPartyInsurance = _resolveComponent("FinancingPartyInsurance")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.totalAmount), 1),
            _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t(`middleOfficeFinancingService.label.over`)) + " " + _toDisplayString(_ctx.numberOfPaymentTerm) + " " + _toDisplayString(_ctx.$t(`middleOfficeFinancingService.label.month`)), 1)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.totalAmountHs), 1),
            _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t(`middleOfficeFinancingService.label.outOfService`)), 1)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("span", {
          class: "text-primary",
          style: {"text-decoration":"underline"},
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openServicesDetail()))
        }, _toDisplayString(_ctx.$t(`middleOfficeFinancingService.label.service`)), 1)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.servicesList, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "bg-white default-border-radius p-3 mb-2 row"
        }, [
          _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.serviceName(_ctx.calculatestatus,item,_ctx.offer)), 1),
          _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.amountHT(true, _ctx.calculatestatus, _ctx.paymentDatesOption(_ctx.calculatestatus,item, _ctx.offer), item, _ctx.offer)), 1),
          _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.amountTTC(true, _ctx.calculatestatus, _ctx.paymentDatesOption(_ctx.calculatestatus, item, _ctx.offer), item,_ctx.offer)), 1)
        ]))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partyInsurance, (partyInsuranceItem, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "row"
        }, [
          _createVNode(_component_FinancingPartyInsurance, { data: partyInsuranceItem }, null, 8, ["data"])
        ]))
      }), 128))
    ])
  ]))
}