
import store from "@/store";
import { globalConfig } from "@/utils";
import { formatAmoutswitchCurrency } from "@/utils/configuration/formatters-config";
import { Options, Vue } from "vue-class-component";
import { calculateBeneficiaryAmount } from "@/store/services/financing/partyInsurance";
import Notify from 'quasar/src/plugins/Notify.js';;
import i18n from "@/i18n";

@Options({
  props: ["data"],
  watch: {
    "serviceCalculationLoading"(newValue) {
      this.calculating = newValue;
    },
    "serviceCalculationPendent"(newValue) {
      this.calculationPendent = newValue
    }
  }
})
export default class PartyInsuranceComponent extends Vue {

  calculating = false

  calculationPendent = false

  selected = true

  data: any;
  expanded = false;

  get serviceCalculationLoading() {
    return store.state.financingModule.serviceModule.calculationLoading
  }

  get serviceCalculationPendent() {
    return store.state.financingModule.serviceModule.calculationPendent
  }

  get columns() {
    return globalConfig.table_config.partyInsuranceCol;
  }

  showAmountFields(col: any) {
    return !col.isAmount || !this.calculationPendent
  }

  get amountHT() {
    if (this.calculationPendent || !this.data._controls || !this.data._controls.amountValueWoTax) {
      return ' - '
    }
    const amount = this.data._controls.amountValueWoTax
    return `${formatAmoutswitchCurrency("EUR", amount)} ${this.data.paymentDatesOption === "INSTALLMENT" ? "/Mois" : "HT"}`
  }

  get amountTTC() {
    if (this.calculationPendent || !this.data._controls || !this.data._controls.amountValueWTax) {
      return ' - '
    }
    const amount = this.data._controls.amountValueWTax
    return `${formatAmoutswitchCurrency("EUR", amount)} ${this.data.paymentDatesOption === "INSTALLMENT" ? "/Mois" : "TTC"}`
  }

  sumTotalCoverage(beneficiary: any) {
    return (this.data.beneficiaries || [])
      .filter((item: any) => item.partyUid !== beneficiary.partyUid)
      .map((item: any) => item.coverage)
      .reduce((a: number, b: number) => a + b, 0)
  }

  async onCoverageValuesChange(beneficiary: any, event: any) {
    const totalCoverage = Number(this.sumTotalCoverage(beneficiary)) + Number(event)
    if (totalCoverage < 100) {
      Notify.create({
        timeout: 10000,
        actions: [{ icon: 'close', color: 'white' }],
        color: "negative",
        message: i18n.global.t('main.dialog.validation.coverage'),
      })
    } else {
      beneficiary.coverage = event
      await store.dispatch('financingModule/serviceModule/refreshPartyInsurance', {})
    }
  }

  async onValuesChange() {
    await store.dispatch('financingModule/serviceModule/refreshPartyInsurance', {})
  }

  async calculateClick() {
    this.calculating = true
    await this.onValuesChange()
    await store.dispatch('financingModule/serviceModule/calculatePartyInsurance')
    this.calculating = false
  }

  created() {
    this.calculationPendent = this.serviceCalculationPendent
  }

}
