
import { Options, Vue } from "vue-class-component";

import store from "@/store";
import TemplateListItem from "@/components/task/tabs/printDocument/TemplateListItem.vue";
import TemplateListComponent from "@/components/document/template/TemplateListComponent.vue"
import { NoticeTemplate, PackNoticeTemplateInfo, SignatureMethodEnum, SignatureProcessStatusEnum } from "@/types";
import { i18n } from "@/main";
import { useApi } from "@/requests/useApi";
import { UON } from "@/utils";
import Notify from 'quasar/src/plugins/Notify.js';;

@Options({
  props: {
    modal: {
      type: Boolean,
      required: false,  
      default: false,
    },
  },
  components: {
    TemplateListItem,
    TemplateListComponent
  },
})
export default class TemplateList extends Vue {
  checkAllModel = true;
  signMethodSelected: any = null;
  entityIsavailable: boolean = false;
  modal!: boolean;
  SignatureProcessStatusEnum = SignatureProcessStatusEnum;

  get offer() {
    return store.state.taskModule.offer || store.state.demandModule.offer
  }

  // Convert enum to array of objects
  get signatureMethods() {
    return Object.keys(SignatureMethodEnum).map(key => ({
      label: i18n.global.t("task.printDocument.signature_labels." + SignatureMethodEnum[key as keyof typeof SignatureMethodEnum]),
      value: SignatureMethodEnum[key as keyof typeof SignatureMethodEnum],
    }))
  }
  get templates() {
    return store.state.printDocumentModule.templates;
  }

  get offerStatusDisable() {
    return store.state.taskModule?.selectedTask?.variables?.offerStatus === SignatureProcessStatusEnum.MANSIGNATURE_INPROGRESS || store.state.taskModule?.selectedTask?.variables?.offerStatus === SignatureProcessStatusEnum.ELECSIGNATURE_INPROGRESS
  }
  // getter and setter to update selected task variable "SignatureMethod" on change from user...
  get signatureMethod() {
    const taskStatus = store.state.taskModule?.selectedTask?.variables?.offerStatus;
    const middleOfficeStatus = store.state.middleOfficeModule?.offer?.status?.resourceUid;

    if (taskStatus === SignatureProcessStatusEnum.MANSIGNATURE_INPROGRESS ||
        middleOfficeStatus === SignatureProcessStatusEnum.MANSIGNATURE_INPROGRESS)
 {
      return SignatureMethodEnum.MANSIGNATURE
    }
    else {
      const taskSignatureMethod = store.state.taskModule.selectedTask?.variables?.SignatureMethod
      return taskSignatureMethod || store.state.demandModule.signatureMethod || SignatureMethodEnum.MANSIGNATURE;
    }
  }
  get currentRoute() {
    return this.$router.currentRoute.value.name;
  }
  set signatureMethod(newValue: string) {
    if(!this.modal) {
      store.state.taskModule.selectedTask.variables.SignatureMethod = newValue
      console.log('getTemplateRecall', newValue)

    } 

  }

  async getOfferAssociatedpartyFromSelectedTask() {
    let associatedParties = [];
    const offerId = store.state.middleOfficeModule.offer?.resourceUid || store.state.taskModule.selectedTask.variables.offerId; 
    try {
      const { response } = await useApi(UON.SystemUid.odmOffer, 'offer/' + offerId + '/offerassociatedparty').get();
      if (response) {
        associatedParties = response;
      }
    } catch (error) {
      Notify.create({
        timeout: 10000,
        actions: [{ icon: 'close', color: 'white' }],
        message: `An error occurred on service`,
        color: "negative",
      });
      console.error("An error occurred:", error);

    };
    return associatedParties
  }

  isManualSignature(data: any) {
    data.map((el: any) => {
      if (!el.signatureMethod) {
        el.signatureMethod = "MANUAL";
      }
      else {

        if (!el.signatureMethod.resourceUid) {
          el.signatureMethod = {
            resourceUid: el.signatureMethod
          }
        }

      }
      return el;
    });
    const hasManualSignature: boolean = data.some((associatedPartie: any) =>
      associatedPartie.signatureMethod.resourceUid === SignatureMethodEnum.MANSIGNATURE
    );
    return hasManualSignature
  }

  async created() {
    if (!this.modal) {
    store.state.printDocumentModule!.isLoading = true;
    const associatedParties = await this.getOfferAssociatedpartyFromSelectedTask()
    store.dispatch('taskModule/setAssociatedParties', associatedParties).then(() => {
      store.state.taskModule.selectedTask.variables.SignatureMethod = this.isManualSignature(store.state.taskModule.selectedTask.associatedParties) ? SignatureMethodEnum.MANSIGNATURE : SignatureMethodEnum.ELECSIGNATURE
      store.state.printDocumentModule!.isLoading = false;
    });}
    this.entityIsavailable = true
  }




  onSelectAllChange() {
    store.dispatch("printDocumentModule/setAllTemplateSelected", {
      selected: this.checkAllModel,
    });
  }

  handleCheckboxes(item: NoticeTemplate, action: boolean) {
    const templates = store.state.printDocumentModule.templates;
    this.handleCheckboxWithAction(item, action, templates);
    let findItem: any = templates.find((el) => !el.selected);
    let findPackItem: any = templates
      .filter((el) => el.isPack)
      .map((el: NoticeTemplate) =>
        el.items?.find((el: PackNoticeTemplateInfo) => !el.selected)
      );
    if (findItem || findPackItem[0]) return true;
  }

  private handleCheckboxWithAction(
    item: NoticeTemplate,
    action: boolean,
    templates: NoticeTemplate[]
  ) {
    if (action) {
      item.selected = true;
      templates.forEach((el: NoticeTemplate) => {
        if (el.fileName !== item.fileName) {
          el.selected = false;
          if (el.items && el.items.length > 0) {
            el.items.forEach((packItem) => (packItem.selected = false));
          }
        } else if (
          item.isPack &&
          item.items?.filter((item) => item.selected).length === 0
        ) {
          el.items?.forEach((packItem) => (packItem.selected = true));
        }
      });
    }
  }

  updateParentBoolean(item: NoticeTemplate, action: boolean) {
    if (this.handleCheckboxes(item, action)) {
      this.checkAllModel = false;
    } else this.checkAllModel = true;
  }
}
