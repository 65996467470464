import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!

  return (_openBlock(), _createBlock(_component_q_input, {
    modelValue: _ctx.formattedValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formattedValue) = $event)),
    outlined: "",
    dense: "",
    "input-class": "text-right",
    style: {"font-family":"sans-serif"},
    suffix: "%",
    readonly: _ctx.readonly,
    disable: _ctx.disable,
    onBlur: _ctx.validateInput
  }, null, 8, ["modelValue", "readonly", "disable", "onBlur"]))
}