
import QCard from 'quasar/src/components/card/QCard.js';import QCardSection from 'quasar/src/components/card/QCardSection.js';import QInput from 'quasar/src/components/input/QInput.js';import QBtn from 'quasar/src/components/btn/QBtn.js';import QSelect from 'quasar/src/components/select/QSelect.js';;
import { Vue, Options } from "vue-class-component";
@Options({
  props:{
    isSelected:{
      type: Boolean,
      required: true 
    }
  },

})
export default class DemandAssetDetailForm extends Vue {
  isSelected: boolean|undefined;

}
