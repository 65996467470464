import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card-title flex-grow-1 justify-content-between align-items-center mb-0" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { class: "font-semi-bold font-size-12" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = { class: "p-2 bg-white default-border-radius mb-3" }
const _hoisted_6 = { class: "d-flex" }
const _hoisted_7 = {
  key: 0,
  class: "flex-grow-1"
}
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "card-title flex-grow-1 justify-content-between align-items-center mb-0" }
const _hoisted_10 = { class: "d-flex align-items-center" }
const _hoisted_11 = { class: "font-semi-bold font-size-12" }
const _hoisted_12 = { class: "card-body" }
const _hoisted_13 = { class: "p-2 bg-white default-border-radius mb-3" }
const _hoisted_14 = { class: "d-flex" }
const _hoisted_15 = {
  key: 0,
  class: "flex-grow-1"
}
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { class: "text-danger" }
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { key: 1 }
const _hoisted_20 = { class: "card-title flex-grow-1 justify-content-between align-items-center mb-0" }
const _hoisted_21 = { class: "d-flex align-items-center" }
const _hoisted_22 = { class: "font-semi-bold font-size-12" }
const _hoisted_23 = { class: "card-body" }
const _hoisted_24 = { class: "p-2 bg-white default-border-radius mb-3" }
const _hoisted_25 = { class: "d-flex" }
const _hoisted_26 = { class: "flex-grow-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuestionsByCategory = _resolveComponent("QuestionsByCategory")!
  const _component_q_spinner_facebook = _resolveComponent("q-spinner-facebook")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!
  const _component_Algorithmes = _resolveComponent("Algorithmes")!
  const _component_Reserves = _resolveComponent("Reserves")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_form = _resolveComponent("q-form")!

  return (_openBlock(), _createBlock(_component_q_form, {
    class: "tw-unsetForm-controle",
    disable: _ctx.isLoading
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_list, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_expansion_item, {
            "default-opened": "",
            "switch-toggle-side": "",
            "header-class": "card-header bg-transparent no-border p-4",
            class: "card tw-items-dashed-group tw-items-group-with-bg tw-level-two-group",
            onShow: _cache[0] || (_cache[0] = () => {}),
            onHide: _cache[1] || (_cache[1] = () => {})
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("task.study.questionnaire")), 1)
                ])
              ])
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    (_ctx.gatheringQuestions)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          _createVNode(_component_QuestionsByCategory, {
                            data: _ctx.questionnaires,
                            reCalculation: _ctx.reCalculation
                          }, null, 8, ["data", "reCalculation"])
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createVNode(_component_q_spinner_facebook, {
                            size: "2em",
                            color: "primary"
                          })
                        ]))
                  ])
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_q_expansion_item, {
            "switch-toggle-side": "",
            "header-class": "card-header bg-transparent no-border p-4",
            class: "card tw-items-dashed-group tw-items-group-with-bg tw-level-two-group",
            onShow: _cache[2] || (_cache[2] = () => {}),
            onHide: _cache[3] || (_cache[3] = () => {})
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("task.study.algorithms")), 1)
                ])
              ])
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    (!_ctx.isLoading)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                          (_ctx.errors)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t(_ctx.errors)), 1)
                              ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                _createVNode(_component_Algorithmes, { reCalculation: _ctx.reCalculation }, null, 8, ["reCalculation"])
                              ]))
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_19, [
                          _createVNode(_component_q_spinner_facebook, {
                            size: "2em",
                            color: "primary"
                          })
                        ]))
                  ])
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_q_expansion_item, {
            "switch-toggle-side": "",
            "header-class": "card-header bg-transparent no-border p-4",
            class: "card tw-items-dashed-group tw-items-group-with-bg tw-level-two-group",
            onShow: _cache[4] || (_cache[4] = () => {}),
            onHide: _cache[5] || (_cache[5] = () => {})
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t("task.study.reserves")), 1)
                ])
              ])
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, [
                      _createVNode(_component_Reserves, { reCalculation: _ctx.reCalculation }, null, 8, ["reCalculation"])
                    ])
                  ])
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["disable"]))
}