
import i18n from "@/i18n";
import store from "@/store";
import { globalConfig } from "@/utils";
import {  Vue } from "vue-class-component";



export default class GoodOrderList extends Vue {

  mainView?: boolean;
  rows = [];
  selected = [] ;
  logoImage = require("@/assets/images/Car.svg");
  hyundaImage = require("@/assets/images/HyundaiI10_RemovedBg.png");



  /**
   * Updates the 'rows' property with data received as a parameter.
   *
   * @function
   * @name updateResultRows
   * @param {any} rows - The data to update the 'rows' property with.
   */
  updateResultRows(rows: any) {
    this.rows = rows
  }

  /**
   * Lifecycle hook 'created()' method that dispatches an action to search for asset data.
   *
   * @function
   * @name created
   */
  created() {
    store.dispatch("orderModule/searchAssetOrder", {
      callback: this.updateResultRows,
      filter: {},
    });
  }
  get columns() {
    return globalConfig.table_config.searchAssetOrderCols;
  }
   getLabel(row:any) {
    return  row.opendetailInfo=== false || row.opendetailInfo=== undefined ?  i18n.global.t("order.asset.dialog.moreInfo") : i18n.global.t("order.asset.dialog.lessInfo")
  }
  onSelect(productRow: any) { 
    productRow.opendetailInfo=== false || productRow.opendetailInfo=== undefined ?
    productRow.opendetailInfo= true :  
    productRow.opendetailInfo= false  
  }  
   onCheck() {
    store.dispatch("orderModule/updateTempSelectedOrderGoods", this.selected[0]);
  }


}
