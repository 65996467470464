

import {  Vue } from "vue-class-component";
import {  OrderAssetSearch } from "@/types";
import store from "@/store";
import { Emit } from "vue-property-decorator";
import i18n from "@/i18n";

export default class GoodOrderFilter extends Vue {

    rows = [];
    filter: OrderAssetSearch ={}
    modelList = []
    
    

    get getBrand() {
        return store.getters["picklistModule/brandItems"];
    }

    getRang(res: any) {
        this.modelList = res.items;
    }

    get colorsList() {
    return store.getters["picklistModule/colorsList"].map((item: any) => {
      return {
        ...item,
        label: item.label?.toString() !== "" ? i18n.global.t("demand.asset.colors." + item.label) : ''
      };
    });
  }
  get activityList() {
    return store.getters["picklistModule/activityList"].map((item: any) => {
      return {
        ...item,
        label: item.label?.toString() !== "" ? i18n.global.t("asset.vehicleType." + item.label) : ''
      };
    });
  }

    onChangeBrand(event: any) {
        const brand = event.id;

        store.dispatch("demandModule/assetModel", {
            callback: (res: any) => this.getRang(res),
            filter: {
                id: "asset.good.vehicle.brand.range",
                marque_id: brand,
            },
        });
    }


    /**
    * Resets the filter.
    * @return {void}
    */
    onReset() {
        this.filter = {};
        this.onSearch();
    }

    /**
    * Searches for assets based on the filter.
    * @return {void}
    */
    onSearch() {
        const filter = this.filter;
        // TODO: Implement the search.
        return store.dispatch("orderModule/searchAssetOrder", {
            filter,
            callback: this.passingRows
        });
    }

    /**
    * Emits the `callGoodListRows` event with the search results.
    * @param {any} res - The search results.
    * @return {void}
    */
    @Emit('callGoodListRows')
    passingRows(res: any) {
        this.rows = res;
    }


}
