
import {Options, Vue} from "vue-class-component";
import {getColumns} from "@/store/services/financing/financingGlobalPaymentScheduleMapper";
import store from "@/store";

@Options({})
export default class GlobalPaymentScheduleTable extends Vue {

  get rows() {
    return this.calculationResult.paymentSchedules
  }

  get columns() {
    return getColumns(this.rows.length, this.calculationResult)
  }

  get calculationResult() {
    return this.scaleSelected?.calculationResult
  }

  get scaleSelected() {
    return store.state.financingModule.scalesModule.scaleSelected
  }

}
