
import { Options, Vue } from "vue-class-component";
import Score from "@/components/Analysis/Score.vue";
import Ratings from "@/components/Analysis/Ratings.vue";
import Ratios from "@/components/Analysis/Ratios.vue";
import store from "@/store";
import { Question } from "@/types";
import Notify from 'quasar/src/plugins/Notify.js';;
import i18n from "@/i18n";

@Options({
  components: {
    Score,
    Ratings,
    Ratios,
  },
  props: ["data", "reCalculation"],
  watch: {
    "$store.state.financingModule.simulationChanged"(simulationChanged) {
      if (simulationChanged === true) {
        store.state.analysisModule!.AlgorithmeChanged = true;
      }
    },
    "$store.state.financingModule.serviceModule.calculationPendent"(calculationPendent) {
      if (calculationPendent === true) {
        store.state.analysisModule!.AlgorithmeChanged = true;
      }
    },
    "$store.state.demandModule.offer": {
      handler(newvalue) {
        console.log("changed offer", newvalue);
        store.state.analysisModule!.AlgorithmeChanged = true;
      },
      deep: true, // Watch nested properties
    },

    "$store.state.taskModule.analysisModule.selectedAnswersOptions": {
      handler(newvalue) {
        store.state.analysisModule!.AlgorithmeChanged = true;
      },
      deep: true, // Watch nested properties
    },
  },
})
export default class Algorithmes extends Vue {
  data: any;
  reCalculation: any;
  simulationChanged?: boolean = false;
  calculationPendent?: boolean = false;
  loading?: boolean = false;
  get currentRoute() {
    return this.$router.currentRoute;
  }
  get algorithmeChanged() {
    return store.state.analysisModule?.AlgorithmeChanged;
  }

  get analysisIsDone() {
    return store.state.analysisModule!.isLoading
  }
  reset() {
    if (this.algorithmeChanged) {
      this.loading = true;
      const gatheringQuestionsState = store.state.analysisModule?.gatheringQuestions;
      const answersState = store.state.analysisModule?.selectedAnswersOptions;
      const updatedAnswers: Question[] = gatheringQuestionsState!.map((item) => {
        const questionId = item.question.resourceUid;
        const answerData: any = Object.values(answersState as any).find(
          (data: any) => data.question_id === questionId
        );

        if (answerData) {
          // Update the answer resourceUid
          item.answer.resourceUid = answerData.answer_option_id;
        }
        return item;
      });
      const TaskVariablesState: any = store.state.taskModule.selectedTask?.variables;
      store.dispatch("analysisModule/updateAnalysis").then(() => {
        this.loading = false;
        store.state.analysisModule!.AlgorithmeChanged = false;
        Notify.create({
          timeout: 10000,
          actions: [{ icon: 'close', color: 'white' }],
          message: i18n.global.t("decision.analysisTab.successMessage"),
          color: "positive",
        });
      });
   
    }
  }
}
