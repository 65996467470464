
import { Options, Vue } from "vue-class-component";
import LabelAndValue from "@/components/general/LabelAndValue.vue";
import store from "@/store";
import i18n from "@/i18n";
import { FinancingPhase } from "@/types";
import {
  formatAmoutswitchCurrency,
  formatWithMask,
} from "@/utils/configuration/formatters-config";

@Options({
  components: { LabelAndValue },
})
export default class GlobalPaymentSchedulePanel extends Vue {
  emptyValue = "- - - - - - -";
  emptyValueCurrency = formatAmoutswitchCurrency("EUR", 0.0);

  valueStyle = "tw-formattedNumberFont";

  labelStyleItem = {
    "font-size": "1rem",
    color: "gray",
    "font-weight": "bold",
  };

  get calculationResult() {
    return this.scaleSelected?.calculationResult;
  }

  get scaleSelected() {
    return store.state.financingModule.scalesModule.scaleSelected;
  }

  get offer() {
    return store.state.demandModule.offer;
  }

  get reference() {
    return this.offer && this.offer.reference ? this.offer.reference : this.emptyValue;
  }

  get phase() {
    return i18n.global.t(`enum.offer.financingPhase.${FinancingPhase.SIMULATION}`);
  }

  get product() {
    const productName = this.scaleSelected?.productName;
    return productName ? productName : this.emptyValue;
  }

  get amount() {
    const value = this.calculationResult?.price;
    return value ? `${formatAmoutswitchCurrency("EUR", value)}` : this.emptyValueCurrency;
  }

  get downPayment() {
    const value = this.calculationResult?.downPayment;
    return value ? `${formatAmoutswitchCurrency("EUR", value)}` : this.emptyValueCurrency;
  }

  get residualValue() {
    const value = this.calculationResult?.residualValue;
    return value ? `${formatAmoutswitchCurrency("EUR", value)}` : this.emptyValueCurrency;
  }

  get residualValueAmount() {
    const value = this.calculationResult?.financedValue;
    return value ? `${formatAmoutswitchCurrency("EUR", value)}` : this.emptyValueCurrency;
  }

  get duration() {
    return this.calculationResult?.duration
      ? `${this.calculationResult?.duration} ` + this.durationUnit()
      : this.emptyValueCurrency;
  }
  camelSentence(str?: string) {
    return  (" " + str).toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, function(match, chr)
    {
      return chr.toUpperCase();
    });
  }

  get frequency() {
    return this.calculationResult?.duration
        ? this.durationUnit('frequency').replace(/[\])[(]/g, '')
        : this.emptyValueCurrency;
  }

  durationUnit(type?: string) {
    const basePath = 'main.labels.sliders'
    if(!this.scaleSelected?.scalePresentation?.periodBetween2Installments?.duration) {
      return ''
    }
    switch (this.scaleSelected?.scalePresentation?.periodBetween2Installments?.duration) {
      case 1:
        return i18n.global.t(`${basePath}.1_months.slider`)
      case 3:
        return i18n.global.t(`${basePath}.3_months.slider`)
      case 6:
        return i18n.global.t(`${basePath}.6_months.slider`)
      case 12:
        return i18n.global.t(`${basePath}.12_months.slider`)
      default:
        return type ? i18n.global.t(`${basePath}.default.serviceInfo`, {duration: this.calculationResult?.duration, param2: this.scaleSelected?.scalePresentation?.periodBetween2Installments?.duration, period2: this.camelSentence(this.scaleSelected?.scalePresentation?.periodBetween2Installments?.unit)}) :
            i18n.global.t(`${basePath}.default.label`, {period: this.camelSentence(this.scaleSelected?.scalePresentation?.periodBetween2Installments?.unit)})

    }
  }

  get sumOfFinancialPayments() {
    const value = this.calculationResult?.sumOfFinancialPayments?.amount;
    return value ? `${formatAmoutswitchCurrency("EUR", value)}` : this.emptyValueCurrency;
  }

  get firstPayment() {
    const value = this.calculationResult?.firstPayment;
    return value ? `${formatAmoutswitchCurrency("EUR", value)}` : this.emptyValueCurrency;
  }

  get rate() {
    const value = this.calculationResult?.paymentSchedules[1]?.nominalRate;
    return value ? `${formatWithMask(value, 2)} %` : "0,00 %";
  }

  get taeg() {
    const value = this.calculationResult?.aprc;
    return value ? `${formatWithMask(value, 2)}` : this.emptyValueCurrency;
  }

  get taea() {
    const value = this.calculationResult?.air;
    return value ? `${formatWithMask(value, 2)}` : this.emptyValueCurrency;
  }

  get sumOfInterests() {
    const value = this.calculationResult?.sumOfInterests?.amount;
    return value ? `${formatAmoutswitchCurrency("EUR", value)}` : this.emptyValueCurrency;
  }

  get sumOfServices() {
    const value = this.calculationResult?.sumOfServices?.amount;
    return value ? `${formatAmoutswitchCurrency("EUR", value)}` : this.emptyValueCurrency;
  }

  get creditCost() {
    const value = this.calculationResult?.creditCost?.amount;
    return value ? `${formatAmoutswitchCurrency("EUR", value)}` : this.emptyValueCurrency;
  }

  get sumOfTotalPayments() {
    const value = this.calculationResult?.sumOfTotalPayments?.amount;
    return value ? `${formatAmoutswitchCurrency("EUR", value)}` : this.emptyValueCurrency;
  }

  get tva() {
    return this.emptyValueCurrency;
  }

  get sumOfTotalPaymentsTTC() {
    const value = this.calculationResult?.sumOfTotalPayments?.amount;
    return value ? `${formatAmoutswitchCurrency("EUR", value)}` : this.emptyValueCurrency;
  }
}
