
import { Options, Vue } from "vue-class-component";
import store from "@/store";
import { Emit } from "vue-property-decorator";

import { getMaskFormat, getMaskInput, globalConfig } from "@/utils";
import { Third, ThirdType, PicklistItem, PostalCode, Roles } from "@/types";

@Options({
  created() {
    this.onSearchClick();
  },
  props: ["openDialog", "party", "asset", "assetResourceUid", "role"],
})
export default class ThirdSearchDialog extends Vue {
  third?: Third;
  openModalDialog: boolean | undefined = false;
  name = "third-search-dialog";
  person: boolean | undefined = false;
  organization: boolean | undefined = false;
  supplier: boolean | undefined = false;
  manager: boolean | undefined = false;
  partyReference = "";
  partyName = "";
  lastName = "";
  firstName = "";
  birthdate = "";
  city = "";
  postalCode = "";
  phoneNumber = "";
  sirenSiret = "";
  rows = [] as any;
  openDialog?: any;
  party?: Third;
  asset: any;
  maskFormat = getMaskFormat();
  maskInput = getMaskInput();
  assetResourceUid?: string;
  addressPlusBtn = false;
  role: any;

  get columns() {
    return globalConfig.table_config.partyCols(this.openDialog);
  }

  reset() {
    this.organization = false;
    this.person = false;
    this.supplier = false;
    this.manager = false;
  }

  openDialogThird() {
    this.openModalDialog = true;
  }

  onSearchClick() {
    if (this.openDialog === ThirdType.PERSON) {
      store.dispatch("searchThirdModule/searchPerson", {
        callback: this.callback,
        filter: {
          discriminator: "party-FrenchPerson",
          reference  : this.partyReference,
          firstName: this.firstName,
          familyName: this.lastName,
          birthDate: this.birthdate,
          phoneNumber: this.phoneNumber,
          territory: this.city,
          postalCode: this.postalCode,
          role: this.role?.role_code,
        },
      });
    } else if (this.openDialog === ThirdType.ORGANIZATION && !this.asset) {
      store.dispatch("searchThirdModule/searchOrganization", {
        callback: this.callback,
        filter: {
          discriminator: "party-FrenchOrganization",
          reference  : this.partyReference,
          name: this.partyName,
          territory: this.city,
          postalCode: this.postalCode,
          siren: this.sirenSiret,
          role: this.role?.role_code,
        },
      });
    }
     else if (this.openDialog === ThirdType.MANAGER) {
      store.dispatch("searchThirdModule/searchPerson", {
        callback: this.callback,
        filter: {
          discriminator: "party-FrenchPerson",
          reference  : this.partyReference,
          firstName: this.firstName,
          familyName: this.lastName,
          birthDate: this.birthdate,
          phoneNumber: this.phoneNumber,
          territory: this.city,
          postalCode: this.postalCode,
          role: Roles.DIRG,
        },
      });
    } else if (this.openDialog === ThirdType.ORGANIZATION && this.asset) {
      store.dispatch("searchThirdModule/searchOrganizationAsset", {
        callback: this.callback,
        filter: {
          asset: this.assetResourceUid,
          discriminator: "party-FrenchOrganization",
          reference: this.partyReference,
          name: this.partyName,
          territory: this.city,
          postalCode: this.postalCode,
          siren: this.sirenSiret,
        },
      });
    } else if (this.openDialog === "party-supplier") {
      store.dispatch("searchThirdModule/searchSupplier", {
        callback: this.callback,
        filter: {
          discriminator: "party-FrenchOrganization", //TODO in the future it will be for organization and person
          reference: this.partyReference,
          name: this.partyName,
          territory: this.city,
          postalCode: this.postalCode,
          siren: this.sirenSiret,
          productCode: this.assetResourceUid,
        },
      });
    }
  }
  callback(res: any) {
    const isPerson = !!res.find((el: any) => el._discriminator.includes("Person"));
    //var currentTime = new Date();

    //const resFilter =res.filter((el: any) => el.validity_until === null || new Date(el.validity_until)>currentTime);

    let withPhone = res.filter((el: any) => el.phonenumber !== null);
    let withEmail = res.filter((el: any) => el.email_address !== null);
    let withoutEmailAndTelephone = res.filter(
      (el: any) => el.phonenumber === null && el.email_address === null
    );

    withPhone.forEach((wPhone: any) => {
      withEmail.forEach((wEmail: any, index: number) => {
        if (wEmail.id === wPhone.id) {
          withEmail.splice(index, 1);
        }
      });
    });

    this.rows = isPerson
      ? []
          .concat(withPhone, withEmail, withoutEmailAndTelephone)
          .sort((a: any, b: any) => a.familyname.localeCompare(b.familyname))
      : res;
  }

  @Emit("onSelectedItem")
  onSelectedItem(event: any, row: any, index: any) {
    return row;
  }

  @Emit("onClose")
  onClose(evt: any) {
    this.openModalDialog = false;
  }

  mounted() {
    this.reset();

    if (this.openDialog === ThirdType.ORGANIZATION) {
      this.organization = true;
    } else if (this.openDialog === ThirdType.PERSON) {
      this.person = true;
    } else if (this.openDialog === ThirdType.SUPPLIER) {
      this.supplier = true;
    }
    else if (this.openDialog === ThirdType.MANAGER) {
      this.manager = true;
    } else {
      this.person = true;
    }
    this.openDialogThird();
  }
  get postalCodes() {
    return store.getters["picklistModule/postalCodesItems"];
  }
  get City() {
    return store.state.picklistModule.cities?.items;
  }
  get facilities() {
    return this.party?.organization?.facilities;
  }
  filterPostalCode(value: string, update: any) {
    update(() => {
      store.dispatch("picklistModule/applyPostalCodeQuery", {
        query: `%${value}%`,
      });
    });
  }
  get addresses() {
    return this.party?.addresses;
  }
  get addressType() {
    return store.getters["picklistModule/addressTypeItems"];
  }
  private static populatePostalCode(value: PostalCode, event: PicklistItem) {
    value.city = event.config.city;
    if (value.country) {
      value.country.name = event.config.country.label;
    }
    value.postalCode = event.id;
  }
  changePostalCode(value: any, event: PicklistItem, index: any) {
    ThirdSearchDialog.populatePostalCode(value, event);
    if (this.facilities && this.facilities[index].address) {
      this.facilities[index].address!.postalCode = value;
      this.facilities[index].address!.postalCode.city = value.city;
    }
  }
}
