import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card-title flex-grow-1 justify-content-between align-items-center mb-0" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { class: "font-semi-bold font-size-12" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = { class: "p-2 bg-white default-border-radius mb-3" }
const _hoisted_6 = { class: "d-flex" }
const _hoisted_7 = { class: "flex-grow-1" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-lg-4 col-md-4 col-sm-12 form-control no-border" }
const _hoisted_10 = { class: "d-block mb-0 small text-muted" }
const _hoisted_11 = { class: "col-lg-4 col-md-4 col-sm-12 form-control no-border" }
const _hoisted_12 = { class: "d-block mb-0 small text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!

  return (_openBlock(), _createBlock(_component_q_expansion_item, {
    "switch-toggle-side": "",
    "header-class": "card-header bg-transparent no-border p-4",
    class: "card tw-items-dashed-group tw-items-group-with-bg tw-level-two-group"
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("task.study.Score")), 1)
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("task.study.score")), 1),
                  _createVNode(_component_q_input, {
                    dense: "",
                    outlined: "",
                    modelValue: _ctx.score.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.score.value) = $event)),
                    disabled: "",
                    readonly: true
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t("task.study.decision")), 1),
                  _createVNode(_component_q_input, {
                    dense: "",
                    outlined: "",
                    modelValue: _ctx.scoreTraduction,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.scoreTraduction) = $event)),
                    disabled: "",
                    readonly: true
                  }, null, 8, ["modelValue"])
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}