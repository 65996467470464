
import i18n from "@/i18n";
import store from "@/store";
import { FinancingProductPackInfo } from "@/types";
import { getGraceTimePeriod } from "@/utils/configuration/formatters-config";
import { ref } from "vue";
import { Options, Vue } from "vue-class-component";
import { Emit } from "vue-property-decorator";
@Options({
  props: ["scale", "isScaleSelected"],
})
export default class SimulationScaleItem extends Vue {
  expanded = ref(false);
  scale?: FinancingProductPackInfo;

  isScaleSelected?: boolean;

  // events

  @Emit("onSelectScale")
  onSelectScale() {
    return this.scale;
  }


  // Getters

  get scaleSelected() {
    return store.getters["financingModule/scalesModule/scaleSelected"];
  }

  get activitySelected() {
    return store.state.financingModule.scalesModule.categorySelected;
  }

  get presentation() {
    return this.isScaleSelected
      ? this.scaleSelected.scalePresentation
      : this.scale?.scalePresentation;
  }

  get loadingError() {
    return this.presentation?.loadingError;
  }

  get loading() {
    return this.presentation?.loading;
  }

  get visible() {
    return (
      this.presentation?.visible &&
      (!this.activitySelected ||
        this.activitySelected.includes(this.scale?.category || ""))
    );
  }

  get tittle() {
    return this.presentation?.payment + ' € / ';
  }

  get subTittle() {
    return i18n.global.t(this.presentation?.monthlyPresentation, { param: this.monthly, period: this.period, duration: this.periodBetween2Installments?.duration})
  }

  get gracePeriodPresentation() {
    return i18n.global.t(this.presentation?.gracePeriodPresentation, {value: this.gracePeriod, period: this.gracePeriodUnit})
  }

  get gracePeriod() {
    return this.presentation?.gracePeriod;
  }

  get monthly() {
    return this.presentation?.monthly;
  }

  get periodBetween2Installments() {
    return this.presentation?.periodBetween2Installments;
  }

  get period() {
    return i18n.global.t('main.labels.'.concat(this.presentation?.period));
  }
  get gracePeriodUnit(){
    return i18n.global.t(`main.labels.${getGraceTimePeriod(this.periodBetween2Installments)}`);
  }

}
