import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-666cf559"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "align-items-center d-flex w-100 border-top pt-2" }
const _hoisted_2 = { class: "flex-grow-1 font-semi-bold font-size-14" }
const _hoisted_3 = { class: "text-right" }
const _hoisted_4 = { class: "template-list col-12" }
const _hoisted_5 = {
  key: 0,
  class: "loading"
}
const _hoisted_6 = {
  key: 1,
  class: "text-danger"
}
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { class: "table table-bordered" }
const _hoisted_9 = ["checked", "indeterminate"]
const _hoisted_10 = { class: "text-left" }
const _hoisted_11 = { class: "text-left" }
const _hoisted_12 = { class: "text-right" }
const _hoisted_13 = { class: "bg-grey-11" }
const _hoisted_14 = { style: {"width":"20px"} }
const _hoisted_15 = ["checked", "onChange"]
const _hoisted_16 = { colspan: "3" }
const _hoisted_17 = { class: "mb-0" }
const _hoisted_18 = { style: {"width":"20px"} }
const _hoisted_19 = ["checked", "onChange"]
const _hoisted_20 = { class: "text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_spinner_facebook = _resolveComponent("q-spinner-facebook")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('main.labels.templateList')), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          class: "btn btn-danger mb-3 cursor-pointer",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (this.refetchTemplates()))
        }, _toDisplayString(_ctx.$t('main.labels.refreshList')), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('main.templateModule.loading')), 1))
        : (_ctx.error)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.error), 1))
          : (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("table", _hoisted_8, [
                _createElementVNode("thead", null, [
                  _createElementVNode("th", null, [
                    _createElementVNode("input", {
                      type: "checkbox",
                      checked: _ctx.allTemplatesSelected,
                      indeterminate: _ctx.hasSelectedTemplates && !_ctx.allTemplatesSelected,
                      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleMainCheckboxChange && _ctx.handleMainCheckboxChange(...args))),
                      class: "w-auto mr-0"
                    }, null, 40, _hoisted_9)
                  ]),
                  _createElementVNode("th", _hoisted_10, _toDisplayString(_ctx.$t('main.templateModule.documentTitle')), 1),
                  _createElementVNode("th", _hoisted_11, _toDisplayString(_ctx.$t('main.templateModule.status')), 1),
                  _createElementVNode("th", _hoisted_12, [
                    (_ctx.hasSelectedTemplates)
                      ? (_openBlock(), _createBlock(_component_q_btn, {
                          key: 0,
                          label: _ctx.$t('main.templateModule.download'),
                          class: "btn btn-sm btn-outline-primary ml-1",
                          size: "sm",
                          padding: "xs",
                          icon: "download",
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onDownloadAllEvent()))
                        }, {
                          loading: _withCtx(() => [
                            _createVNode(_component_q_spinner_facebook)
                          ]),
                          _: 1
                        }, 8, ["label"]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupedTemplates, (packageTemplates, packageName) => {
                    return (_openBlock(), _createElementBlock(_Fragment, { key: packageName }, [
                      _createElementVNode("tr", _hoisted_13, [
                        _createElementVNode("td", _hoisted_14, [
                          _createElementVNode("input", {
                            type: "checkbox",
                            checked: _ctx.isPackageFullySelected(packageName),
                            onChange: ($event: any) => (_ctx.togglePackageSelection(packageName)),
                            class: "w-auto mr-0"
                          }, null, 40, _hoisted_15)
                        ]),
                        _createElementVNode("td", _hoisted_16, [
                          _createElementVNode("h6", _hoisted_17, _toDisplayString(packageName), 1)
                        ])
                      ]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(packageTemplates, (template) => {
                        return (_openBlock(), _createElementBlock("tr", {
                          key: template.processId,
                          class: _normalizeClass([
                            _ctx.isPreview(template) ? 'bg-secondary' : '',
                            _ctx.isPreview(template)
                        ])
                        }, [
                          _createElementVNode("td", _hoisted_18, [
                            _createElementVNode("input", {
                              type: "checkbox",
                              class: "w-auto mr-0",
                              checked: _ctx.isTemplateSelected(template.processId),
                              onChange: ($event: any) => (_ctx.toggleSelection(template.processId))
                            }, null, 40, _hoisted_19)
                          ]),
                          _createElementVNode("td", null, _toDisplayString(template.fileName), 1),
                          _createElementVNode("td", null, [
                            _createElementVNode("span", {
                              class: _normalizeClass(["badge w-100", {
                                    'badge-warning': template.statusPrint === 'IN_PROGRESS',
                                    'badge-danger': template.statusPrint === 'FAIL',
                                    'badge-success': template.statusPrint === 'DONE'
                                }])
                            }, _toDisplayString(template.statusPrint), 3)
                          ]),
                          _createElementVNode("td", _hoisted_20, [
                            (template.statusPrint === 'DONE')
                              ? (_openBlock(), _createBlock(_component_q_btn, {
                                  key: 0,
                                  class: "btn btn-sm btn-outline-primary",
                                  size: "sm",
                                  padding: "xs",
                                  icon: "visibility",
                                  onClick: ($event: any) => (_ctx.onViewEvent(template.fileId, template))
                                }, {
                                  loading: _withCtx(() => [
                                    _createVNode(_component_q_spinner_facebook)
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"]))
                              : _createCommentVNode("", true),
                            (template.statusPrint === 'DONE')
                              ? (_openBlock(), _createBlock(_component_q_btn, {
                                  key: 1,
                                  class: "btn btn-sm btn-outline-primary ml-1",
                                  size: "sm",
                                  padding: "xs",
                                  icon: "download",
                                  onClick: ($event: any) => (_ctx.onDownloadEvent(template.fileId, template, packageName))
                                }, {
                                  loading: _withCtx(() => [
                                    _createVNode(_component_q_spinner_facebook)
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"]))
                              : _createCommentVNode("", true),
                            (template.statusPrint === 'FAIL')
                              ? (_openBlock(), _createBlock(_component_q_btn, {
                                  key: 2,
                                  onClick: ($event: any) => (_ctx.retryTemplate(template)),
                                  class: "btn btn-sm btn-outline-danger ml-1",
                                  size: "sm",
                                  padding: "xs",
                                  icon: "refresh"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('main.templateModule.retry')), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"]))
                              : _createCommentVNode("", true)
                          ])
                        ], 2))
                      }), 128))
                    ], 64))
                  }), 128))
                ])
              ])
            ]))
    ])
  ], 64))
}