
import {Options, Vue} from "vue-class-component";
import DocumentsPanel from "@/components/middle-office/tabs/documents/DocumentsPanel.vue";
import store from "@/store";
import i18n from "@/i18n";
import {useRoute} from "vue-router";
import Comment from "@/components/communication/Comment.vue";
import UploadConditionsDocuments from "@/components/task/tabs/upload/UploadConditionsDocuments.vue";
@Options({
  components: { DocumentsPanel, Comment, UploadConditionsDocuments },
  props: ["associatedGatheringProcess"],
})
export default class Documents extends Vue {
  associatedGatheringProcess?: [];
  isLoading = true
  get gatheringProcessList() {
    return store.state.taskModule.gatheringProcess;
  }
  get currentRoute() {
    return useRoute().name;
  }
  get opinionQualifications() {
    return store.getters["taskModule/analysisModule/getOpinionQualifications"];
  }
  gatheringProcessDocuments(row: any) {
    let countValid = 0;
    let countTotal =0;
    row.entityTargets.forEach((entity: any) => {
      entity.documents.forEach((doc: any) => {
        if (doc.status) {
          const valid = i18n.global.t(`task.uploadDocument.headers.supportingDocumentStatus.VALID`)
          const qualified = i18n.global.t(`task.uploadDocument.headers.supportingDocumentStatus.QUALIFIED`)
          if (doc.status === valid ||  doc.status === 'VALID' || doc.status === qualified || doc.status === 'QUALIFIED') {
            countValid++;
          }
        }
        countTotal++;
      })
    })
    return `${countValid} / ${countTotal}`;
  }

  gatheringProcessDocumentsAccepted(row: any) {
    let countValid = 0;
    let countTotal =0;
    row.entityTargets.forEach((entity: any) => {
      entity.documents.forEach((doc: any) => {
        if (doc.status) {
          const valid = i18n.global.t(`task.uploadDocument.headers.supportingDocumentStatus.VALID`)
          if (doc.status === valid || doc.status === 'VALID') {
            countValid++;
          }
        }
        countTotal++;
      })
    })
    return `${countValid} / ${countTotal}`;
  }

  get EmptyGatheringProcessListMessage() {
    return i18n.global.t("middleOffice.documents.empty");
  }

  setType(type: string) {
    if (type.includes('asset')) {
      return i18n.global.t('demand.tabs.asset')
    }
    else {
      return i18n.global.t('demand.documents.columns.entityType.third')
    }

  }



  setRoles(uid:any) {
    const associatedParties = this.currentRoute === 'TaskDetail' ? store.state.taskModule.offer?.associatedParties : store.state.middleOfficeModule.offer?.associatedParties;
    let party = associatedParties?.find(party =>party.associatedParty?.third?.resourceUid === uid);
    let roleCode = party ? party.associatedParty?.role_code : null;
    return  i18n.global.t(`demand.party.roles.${roleCode}` );
  }

  created(){
   setTimeout(() => {
      this.isLoading = false
    }, 2000)
  }

}
