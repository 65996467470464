
import { Options, Vue } from "vue-class-component";
import { globalConfig } from "@/utils";
import store from "@/store";
import GarantyDetails from "@/components/configuration/garanty/GarantyDetails.vue";
import SideBar from "@/components/general/SideBar.vue";
import { GarantySearch, OfferGuarantee, UniformOdmName } from "@/types";
import i18n from "@/i18n";
import Notify from 'quasar/src/plugins/Notify.js';;

@Options({
    props: ["label", "mode", "mainView"],
    components: {
        GarantyDetails,
        SideBar,
    },
    watch: {
        "$store.state.garantyModule.reloadListGaranty"(newValue) {
            const index = this.rows.findIndex((obj: any) => obj.code === newValue);
            this.rows.splice(index, 1);
        },
    },
})
export default class GuaranteeList extends Vue {
    rows: any[] = [];
    selected: GarantySearch[] = [];
    openAddGarentyItem = false;
    componentToRenderSB = GarantyDetails;
    componentPropsSB?: object;
    ClickAdd?: boolean = false;
    mainView?: boolean;
    label?: string;
    validField: boolean = store.state.garantyModule.validField;
    filter: OfferGuarantee = {};
    originalRows: any[] = [];

    /**
     * Handles the save operation for the guaranty details.
     *
     * @function
     * @name onSaveGaranty
     * @return {void}
     */
    onSaveGaranty() {
        // List of required fields for the guarantee
        const requiredFields = [
            this.garanty?.reference,
            this.garanty?.garantietype.type.value,
            this.garanty?.validity?.from,
            this.garanty?.validity?.until,
            this.garanty?.name,
            this.garanty?.status.type.value,
            this.garanty?.role.type.value,
        ];

        // Check if all required fields are valid
        const validRequiredFields =
            requiredFields.filter((f: any) => f?.length > 0).length ===
            requiredFields.length;

        // Check if required fields are valid
        if (
            validRequiredFields &&
            typeof this.garanty?.evaluationMethod === "string"
        ) {
            store.dispatch("garantyModule/saveGaranty", { garanty: this.garanty });
            store.dispatch("garantyModule/showGarantyDetail");
            store.dispatch("garantyModule/changeValidField", true);
        } else if (!validRequiredFields) {
            // Show a notification if required fields are missing
            Notify.create({
            timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
                color: "negative",
                message: i18n.global.t("garantie.error.requiredFields"),
            });
            store.dispatch("garantyModule/changeValidField", false);
        } else if (
            validRequiredFields &&
            typeof this.garanty?.evaluationMethod !== "string"
        ) {
            // Show a notification if the evaluation field is missing
            Notify.create({
            timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
                color: "negative",
                message: i18n.global.t("garantie.error.evaluationMethodField"),
            });
            store.dispatch("garantyModule/changeValidField", false);
        }
    }
    /**
     * Closes the guaranty details panel and triggers a confirmation dialog.
     *
     * @function
     * @name onCloseGaranty
     * @return {void}
     */
    onCloseGaranty() {
        store.dispatch("showConfirmationDialog", {
            message: i18n.global.t("main.confirmationDialog.message"),
            confirmation: true,
        });
    }

    /**
     * Gets the current state of the openGaranty property from the garantyModule.
     *
     * @getter
     * @name openGaranty
     * @return {boolean} - The current state of the openGaranty property.
     */
    get openGaranty() {
        return store.state.garantyModule?.openGaranty;
    }
    /**
     * Gets the column configuration for the searchGuaranteeFilterCols.
     *
     * @getter
     * @name columns
     * @return {Array} - The column configuration for the searchGuaranteeFilterCols.
     */
    get columns() {
        return globalConfig.table_config.searchGuaranteeFilterCols;
    }
    /**
     * Gets the details of the selected guaranty from the garantyModule.
     *
     * @getter
     * @name garanty
     * @return {Object|null} - The details of the selected guaranty or null if no guaranty is selected.
     */
    get garanty() {
        return store.state.garantyModule?.selectedgarantydetails;
    }

    /**
     * Updates the 'checked' property of each row in the 'rows' array based on the provided reference.
     * Also dispatches an action to set the selected product guarantee in the Vuex store.
     *
     * @function
     * @name updateCheck
     * @param {any} reference - The reference used to identify the row to be checked.
     */
    updateCheck(reference: string) {
        this.rows.forEach((row) => {
            row.checked = row.resourceUid === reference;
            if (row.checked) {
                store.dispatch(
                    "financingModule/proposalGuaranteeModule/setProductGuaranteeSelected",
                    this.setProductGuaranteePayload(row)
                );
            }
        });
    }
    /**
     * Creates and returns a payload for the product guarantee based on the provided row.
     * It sets specific properties such as role and partyReference to default values.
     *
     * @function
     * @name setProductGuaranteePayload
     * @template T - The type of the row object.
     * @param {T} row - The row object to create the payload from.
     * @return {Object} - The payload for the product guarantee.
     */
    setProductGuaranteePayload<T>(row: T) {
        return {
            ...row,
            role: {
                resourceUid: undefined,
            } as UniformOdmName,
            partyReference: {
                resourceUid: undefined,
            } as UniformOdmName,
        };
    }

    /**
     * Updates the 'rows' property with data received as a parameter.
     *
     * @function
     * @name updateResultRows
     * @param {any} rows - The data to update the 'rows' property with.
     */
    updateResultRows(rows: any) {
        this.rows = rows.filter((row : any) => row.productStatus === 'USE');
        this.rows = this.rows.map((row) => {
            row.checked = false;
            return row;
        });
        this.originalRows = [...this.rows];
    }
    /**
     * Initiates a global search for guarantee list based on the current offer in the demand module.
     * Invokes the searchGuaranteeList action and updates result rows with the callback.
     *
     * @function
     * @name globalSearch
     * @return {void}
     */
    globalSearch() {
        store.dispatch("demandModule/searchGuaranteeList", {
            offer: store.state.demandModule.offer,
            callback: this.updateResultRows,
        });
    }
    /**
     * Lifecycle hook called when the component is created.
     * Invokes the globalSearch method to perform an initial search on component creation.
     *
     * @function
     * @name created
     * @return {void}
     */
    created() {
        this.globalSearch();
    }

    /**
     * Gets the options for guarantee type based on the picklistModule.
     *
     * @getter
     * @name typeOptions
     * @return {Array} - The options for guarantee type.
     */
    get typeOptions() {
        return store.getters["picklistModule/garantyTypeItems"].map((item: any) => {
            return {
                ...item,
                label:
                    item.value?.toString() !== ""
                        ? i18n.global.t("garanty.garantyFilter.picklist.type." + item.value)
                        : "",
            };
        });
    }
    /**
     * Resets the filter criteria and performs a global search.
     *
     * @function
     * @name onReset
     * @return {void}
     */
    onReset() {
        this.filter = {};
        this.globalSearch();
    }
    /**
     * Performs a filtered search based on the provided filter criteria and updates the rows accordingly.
     *
     * @function
     * @name onSearch
     * @return {void}
     */
    onSearch() {
        const filteredRows = this.originalRows.filter((row: any) => {
            return (
                (!this.filter.productReference ||
                    row.productReference.includes(this.filter.productReference)) &&
                (!this.filter.categoryClass ||
                    row.classifications[0].categoryClass === this.filter.categoryClass) &&
                (!this.filter.productName ||
                    row.productName.includes(this.filter.productName))
            );
        });
        this.rows = filteredRows;
    }

}
