
import { Options, Vue } from "vue-class-component";
import store from "@/store";
import { Emit } from "vue-property-decorator";

import { getMaskFormat, getMaskInput, globalConfig } from "@/utils";
import { Third, ThirdType, PicklistItem, PostalCode } from "@/types";
import i18n from "@/i18n";

@Options({
  created() {
    let filter ={}
    if(this.party.resourceUid){
        filter={offer_associated_party:this.party.resourceUid}
    }
    if(this.party.party&&this.party.party.resourceUid){
        filter={id:this.party.party.resourceUid}
    }
    store.dispatch("searchThirdModule/searchSupplierAddress", {
        callback: this.callback,
        filter: filter,
      });
  },
  props: [ "party"],
})
export default class AddressSearchDialog extends Vue {
  openModalDialog: boolean | undefined = true;
  street_num = "";
  street = "";
  postalCode:any = "";
  city:any  = "";
  country_code:any  = "";
  rows = [] as any;
  maskFormat = getMaskFormat();
  maskInput = getMaskInput();
  party:any;

  get columns() {
    return globalConfig.table_config.searchSupplierAddress;
  }

  openDialogThird() {
    this.openModalDialog = true;
  }

  onSearchClick() {
    let filter:any ={}
    if(this.party.resourceUid){
        filter={offer_associated_party:this.party.resourceUid}
    }
    if(this.party.party&&this.party.party.resourceUid){
        filter={id:this.party.party.resourceUid}
    }
    if(this.street_num){
        filter.street_num =this.street_num
    }
    if(this.street){
        filter.street =this.street
    }
    if(this.postalCode&&this.postalCode.value){
        filter.postalCode =this.postalCode.value
    }
    if(this.city&&this.city.value){
        filter.city =this.city.value
    }
    if(this.country_code&&this.country_code.value){
        filter.country_code =this.country_code.value
    }
      store.dispatch("searchThirdModule/searchSupplierAddress", {
        callback: this.callback,
        filter: filter,
      });

  }
  callback(res: any) {
   this.rows= res
 }

  @Emit("onSelectedItem")
  onSelectedItem(event: any, row: any, index: any) {
    return row;
  }

  @Emit("onClose")
  onClose(evt: any) {
    this.openModalDialog = false;
  }

  get postalCodes() {
    return store.getters["picklistModule/postalCodesItems"];
  }

  get City() {
    return store.state.picklistModule.cities?.items;
  }

  get addressType() {
    return store.getters["picklistModule/addressTypeItems"];
  }
  get nationality() {
    return store.getters["picklistModule/nationalityItems"]?.map((item: any) => {
      return {
        ...item,
        label: item.value?.toString() !== "" ? i18n.global.t("demand.party.country." + item.value) : ''
      };
    }).sort((a: any, b: any) => a.label.localeCompare(b.label));
  }

  filterPostalCode(value: string, update: any) {
    update(() => {
      store.dispatch("picklistModule/applyPostalCodeQuery", {
        query: `%${value}%`,
      });
    });
  }

  private static populatePostalCode(value: PostalCode, event: PicklistItem) {
    value.city = event.config.city;
    if (value.country) {
      value.country.name = event.config.country.label;
    }
    value.postalCode = event.id;
  }
}
