import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-46c4cc0c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-12" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-4" }
const _hoisted_4 = { class: "text-blue-8 bold" }
const _hoisted_5 = { class: "col-4 flex justify-end" }
const _hoisted_6 = { class: "bold q-ml-sm" }
const _hoisted_7 = { class: "col-4 flex flex-center" }
const _hoisted_8 = { class: "bold q-ml-sm" }
const _hoisted_9 = { class: "col-12" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-4" }
const _hoisted_12 = { class: "text-blue-8 bold" }
const _hoisted_13 = { class: "col-4 flex justify-end" }
const _hoisted_14 = { class: "bold q-ml-sm" }
const _hoisted_15 = { class: "col-4 flex flex-center" }
const _hoisted_16 = { class: "bold q-ml-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("span", {
      class: "bold q-mb-lg cursor-pointer",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openServicesDetail()))
    }, [
      _createVNode(_component_q_icon, {
        size: "sm",
        name: "arrow_left",
        class: "text-gray-1"
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('middleOfficeService.previous')), 1)
    ]),
    _createVNode(_component_q_list, {
      bordered: "",
      class: "list-services q-mt-sm q-mr-sm q-pt-none"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.servicesList, (item, index) => {
          return (_openBlock(), _createElementBlock("div", { key: index }, [
            _createVNode(_component_q_item_label, {
              class: _normalizeClass(index === 0 ? 'bg-blue-1 list-item-services' : 'bg-blue-1'),
              header: ""
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.serviceName(_ctx.calculatestatus,item,_ctx.offer)), 1)
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t(`demand.product.services.columns.amountHT`)) + " : ", 1),
                      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.getAmountHT(item)), 1)
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t(`demand.product.services.columns.amountTTC`)) + " : ", 1),
                      _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.getAmountTTC(item)), 1)
                    ])
                  ])
                ])
              ]),
              _: 2
            }, 1032, ["class"]),
            _withDirectives((_openBlock(), _createBlock(_component_q_item, { class: "q-mb-lg q-mt-sm" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_item_section, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_table, {
                      rows: _ctx.getRows(item),
                      columns: _ctx.columns,
                      "row-key": "name",
                      separator: "none",
                      "hide-pagination": "",
                      dense: ""
                    }, {
                      header: _withCtx((props) => [
                        _createVNode(_component_q_tr, { props: props }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                              return (_openBlock(), _createBlock(_component_q_th, {
                                key: col.name,
                                props: props
                              }, {
                                default: _withCtx(() => [
                                  (col.name !== 'actions')
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                        _createTextVNode(_toDisplayString(_ctx.$t(col.label)), 1)
                                      ], 64))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1032, ["props"]))
                            }), 128)),
                            _createVNode(_component_q_th, { "auto-width": "" })
                          ]),
                          _: 2
                        }, 1032, ["props"])
                      ]),
                      _: 2
                    }, 1032, ["rows", "columns"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024)), [
              [_directive_ripple]
            ])
          ]))
        }), 128))
      ]),
      _: 1
    }),
    _createVNode(_component_q_list, {
      bordered: "",
      class: "list-services q-mt-sm q-mr-sm q-pt-none"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partyInsurance, (service, index) => {
          return (_openBlock(), _createElementBlock("div", { key: index }, [
            _createVNode(_component_q_item_label, {
              class: _normalizeClass(index === 0 ? 'bg-blue-1 list-item-services' : 'bg-blue-1'),
              header: ""
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.insuranceServiceName(service)), 1)
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t(`middleOfficeService.amountWoTax`)) + ": ", 1),
                      _createElementVNode("span", _hoisted_14, _toDisplayString(`${_ctx.formatAmoutswitchCurrency('EUR', service.amountValueWoTax)}`), 1)
                    ]),
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t(`middleOfficeService.amountWTax`)) + " : ", 1),
                      _createElementVNode("span", _hoisted_16, _toDisplayString(`${_ctx.formatAmoutswitchCurrency('EUR',service.amountValueWTax)}`), 1)
                    ])
                  ])
                ])
              ]),
              _: 2
            }, 1032, ["class"]),
            _withDirectives((_openBlock(), _createBlock(_component_q_item, { class: "q-mb-lg q-mt-sm" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_item_section, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_table, {
                      rows: _ctx.getRows(service),
                      columns: _ctx.columns,
                      "row-key": "name",
                      separator: "none",
                      "hide-pagination": "",
                      dense: ""
                    }, {
                      header: _withCtx((props) => [
                        _createVNode(_component_q_tr, { props: props }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                              return (_openBlock(), _createBlock(_component_q_th, {
                                key: col.name,
                                props: props
                              }, {
                                default: _withCtx(() => [
                                  (col.name !== 'actions')
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                        _createTextVNode(_toDisplayString(_ctx.$t(col.label)), 1)
                                      ], 64))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1032, ["props"]))
                            }), 128)),
                            _createVNode(_component_q_th, { "auto-width": "" })
                          ]),
                          _: 2
                        }, 1032, ["props"])
                      ]),
                      _: 2
                    }, 1032, ["rows", "columns"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024)), [
              [_directive_ripple]
            ])
          ]))
        }), 128))
      ]),
      _: 1
    })
  ], 64))
}