import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_step = _resolveComponent("q-step")!
  const _component_q_stepper = _resolveComponent("q-stepper")!

  return (_openBlock(), _createBlock(_component_q_stepper, {
    ref: "stepper",
    color: "primary",
    class: "customStepper",
    "alternative-labels": ""
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workflowItems, (item, index) => {
        return (_openBlock(), _createBlock(_component_q_step, {
          key: item.status,
          name: item.status,
          title: _ctx.getTranslationForCurrentStatus( item.status.includes(_ctx.taskDefinition) ? _ctx.taskDefinition : item.status[0]),
          "header-class": "originationTextSize",
          icon: item.icon,
          color: _ctx.getStepColor(index),
          done: _ctx.isStepDone(index),
          "done-icon": item.doneIcon
        }, null, 8, ["name", "title", "icon", "color", "done", "done-icon"]))
      }), 128))
    ]),
    _: 1
  }, 512))
}