
import { Options, Vue } from "vue-class-component";
import store from "@/store";
import ThirdSearchDialog from "@/components/demand/dialogs/ThirdSearchDialog.vue";

import DemandVehicleDetailForm from "@/components/demand/tabs/DemandTabAssetDetail/DemandVehicleDetailForm.vue";
import DemandVehicleCustom from "@/components/demand/tabs/DemandTabAssetDetail/DemandVehicleCustom.vue";
import DemandModelVehicleBox from "@/components/demand/tabs/DemandTabAssetDetail/DemandModelVehicleBox.vue";
import DemandModelAssetBox from "@/components/demand/tabs/DemandTabAssetDetail/DemandModelAssetBox.vue";
import DemandAssetDetailForm from "@/components/demand/tabs/DemandTabAssetDetail/DemandAssetDetailForm.vue";
import DemandAssetCustom from "@/components/demand/tabs/DemandTabAssetDetail/DemandAssetCustom.vue";
import Comment from "@/components/communication/Comment.vue";
import ManageAssetFromInventory from "@/components/general/ManageAssetFromInventory.vue";

import { ref } from "vue";
import {
  Asset,
  Proposal,
  ProposalAsset,
  ProposalAssetCar,
  ProposalAssetTruck,
  TaskModule,
} from "@/types";
import { useRoute } from "vue-router";
import i18n from "@/i18n";

@Options({
  components: {
    ThirdSearchDialog,
    DemandVehicleDetailForm,
    DemandVehicleCustom,
    DemandModelVehicleBox,
    DemandModelAssetBox,
    DemandAssetDetailForm,
    DemandAssetCustom,
    Comment,
    ManageAssetFromInventory
  },
  computed: {
    activeColor() {
      return this.assets[0]?.config?.assetType ? "primary" : "";
    },
    activeIndicator() {
      return this.assets[0]?.config?.assetType ? "primary" : "transparent";
    },
    startLabel() {
      return this.assets[0].config?.assetType
        ? this.$t("demand.button.addAsset")    
        : this.$t("demand.button.pleaseChooseYourAsset")  ;
    },
  },
  watch: {
    typeThird(value) {
      this.openDialog = value;
    },
    "$store.state.demandModule.assetState.toShowAssetBox"(newValue) {
      this.toShowAssetBox = newValue;
    },
  },
})
export default class DemandAssetTab extends Vue {
  isSelected?: boolean = false;
  startLabel?: string;
  activeColor?: string;
  activeIndicator?: string;
  openDialog = "";
  asset: Asset = this.getAssetInit();
  toShowAssetBox = true;
  internTab: any = ref(1);
  roles = [
    {
      value: "vehicle",
      label: i18n.global.t("demand.asset.vehicle.name"),
    },
    {
      value: "equipment",
      label: i18n.global.t("demand.asset.Equipment.name"),
    },
  ];
  currentModule = TaskModule.OFFER
  getAssetIndexString(value: any, index: number, assets: any): string {
    const assetType = value.config?.assetType;
    const brand = value.config?.formBind?.brand?.label;
    const model = value.config?.formBind?.range?.label;

    switch (assetType) {
      case "vehicle": {
        return `${brand} - ${model}`;
      }
      case "equipment": {
        const assetNumber =
          index -
          assets.slice(0, index).filter((f: any) => f.config?.assetType === "vehicle")
            .length +
          1;
        return `${assetType} ${assetNumber}`;
      }
      default:
        return "Asset";
    }
  }
  getAssetInit() {
    return store.getters["demandModule/getAssetInit"];
  }
  get opinionQualifications() {
    return store.getters["taskModule/analysisModule/getOpinionQualifications"];
  }

  get currentRoute() {
    return useRoute().name;
  }
  get isTaskAnalysis() {
    return store.getters["taskModule/isTaskAnalysis"];
  }
  get assetState() {
    return store.state.demandModule.assetState;
  }

  get assets() {
    return this.assetState.proposalAssets;
  }

  addInternAssetTab(role: any) {
    this.isSelected = true;
    const asset: ProposalAssetCar | ProposalAssetTruck =
      store.getters["demandModule/getAssetInit"];
    asset.objectType = "odm.offer.proposalasset.proposalgood.proposalvehicle.proposalcar";
    if(asset.config) {
      asset.config.assetType = role.value;
    }
    store.dispatch("demandModule/addAsset", { asset: asset });
    store.dispatch("demandModule/setFirstTab", false);
  }

  get isFirstTab() {
    return store.state.demandModule.assetState.isFirstTab;
  }

  deleteAsset(index: number) {
    store.dispatch("demandModule/removeSpecificAssetState", { index: index });
  }

  created() {
    store.dispatch('financingModule/serviceModule/refreshPartyInsuranceForAllScales', {})
    if (!this.assets.length) {
      store.dispatch(
        "demandModule/changeAssetState",
        store.getters["demandModule/getAssetInit"]
      );
    }
  }
}
