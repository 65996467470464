
import { Options, Vue } from "vue-class-component";
import { globalConfig } from "@/utils";
import store from "@/store";

@Options({
  props: ["decisions"],
})
export default class DecisionReserve extends Vue {
  decisions?: any;

  get decisionsReserve() {
    for (const reserve of this.decisions?.decisionReserves) {
      reserve.resourceUid =
        store.getters["picklistModule/decisionreserveItems"].filter(
          (o: any) => reserve.reserve?.resourceUid == o.value
        )[0]?.label || "";
    }
    return this.decisions?.decisionReserves;
  }

  columns = globalConfig.table_config.middleOfficeTable.decisionsReserve;
}
