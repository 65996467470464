
import {Options, Vue} from "vue-class-component";
import i18n from "@/i18n";

@Options({
  name: "comboRecipients",
  components: {},
  computed: {},
  props: {
    recipients: {
      type: Object,
      default: () => ({}), // set default value as an empty object
    },
    metadata: {
      type: Object,
      default: () => ({}), // set default value as an empty object
    },
  },
})
export default class comboRecipients extends Vue {
  recipientType?: string = "All";
  destinations: any = [];
  //holding original dests list
  optionsRecipient: any = [];
  recipients: any;
  metadata:any;
  get filteredOptions() {
    let odm: any;
    switch (this.recipientType) {
      case "All":
        odm = null;
        break;

      case "User":
        odm = "odm.user.leaseforgeuser";
        break;

      case "Group":
        odm = "odm.user.securitygroup";
        break;

      default:
        break;
    }
    const daaq = (this.metadata && Array.isArray(this.metadata)) ? this.metadata.find((meta: any) => meta.key === "Entity_Daaq")?.value : null;
    //In case is not related to an entity
    if (daaq && daaq!='null'){
      const recipientList=this.optionsRecipient.filter((option: any) =>  daaq?.includes(option.read_daaq))
      return odm !== null
      ? recipientList.filter((option: any) => option.objectType === odm)
      : recipientList;
    }
    return odm !== null
      ? this.optionsRecipient.filter((option: any) => option.objectType === odm)
      : this.optionsRecipient;
    
  }

  /**
   *  filter recipients options with input value
   * @param val
   * @param update
   */
  filterDestFn(val: string, update: any) {
    if (val === "") {
      this.optionsRecipient = this.recipients;
      update(this.recipients);
      return;
    }

    const needle = val.toLowerCase();
    const filteredList = this.optionsRecipient.filter((dest: any) => {
      if ("firstName" in dest) {
        // check if object has 'firstName' property
        return (
          dest.firstName.toLowerCase().includes(needle.toLowerCase()) ||
          dest.lastName.toLowerCase().includes(needle.toLowerCase()) ||
          dest.resourceUid.toLowerCase().includes(needle.toLowerCase())
        );
      } else {
        // check if object has 'label' and 'value' properties
        const nameGroup = i18n.global.t(
          `middleOffice.decision.profil.${dest.resourceUid}`
        );
        return (
          nameGroup.toLowerCase().includes(needle.toLowerCase()) ||
          dest.resourceUid.toLowerCase().includes(needle.toLowerCase())
        );
      }
    });

    //
    this.optionsRecipient = filteredList;
    update(filteredList);
  }

  /**
   * abort recipients filter
   */
  abortDestFilter() {
    // reset to original list
    this.optionsRecipient = this.recipients;
  }
  isRequired(val: string | any[]) {
    return (
      (val && val.length > 0) || i18n.global.t("communication.comMessage.missingField")
    );
  }
  /**
     * This function retrieves the selected recipient tag in the given scope.
     @param {any} scope - The scope to retrieve the selected tag.
     @returns {string} - The selected tag in the given scope.
     */
  getSelectedRecipientLabel(scope: any) {
    if (scope.opt.username) {
      return {
        id: scope.opt.firstName + " " + scope.opt.lastName,
        context: "User",
      };
    } else {
      return {
        id: i18n.global.t(`middleOffice.decision.profil.${scope.opt.value}`),
        context: "Group",
      };
    }
  }
}
