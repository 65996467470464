import store from "@/store";
import { DownloadRequest, ElectronicSignKeys, FileResponse, MessageToSend, MessageToSendEnum, Signature_Doc_Type, Template } from "@/types";
import { deepClone } from "@/utils";
import mock from '@/store/services/document/mock/generatedFileMock.json'
import Notify from 'quasar/src/plugins/Notify.js';;
import i18n from "@/i18n";
import { settings } from "@/settings";
import { PDFDocument } from "pdf-lib";

/**
 * Sends a message and handles the response by updating the task offer status.
 * 
 * @param {MessageToSend} messageToSend - The message to be sent.
 */
export const sentMessage = (messageTosend: any) => {
    store.dispatch("taskModule/printOfferModule/sendMessage", {
        messageTosend,
        callback: (res: any) => {
            const clone = deepClone(res);
            store.dispatch("taskModule/setTaskOfferStatus", { data: clone.data[0], context: "print" });
            if (messageTosend.messageName === MessageToSendEnum.SIGNATURE_PROCESS_MESSAGE) {
                Notify.create({
                    timeout: 10000,
                    actions: [{ icon: 'close', color: 'white' }],
                    message: i18n.global.t(`task.actions.${messageTosend.processVariables.signatureType.value}_${messageTosend.processVariables.currentAction.value}`),
                    color: 'positive'
                });
            }
           
            store.state.printDocumentModule!.isLoading = false;
        }
    });
};

/**
 * Retrieves recipient information from the provided data.
 * 
 * @param {any} data - The data containing recipient information.
 * @returns {any[]} - The list of recipients with role code 'CUSTOMER'.
 */
const getRecipient = (data: any) => {
    const customers: any = [];
    data.forEach((item: any) => {
        if (item.role_code === "CUSTOMER") {
            customers.push(item);
        }
    });
    return customers;
};

/**
 * Sends an electronic message.
 * 
 * @param {any} message - The message to be sent.
 */
const sendElectronicMessage = async (response: any, message: any) => {
    const transactionID = response.data.id;
    let messageObj = deepClone(message);
    messageObj.processVariables.transactionId = {
        value: transactionID,
        type: "String"
    }
    sentMessage(messageObj);
};


const buildDocRecipient = (recipients: any, signatureTag: string) => {
    let recipient: any = null;
    let phoneContact = null;
    let emailContact = null;
    let phoneNumber = null;
    let email = null;
    let fullName = null
    if (signatureTag === "$LOC$") {
        recipient = recipients.find((item: any) => item.associatedParty.role_code === "CUSTOMER")
    }
    else if (signatureTag.includes("$COLOC_")) {
        recipient = recipients.filter((item: any) => item.associatedParty.role_code === "COLOCATAIRE")[Number(signatureTag.split("_")[1].split('$')[0]) - 1]
    }

    if (signatureTag !== "$SERVER_STAMP$" && recipient !== null) {
        // phoneContact = recipient.associatedParty.third.contacts.find((item: any) => item.contactMechanism.type.id === "PHONE_CONTACT");
        emailContact = recipient.associatedParty.third.contacts.find((item: any) => item.contactMechanism.type.id === "EMAIL_CONTACT");

        // if (!phoneContact || !phoneContact.contactMechanism.phoneNumber) {
        //     Notify.create({
        //         timeout: 20000,
        //         actions: [{ icon: 'close', color: 'white' }],
        //         color: "negative",
        //         message: i18n.global.t("main.missingRecipientPhoneNumber"),

        //     });
        //     throw new Error('Phone number is missing or empty.');

        // }

        if (!emailContact || !emailContact.contactMechanism.emailAddress) {
            Notify.create({
                actions: [{ icon: 'close', color: 'white' }],
                color: "negative",
                message: i18n.global.t("main.missingRecipientEmail"),
                timeout: 20000,

            });
            throw new Error('Email address is missing or empty.');
        }

        // phoneNumber = phoneContact.contactMechanism.phoneNumber;
        email = emailContact.contactMechanism.emailAddress;
        fullName = recipient.associatedParty.third.firstName + ' ' + recipient.associatedParty.third.familyName
    }

    return {
        phoneNumber,
        email,
        fullName
    }
}

/**
 * Extracts signature information from metadata for a given recipient.
 * 
 * @param {any} response - The response containing metadata.
 * @param {any} recipient - The recipient for whom signatures are to be generated.
 * @returns {any[]} - The list of signature payloads or an error message if any required information is missing.
 */
export const getSignaturesFromMetaData = (response: any, recipients: any, originalDoc: any) => {
    let UniversignSignaturesPayload = []
    if (originalDoc.documentType !== Signature_Doc_Type.VISA) {
        const ElectronicSignPosition = response.metadata.filter((item: any) => item.key === ElectronicSignKeys.ELEC_SIGN_POSITION);
        UniversignSignaturesPayload = (ElectronicSignPosition as any[]).map((position: any) => {
                const value = JSON.parse(position.value);
                if (value.signatureTag === "$SERVER_STAMP$") {
                    return {
                        page: value.page,
                        posX: value.x,
                        posY: value.y,
                        id: settings.serverEmail,
                        name: settings.serverFullName
                    }
                } else {
                    return {
                        page: value.page,
                        posX: value.x,
                        posY: value.y,
                        fullName: buildDocRecipient(recipients, value.signatureTag).fullName,
                        // phoneNumber: buildDocRecipient(recipients, value.signatureTag).phoneNumber,
                        email: buildDocRecipient(recipients, value.signatureTag).email,
                        invitationSubject: ElectronicSignKeys.invitationSubject,
                        invitationMessage: ElectronicSignKeys.invitationMessage,
                        typeField: originalDoc.documentType || Signature_Doc_Type.SIGNATURE
                    }
                }
            
        }) ?? [];
    }

    else {
        UniversignSignaturesPayload.push({
            page: 1,
            posX: 1,
            posY: 1,
            fullName: buildDocRecipient(recipients, "$LOC$").fullName,
            // phoneNumber: buildDocRecipient(recipients, value.signatureTag).phoneNumber,
            email: buildDocRecipient(recipients, "$LOC$").email,
            invitationSubject: ElectronicSignKeys.invitationSubject,
            invitationMessage: ElectronicSignKeys.invitationMessage,
            typeField: originalDoc.documentType
        })
    }
    return UniversignSignaturesPayload;
};



/**
 * Initializes the electronic signing process.
 * 
 * @param {any} currentRoute - The current route in the application.
 * @param {any} selectedTemplates - The selected templates for document generation.
 * @param {any} message - The message to be sent after generating the document.
 */
export const initElectronicSign = async (currentRoute: any, selectedTemplates: any, message: any, sendDoc?: boolean, emailSendList?:any) => {
    store.state.printDocumentModule!.isLoading = true;
    const fileList = store.getters['templateModule/getSelectedTemplates']
        .filter((template: string) => {
            const templates = store.getters['templateModule/getTemplates'];
            return templates.some((t: Template) => t.processId === template && t.signatureType === store.state.taskModule.selectedTask.variables.SignatureMethod);
        }).sort((a:any, b:any) => {
            const templateA = store.getters['templateModule/getTemplates'].find((t:any) => t.processId === a);
            const templateB = store.getters['templateModule/getTemplates'].find((t:any) => t.processId === b);
            return (templateA?.documentOrder || 0) - (templateB?.documentOrder || 0);
        });;
   const selectedFilesToSign = store.getters['templateModule/getTemplates'].filter((template: Template) => fileList.includes(template.processId))
    downloadAllFiles(selectedFilesToSign.map((item: Template) => {
        return item.fileId
    }), selectedFilesToSign, message, sendDoc, emailSendList)

};


// /**
//  * Initiates the file download process and proceeds with the e-sign request.
//  *
//  * @param {any} message - The message to be sent after downloading the file.
//  */
// const downloadFile = (message: any) => {
//     store.dispatch("printDocumentModule/downloadFile", {
//         message: message,
//         callback: EsignRequest,
//     });
// };



/**
 * Prepares an e-sign request and initiates the e-signature process.
 * 
 * @param {any} response - The response containing document and metadata.
 * @param {any} message - The message to be sent.
 */
const EsignRequest = (responses: any, selectedFilesToSign: any, message: any) => {
    if (store.state.taskModule.offer) {
        let eSignRequest;
        let documentIds = [];
        let documents = [];
        const { resourceUid, associatedParties } = store.state.taskModule.offer;
        const assignee = store.state.taskModule.selectedTask.assignee?.includes(' ## ') ?
            store.state.taskModule.selectedTask.assignee.substring(0, store.state.taskModule.selectedTask.assignee.indexOf(' ## ')) :
            store.state.taskModule.selectedTask.assignee;
        
        for (const response of responses) {
            const originalDoc = selectedFilesToSign.filter((item: any) => item.fileId === response.resourceUid)[0]
            const metaData: any = getSignaturesFromMetaData(response, associatedParties, originalDoc);
            const signatures: any = metaData.filter((item: any) => item.email) ?? []
            const stamps: any = metaData.filter((item: any) => item.id)
            
            documentIds.push({
                "fileId": originalDoc.fileId,
                "fileName": originalDoc.fileName,
            })
            documents.push(
                {
                    "content": response.content,
                    "name": originalDoc.fileName,
                    "consent": "I Agree !!!",
                    "stamps": stamps,
                    "signatures": signatures
                }
            )
        }
    
        const signatureRequest = {
            "documentIds": documentIds,
            "esignTransaction": {
                "autostart": true,
                "language": "en",
                "duration": 15500,
                "senderName": assignee,
                "entity": "offer",
                "entityId": resourceUid,
                "documents": documents
            },

        }
        store.dispatch("printDocumentModule/initEsign", {
            request: signatureRequest,
            message: message,
            callback: sendElectronicMessage,
        });
    }
};

function sendResponse() {
    store.state.printDocumentModule!.isLoading = false; 
}
async function downloadAllFiles(fileIds: string[], selectedFilesToSign: any, message: any, sendDoc?: boolean, emailSendList?:any): Promise <void> {
    try {
        // Download all files in parallel
        const downloadPromises = fileIds.map(fileId =>
            downloadSingleFile({ resourceUid: fileId })
        );

        const responses = await Promise.all(downloadPromises);
        if (!sendDoc) {
            EsignRequest(responses, selectedFilesToSign, message)
        }
        else {
            store.dispatch("printDocumentModule/sendDocument", {
                emailSendList: emailSendList,
                responses: responses,
                selectedFilesToSign: selectedFilesToSign,
                callback: sendResponse,
            });
        }
        
       
    } catch(error) {
        console.error('Error in downloadAndCombineFiles:', error);
        throw new Error('Failed to download and combine files');
    }
}

    /**
     * Downloads a single file
     * @param request Download request parameters
     * @returns Promise<FileResponse>
     */
async function downloadSingleFile(request: DownloadRequest): Promise < FileResponse > {
    return new Promise((resolve, reject) => {
        store.dispatch("printDocumentModule/downloadFileGeneratedFile", {
            request,
            callback: (response: FileResponse) => {
                if (response) {
                    resolve(response);
                } else {
                    reject(new Error('Download failed'));
                }
            },
        });
    });
}