import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card-title flex-grow-1 justify-content-between align-items-center mb-0" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { class: "font-semi-bold font-size-12" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = { class: "p-2 bg-white default-border-radius mb-3" }
const _hoisted_6 = { class: "d-flex" }
const _hoisted_7 = { class: "flex-grow-1" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = ["id"]
const _hoisted_10 = { class: "d-block mb-0 small text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_form = _resolveComponent("q-form")!

  return (_openBlock(), _createBlock(_component_q_form, null, {
    default: _withCtx(() => [
      _createVNode(_component_q_list, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (categoryQuestions, categoryKey) => {
            return (_openBlock(), _createElementBlock("div", { key: categoryKey }, [
              _createVNode(_component_q_expansion_item, {
                "switch-toggle-side": "",
                "default-opened": "",
                "header-class": "card-header bg-transparent no-border p-4",
                class: "card tw-items-dashed-group tw-items-group-with-bg tw-level-two-group",
                onShow: _cache[0] || (_cache[0] = () => {}),
                onHide: _cache[1] || (_cache[1] = () => {})
              }, {
                header: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("" + categoryKey)), 1)
                    ])
                  ])
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("div", _hoisted_8, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(categoryQuestions, (question) => {
                              return (_openBlock(), _createElementBlock("div", {
                                id: `task_detail_study_questionnaire_${categoryKey}_${question.question_i18n_code}`,
                                class: "col-lg-4 col-md-4 col-sm-12 form-control no-border",
                                key: question.question_i18n_code
                              }, [
                                _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t(question.question_i18n_code)), 1),
                                _createVNode(_component_q_select, {
                                  "data-testid": `task_detail_study_questionnaire_${categoryKey}_${question.question_i18n_code}`,
                                  dense: "",
                                  outlined: "",
                                  options: question.answerOptions,
                                  "option-label": (opt) =>
                        'answer_option_i18n_code' in opt
                          ? _ctx.$t(opt.answer_option_i18n_code)
                          : null
                        ,
                                  modelValue: _ctx.answersState[question.question_i18n_code],
                                  "onUpdate:modelValue": ($event: any) => ((_ctx.answersState[question.question_i18n_code]) = $event),
                                  disable: _ctx.reCalculation === false
                                }, null, 8, ["data-testid", "options", "option-label", "modelValue", "onUpdate:modelValue", "disable"])
                              ], 8, _hoisted_9))
                            }), 128))
                          ])
                        ])
                      ])
                    ])
                  ])
                ]),
                _: 2
              }, 1024)
            ]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}