
import {Options, Vue} from "vue-class-component";
import {globalConfig} from "@/utils";
import {IGuaranteeProduct, ProposalGuarantee} from "@/types";
import i18n from "@/i18n";
import Notify from 'quasar/src/plugins/Notify.js';;
import store from "@/store";
import GuaranteeDetail from "./GuaranteeDetail.vue";
import GuaranteeSearchDialog from "./dialog/GuaranteeSearchDialog.vue";
import Dialog from "@/components/general/Dialog.vue";

@Options({
  components: {
    GuaranteeDetail,
    Dialog
  }
})
export default class GuaranteeTab extends Vue {
  showDialog?: boolean = false;
  componentToRender = GuaranteeSearchDialog;

  openDialog() {
    this.showDialog = true;
  }

  onSaveGuarantee() {

  }

  closeProductGuaranteeDialog() {
    this.showDialog = false;
  }

  /**
   * Retrieves the columns of the table.
   *
   * @return {any} The columns of the table.
   */
  get columns(): any {
    return globalConfig.table_config.guaranteeCols;
  }

  /**
   * Retrieves the rows of proposalGuarantees.
   *
   * @return {Array<ProposalGuarantee>} The array of guarantee rows.
   */
  get rows(): Array<ProposalGuarantee> {
    return this.proposalGuarantees;
  }

  /**
   * Edits a row.
   *
   * @param {IGuaranteeProduct} row - The row to be edited.
   * @param rowIndex
   */
  edit(row: ProposalGuarantee, rowIndex: number): void {
    row.index = rowIndex;
    store.dispatch("financingModule/proposalGuaranteeModule/showProposalGuaranteeDetail");
    store.dispatch('financingModule/proposalGuaranteeModule/editProposalGuarantee', row);
  }

  addNewProposalGuarantee() {
    this.closeProductGuaranteeDialog();
    const productGuaranteeSelected = store.getters["financingModule/proposalGuaranteeModule/getProductGuaranteeSelected"];
    store.dispatch("financingModule/proposalGuaranteeModule/initProposalGuarantee", {
      productGuarantee: productGuaranteeSelected,
      proposalGuaranteeIndex: this.proposalGuarantees.length
    });
  }

  addProposalGuarantee() {
    let index = this.proposalGuaranteeSelected.index;
    if (index > -1) {
      this.proposalGuarantees[index] = this.proposalGuaranteeSelected;
    }
    else {
      this.proposalGuaranteeSelected.index = this.proposalGuarantees.length;
      this.proposalGuarantees.push(this.proposalGuaranteeSelected);
    }
  }

  /**
   * Removes an item at the specified rowIndex.
   *
   * @param row
   * @param {number} rowIndex - The rowIndex of the item to remove.
   * @return {void} This function does not return a value.
   */
  remove(row: ProposalGuarantee, rowIndex: number): void {
    Notify.create({
      timeout:0,
      message: i18n.global.t("guarantees.messages.delete", { label: (row.productGuarantee as IGuaranteeProduct)?.name }),
      color: "white",
      textColor: "black",
      position: "center",
      progress: false,
      actions: [
      { icon: 'close', color: 'white' },
        {
          label: 'Cancel', color: 'grey', handler: () => {/* ... */ }
        },
        {
          label: 'Confirm', color: 'primary', handler: () => {
            store.dispatch('financingModule/proposalGuaranteeModule/deleteProposalGuarantee', rowIndex);
          }
        }
      ]
    });
  }

  /**
   * Get the proposalGuarantees.
   *
   * @return {Array<ProposalGuarantee>} The proposalGuarantees.
   */
  get proposalGuarantees(): Array<ProposalGuarantee> {
    return store.getters["financingModule/proposalGuaranteeModule/getProposalGuarantees"];

  }

  get proposalGuaranteeSelected(): ProposalGuarantee {
    return store.getters["financingModule/proposalGuaranteeModule/getProposalGuaranteeSelected"];
  }

  get showDetailGuarantee() {
    return store.state.financingModule.proposalGuaranteeModule?.openGuarantee;
  }

  closeScaleDetail() {
    store.dispatch("showConfirmationDialog", {
      message: i18n.global.t("main.confirmationDialog.message"),
      confirmation: true
    })
  }
}
