
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import { FileContent } from "@/types";
import { UON } from "@/utils";
import store from "@/store";
import Notify from 'quasar/src/plugins/Notify.js';;
import moment from "moment/moment";
import uploadDocumentModule from "@/store/modules/task/actions/uploadDocumentModule";
import i18n from "@/i18n";

@Options({
  methods: {
    clearUploader() {
      this.$refs.uploader.reset();
      this.files.splice(0, 1);
    },
  },
})
export default class UploadPanel extends Vue {
  //TODO All ts must to be implemented on integration ticket 35783
  filter: any = ref("");
  ListGatheringProcessAssociated = [] as any;
  files: any[] = [];
  listRequeredDocument = [] as any;
  options = ["Reçu", "Validé", "Refusé"];
  clearUploader: any;
  documentType: any = ref(null);
  documentTypeResourceUid: any = ref(null);
  expandedUploadPanel: boolean = false;
  get getFile() {
    return uploadDocumentModule.state.files
  }
  // TODO: both methods need to be adapted when have more than one file
  addFile(file: any) {
    this.files.push(file[0]);
  }
  onDocumentType(documentType: any) {
    this.documentTypeResourceUid = documentType;
  }

  get getGatheringProcessRows() {
    return store.state.taskModule.gatheringProcess;
  }
  isPdfFile(file: any) {
    return file.type === "application/pdf";
  }
  createObjectURL(file: any) {
    return window.URL.createObjectURL(file);
  }
  isLoadingDoc = false;
  uploadDoc() {
    let processid: any[] = [];
    for (const GatheringProcessRow of this.getGatheringProcessRows) {
      const row = GatheringProcessRow.filter(
        (item: any) =>
          item.documentType === this.documentTypeResourceUid &&
          item.status.includes("Val")
      );
      if (row.length > 0) {
        processid.push(row.processResourceUid);
      }
    }

    if (this.documentTypeResourceUid && this.files.length > 0) {
      this.isLoadingDoc = true;
      this.files.forEach((file) => {
        this.getBase64(file).then((base64) => {
          const fileContent: FileContent = {
            originalFileName: file.name,
            format: file.type,
            content: base64,
            relations: {
              items: [
                {
                  qualifier: {
                    rawValue: this.documentTypeResourceUid,
                  },
                  objectUON: {
                    objectType: "odm.filemngt.filecontent",
                    systemUid: UON.SystemUid.odmFileManagement,
                  },
                },
              ],
            },
          };

          this.ListGatheringProcessAssociated.forEach(
            (gatheringProcessAssociated: any) => {
              if (
                !processid.includes(
                  gatheringProcessAssociated.gatheringProcess.resourceUid
                )
              ) {
                store.dispatch("taskModule/uploadDocumentModule/uploadFile", {
                  request: fileContent,
                  documentType: this.documentTypeResourceUid,
                  processid: gatheringProcessAssociated.gatheringProcess.resourceUid,
                  callback: this.handleDocumentResponse,
                });
              }
            }
          );
        });
      });
    } else {
      Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
        color: "negative",
        message: "Erreur! Veuillez choisir un type de document.",
      });
    }
  }

  handleDocumentResponse(response: FileContent) {
    let nbrReceivedItems = 0;

    for (const GatheringProcessRow of this.getGatheringProcessRows) {
      for (const rows of GatheringProcessRow) {
        // const reg = rows.filter((row:any) => row.id === Number(response.relations.items[0].qualifier.rawValue || 0))[0]
        if (rows.documentType === response.relations?.items[0].qualifier.rawValue) {
          rows.creationDate = moment(new Date()).format("DD-MM-YYYY HH:mm:ss");
          rows.document = response;
          rows.status = i18n.global.t(
            `task.uploadDocument.headers.supportingDocumentStatus.QUALIFIED`
          );
          rows.comment = response.originalFileName;
        }
        if (
          rows.status ===
          i18n.global.t(`task.uploadDocument.headers.supportingDocumentStatus.QUALIFIED`)
        ) {
          nbrReceivedItems++;
        }
      }
      GatheringProcessRow.nbrReceiveditems =
        nbrReceivedItems + "/" + GatheringProcessRow.length;
    }
    this.clearUploader();
    this.isLoadingDoc = false;
  }
  //construct  drag start event with file index from the local uploaded list + the file name
  drag(event: any, indexFile: any) {
    const file = this.files[indexFile];
    event.dataTransfer.dropEffect = "copy";
    event.dataTransfer.setData("fileIndex", indexFile);
    event.dataTransfer.setData("originalFileName", file.name);
  }
  getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const resp = reader.result as string;
        resolve(resp.split("base64,")[1]);
      };
      reader.onerror = (error) => reject(error);
    });
  }

  get getTaskEntityId() {
    return store.state.taskModule.offer?.resourceUid;
  }

  updateDocument(res: any) {
    this.ListGatheringProcessAssociated = res;
    for (const GatheringProcess of this.ListGatheringProcessAssociated) {
      store.dispatch("taskModule/uploadDocumentModule/getRequiredDocument", {
        callback: this.requiredDocument,
        request: {
          resourceUid: GatheringProcess.gatheringProcess.resourceUid,
        },
      });
    }
  }
  remove(i: any) {
    this.files.splice(i, 1);
  }

  dragover(event: any) {
    event.preventDefault();
    // Add some visual fluff to show the user can drop its files
    if (!event.currentTarget.classList.contains("bg-green-300")) {
      event.currentTarget.classList.remove("bg-gray-100");
      event.currentTarget.classList.add("bg-green-300");
    }
  }

  dragleave(event: any) {
    // Clean up
    event.currentTarget.classList.add("bg-gray-100");
    event.currentTarget.classList.remove("bg-green-300");
  }

  drop(event: any) {
    event.preventDefault();
    const originalFileName = event.dataTransfer.getData("originalFileName");
    if (!originalFileName) {
      const files = event.dataTransfer.files;
      this.onChange(event);
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    }
  }
  onChange(event: any) {
    let files;
    if (event.type === "change") {
      files = event.target.files;
    }
    if (event.type === "drop") {
      files = event.dataTransfer.files;
    }
    [...files].forEach((f) => {
      this.files.push(f);
    });
    //this.files = [...files];
    store.dispatch("taskModule/uploadDocumentModule/uploadTempFiles", this.files)
  }
  requiredDocument(res: any) {
    this.listRequeredDocument.push.apply(this.listRequeredDocument, res);

    let unique = [] as any;
    this.listRequeredDocument.forEach((element: any) => {
      if (unique.length == 0) {
        unique.push(element);
      } else {
        let boolean = false;
        unique.forEach((uniq: any) => {
          if (uniq.resourceUid == element.resourceUid) {
            boolean = true;
          }
        });
        if (!boolean) {
          unique.push(element);
        }
      }
    });

    this.listRequeredDocument = unique;
  }
  updated() {
    this.$emit('update')
  }
   

  created() {
    store.dispatch("taskModule/uploadDocumentModule/getOfferDocument", {
      callback: this.updateDocument,
      request: {
        variable: this.getTaskEntityId,
      },
    });
  }
}
