import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DemandEdit = _resolveComponent("DemandEdit")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card-body", _ctx.disable ? 'tw-disabled-components-wrapper' : ''])
  }, [
    _createVNode(_component_DemandEdit, {
      onUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleUpdateEvent('analysis-task', 'insert' ,_ctx.$router)))
    })
  ], 2))
}